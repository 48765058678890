import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DomSanitizer } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { alertAttributes } from 'src/app/helper/appAlert';
import { IBrowseTableSettings, IDataBlock } from 'src/app/helper/appInterfaces';
import { appParam } from 'src/app/helper/appSettings';
import { appUtils } from 'src/app/helper/appUtils';
import { ApiService } from 'src/app/services/api.service';
import { AppService } from 'src/app/services/app.service';
import { AuthService } from 'src/app/services/auth.service';

export interface TableRow {
  id: string; // used for the checkbox column
  batch_id: string;
  device: string;
  method: string;
  batch_refund: string;
  batch_status: string;
  total_qty: number;
  bin_code: string;
  aba_batch: string;
  customer_id: string;
  selected: boolean;
  context_menu: string;
}

let ELEMENT_DATA: TableRow[] = [];

@Component({
  selector: 'app-uxa11001',
  templateUrl: './uxa11001.component.html',
  styleUrls: ['./uxa11001.component.scss']
})
export class Uxa11001Component implements OnInit {
  _style: string = '';
  _defaultDateFormat: string = appParam.defaultDateFormat;

  _route_NewDriver: string = '../' + appParam.routing.A7_driverManagement.A7001a;

  _currentContext: string[] = ['']; // used to store the selected bin(s)

  abaDownloadFile: any = '';
  abaFilename: any = '';

  //  table settings
  public _browseTableSettings: IBrowseTableSettings = {
    active: "onboard_date",
    direction: "desc",
    pageIndex: 0,
    pageSize: 15,
  };

  constructor(
    private appService: AppService,
    private apiService: ApiService,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer
  ) {
    this._style = this.authService.getStyle()
  }

  ngOnInit(): void {
    this.getData(0, 10)
  }

  //  used to control the pagination with the back-end
  _pageSize = 5;
  public _dataBlocks: IDataBlock[] = [
    {
      label: "0-10",
      from: 0,
      size: this._pageSize,
      class: "btnPaginator",
    },
  ];
  _currentBlock: string = "1-10";

  //  columns to be displayed in the table
  displayedColumns: string[] = [
    "id",
    "batch_id",
    "device",
    "method",
    "batch_refund",
    "batch_status",
    "total_qty",
    "bin_code",
    "aba_batch",
    "context_menu"
  ];

  dataSource = new MatTableDataSource<TableRow>(ELEMENT_DATA);

  tableSortField: string;
  tableSortDirection: string;
  tablePageSize: number = 10;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  // @ViewChild("searchInput") input: ElementRef;

  //  get data from API
  async getData(from: number, size: number) {
    this.appService.appSpinner(true);

    //  get the bins from the db
    let _data = await this.apiService.getRecordsForABA();
    // console.log('_data', _data)

    await this.updatePages(_data.length);

    //  update the array for the table
    this.dataSource.data = [];
    try {

      if (_data.length > 0) {
        for (let _row of _data) {
          if (_row['code'] != '9999999-EOF') this.dataSource.data.push({
            id: _row['id'],
            batch_id: _row['code'],
            device: _row['device'],
            method: _row['method'],
            batch_refund: _row['batch_refund'],
            batch_status: _row['batch_status'],
            total_qty: _row['total_qty'],
            bin_code: _row['refBin'] != undefined ? _row['refBin']['code'] : '-',
            customer_id: _row['customer_id'],
            aba_batch: _row['aba_batch'],
            context_menu: '...',
            selected: false
          });
        }
      }

    } catch (err) {
      console.log("err", err);
    }

    this.paginator.pageSize = this.tablePageSize;
    this.paginator.pageSize = this._browseTableSettings.pageSize;
    this.paginator.pageIndex = this._browseTableSettings.pageIndex;

    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    this.tableSortField = this._browseTableSettings.active;
    this.tableSortDirection = this._browseTableSettings.direction;

    this.appService.appSpinner(false)
  }


  //  when the user clicks on a column to sort
  sortEvent(event: any) {
    // console.log('sort', event)
    this._browseTableSettings = {
      active: event.active,
      direction: event.direction === "" ? "asc" : "desc",
      pageIndex: this._browseTableSettings.pageIndex,
      pageSize: this._browseTableSettings.pageSize,
    };
  }


  openDoc(event: any) {
    // console.log("openDoc", event);
  }

  //  setup the current displayed page
  updatePages(hits: number) {
    this._dataBlocks = [];

    let isCurrentPage = "btnPaginatorActive";

    if (hits < this._pageSize) {
      this._dataBlocks.push({
        label: "".concat("1", " - ", hits.toString()),
        from: 1,
        size: this._pageSize,
        class: isCurrentPage,
      });

      return true;

    } else {
      for (let i = 0; i < hits; i += this._pageSize) {
        this._dataBlocks.push({
          label: "".concat(
            (i + 1).toString(),
            "-",
            (i + this._pageSize).toString()
          ),
          from: i,
          size: this._pageSize,
          class: isCurrentPage,
        });
        if (i > hits) {
          return true;
        }

        isCurrentPage = "btnPaginator";
      }
    }

    return true
  }


  // controls pagination
  async loadPage(event: any) {
    this._currentBlock = event.value;
    let blockStart = 0;

    await this._dataBlocks.forEach((_block) => {
      if (_block.label == event.value) {
        blockStart = _block.from;
      }
    });

    this.getData(blockStart, this._pageSize);
  }

  paginatorEvent(event: any) {
    this._browseTableSettings = {
      active: this._browseTableSettings.active,
      direction: this._browseTableSettings.direction,
      pageIndex: event.pageIndex,
      pageSize: event.pageSize,
    };

  }


  contextClick(event: any) {
    this._currentContext[0] = event
    // console.log('contextClick', event, this._currentContext)
  }


  async checkboxClick(event: any) {
    //  get selected item from array
    const res = await appUtils.searchInArray(this.dataSource.data, 'id', event)
    //  toggle the selected attribute
    res.selected = !res.selected
  }


  async generateABA() {
    let selectedRecords = await this.dataSource.data.filter(i => i.selected === true);
    // console.log('selectedRecords', selectedRecords)

    this.appService.appSpinner(true)

    if (selectedRecords.length > 0) {
      const res = await this.apiService.generateABAFile(1)
      // console.log('ABA result:', res)

      // create the file for download
      let data = res['data'].join("\r\n");
      const blob = new Blob([data], { type: 'text/csv' });

      this.abaDownloadFile = this.sanitizer.
        bypassSecurityTrustResourceUrl(window.URL.createObjectURL(blob))
      this.abaFilename = res['batchId'] + '.csv'

      if (res != undefined) {
        this.appService.appSpinner(false)

        this.appService.sendNotification({
          type: alertAttributes.types.info,
          message: alertAttributes.alerts.A056,
          body: '',
          displayNotification: true
        })

        this.getData(0, 10)
      }

    } else {

      this.appService.appSpinner(false)
      this.appService.sendNotification({
        type: alertAttributes.types.warning,
        message: 'Please check atleast one record',
        body: '',
        displayNotification: true
      })

    }

    this.appService.appSpinner(false)
  }


  async downloadABA(batch_id: any) {
    const res = await this.apiService.getABAFile(batch_id)
    if (res.length > 0) {
      const blob = new Blob([res[0]['aba_file']], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob);
      window.open(url);

    } else {
      this.appService.sendNotification({
        type: alertAttributes.types.error,
        message: 'Cannot find the selected ABA file',
        body: '',
        displayNotification: true
      })

    }
  }

}
