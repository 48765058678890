import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-uxa3002',
  templateUrl: './uxa3002.component.html',
  styleUrls: ['./uxa3002.component.scss']
})
export class Uxa3002Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
