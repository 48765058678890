import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppService } from "../../services/app.service";
import { ApiService } from "../../services/api.service";
import { appUtils } from "../../helper/appUtils";
import { MatTableDataSource } from '@angular/material/table';
import { alertAttributes } from "../../helper/appAlert";
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

export interface History {
  desc: string;
  date: string;
  value: number;
}

let ELEMENT_DATA: History[] = [];

@Component({
  selector: 'app-uxa9002g',
  templateUrl: './uxa9002g.component.html',
  styleUrls: ['./uxa9002g.component.scss']
})
export class Uxa9002gComponent implements OnInit {
  subscriptionTypeItems: any[] = [];
  selectedSubscription: string = '';
  selectedSubObj: any;

  charityItems: any[] = [];
  selectedCharity: string = '';
  selectedCharityObj: any;

  frmGroup: FormGroup;
  charityControl = new FormControl('', Validators.required)

  BSB: any[] = [];
  bankName: string = '';

  reimburseCustomer: boolean = false;
  tcAccepted: boolean = false;

  successfulPayment: boolean = false;
  disableCancel: boolean = false;

  displayedColumn: string[] = ['desc', 'date', 'value']
  dataSource = new MatTableDataSource<History>(ELEMENT_DATA);

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      id: string,
      username: string,
      oldPlanDesc: string,
      oldPlan: any,
      customerName: string,
      accountNumber: string,
      accountName: string,
      accountBSB: string,
      bankName: string
    },
    private dialogRef: MatDialogRef<Uxa9002gComponent>,
    private appService: AppService,
    private apiService: ApiService,
    private fg: FormBuilder,
  ) { }

  ngOnInit(): void {
    this.frmGroup = this.fg.group({
      id: [this.data.id, Validators.required],
      username: [this.data.username, Validators.required],
      taxInvoiceEmail: [this.data.username, [Validators.pattern(/^\w+([\.\+-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/)]],
      accountName: [''],
      accountNumber: [''],
      accountBSB: ['', [Validators.pattern(/[0-9]{3}-[0-9]{3}/)]],
      bankName: [''],
      reimbursementAmount: [null, [Validators.pattern(/^\d+$/)]]
    });

    this.frmGroup.controls.accountBSB.valueChanges.subscribe((value) => {
      this.updateBankName(value);
    });

    this.getData()
  }

  async getData() {
    this.appService.appSpinner(true);
    const result = await Promise.all([
      this.apiService.getSubscriptionTypes(''),
      this.apiService.getPlanResetDetails(this.data.id),
      this.apiService.getCharities('', ''),
      this.apiService.getBSB(),
    ]);
    this.appService.appSpinner(false);

    this.subscriptionTypeItems = result[0];
    let _data = result[1];
    this.charityItems = result[2];
    this.BSB = result[3];

    //  update the array for the table
    this.dataSource.data = [];
    try {
      for (let _row of _data) {
        let date = new Date(_row['transaction_timestamp'])

        this.dataSource.data.push({
          desc: _row['transaction_desc'],
          date: date.toLocaleDateString('en-AU'),
          value: _row['amount']
        })
      }
    } catch (err) {
      console.log('err', err);
    }
  }

  async onChange(event) {
    this.selectedSubObj = await appUtils.searchInArray(this.subscriptionTypeItems, 'key', event.value)

    if (this.selectedSubObj.category == 'BASIC' || this.selectedSubObj.category == 'PREMIUM') {
      this.frmGroup.patchValue({
        accountName: this.data.accountName,
        accountNumber: this.data.accountNumber,
        accountBSB: this.data.accountBSB,
        bankName: this.data.bankName
      })
      this.frmGroup.controls['accountName'].setValidators([Validators.required]);
      this.frmGroup.controls['accountNumber'].setValidators([Validators.required]);
      this.frmGroup.controls['accountBSB'].setValidators([Validators.required]);
    } else {
      this.frmGroup.get('accountNumber').clearValidators();
      this.frmGroup.get('accountNumber').updateValueAndValidity();

      this.frmGroup.get('accountBSB').clearValidators();
      this.frmGroup.get('accountBSB').updateValueAndValidity();

      this.frmGroup.get('accountName').clearValidators();
      this.frmGroup.get('accountName').updateValueAndValidity();
    }
  }

  async paymentAttempted(event) {
    if (event == true) {
      this.successfulPayment = true;
      this.disableCancel = true;
    };
  }

  async onCharityChange(event) {
    this.selectedCharityObj = await appUtils.searchInArray(this.charityItems, 'key', event.value)
  }

  async updateBankName(_bsb: any) {
    let regex = /[0-9]{6}$/g
    if (regex.test(_bsb)) {
      this.frmGroup.patchValue({ accountBSB: _bsb.substring(0,3)+'-'+_bsb.substring(3,6)})
    }

    if (_bsb != null && _bsb.length == 7) {
      const res = await appUtils.searchInArray(this.BSB, 'bsb', _bsb);
      if (res) {
        this.frmGroup.patchValue({ bankName: res.bankCode + ' - ' + res.state });
      } else this.frmGroup.patchValue({ bankName: null });
    }
  }

  async save() {
    this.appService.appSpinner(true)

    let planId = this.selectedSubObj.key
    let planType = this.selectedSubObj.category

    // conditionally generate attributes
    let attributes
    if (planType == 'BASIC' || planType == 'PREMIUM') {
      let bsb = await appUtils.searchInArray(this.BSB, 'bsb', this.frmGroup.value.accountBSB)
      let attrCode = this.data.id + '-' + bsb.bsb + ':' + this.frmGroup.value.accountNumber

      attributes = {
        code: attrCode,
        account_name: this.frmGroup.value.accountName,
        account_number: this.frmGroup.value.accountNumber,
        refBSBId: bsb.id
      }
    } else {
      attributes = {
        taxInvoiceEmail: this.frmGroup.value.taxInvoiceEmail,
        refCharityId: this.selectedCharityObj.key
      }
    }

    try {
      let res = await this.apiService.updateCustomerToNewPlan(
        this.data.id, planId, this.frmGroup.value.reimbursementAmount, this.tcAccepted, attributes
      )

      if (res[0].statusid != 1) {
        this.appService.sendNotification({
          type: alertAttributes.types.warning,
          message: res[0].statusdesc,
          body: '',
          displayNotification: true,
          autoCloseAfter: 3
        });
        this.appService.appSpinner(false);
      } else {
        this.appService.sendNotification({
          type: alertAttributes.types.info,
          message: 'Customer has been updated!',
          body: '',
          displayNotification: true,
          autoCloseAfter: 2
        });
        this.appService.appSpinner(false);
        setTimeout(() => {
          this.dialogRef.close(true);
        }, 1500);
      }

    } catch (err) {
      console.log(err)
      this.appService.appSpinner(false)
    }
  }
}
