<div class="px-4 py-3">
  <div class="px-2 py-1">
    1. Download the CSV from the CDS Grafana.
  </div>

  <div class="px-2 py-1" fxLayout="row" fxLayoutAlign="space-between center">
    <div>
      2. Please select the CSV file downloaded from the CDS System
      <input
        #fileImportInput
        class="px-2"
        type="file"
        name="datasource_upload"
        id="datasource_upload"
        accept=".csv"
        ngf-max-size="20MB"
        fd-input
        (change)="fileChangeListener($event)"
      />
    </div>
    <button style="border-radius: 0.25em !important;" mat-raised-button color="primary" *ngIf="csvImport" (click)="clear()">Clear</button>
  </div>
  <div class="px-2 py-1">
    3. Click the upload button at the bottom of the page.
  </div>
  <div class="px-2 py-1">
    4. Wait for ABA file to be generated and automatically downloaded.
  </div>
  <div class="px-2 py-1">
    5. Uploaded the ABA file to your bank.
  </div>
</div>


<div class="px-4 pb-2">
  <mat-divider></mat-divider>
</div>

<div *ngIf="displayLoading" class="px-4 py-2">
  <div class="mat-h2">Generating ABA files now!</div>
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</div>


<div *ngIf="csvImport && !displayLoading" class="px-3 py-3">
  <span>Total rows in selected file: {{ rowCount }}</span>
</div>

<div *ngIf="!displayLoading">
  <div class="csvTable py-2">
    <table>
      <thead>
      <tr>
        <th class="px-2 py-1" *ngFor="let item of csvHeaders">{{ item }}</th>
      </tr>
      </thead>

      <tbody>
      <tr *ngFor="let element of csvRecords; let i = index">
        <td>{{ csvRecords[i][csvHeaders[0]] }}</td>
        <td>{{ csvRecords[i][csvHeaders[1]] }}</td>
        <td>{{ csvRecords[i][csvHeaders[2]] }}</td>
        <td>{{ csvRecords[i][csvHeaders[3]] }}</td>
        <td>{{ csvRecords[i][csvHeaders[4]] }}</td>
        <td>{{ csvRecords[i][csvHeaders[5]] }}</td>
      </tr>
      </tbody>
    </table>
  </div>

  <div *ngIf="csvImport" class="px-3 py-3">
    <button
      mat-raised-button
      id="save"
      class="d-block {{ this._style }}-small {{ this._style }}-primary mt-4"
      (click)="upload()"
      data-cy="upload"
    >
      Upload
    </button>
  </div>
</div>
