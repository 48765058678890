<div fxLayout="column" style="padding: 10px 10px 10px 10px">
  <div fxLayout="row" fxLayoutAlign="center center">
    <h2 mat-dialog-title>Edit Service Day Limits - {{ depot }}</h2>
  </div>

  <div fxLayout="row" fxLayoutAlign="start center">
    <h3>Selected day: <b>{{ weekday }}</b></h3>
  </div>

  <div fxLayout="row" fxLayoutAlign="space-between center">
    <h3 style="padding-right: 20px;">Effective Date: </h3>
    <mat-form-field appearance="outline" fxFlex>
      <mat-label>Select a date</mat-label>
      <input matInput [matDatepicker]="picker" [formControl]="reqDate" (dateChange)="datePicked($event)" [min]="minDate" required>
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
  </div>

  <div class="mb-2" fxLayoutAlign="space-between center">
    <mat-form-field fxFlex>
      <input placeholder="New Dropoff Limit" matInput [(ngModel)]="newDropoffs">
    </mat-form-field>

    <mat-form-field fxFlex style="margin-left: 13px">
      <input placeholder="New Changeover Limit" matInput [(ngModel)]="newChangeovers">
    </mat-form-field>
  </div>

  <div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="center center">
    <button mat-raised-button color="primary" mat-dialog-close style="width: 25%">Cancel</button>
    <button mat-raised-button color="primary" (click)="save()" style="width: 25%" [disabled]="!reqDate.valid">Update</button>
  </div>
</div>
