import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { alertAttributes } from '../helper/appAlert';
import { IAppNotification } from '../helper/appInterfaces';
import { appParam } from '../helper/appSettings';

import { dummyOrders } from '../helper/temp'

@Injectable({
  providedIn: 'root'
})
export class AppService {

  //  store the user details for use throughout the application
  public UserObject = {
    userId: '',
    firstName: '',
    lastName: '',
    email: '',
    mobile: '',
    userConfirmed: false,
  }

  public manifest: any
  public manifestOrders: any[]

  constructor() { }


  private appNotification = new BehaviorSubject({} as IAppNotification);
  currentAppNotification = this.appNotification.asObservable();

  sendNotification(value: IAppNotification) {
    // console.log('notification ', value);

    // during admin sign-up process, the system pass customer details to the backend
    if (value.type == alertAttributes.types.workflowSteps.A1_signIn.A1001) {
      this.UserObject.userId = value.body['userId']
      this.UserObject.firstName = value.body["cognito:username"]
      this.UserObject.lastName = value.body['lastName']
      this.UserObject.email = value.body['email']
      this.UserObject.mobile = value.body['mobile']
    }

    this.appNotification.next(value);
  }


  //	application wide spinner
  //	------------------------------------------------------
  private appSpinnerStatus = new BehaviorSubject(false);
  currentAppSpinner = this.appSpinnerStatus.asObservable();

  appSpinner(value: boolean) {
    // console.log('spinner', value)
    this.appSpinnerStatus.next(value);
  }



  async updateCache(_key: any, _data: any) {
    try {
      return localStorage.setItem(_key, JSON.stringify(_data))

    } catch (e: any) {
      console.log(e.message)
    }
  }


  async getCache(_key: string) {
    return JSON.parse(localStorage.getItem(_key)!)
  }


  async clearCache() {
    for (let idx in appParam.cacheKeys) {
      localStorage.removeItem(idx);
    }
  }



}
