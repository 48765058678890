<div class="container-fluid py-2 w-100">
  <div class="row">
    <div class="col-12 m-0 p-0">
      <!--  -->

      <div class="container-fluid {{ this._style }}-main-panel py-2">
        <!-- Run date -->
        <div class="row p-0 py-1 byc-row-border-bottom">
          <div class="col-3 p-0">Run date</div>
          <div class="col-7 p-0">
            <app-date-picker
              *ngIf="this._canChangeRunDate"
              [_availableDate]="this.availableDates"
              [_selectedDate]="this._runDate"
              (mDatePicked)="updateRunDate($event)"
              data-cy="pub_from"
            >
            </app-date-picker>
            <span
              *ngIf="!this._canChangeRunDate"
              class="{{ this._style }}-text-bold"
              >{{ this._runDate | date: this._dateFormat }}</span
            >
          </div>

          <div class="col-2 px-1">
            <button
              mat-flat-button
              (click)="openManifest()"
              class="align-self-end"
            >
              <mat-icon>open_in_new</mat-icon>
            </button>
          </div>
        </div>

        <!-- Status and Stops -->
        <div class="row py-2">
          <div
            class="col-7 p-0 {{ this._style }} {{
              this._style
            }}"
          >
            Status: {{ _manifest["manifest_status"] }}
          </div>
          <div
            class="col-5 {{ this._style }} {{
              this._style
            }}"
          >
            {{ _manifest["stats_01"] }} Stops
          </div>
          <div class="p-0 mt-1" *ngIf="this._manifest['refDriver'] != null">
            <div class="{{this._style}}">Assigned driver: {{this._manifest['refDriver']['first_name']}} {{this._manifest['refDriver']['last_name']}}</div>
          </div>
        </div>

        <div class="row p-0 pt-1" *ngIf="this._canOptimize">
          <div class="my-2" fxLayout="row" fxLayoutGap="30px">
            <mat-checkbox [(ngModel)]="_editStart">Edit Start Address</mat-checkbox>
            <mat-checkbox [(ngModel)]="_editFinish">Edit Finish Address</mat-checkbox>
          </div>
          <ng-container *ngIf="_editStart">
            <div>Start Address</div>
            <app-address-picker id="startAddressPicker" (addressPicked)="addressPicked($event, true, false)"></app-address-picker>
          </ng-container>

          <ng-container *ngIf="_editFinish">
            <div>Finish Address</div>
            <app-address-picker id="finishAddressPicker" (addressPicked)="addressPicked($event, false, true)"></app-address-picker>
          </ng-container>
        </div>


        <!-- Optimize -->
        <div class="row p-0 py-3" *ngIf="this._canOptimize">
          <div class="col-12 p-0">
            <button
              mat-raised-button
              id="optimize"
              color="primary"
              (click)="optimize()"
              data-cy="optimize"
            >
              Optimize route
            </button>

            <button
              mat-raised-button
              id="skipOptimize"
              color="primary"
              (click)="skipOptimization()"
              data-cy="skipOptimize"
              class="mx-2"
            >
              Skip optimization
            </button>
          </div>
        </div>

        <!-- Reopen manifest -->
        <div class="row p-0 py-3" *ngIf="this._canReopen">
          <div class="col-12 p-0">
            <button
              mat-raised-button
              id="Reopen"
              color="primary"
              (click)="reopen()"
              data-cy="Reopen"
            >
              Reopen
            </button>
          </div>
        </div>

        <!-- Assign driver -->
        <div class="row py-3" *ngIf="this._canAssignDriver">
          <!--  -->
          <div class="col-12 p-0">
            <span>
              <mat-form-field>
                <mat-select
                  data-cy="driver"
                  placeholder="Assign driver"
                  (selectionChange)="driverSelected($event)"
                  [value]="this._selectedDriver"
                >
                  <mat-option
                    *ngFor="let driver of _drivers"
                    [value]="driver.id"
                    >{{ driver.full_name }}</mat-option
                  ></mat-select
                >
              </mat-form-field>
            </span>
            <span class="px-1">
              <button
                mat-raised-button
                id="assignDriver"
                color="primary"
                (click)="assignDriver()"
                data-cy="assignDriver"
                [disabled]="this._selectedDriver == ''"
              >
                Assign to driver
              </button>
            </span>
          </div>
        </div>

        <!-- Notes -->
        <div class="row pt-3 pb-1">
          <div class="col px-0">
            <span class="{{ this._style }}">Depot notes for driver:</span>

            <mat-form-field appearance="outline">
              <textarea
                matInput
                data-cy="depot_comments"
                row="2"
                [(ngModel)]="_depotNotes"
                [readonly]="!_canUpdateNotes"
              ></textarea>
            </mat-form-field>

            <div fxLayout="row" fxLayoutAlign="space-between center">
              <button
                *ngIf="_canUpdateNotes"
                mat-raised-button
                id="updateNotes"
                color="primary"
                (click)="updateNotes()"
                data-cy="updateNotes"
              >
                Update notes
              </button>
              <button *ngIf="_canDelete" mat-raised-button color="primary" (click)="sendMessage()">
                Send Reminder SMS
              </button>
              <button *ngIf="_canDelete" style="float: right;" mat-raised-button color="primary" (click)="deleteManifest()">
                Delete
              </button>
            </div>
          </div>
        </div>

        <!-- Stats -->
        <div
          class="row py-2 byc-manifest-stats-row"
          *ngIf="this._canDisplayStats"
        >
          <div class="col-4">
            <span>Orders</span>
          </div>
          <div class="col-4">
            <span>Drop-offs</span>
          </div>
          <div class="col-4">
            <span>Pick ups</span>
          </div>
          <div class="col-4">
            <span class="byc-manifest-stats">{{ _manifest["stats_01"] }}</span>
          </div>
          <div class="col-4">
            <span class="byc-manifest-stats">{{ _manifest["stats_02"] }}</span>
          </div>
          <div class="col-4">
            <span class="byc-manifest-stats">{{ _manifest["stats_03"] }}</span>
          </div>
        </div>
      </div>

      <!--  -->
    </div>
  </div>
</div>
