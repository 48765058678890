import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { appParam } from './helper/appSettings';

import { AuthGuard } from "./services/auth-guard.service";

import { Uxa1001Component } from './UX-A1/001/uxa1001.component';

import { Uxa2001Component } from './UX-A2/001/uxa2001.component';
import { Uxa2001aComponent } from './UX-A2/001a/uxa2001a.component';

import { Uxa3001Component } from './UX-A3/001/uxa3001.component';
import { Uxa3002Component } from './UX-A3/002/uxa3002.component';
import { Uxa3003Component } from './UX-A3/003/uxa3003.component';

import { Uxa6001Component } from './UX-A6/001/uxa6001.component';
import { Uxa6001aComponent } from './UX-A6/001a/uxa6001a.component';
import { Uxa6001cComponent } from './UX-A6/001c/uxa6001c.component';

import { Uxa7001Component } from './UX-A7/001/uxa7001.component';
import { Uxa7001aComponent } from './UX-A7/001a/uxa7001a.component';
import { Uxa7001cComponent } from './UX-A7/001c/uxa7001c.component';

import { Uxa8001Component } from './UX-A8/001/uxa8001.component';
import { Uxa8001aComponent } from './UX-A8/001a/uxa8001a.component';
import { Uxa8001bComponent } from './UX-A8/001b/uxa8001b.component';
import { Uxa8001cComponent } from './UX-A8/001c/uxa8001c.component';
import { Uxa8001dComponent } from './UX-A8/001d/uxa8001d.component';

import { Uxa9001Component } from './UX-A9/001/uxa9001.component';
import { Uxa9002Component } from './UX-A9/002/uxa9002.component';
import { Uxa9002cComponent } from './UX-A9/002c/uxa9002c.component';
import { Uxa9002dComponent } from './UX-A9/002d/uxa9002d.component';
import { Uxa9002eComponent } from './UX-A9/002e/uxa9002e.component';
import { Uxa9002fComponent } from './UX-A9/002f/uxa9002f.component';

import { Uxa9003Component } from './UX-A9/003/uxa9003.component';

import { Uxa10001Component } from './UX-A10/001/uxa10001.component';

import { Uxa11001Component } from './UX-A11/001/uxa11001.component';

import { Uxa12001Component } from './UX-A12/001/uxa12001.component';

import { Uxa13001Component } from "./UX-A13/001/uxa13001.component";

import { Uxa14001Component } from "./UX-A14/001/uxa14001.component";

import { Uxa000Component } from './000/uxa000.component';
import {Uxa15001Component} from "./UX-A15/001/uxa15001.component";

const routes: Routes = [
  { path: appParam.routing.A1_signIn.A1001, component: Uxa1001Component },

  {
    path: appParam.routing.A2_orderManagement.A2001, component: Uxa2001Component, canActivate: [AuthGuard],
    children: [
      { path: appParam.routing.A2_orderManagement.A2001a, component: Uxa2001aComponent },

      { path: appParam.routing.A3_manifestManagement.A3001, component: Uxa3001Component },
      { path: appParam.routing.A3_manifestManagement.A3002, component: Uxa3002Component },
      { path: appParam.routing.A3_manifestManagement.A3003, component: Uxa3003Component },


      { path: appParam.routing.A6_binManagement.A6001, component: Uxa6001Component },
      { path: appParam.routing.A6_binManagement.A6001a, component: Uxa6001aComponent },
      { path: appParam.routing.A6_binManagement.A6001c, component: Uxa6001cComponent },

      { path: appParam.routing.A7_driverManagement.A7001, component: Uxa7001Component },
      { path: appParam.routing.A7_driverManagement.A7001a, component: Uxa7001aComponent },
      { path: appParam.routing.A7_driverManagement.A7001c, component: Uxa7001cComponent },

      { path: appParam.routing.A8_announcementManagement.A8001, component: Uxa8001Component },
      { path: appParam.routing.A8_announcementManagement.A8001a, component: Uxa8001aComponent },
      { path: appParam.routing.A8_announcementManagement.A8001b, component: Uxa8001bComponent },
      { path: appParam.routing.A8_announcementManagement.A8001c, component: Uxa8001cComponent },
      { path: appParam.routing.A8_announcementManagement.A8001d, component: Uxa8001dComponent },

      { path: appParam.routing.A9_customerManagement.A9001, component: Uxa9001Component },
      { path: appParam.routing.A9_customerManagement.A9002, component: Uxa9002Component },
      { path: appParam.routing.A9_customerManagement.A9002c, component: Uxa9002cComponent },
      { path: appParam.routing.A9_customerManagement.A9002d, component: Uxa9002dComponent },
      { path: appParam.routing.A9_customerManagement.A9002e, component: Uxa9002eComponent },
      { path: appParam.routing.A9_customerManagement.A9002f, component: Uxa9002fComponent },

      { path: appParam.routing.A9_customerManagement.A9003, component: Uxa9003Component },

      { path: appParam.routing.A10_CDSManagement.A10001, component: Uxa10001Component },

      { path: appParam.routing.A11_ABAManagement.A11001, component: Uxa11001Component },

      { path: appParam.routing.A12_PublicHolidayManagement.A12001, component: Uxa12001Component },

      { path: appParam.routing.A13_DepotDayConfig.A13001, component: Uxa13001Component },

      { path: appParam.routing.A14_DepotDateExceptions.A14001, component: Uxa14001Component },

      { path: appParam.routing.A15_Plans.A15001, component: Uxa15001Component },

      { path: appParam.routing.A000, component: Uxa000Component },
    ]
  },

  { path: "", redirectTo: appParam.routing.A1_signIn.A1001, pathMatch: "full" }, // redirect to the default login page
  { path: "**", redirectTo: appParam.routing.A1_signIn.A1001, pathMatch: "full" }, // redirect to the default login page
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
// export const routingComponents = [
//   Uxa1001Component,
//   Uxa2001Component,
//   Uxa6001Component,
//   Uxa6001aComponent,
// ];
