import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';
import { alertAttributes } from '../../helper/appAlert';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent {
  types = alertAttributes.types;
  constructor(public snackBarRef: MatSnackBarRef<AlertComponent>, @Inject(MAT_SNACK_BAR_DATA) public data: any) {}

  onCloseClick(): void {
    this.snackBarRef.dismiss();
  }
}
