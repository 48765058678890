import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Router, ActivatedRoute } from '@angular/router';
import { appParam } from 'src/app/helper/appSettings';
import { AppService } from 'src/app/services/app.service';
import { AuthService } from 'src/app/services/auth.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-uxa2001',
  templateUrl: './uxa2001.component.html',
  styleUrls: ['./uxa2001.component.scss']
})
export class Uxa2001Component implements AfterViewInit {
  _style: string = '';
  _currentApplicationVersion = environment.appVersion;
  userObject: any = {};

  @ViewChild('sidenav', { static: true })
  sidenav!: MatSidenav;

  _breadcrumb: string = '';
  params = appParam;

  _route_AdminHome: string = appParam.routing.A2_orderManagement.A2001;
  _route_A2001a: string = appParam.routing.A2_orderManagement.A2001a;
  _route_A3001: string = appParam.routing.A3_manifestManagement.A3001;
  _route_A6001: string = appParam.routing.A6_binManagement.A6001;
  _route_A7001: string = appParam.routing.A7_driverManagement.A7001;
  _route_A8001: string = appParam.routing.A8_announcementManagement.A8001;
  _route_A9001: string = appParam.routing.A9_customerManagement.A9001;
  _route_A10001: string = appParam.routing.A10_CDSManagement.A10001;
  _route_A11001: string = appParam.routing.A11_ABAManagement.A11001;
  _route_A12001: string = appParam.routing.A12_PublicHolidayManagement.A12001;
  _route_A13001: string = appParam.routing.A13_DepotDayConfig.A13001;
  _route_A14001: string = appParam.routing.A14_DepotDateExceptions.A14001;
  _route_A15001: string = appParam.routing.A15_Plans.A15001;

  constructor(
    private appService: AppService,
    private authService: AuthService,
    public router: Router,
    private route: ActivatedRoute
  ) {
    this.userObject = this.appService.UserObject;
    this._style = this.authService.getStyle();
  }

  //  after loading hide the spinner
  ngAfterViewInit(): void {
    setTimeout(() => {
      this.appService.appSpinner(false);
    });
  }

  closeSideNav(event: string) {
    if (event.indexOf(appParam.routing.A2_orderManagement.A2001a) > 0) this._breadcrumb = ' / Order Management';
    else if (event.indexOf(appParam.routing.A6_binManagement.A6001) > 0) this._breadcrumb = ' / Bin Management';
    else if (event.indexOf(appParam.routing.A6_binManagement.A6001a) > 0) this._breadcrumb = ' / Bin On-boarding';
    else if (event.indexOf(appParam.routing.A6_binManagement.A6001c) > 0) this._breadcrumb = ' / Bin History';
    else if (event.indexOf(appParam.routing.A7_driverManagement.A7001) > 0) this._breadcrumb = ' / Driver Management';
    else if (event.indexOf(appParam.routing.A7_driverManagement.A7001a) > 0)
      this._breadcrumb = ' / Driver On-boarding/edit';
    else if (event.indexOf(appParam.routing.A9_customerManagement.A9001) > 0)
      this._breadcrumb = ' / Customer Management';
  }

  async signOut() {
    await this.authService.signOut();
    await this.appService.clearCache();
    this.router.navigate(['/' + appParam.routing.A1_signIn.A1001], {
      relativeTo: this.route
    });
  }
}
