<div fxLayout="column">
  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" class="m-3">
    <button mat-raised-button color="primary" data-cy="new-announcement" [routerLink]="_route_NewAnnouncement">
      <mat-icon>add</mat-icon>Create
    </button>
    <button mat-raised-button color="primary" (click)="viewAnnouncements('All')">View all announcements</button>
  </div>

  <div *ngFor="let item of this._announcements; let i = index" class="mx-3">
    <app-announcement-tile [_announcement]="item"></app-announcement-tile>
  </div>
</div>
