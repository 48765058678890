<form [formGroup]="frmGroup" class="w-100 h-100">
  <div class="container-fluid w-100 h-100 {{ this._style }}-main-panel">
    <div class="row">
      <div class="col-2 my-auto">Subs</div>
      <div class="col-10">
        <mat-form-field>
          <mat-select formControlName="id" data-cy="subscription">
            <mat-option *ngFor="let sub of _subscriptions" [value]="sub.id">{{
              sub.address
            }}</mat-option></mat-select
          >
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <!-- Subscription type -->
      <div class="col-3 my-auto">Type</div>
      <div class="col-9">
        <mat-form-field>
          <input
            matInput
            formControlName="subscription_type"
            data-cy="subscription_type"
            readonly
          />
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <!-- Bin number -->
      <div class="col-3 my-auto">Bin number</div>
      <div class="col-9">
        <mat-form-field>
          <input
            matInput
            formControlName="bin_number"
            data-cy="bin_number"
            readonly
          />
        </mat-form-field>
      </div>
    </div>

    <!-- QR Code -->
    <!-- <div class="row">
      <div class="col-3 my-auto">QR Code</div>
      <div class="col-9">
        <mat-form-field>
          <input
            matInput
            formControlName="qr_Code"
            data-cy="qr_Code"
            readonly
          />
        </mat-form-field>
      </div>
    </div> -->

    <div class="row">
      <!-- Bin Type -->
      <div class="col-3 my-auto">Bin type</div>
      <div class="col-9">
        <mat-form-field>
          <input
            matInput
            formControlName="bin_type"
            data-cy="bin_type"
            readonly
          />
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <!-- Payment -->
      <div class="col-3 my-auto">Payment</div>
      <div class="col-9">
        <mat-form-field>
          <input
            matInput
            formControlName="payment"
            data-cy="payment"
            readonly
          />
        </mat-form-field>
      </div>
    </div>

    <!-- Charity -->
    <div class="row" *ngIf="frmGroup.value.charity">
      <div class="col-3 my-auto">Charity</div>
      <div class="col-9">
        <mat-form-field>
          <input
            matInput
            formControlName="charity"
            data-cy="charity"
            readonly
          />
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <!-- Status -->
      <div class="col-3 my-auto">Status</div>
      <div class="col-9">
        <mat-form-field>
          <input
            matInput
            formControlName="status"
            data-cy="status"
            readonly
            readonly
          />
        </mat-form-field>
      </div>
    </div>

    <!-- Save -->
    <!-- <div class="row">
      <div class="col">
        <button
          mat-raised-button
          id="signIn"
          class="d-block {{ this._style }}-small {{ this._style }}-primary mt-4"
          (click)="save()"
          data-cy="save"
          [disabled]="frmGroup.status != 'VALID'"
        >
          Save
        </button>
      </div>
    </div> -->
  </div>
</form>
