<div class="container h-100 w-100 {{this._style}}-charity-panel p-1"
    [ngClass]="{'byc-selected': _Selected == _Charity.key}">

    <div class="row h-100" *ngIf="this._Charity.description!=''" (click)="itemSelected(this._Charity)">
        <div class='h-20 d-inline-block d-flex justify-content-center text-center'>
            {{_Charity.description}}
        </div>
        <div class='h-60 d-inline-block d-flex justify-content-center'>
            <img src="./assets/images/charity.png" class="d-block bc_halign_center {{this._style}}-image-default"
                alt="..." />
        </div>
        <div class='h-20 d-inline-block d-flex justify-content-center'>
            <div class='align-self-end w-100 pb-1 d-flex justify-content-center'>
                <button class='{{this._style}}-button-charity-select'
                    (click)="itemSelected(this._Charity)">Select</button>
            </div>
        </div>
    </div>
</div>