import { Inject, ViewChild } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { appParam } from "../../helper/appSettings";
import { AppService } from "../../services/app.service";
import { ApiService } from "../../services/api.service";
import { AuthService } from "../../services/auth.service";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { alertAttributes } from "../../helper/appAlert";
import { FormControl, Validators } from '@angular/forms';
import { ISuburb } from "../../helper/appInterfaces";

//  row structure
export interface TableRow {
  id: string; // used for the checkbox column
  weekday: string;
  depotId: number;
  depot: string;
  description: string;
  dropoff: string;
  new_dropoff: string;
  changeover: string;
  new_changeover: string;
  effective: string;
  context_menu: string;
}

let ELEMENT_DATA: TableRow[] = [];
let MASTER_ELEMENT_DATA: TableRow[] = [];

@Component({
  selector: 'app-uxa13001',
  templateUrl: './uxa13001.component.html',
  styleUrls: ['./uxa13001.component.scss']
})
export class Uxa13001Component implements OnInit {
  @ViewChild('tabGroup') tabGroup;
  @ViewChild('suburbWeekdayGroup') suburbWeekdayGroup;
  @ViewChild('nestedTabGroup') nestedTabGroup;

  masterDataSource = new MatTableDataSource<TableRow>(MASTER_ELEMENT_DATA);
  dataSource = new MatTableDataSource<TableRow>(ELEMENT_DATA);

  unassignedSuburbs: any[] = []
  assignedSuburbs: any[] = []

  selectedUnassignedSuburbs: any[] = []
  selectedAssignedSuburbs: any[] = []

  _style: string = '';
  _defaultDateFormat: string = appParam.defaultDateFormat;
  tabList: {[key: string]: number} = {};
  suburbConfigTabList: string[] = []
  public tabIndex: number = 0;

  _currentDepot: string;
  _currentContext: TableRow;
  _currentWeekday: string;
  _currentSuburbConfigTab: boolean;

  //  columns to be displayed in the table
  displayedColumns: string[] = [
    'id',
    'weekday',
    'description',
    'dropoff',
    'new_dropoff',
    'changeover',
    'new_changeover',
    'effective',
    'context_menu',
  ];

  weekdays: {[key: string]: number} = {
    'Monday': 1,
    'Tuesday': 2,
    'Wednesday': 3,
    'Thursday': 4,
    'Friday': 5,
    'Saturday': 6,
    'Sunday': 7
  }

  constructor(
    private appService: AppService,
    private apiService: ApiService,
    private authService: AuthService,
    public dialog: MatDialog
  ) {
    this._style = this.authService.getStyle();
  }

  async ngOnInit(): Promise<void> {
    await this.getData(false)
  }

  // ngAfterViewInit() {
  // }

  async getData(reload: boolean) {
    this.appService.appSpinner(true);

    // get all the users depot access
    let depotIds = [];
    let user = await this.apiService.getUsers(this.authService.getCurrentUserId())
    for (let depot of user.UsersDepotAccess) {
      this.tabList[depot.Depot.desc] = depot.Depot.id
      depotIds.push(depot.Depot.id)
    }

    let _data = await this.apiService.getServiceDayConfig(depotIds.toString())

    this.masterDataSource.data = [];

    try {
      for (let _row of _data) {
        let depotId = this.tabList[_row['depot_name']]

        this.masterDataSource.data.push({
          id: _row['depot_day_id'],
          weekday: _row['weekday_name'],
          depot: _row['depot_name'],
          depotId: depotId,
          description: _row['depot_day_description'],
          dropoff: _row['dropoff_limit'],
          new_dropoff: _row['new_dropoff_limit'],
          changeover: _row['changeover_limit'],
          new_changeover: _row['new_changeover_limit'],
          effective: _row['effective_date'],
          context_menu: '...'
        });
      }
    } catch (err) {
      console.log('err', err);
    }

    if (!reload) {
      this._currentDepot = this.tabGroup._allTabs.first.textLabel
      this.updateTableData(this._currentDepot)
    } else {
      let selectedTabIndex = this.tabGroup._selectedIndex
      this.updateTableData(this.tabGroup._allTabs._results[selectedTabIndex].textLabel)
    }

    this.appService.appSpinner(false);

  }

  updateTableData($event?) {
    if ($event.tab === undefined) {
      this._currentDepot = $event
    } else {
      this._currentDepot = $event.tab.textLabel
    }

    // since change of depot
    this._currentSuburbConfigTab = false;
    setTimeout(() => {
      this.nestedTabGroup.selectedIndex = 0;
    })

    let tempData = []
    for (let data of this.masterDataSource.data) {
      if (data.depot == this._currentDepot) {
        tempData.push({
          id: data.id,
          weekday: data.weekday,
          depot: data.depot,
          depotId: data.depotId,
          description: data.description,
          dropoff: data.dropoff,
          new_dropoff: data.new_dropoff,
          changeover: data.changeover,
          new_changeover: data.new_changeover,
          effective: data.effective,
          context_menu: '...'
        })
      }
    }
    this.dataSource = new MatTableDataSource(tempData)

    // update what weekdays have a config here
    this.getDaysWithConfigs()
  }

  async updateSuburbData() {
    let depot = this.tabList[this._currentDepot].toString()

    // reset the data arrays
    this.unassignedSuburbs = [];
    this.assignedSuburbs = [];

    if (this.suburbConfigTabList.length > 0) {
      this.appService.appSpinner(true);

      // get the weekday
      let selectedTabIndex = this.suburbWeekdayGroup._selectedIndex
      let weekday = this.weekdays[this.suburbWeekdayGroup._allTabs._results[selectedTabIndex].textLabel].toString()

      // get the data for the selected depot and weekday
      this.unassignedSuburbs = await this.apiService.getSuburbsByDepot(true, depot, weekday)
      this.assignedSuburbs = await this.apiService.getSuburbsByDepot(false, depot, weekday)

      // sort by suburb name for display
      this.unassignedSuburbs.sort((a, b) => (a.suburb_name < b.suburb_name ? -1 : 1))
      this.assignedSuburbs.sort((a, b) => (a.suburb_name < b.suburb_name ? -1 : 1))
    }

    this.appService.appSpinner(false);
  }

  async getWeekdayConfig($event) {
    const label = $event.target.outerText

    if (label == 'Suburb Config') {
      await this.updateSuburbData()
    }
  }

  async addSuburbs() {
    // if pressed add button with no data return
    if (this.selectedUnassignedSuburbs.length == 0) return;

    // push suburbs from selected unassigned suburbs into the assigned suburbs array and send to API
    let tempAssignedSuburbs = this.assignedSuburbs
    for (let suburb of this.selectedUnassignedSuburbs) tempAssignedSuburbs.push(suburb)

    // get depot ID and weekday ID and concat to form depotWeekdayId
    let depotId = this.tabList[this._currentDepot].toString()
    let selectedTabIndex = this.suburbWeekdayGroup._selectedIndex
    let weekday = this.weekdays[this.suburbWeekdayGroup._allTabs._results[selectedTabIndex].textLabel].toString()
    let depotDayID = depotId + weekday

    // get list of all assigned suburbs
    let suburbIdList = tempAssignedSuburbs.map(a => a.suburb_id).toString()

    // call API with list and concatenated depot day ID
    this.appService.appSpinner(true);

    let res = await this.apiService.updateDepotDaySuburbs(depotDayID, suburbIdList)

    this.selectedUnassignedSuburbs = [];

    // call update suburb data to return new arrays
    await this.updateSuburbData();

    this.appService.appSpinner(false);
  }

  async removeSuburbs() {
    // if pressed remove button with no data return
    if (this.selectedAssignedSuburbs.length == 0) return;

    // pop suburbs from selected assigned suburbs array and send to API
    let tempAssignedSuburbs = this.assignedSuburbs
    for (let suburb of this.selectedAssignedSuburbs) {
      const index = tempAssignedSuburbs.indexOf(suburb, 0)
      if (index > -1) tempAssignedSuburbs.splice(index, 1);
    }

    // get depot ID and weekday ID and concat to form depotWeekdayId
    let depotId = this.tabList[this._currentDepot].toString()
    let selectedTabIndex = this.suburbWeekdayGroup._selectedIndex
    let weekday = this.weekdays[this.suburbWeekdayGroup._allTabs._results[selectedTabIndex].textLabel].toString()
    let depotDayID = depotId + weekday

    // get list of all assigned suburbs
    let suburbIdList = tempAssignedSuburbs.map(a => a.suburb_id).toString()

    // call API with list and concatenated depot day ID
    this.appService.appSpinner(true);

    let res = await this.apiService.updateDepotDaySuburbs(depotDayID, suburbIdList)

    this.selectedAssignedSuburbs = [];

    // call update suburb data to return new arrays
    await this.updateSuburbData();

    this.appService.appSpinner(false);
  }

  async addSuburb() {
    let suburbDialogRef = this.dialog.open(AddSuburbDialog, {
      autoFocus: true,
      minWidth: '25%',
      height: 'auto',
      data: {
        depotName: this._currentDepot,
        depot: this.tabList[this._currentDepot].toString()
      }
    });
    suburbDialogRef.afterClosed().subscribe(async (result: boolean) => {
      if (result) await this.updateSuburbData()
    })
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  contextClick(row: any) {
    this._currentContext = row;
  }

  async regenerateAvailableDays() {
    this.appService.appSpinner(true);
    try {
      let res = await this.apiService.updateAvailableDates();
    } catch (err) {
      this.appService.sendNotification({
        type: alertAttributes.types.error,
        message: 'Could not generate available dates!',
        body: '',
        displayNotification: true
      })
      this.appService.appSpinner(false);
      return;
    }
    this.appService.sendNotification({
      type: alertAttributes.types.success,
      message: 'Regenerated available dates!',
      body: '',
      displayNotification: true
    })
    this.appService.appSpinner(false);
  }

  getDaysWithConfigs() {
    this.suburbConfigTabList = []

    for (let data of this.dataSource.data) {
      // if any of the limits are greater than 0 the weekday has a config
      if (Number(data.changeover) > 0 || Number(data.dropoff) > 0 || Number(data.new_dropoff) > 0 || Number(data.new_changeover) > 0) {
        this.suburbConfigTabList.push(data.weekday)
      }
    }
  }

  async openEditConfigDialog() {
    let dialogRef = this.dialog.open(EditConfigDialog, {
      autoFocus: true,
      minWidth: '25%',
      height: 'auto',
      data: {
        depotDayId: this._currentContext.id,
        newDropoffs: this._currentContext.new_dropoff,
        newChangeovers: this._currentContext.new_changeover,
        depot: this._currentContext.depot,
        weekday: this._currentContext.weekday
      }
    });
    dialogRef.afterClosed().subscribe(async (result: boolean) => {
        if (result) await this.getData(true);
      }
    )
  }

}

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'edit-config-dialog',
  templateUrl: 'edit-config-dialog.html',
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class EditConfigDialog {
  depotDayId: number;
  newDropoffs: number;
  newChangeovers: number;
  weekday: string;
  dateString: string;
  depot: string;
  isBtnDisabled: boolean = false;
  availableDates: any[] = [];
  minDate = new Date();
  reqDate = new FormControl(this.minDate, [Validators.required])

  constructor(
    public dialogRef: MatDialogRef<EditConfigDialog>,
    private route: ActivatedRoute,
    private appService: AppService,
    private apiService: ApiService,
    @Inject(MAT_DIALOG_DATA) public data: {
      depotDayId: number, newDropoffs: number, newChangeovers: number, depot: string, weekday: string
    },
  ) {
    this.depotDayId = Number(this.data.depotDayId);
    this.newDropoffs = Number(this.data.newDropoffs);
    this.newChangeovers = Number(this.data.newChangeovers);
    this.weekday = this.data.weekday;
    this.depot = this.data.depot;
    this.dateString = this.minDate.toLocaleDateString('en-CA');
  }

  closeDialogRef() {
    this.dialogRef.close(true)
  }

  async datePicked(event: any) {
    await this.updateSelectedDate(event.value);
  }

  async updateSelectedDate(_date: any) {
    let date = new Date(_date)
    this.dateString = date.toLocaleDateString('en-CA')
  }

  async save() {
    this.appService.appSpinner(true);
    this.isBtnDisabled = true;

    try {
      const res = await this.apiService.updateServiceDayConfig(
        this.depotDayId,
        this.newDropoffs,
        this.newChangeovers,
        this.dateString
      )

      this.appService.appSpinner(false)
      this.appService.sendNotification({
        type: alertAttributes.types.info,
        message: 'Service config has been updated',
        body: '',
        displayNotification: true,
        autoCloseAfter: 3
      })

      setTimeout(() => {
        this.closeDialogRef()
      }, 500);

    } catch (err) {
      this.appService.appSpinner(false);
      this.isBtnDisabled = false;
      console.log(err)
    }
  }
}

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'add-suburb-dialog',
  templateUrl: 'add-suburb-dialog.html',
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class AddSuburbDialog {
  depot: string;
  depotName: string;
  selectedSuburb: ISuburb;
  isBtnDisabled: boolean = false;

  constructor(
    public suburbDialogRef: MatDialogRef<AddSuburbDialog>,
    private route: ActivatedRoute,
    private appService: AppService,
    private apiService: ApiService,
    @Inject(MAT_DIALOG_DATA) public data: {
      depot: string,
      depotName: string
    },
  ) {
    this.depot = this.data.depot;
    this.depotName = this.data.depotName;
  }

  async suburbEntered(event: ISuburb) {
    this.selectedSuburb = event;
  }

  async save() {
    this.appService.appSpinner(true);
    this.isBtnDisabled = true;

    try {
      const res = await this.apiService.createSuburbInRegion(
        this.selectedSuburb.suburb, this.selectedSuburb.postcode, this.depot, this.selectedSuburb.state)

      this.appService.appSpinner(false)
      this.appService.sendNotification({
        type: alertAttributes.types.info,
        message: 'Suburb has been added',
        body: '',
        displayNotification: true,
        autoCloseAfter: 3
      })

      setTimeout(() => {
        this.suburbDialogRef.close(true)
      }, 500);

    } catch (err) {
      this.appService.appSpinner(false);
      this.isBtnDisabled = false;
      console.log(err)
    }
  }
}
