import {
  IManifestCustomer,
  IManifestMetadata,
  IManifestDepot,
  IManifestShift,
  IManifestVehicle,
  IManifestJob,
  IVehicle, ITravelSalespersonJob, IRouteParameters, IStop
} from "./appInterfaces"

export const appRouteOptimization = {

  async prepareTspJob(_orders: any[], _manifestId: any, depot: any, startDepot: any, endDepot: any) {
    let _vehicle: IVehicle = {
      id: 'V001',
      transportMode: 'DRIVING',
      capacities: [100]
    }

    // init _depot obj for use in case start/end not defined
    let _depot = {
      id: depot.desc,
      location: {
        address: depot.street_address,
        lat: depot.lat,
        long: depot.lng
      },
    }

    if (startDepot != null && endDepot != null) {
      _vehicle.startPoint = startDepot;
      _vehicle.endPoint = endDepot;
    } else if (startDepot != null) {
      _vehicle.startPoint = startDepot;
      _vehicle.endPoint = _depot;
    } else if (endDepot != null) {
      _vehicle.startPoint = _depot;
      _vehicle.endPoint = endDepot;
    } else {
      _vehicle.startPoint = _depot;
      _vehicle.endPoint = _depot;
    }

    // set start and end point times
    _vehicle.startPoint.earliestDepartureTime = this.getNextDayEpochTime(7);
    _vehicle.startPoint.latestArrivalTime = this.getNextDayEpochTime(23);

    _vehicle.endPoint.earliestDepartureTime = this.getNextDayEpochTime(7);
    _vehicle.endPoint.latestArrivalTime = this.getNextDayEpochTime(23);

    let _stops: IStop[] = []
    let sortOrder = 0

    for (var idx in _orders) {
      const order = _orders[idx]
      if (order['draft_order'] !== 0 && order['draft_order'] !== 999) {
        let _stop: IStop = {
          type: 'delivery',
          demands: [1],
          serviceTime: 60,
          stopName: order['refCustomerOrder']['id'],
          id: _manifestId + '-' + order['refCustomerOrder']['id'],
          location: {
            lat: order['refCustomerOrder']['refCustomerAddress']['lat'],
            long: order['refCustomerOrder']['refCustomerAddress']['lng']
          },
          // start at 7AM
          startTime: this.getNextDayEpochTime(7),
          // finish at 11PM
          endTime: this.getNextDayEpochTime(23)
        }
        _stops.push(_stop)
      }
      sortOrder++
    }

    let _routingParameters: IRouteParameters = {
      trafficConditionQueryTime: this.getNextDayEpochTime(13),
      maximumTripDuration: 24
    }

    const tspJobDes: ITravelSalespersonJob = {
      routingParameters: _routingParameters,
      algorithmParameters: {
        maximumAlgorithmExecutionTime: 600
      },
      vehicle: _vehicle,
      stops: _stops
    }

    return tspJobDes
  },

  getNextDayEpochTime(hour: number): number {
    const now = new Date();
    now.setDate(now.getDate() + 1); // move to the next day
    now.setHours(hour, 0, 0, 0); // set the desired time
    return Math.floor(now.getTime() / 1000); // convert from ms to s
  },

  async prepareJob(_orders: any[], _manifestId: any, depot: any, startDepot: any, endDepot: any) {
    let _return_to_depot: boolean = true;
    if (endDepot != null || startDepot != null) _return_to_depot = false;

    let _customers: IManifestCustomer[] = []

    let _metaParams: IManifestMetadata = {
      target_trip_duration: 9,
      special_time_windows: [],
      workflow: "IMMEDIATE",
      return_to_depot: _return_to_depot
    }

    let _depot: IManifestDepot[] = []

    if (startDepot != null) {
      _depot.push(startDepot)
    } else {
      _depot.push({
        id: depot.desc,
        location: {
          address: depot.street_address,
          lat: depot.lat,
          long: depot.lng
        }
      })
    }

    let _shift: IManifestShift[] = []
    _shift.push({
      id: "night",
      customerServiceTime: 60,
      start: "07:00",
      end: "23:00"
    })

    let _vehicle: IManifestVehicle[] = []
    if (startDepot != null) {
      _vehicle.push({
        id: 'V001',
        available: {
          night: {
            capacities: [
              100,
              90
            ],
            depots: [
              startDepot.id
            ]
          }
        }
      })
    } else {
      _vehicle.push({
        id: 'V001',
        available: {
          night: {
            capacities: [
              100,
              90
            ],
            depots: [
              depot.desc
            ]
          }
        }
      })
    }

    let sortOrder = 0

    for (var idx in _orders) {
      const order = _orders[idx]

      // the dummy stop for Start/Finish which is the Depot must be removed before sent to
      // the route optimization API as the Depot is specified as a saperate attribute
      if (order['draft_order'] !== 0 && order['draft_order'] !== 999) _customers.push({
        id: _manifestId + '-' + order['refCustomerOrder']['id'],
        location: {
          address: order['refCustomerOrder']['refCustomerAddress']['street_address']
        },
        demands: [
          1,
          1
        ],
        "startTime": "07:00",
        "endTime": "23:00",
        "zone": "Adelaide Metro"
      })

      sortOrder++
    }

    const jobDes: IManifestJob = {
      meta_parameters: _metaParams,
      customers: _customers,
      depots: _depot,
      shifts: _shift,
      vehicles: _vehicle
    }

    return jobDes
  }
}
