<div fxLayout="column">
  <table
    mat-table
    [dataSource]="dataSource"
    matSort
    matSortDirection="desc"
    [matSortActive]="this._browseTableSettings.active"
    (matSortChange)="sortEvent($event)"
  >
    <!-- Id/Checkbox Column -->
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Code</th>
      <td mat-cell *matCellDef="let element">
        <mat-checkbox (change)="checkboxClick(element.id)" [disabled]="element.disabled"></mat-checkbox>
      </td>
    </ng-container>

    <!-- Code Column -->
    <ng-container matColumnDef="main_heading">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Main heading</th>
      <td mat-cell *matCellDef="let element">
        {{ element.main_heading }}
      </td>
    </ng-container>

    <!-- Code Column -->
    <ng-container matColumnDef="sub_heading">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Sub heading</th>
      <td mat-cell *matCellDef="let element">
        {{ element.sub_heading }}
      </td>
    </ng-container>

    <!-- From date Column -->
    <ng-container matColumnDef="pub_from">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>From</th>
      <td mat-cell *matCellDef="let element">
        {{ element.pub_from | date: this._defaultDateFormat }}
      </td>
    </ng-container>

    <!-- To date Column -->
    <ng-container matColumnDef="pub_to">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>To</th>
      <td mat-cell *matCellDef="let element">
        {{ element.pub_to | date: this._defaultDateFormat }}
      </td>
    </ng-container>

    <!-- New bin Column -->
    <ng-container matColumnDef="new_announcement">
      <th mat-header-cell *matHeaderCellDef [routerLink]="_route_NewAnnouncement" data-cy="new-announcement">
        <mat-icon>add</mat-icon>
      </th>
      <td mat-cell *matCellDef="let element" [matMenuTriggerFor]="menu" (click)="contextClick(element.id)">
        {{ element.new_announcement }}
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons (page)="paginatorEvent($event)"></mat-paginator>
</div>

<mat-menu #menu="matMenu">
  <button mat-menu-item (click)="contextSelected('edit')" data-cy="edit-announcement">
    <span>Edit</span>
  </button>
  <button mat-menu-item (click)="contextSelected('end')" data-cy="end-pub">
    <span>End publish</span>
  </button>
</mat-menu>
