import { Component, OnInit, ViewChild } from '@angular/core';
import { AppService } from 'src/app/services/app.service';
import { AuthService } from 'src/app/services/auth.service';
import { IBrowseTableSettings, IDataBlock } from 'src/app/helper/appInterfaces'

import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { MatSort } from "@angular/material/sort";

import { ApiService } from 'src/app/services/api.service';
import { appParam } from 'src/app/helper/appSettings';
import { appUtils } from 'src/app/helper/appUtils';
import { alertAttributes } from "src/app/helper/appAlert";
import { Router, ActivatedRoute } from '@angular/router';
import * as moment from 'moment';


//  row structure
export interface TableRow {
  id: string; // used for the checkbox column
  main_heading: string;
  sub_heading: string;
  pub_from: string;
  pub_to: string;
  new_announcement: string;
  selected: boolean;
  disabled: boolean;
}

let ELEMENT_DATA: TableRow[] = [];

@Component({
  selector: 'app-uxa8001b',
  templateUrl: './uxa8001b.component.html',
  styleUrls: ['./uxa8001b.component.scss']
})
export class Uxa8001bComponent implements OnInit {
  _style: string = '';
  _defaultDateFormat: string = appParam.defaultDateFormat;

  _currentContext: string[] = ['']; // used to store the selected bin(s)
  _route_NewAnnouncement: string = '../' + appParam.routing.A8_announcementManagement.A8001a;

  _type: string;

  //  table settings
  public _browseTableSettings: IBrowseTableSettings = {
    active: "pub_from",
    direction: "desc",
    pageIndex: 0,
    pageSize: 15,
  };

  constructor(
    private appService: AppService,
    private apiService: ApiService,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this._style = this.authService.getStyle()
  }

  ngOnInit(): void {
    this._type = this.route.snapshot.queryParamMap.get('type')
    this.getData(0, 10)
  }

  //  used to control the pagination with the back-end
  _pageSize = 5;
  public _dataBlocks: IDataBlock[] = [
    {
      label: "0-10",
      from: 0,
      size: this._pageSize,
      class: "btnPaginator",
    },
  ];
  _currentBlock: string = "1-10";

  //  columns to be displayed in the table
  displayedColumns: string[] = [
    "id",
    "main_heading",
    "sub_heading",
    "pub_from",
    "pub_to",
    "new_announcement"
  ];

  dataSource = new MatTableDataSource<TableRow>(ELEMENT_DATA);

  tableSortField: string;
  tableSortDirection: string;
  tablePageSize: number = 10;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;



  //  get data from API
  async getData(from: number, size: number) {
    this.appService.appSpinner(true);

    //  get announcements
    let _data
    if (this._type == 'All') {
      _data = await this.apiService.getAnnouncements();
    } else {
      _data = await this.apiService.getAnnouncements('', '', this._type, 'type');
    }

    // console.log('_data', _data)
    await this.updatePages(_data.length);

    //  update the array for the table
    this.dataSource.data = [];
    try {
      for (let _row of _data) {
        // get start publish and compare to now
        let endDate = new Date(_row.pub_to)
        let endDateOffset = endDate.getTimezoneOffset()
        const end = moment(endDate).utcOffset(endDateOffset);

        // if publish to less than or equal to today, disable checkbox
        let isDisabled = false;
        if (moment() >= end) {
          isDisabled = true;
        }

        this.dataSource.data.push({
          id: _row['id'],
          main_heading: _row['main_heading'],
          sub_heading: _row['sub_heading'],
          pub_from: _row['pub_from'],
          pub_to: _row['pub_to'],
          new_announcement: '...',
          selected: false,
          disabled: isDisabled
        });
      }
    } catch (err) {
      console.log("err", err);
    }

    this.paginator.pageSize = this.tablePageSize;
    this.paginator.pageSize = this._browseTableSettings.pageSize;
    this.paginator.pageIndex = this._browseTableSettings.pageIndex;

    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    this.tableSortField = this._browseTableSettings.active;
    this.tableSortDirection = this._browseTableSettings.direction;

    this.appService.appSpinner(false)
  }

  //  when the user clicks on a column to sort
  sortEvent(event: any) {
    this._browseTableSettings = {
      active: event.active,
      direction: event.direction === "" ? "asc" : "desc",
      pageIndex: this._browseTableSettings.pageIndex,
      pageSize: this._browseTableSettings.pageSize,
    };
  }


  openDoc(event: any) {
    // console.log("openDoc", event);
  }

  //  setup the current displayed page
  updatePages(hits: number) {
    this._dataBlocks = [];

    let isCurrentPage = "btnPaginatorActive";

    if (hits < this._pageSize) {
      this._dataBlocks.push({
        label: "".concat("1", " - ", hits.toString()),
        from: 1,
        size: this._pageSize,
        class: isCurrentPage,
      });

      return true;

    } else {
      for (let i = 0; i < hits; i += this._pageSize) {
        this._dataBlocks.push({
          label: "".concat(
            (i + 1).toString(),
            "-",
            (i + this._pageSize).toString()
          ),
          from: i,
          size: this._pageSize,
          class: isCurrentPage,
        });
        if (i > hits) {
          return true;
        }

        isCurrentPage = "btnPaginator";
      }
    }

    return true
  }


  // controls pagination
  async loadPage(event: any) {
    this._currentBlock = event.value;
    let blockStart = 0;

    await this._dataBlocks.forEach((_block) => {
      if (_block.label == event.value) {
        blockStart = _block.from;
      }
    });

    this.getData(blockStart, this._pageSize);
  }

  paginatorEvent(event: any) {
    this._browseTableSettings = {
      active: this._browseTableSettings.active,
      direction: this._browseTableSettings.direction,
      pageIndex: event.pageIndex,
      pageSize: event.pageSize,
    };

  }


  contextClick(event: any) {
    this._currentContext[0] = event
    // console.log('contextClick', event, this._currentContext)
  }


  async checkboxClick(event: any) {
    //  get selected item from array
    const res = await appUtils.searchInArray(this.dataSource.data, 'id', event)
    //  toggle the selected attribute
    res.selected = !res.selected
  }

  contextSelected(event: any) {

    if (event == 'edit') {
      this.router.navigate(["../" + appParam.routing.A8_announcementManagement.A8001a], { relativeTo: this.route, queryParams: { id: this._currentContext[0] } });
    } else if (event == 'end') {
      this.endAnnouncements()
    }

  }


  async endAnnouncements() {
    let endAnc = await this.dataSource.data.filter(i => i.selected === true);
    // console.log('endAnc', endAnc)

    this.appService.appSpinner(true)

    if (endAnc.length > 0) {
      const res = await this.apiService.endAnnouncement(endAnc)

      if (res.length > 0) {
        this.appService.appSpinner(false)

        this.appService.sendNotification({
          type: alertAttributes.types.info,
          message: 'Selected announcement(s) have been stopped.',
          body: '',
          displayNotification: true
        })

        this.getData(0, 10)
      }

    } else {

      this.appService.appSpinner(false)
      this.appService.sendNotification({
        type: alertAttributes.types.warning,
        message: 'Please check atleast one announcement',
        body: '',
        displayNotification: true
      })

    }

    this.appService.appSpinner(false)
  }


}
