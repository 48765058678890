import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IOptionType } from 'src/app/helper/appInterfaces';
import { AppService } from 'src/app/services/app.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-item-picker',
  templateUrl: './item-picker.component.html',
  styleUrls: ['./item-picker.component.scss']
})
export class ItemPickerComponent implements OnInit {
  _style: string = '';

  @Input() 
  public _readOnly: boolean = false;
  
  @Input() 
  public _items: IOptionType[] = [];

  @Output()
  public itemPicked = new EventEmitter<IOptionType>();

  constructor(public appService: AppService, private authService: AuthService) {
    this._style = this.authService.getStyle();
  }

  ngOnInit(): void {}

  itemSelected(event: any) {
    // console.log('itemSelected', event)
    this.itemPicked.emit({
      key: event.value,
      description: event.source._elementRef.nativeElement.outerText,
      checked: false
    });
  }
}
