<div fxLayout="column" style="padding: 10px 10px 10px 10px">
  <div fxLayout="row" fxLayoutAlign="center center">
    <h2 mat-dialog-title>Add Suburb - {{ depotName }}</h2>
  </div>

  <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px">
    <h3>Suburb search: </h3>
    <app-suburb-picker (addressPicked)="suburbEntered($event)"></app-suburb-picker>
  </div>

  <ng-container *ngIf="selectedSuburb != undefined">
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
      <h4>Selected suburb: </h4>
      <h4><b>{{ selectedSuburb.suburb }}</b></h4>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
      <h4>Selected postcode: </h4>
      <h4><b>{{ selectedSuburb.postcode }}</b></h4>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
      <h4>Selected state: </h4>
      <h4><b>{{ selectedSuburb.state }}</b></h4>
    </div>
  </ng-container>

  <div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="center center">
    <button mat-raised-button color="primary" mat-dialog-close style="width: 25%">Cancel</button>
    <button mat-raised-button color="primary" (click)="save()" style="width: 25%">Update</button>
  </div>
</div>
