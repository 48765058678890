<form [formGroup]="frmGroup" class="w-100 h-100">
  <div class="container-fluid w-100 h-100 {{ this._style }}-main-panel">
    <div class="row">
      <!-- first name -->
      <div class="col-3 my-auto">First name</div>
      <div class="col-9">
        <mat-form-field>
          <input
            matInput
            formControlName="first_name"
            data-cy="first_name"
            [readonly]="!this._editMode"
          />
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <!-- last name -->
      <div class="col-3 my-auto">Last name</div>
      <div class="col-9">
        <mat-form-field>
          <input
            matInput
            formControlName="last_name"
            data-cy="last_name"
            [readonly]="!this._editMode"
          />
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <!-- mobile -->
      <div class="col-3 my-auto">Mobile number</div>
      <div class="col-9">
        <mat-form-field>
          <input
            matInput
            formControlName="mobile"
            data-cy="mobile"
            [readonly]="!this._editMode"
          />
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <!-- email -->
      <div class="col-3 my-auto">Email</div>
      <div class="col-9">
        <mat-form-field>
          <input
            matInput
            formControlName="email"
            data-cy="email"
            [readonly]="!this._editMode"
          />
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <!-- street address -->
      <div class="col-3 my-auto">Street address</div>
      <div class="col-9">
        <mat-form-field>
          <input
            matInput
            formControlName="street_address"
            data-cy="street_address"
            [readonly]="true"
          />
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <!-- suburb -->
      <div class="col-3 my-auto">Suburb</div>
      <div class="col-9">
        <mat-form-field>
          <input
            matInput
            formControlName="suburb"
            data-cy="suburb"
            [readonly]="true"
          />
        </mat-form-field>
      </div>
    </div>

    <!-- Save -->
    <div class="row">
      <div class="col">
        <button
          *ngIf="this._editMode == false"
          mat-raised-button
          id="edit"
          class="d-block {{ this._style }}-small {{ this._style }}-primary mt-4"
          (click)="editMode()"
          data-cy="editMode"
        >
          Edit
        </button>

        <button
          *ngIf="this._editMode"
          mat-raised-button
          id="save"
          class="d-block {{ this._style }}-small {{ this._style }}-primary mt-4"
          (click)="save()"
          data-cy="save"
          [disabled]="frmGroup.status != 'VALID'"
        >
          Update
        </button>
      </div>
    </div>
    <!--  -->
  </div>
</form>
