<div class="mx-3">
  <div fxLayout="row">
    <span fxFlex class="dc-primary mat-h4">Customer plan: <b>{{this.userPlan}}</b></span>
    <span fxFlex class="dc-primary mat-h4">Number of bin(s): <b>{{ this.currentBins }}</b></span>
  </div>
  <div fxLayout="row">
    <span fxFlex *ngIf="true" class="mat-h4" style="color: red"> {{this.errorMsg}} </span>
  </div>
</div>

<mat-horizontal-stepper linear="true" #stepper>
  <mat-step [stepControl]="orderTypeControl">
    <ng-template matStepLabel>Select an order type</ng-template>
    <div fxLayout="row" fxLayoutGap="50px">
      <div fxLayout="column" [style.min-width.px]="250">
        <mat-form-field appearance="outline">
          <mat-label>Order type</mat-label>
          <mat-select (selectionChange)="updateSelectedOrderType($event.value)" [formControl]="orderTypeControl" data-cy="orderType">
            <ng-container *ngFor="let type of this.orderTypes">
              <mat-option
                [disabled]="type.disabled"
                [value]="type.key"
              > {{ type.code }} </mat-option>
            </ng-container>
          </mat-select>
          <mat-error *ngIf="orderTypeControl.invalid">{{getOrderTypeErrorMessage()}}</mat-error>
        </mat-form-field>
      </div>
    </div>
    <div fxLayout="row" fxLayoutGap="5px">
      <div>
        <button mat-raised-button matStepperPrevious color="primary" (click)="closeDialogRef()">Back</button>
      </div>
      <div>
        <button mat-raised-button matStepperNext color="primary">Next</button>
      </div>
    </div>
  </mat-step>

  <mat-step [stepControl]="dateInputControl">
    <ng-template matStepLabel>Select a date and number of bins</ng-template>
    <div fxLayout="row" fxLayoutGap="50px">
      <div fxLayout="column" [style.min-width.px]="250">
        <mat-form-field appearance="outline">
        <mat-label>Select date</mat-label>
        <input matInput
               [formControl]="dateInputControl"
               [matDatepicker]="picker"
               (dateChange)="datePicked($event)"
               />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker [dateClass]="dateClass" disabled="false"></mat-datepicker>
        <mat-error *ngIf="dateInputControl.invalid">{{getDateErrorMessage()}}</mat-error>
        </mat-form-field>
      </div>
      <mat-divider vertical></mat-divider>
      <div>
        <span class="dc-primary mat-h4">Number of bins:</span>
        <mat-list>
          <mat-list-item *ngFor="let item of this.items">
            <div fxLayout="column" [style.min-width.px]="325">
              <div fxFlex fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="none center">
                <button mat-mini-fab color="primary" (click)="decrementValue()">
                  <mat-icon> remove </mat-icon>
                </button>
                <span class="mx-sm-3">{{qty}}</span>
                <button mat-mini-fab color="primary" (click)="incrementValue()">
                  <mat-icon> add </mat-icon>
                </button>
                <div fxLayoutAlign="center">
                  {{item.description}}
                </div>
              </div>
            </div>
          </mat-list-item>
        </mat-list>
      </div>
    </div>
    <div fxLayout="row" fxLayoutGap="15px" *ngIf="selectedDate !== ''">
      <div>
        <span fxFlex class="dc-primary mat-h4">The selected date is: <b>{{ selectedDay }}</b></span>
      </div>
    </div>
    <div fxLayout="row" fxLayoutGap="5px">
      <div>
        <button mat-raised-button matStepperPrevious color="primary">Back</button>
      </div>
      <div>
        <button mat-raised-button matStepperNext color="primary" (click)="renderCardPayment()">Next</button>
      </div>
    </div>
  </mat-step>
  <div>

  <mat-step *ngIf="userType == 'PREMIUM' && selectedOrderType == 'DROPOFF'">
    <ng-template matStepLabel>Confirm the customer's payment</ng-template>
    <div fxFlex fxLayout="row" fxLayoutGap="25px" style="display: inline-block; padding-bottom: 10px;">
      <div style="width: 325px;">
        <app-credit-card-payment
          *ngIf="displayStripePayment"
          [username]='data.uid'
          [membershipFee]="customerDetails.refSubscriptionType.membership_fee"
          [additionalFee]="additionalFee"
          (successfulResponse)="paymentAttempted($event)"
          style="width: 100%"
        ></app-credit-card-payment>
      </div>
      <div fxFlex fxLayout="column">
        <h2>Payment Summary</h2>
        <span class="dc-primary mat-h4"><b>Number of bins:</b> {{this.qty}}</span>
        <span *ngIf="notFirstOrder" class="dc-primary mat-h4"><b>Bin fee:</b> ${{customerDetails.refSubscriptionType.bin_fee * (this.qty)}}</span>
        <span *ngIf="!notFirstOrder" class="dc-primary mat-h4"><b>Bin fee:</b> ${{customerDetails.refSubscriptionType.bin_fee * (this.qty-1)}}</span>

        <ng-container *ngIf="successfulPayment">
          <mat-card class="success-card">
            <mat-card-content class="pb-3">
                  <span
                    class="mat-h3"
                    style="filter: brightness(70%)">
                    Payment was successful!
                  </span>
            </mat-card-content>
          </mat-card>
        </ng-container>
      </div>
    </div>

    <div fxLayout="row" fxLayoutGap="5px">
      <div>
        <button mat-raised-button matStepperPrevious color="primary">Back</button>
      </div>
      <div>
        <button mat-raised-button matStepperNext color="primary">Next</button>
      </div>
    </div>
  </mat-step>
  </div>

  <mat-step>
    <ng-template matStepLabel>Confirm the order</ng-template>

    <div fxLayout="column" class="px-2">
      <span fxFlex class="dc-primary-variant mat-h3 mb-0">User</span>
      <span fxFlex class="dc-primary mat-h4">{{ _customerUid }}</span>
      <span fxFlex class="dc-primary-variant mat-h3 mb-0">Order type</span>
      <span fxFlex class="dc-primary mat-h4">{{ selectedOrderType }}</span>
      <span fxFlex class="dc-primary-variant mat-h3 mb-0">Selected date</span>
      <span fxFlex class="dc-primary mat-h4">{{ selectedDay }}</span>
      <span fxFlex class="dc-primary-variant mat-h3 mb-0">Number of bins</span>
      <span fxFlex class="dc-primary mat-h4" *ngIf="this.selectedOrderType != 'CHANGEOVER'">{{ this.qty }}</span>
      <span fxFlex class="dc-primary mat-h4" *ngIf="this.selectedOrderType == 'CHANGEOVER'">{{ this.currentBins }}</span>
    </div>

    <div fxLayout="row" fxLayoutGap="5px">
      <div>
        <button mat-raised-button color="primary"(click)="closeDialogRef()">Cancel</button>
      </div>
      <div>
        <button mat-raised-button color="primary" [disabled]="this.isBtnDisabled" (click)="placeOrder()">Submit</button>
      </div>
    </div>
  </mat-step>

</mat-horizontal-stepper>
