import { MapsAPILoader } from '@agm/core';
import { EventEmitter, Input, NgZone, Output, ViewChild } from '@angular/core';
import { ElementRef } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { ISuburb } from "../../helper/appInterfaces";

@Component({
  selector: 'app-suburb-picker',
  templateUrl: './suburb-picker.component.html',
  styleUrls: ['./suburb-picker.component.scss']
})
export class SuburbPickerComponent implements OnInit {
  @ViewChild('search')
  public searchElementRef!: ElementRef;

  @Input() _readOnly: boolean = false;
  @Output() addressPicked = new EventEmitter<ISuburb>();

  title: string = 'Suburb picker';
  postcode!: string;
  suburb!: string;
  state!: string;
  private geoCoder: any;

  constructor(private mapsAPILoader: MapsAPILoader, private ngZone: NgZone) { }

  ngOnInit(): void {
    this.mapsAPILoader.load().then(() => {
      // this.setCurrentLocation();
      this.geoCoder = new google.maps.Geocoder();

      const options = {
        componentRestrictions: { country: 'aus' },
        fields: ['name', 'address_components'],
        strictBounds: false,
        types: ['locality']
      };

      let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, options);

      navigator.geolocation.getCurrentPosition(
        (resp) => {
          //  localize address search
          const defaultBounds = {
            north: resp.coords.latitude + 0.1,
            south: resp.coords.latitude - 0.1,
            east: resp.coords.longitude + 0.1,
            west: resp.coords.longitude - 0.1
          };

          autocomplete.setBounds(defaultBounds);
        },
        (err) => {
          console.log('err', err);
        }
      );

      autocomplete.addListener('place_changed', () => {
        this.ngZone.run(async () => {
          //get the place result
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();

          if (place.address_components === undefined || place.address_components === null) {
            return;
          }

          this.postcode = await this.getPlaceAttr(place, 'postal_code');
          this.suburb = await this.getPlaceAttr(place, 'locality');
          this.state = await this.getPlaceAttr(place, 'administrative_area_level_1');

          this.addressPicked.emit({
            postcode: this.postcode,
            suburb: this.suburb,
            state: this.state,
          });
        });
      });
    });
  }

  async getPlaceAttr(_place: any, _attr: string) {
    for (const component of _place.address_components) {
      const componentType = component.types[0];

      if (componentType == _attr) {
        return component.short_name;
      }
    }
  }

}
