<div>
  <mat-card class="payment-card">
    <h3 style="text-align: center; justify-content: center;">
      Payment Information
    </h3>
    <form [formGroup]="cardFormGroup">
      <div fxFlex fxLayout="column" class="container">
        <div fxFlex fxLayout="row">
          <mat-form-field appearance="outline" class="full-width">
            <mat-label>Username</mat-label>
            <input
              matInput
              formControlName="username"
              value=""
            />
          </mat-form-field>
        </div>
        <div fxFlex fxLayout="row">
          <mat-form-field appearance="outline" class="full-width">
            <mat-label>Amount</mat-label>
            <input
              matInput
              formControlName="amount"
              value=""
            />
          </mat-form-field>
        </div>
        <div fxFlex fxLayout="row">
          <div fxFlex class="form-group pb-4">
            <label for="card-number">Credit Card Number</label>
            <div id="card-number" class="form-control"></div>
          </div>
        </div>
        <div fxFlex fxLayout="row" fxLayoutGap="10px" class="pb-3">
          <div fxFlex class="small-input form-group">
            <label for="card-cvc">Security Code</label>
            <div id="card-cvc" class="form-control"></div>
          </div>
          <div fxFlex class="small-input form-group">
            <label for="card-expiry">Expiry</label>
            <div id="card-expiry" class="form-control"></div>
          </div>
        </div>
      </div>
    </form>

    <mat-card-footer class="pay-button-container" align="center">
      <button mat-raised-button color="primary" class="pay-button" [disabled]="btnDisabled || !cardFormGroup.valid" (click)="submitPayment()">
        PAY
      </button>
    </mat-card-footer>
  </mat-card>
</div>
