// 2021-08-12 CJ: This file contains all application level parameter

import { environment as env } from 'src/environments/environment';

export const appParam = {
  apiBase: env.apiBase,
  mapLocation: {
    default: {
      long: 138.599503,
      lat: -34.92123,
      zoom: 11
    }
  },
  defaultDateFormat: 'dd MMM yyyy',
  defaultDateTimeFormat: 'dd MMM yyyy hh:mm:ss',
  maxDate: '2030-12-31',
  userType: {
    charity: 'CHARITY',
    basic: 'BASIC',
    premium: 'PREMIUM',
    commercial: 'COMMERCIAL'
  },
  orderType: {
    drop: 'DROPOFF',
    pickup: 'PICKUP'
  },
  containerCategory: {
    drum: 'DRUM',
    bin: 'BIN'
  },
  subscriptionCategory: {
    charity: 'CHARITY',
    personal: 'PERSONAL'
  },
  manifestStatus: {
    draft: 'DRAFT',
    optimizeInprogress: 'OPTIMIZE-IN-PROGRESS',
    optimized: 'OPTIMIZED',
    driverAssigned: 'DRIVER-ASSIGNED',
    startRoute: 'ROUTE-IN-PROGRESS',
    binsCollectedFromCustomer: 'BINS-COLLECTED-FROM-CUSTOMER',
    complete: 'COMPLETE'
  },
  auth: {
    region: 'ap-southeast-2',
    userPoolId: env.Cognito.userPoolId,
    userPoolWebClientId: env.Cognito.userPoolWebClientId,
    domain: env.Cognito.domain,
    redirectSignIn: env.Cognito.redirectSignIn,
    redirectSignOut: env.Cognito.redirectSignOut
  },
  cacheKeys: {
    userDetails: 'userDetails',
    authToken: 'authToken'
  },

  // routing paths are parameterised so that any changes to the path can be managed from one location
  routing: {
    Userhome: 'userhome',

    A000: 'under-construction',

    A1_signIn: {
      id: 'A1',
      A1001: 'sign-in'
    },

    A2_orderManagement: {
      id: 'A2',
      A2001: 'admin-home',
      A2001a: 'orders-001a'
    },

    A3_manifestManagement: {
      id: 'A3',
      A3001: 'manifests',
      A3002: 'route-optimize-01',
      A3003: 'manifest-view'
    },

    A6_binManagement: {
      id: 'A6',
      A6001: 'bin-001',
      A6001a: 'bin-001a',
      A6001c: 'bin-001c'
    },

    A7_driverManagement: {
      id: 'A7',
      A7001: 'driver-001',
      A7001a: 'driver-001a',
      A7001c: 'driver-001c'
    },

    A8_announcementManagement: {
      id: 'A8',
      A8001: 'anc-001',
      A8001a: 'anc-001a',
      A8001b: 'anc-001b',
      A8001c: 'anc-001c',
      A8001d: 'anc-001d'
    },

    A9_customerManagement: {
      id: 'A9',
      A9001: 'customer-001',
      A9002: 'customer-002',
      A9002c: 'customer-002c',
      A9002d: 'customer-002d',
      A9002e: 'customer-002e',
      A9002f: 'customer-002f',
      A9003: 'customer-003'
    },

    A10_CDSManagement: {
      id: 'A10',
      A10001: 'cds-csv-upload'
    },

    A11_ABAManagement: {
      id: 'A11',
      A11001: 'generate-aba'
    },

    A12_PublicHolidayManagement: {
      id: 'A12',
      A12001: 'public-holidays'
    },

    A13_DepotDayConfig: {
      id: 'A13',
      A13001: 'depot-day-config'
    },

    A14_DepotDateExceptions: {
      id: 'A14',
      A14001: 'depot-date-exceptions'
    },

    A15_Plans: {
      id: 'A15',
      A15001: 'plans'
    }
  }
};
