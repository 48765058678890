<div fxLayout="row">
  <div
    fxFlex="40px"
    *ngIf="!data.type || data.type === types.info"
    class="div-alert-icon"
    [style]="{ 'background-color': '#d7e9fe' }"
  >
    <img class="img-alert" src="../../../assets/logos/DIRECT-COLLECT-ICONS-ALERT-INFO.png" alt="login image" />
  </div>
  <div
    fxFlex="40px"
    *ngIf="data.type === types.success"
    class="div-alert-icon"
    [style]="{ 'background-color': '#cdf0cb' }"
  >
    <img class="img-alert" src="../../../assets/logos/DIRECT-COLLECT-ICONS-ALERT-SUCCESS.png" alt="login image" />
  </div>
  <div
    fxFlex="40px"
    *ngIf="data.type === types.warning"
    class="div-alert-icon"
    [style]="{ 'background-color': '#fcedd5' }"
  >
    <img class="img-alert" src="../../../assets/logos/DIRECT-COLLECT-ICONS-ALERT-WARNING.png" alt="login image" />
  </div>
  <div
    fxFlex="40px"
    *ngIf="data.type === types.error"
    class="div-alert-icon"
    [style]="{ 'background-color': '#f0d5d4' }"
  >
    <img class="img-alert" src="../../../assets/logos/DIRECT-COLLECT-ICONS-ALERT-ERROR.png" alt="login image" />
  </div>
  <div fxFlex="grow" class="div-alert-message mat-h4">
    {{ data.message }}
  </div>
  <div fxFlex="30px" [style]="{ padding: '10px 10px 0 0' }">
    <mat-icon (click)="onCloseClick()">close</mat-icon>
  </div>
</div>
