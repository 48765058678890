import { Component, Input, OnInit } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'app-stop',
  templateUrl: './stop.component.html',
  styleUrls: ['./stop.component.scss']
})
export class StopComponent implements OnInit {
  @Input() _stop: any;
  @Input() _clickable: boolean;

  _arrivalTime: string;
  _departureTime: string;

  constructor() { }

  ngOnInit(): void {
    this._arrivalTime = moment("00:00:00", 'HH:mm:ss').add(this._stop['arrivalTime'], 'seconds').format('HH:mm');
    this._departureTime = moment("00:00:00", 'HH:mm:ss').add(this._stop['departureTime'], 'seconds').format('HH:mm');
  }

}
