import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import {MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { alertAttributes } from 'src/app/helper/appAlert';
import { IAddress, IBSB, ICustomerOrder, IOptionType } from 'src/app/helper/appInterfaces';
import { appParam } from 'src/app/helper/appSettings';
import { appUtils } from 'src/app/helper/appUtils';
import { ApiService } from 'src/app/services/api.service';
import { AppService } from 'src/app/services/app.service';
import { AuthService } from 'src/app/services/auth.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-uxa9002d',
  templateUrl: './uxa9002d.component.html',
  styleUrls: ['./uxa9002d.component.scss']
})
export class Uxa9002dComponent implements OnInit {
  _style: string = '';

  _customerId: string = '';
  _customerUid: string = '';

  // Address picker section
  // --------------------------
  selectedAddress: IAddress = { address: '', postcode: '', suburb: '', state: '', lat: 0, lng: 0 }
  addressItems: any[];
  selectedAddressItem: IOptionType = { key: '', description: '', checked: true };

  // --------------------------
  // Date picker section
  availableDates: any[] = [];
  selectedDate: string = '';
  selectedDay: string = ''

  // --------------------------
  // Payment types
  paymentTypeItems: IOptionType[];
  // selectedPaymentTypeItem: IOptionType = { key: '', description: '', checked: true };
  selectedPaymentType: string = '';

  // --------------------------
  // Defaults
  containerType: IOptionType = { key: '', description: '' };
  subscriptionType: IOptionType = { key: '', description: '' };
  orderType: IOptionType = { key: '', description: '' };
  paymentType: IOptionType = { key: '', description: '' };


  // --------------------------
  // Stripe
  stripe: any;
  stripeElements: any
  cardNumberElement: any;
  cardExpiryElement: any;
  cardCVCElement: any;

  paymentAmount: number = 0;

  // --------------------------
  // Bank
  BSB: IBSB[] = []

  accountName: string = '';
  accountNumber: string = '';
  accountBSB: string = '';
  bankName: string = '';

  censoredAccountNumber: string = ''


  constructor(private fg: FormBuilder,
    private appService: AppService,
    private apiService: ApiService,
    private authService: AuthService,
    private dialogRef: MatDialogRef<Uxa9002dComponent>,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    this._style = this.authService.getStyle()
    this._customerId = this.route.snapshot.queryParamMap.get('id')

    this.stripe = (<any>window).Stripe(environment.stripe_pk);
  }



  ngOnInit(): void {
    this.getData()
  }

  async getData() {
    this.appService.appSpinner(true)

    await this.getDefaults();
    await this.getDataStep1();
    await this.getDataStep2()

    this.appService.appSpinner(false)
  }




  // Stripe
  // --------------------------------------------------
  async initStripe() {
    this.stripeElements = this.stripe.elements();
    var style = {
      base: {
        padding: '25px',
        color: "#32325d",
        fontFamily: 'Arial, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#fff"
        },
      },
      invalid: {
        fontFamily: 'Arial, sans-serif',
        color: "#fa755a",
        iconColor: "#fa755a"
      },
    };

    this.cardNumberElement = this.stripeElements.create('cardNumber', { style: style });
    this.cardNumberElement.mount('#card-number');

    this.cardExpiryElement = this.stripeElements.create('cardExpiry', { style: style });
    this.cardExpiryElement.mount('#card-expiry');

    this.cardCVCElement = this.stripeElements.create('cardCvc', { style: style });
    this.cardCVCElement.mount('#card-cvc');

    this.cardNumberElement.on("change", function (event: any) {
      // console.log('cardNumber', event)
    });
  }

  async makePayment() {
    this.appService.appSpinner(true)
  }


  // Get defaults for Charity order
  // --------------------------------------------------
  async getDefaults() {
    const res = await this.apiService.getCustomer(this._customerId)
    const resCustomer = res[0]

    this._customerUid = resCustomer['code']

    // get default container details and store in workflow object
    let resContainerType = await this.apiService.getContainerTypes()
    if (resContainerType.length > 0) {
      this.containerType.key = resContainerType[0].key
      this.containerType.description = resContainerType[0].description
    }

    // get subscription type details and store in workflow object
    let resSubscriptionType = await this.apiService.getSubscriptionTypes(this._customerUid)
    if (resSubscriptionType.length > 0) {
      this.subscriptionType.key = resSubscriptionType[0].key
      this.subscriptionType.description = resSubscriptionType[0].description
    }

    // get order type details and store in workflow object
    let resOrderType = await this.apiService.getOrderTypes(this._customerUid, appParam.orderType.drop)
    if (resOrderType.length > 0) {
      this.orderType.key = resOrderType[0].key
      this.orderType.description = resOrderType[0].description
    }

    // get BSB listing
    this.BSB = await this.apiService.getBSB();
  }



  // Address picker section
  // --------------------------------------------------
  async getDataStep1() {
    this.addressItems = await this.apiService.getCustomerAddresses(this._customerUid);
  }

  async addressPicked(event: IOptionType) {
    await this.getSelectedAddressDetails(event.key)
    this.selectedAddressItem.key = await event.key
    this.selectedAddressItem.description = event.description
    this.selectedAddressItem.checked = event.checked
  }

  async getSelectedAddressDetails(_key: any) {
    const addressDetails = await appUtils.searchInArray(this.addressItems, 'key', _key)
    this.selectedAddress.address = addressDetails.description
    this.selectedAddress.postcode = addressDetails.postcode
    this.selectedAddress.state = addressDetails.state
  }

  async addressEntered(event: IAddress) {
    this.selectedAddress = event
    let newAddress = true

    // iterate and check if the address is already existing in the list
    await this.addressItems.forEach((item) => {
      if (item.description == event.address) {
        newAddress = false;
        return;
      }
    })

    //  if it's a new address, then send it to the database
    if (newAddress) {
      const address = {
        userId: this._customerUid,
        address: event.address,
        postcode: event.postcode,
        state: event.state, lat: event.lat, lng: event.lng
      }
      const resNewAddress = await this.apiService.updateCustomerAddress(address);

      // get the key from the newly created record in the db
      this.addressItems.push({
        key: resNewAddress.id,
        description: resNewAddress.desc,
        checked: true,
        postcode: resNewAddress.post_code,
        state: resNewAddress.state
      })

      appUtils.updateSelectedItem(this.addressItems, resNewAddress.id)
    }
  }


  // Date picker section
  // --------------------------------------------------
  async getDataStep2() {
  }

  async datePicked(event: any) {
    await this.updateSelectedDate(event)
  }


  async updateSelectedDate(_date: any) {
    this.selectedDate = _date
    this.selectedDay = ' ' + appUtils.getDayofWeek(this.selectedDate)
  }

  // Payment type section
  // --------------------------------------------------
  async paymentTypePicked(event: IOptionType) {
    this.paymentType = event
    if (this.paymentType.description == 'Pay the full amount now') {
      this.selectedPaymentType = 'FULL';

      setTimeout(() => {
        this.initStripe()
      }, 1000)
    }
  }




  // Bank details section
  // --------------------------------------------------
  async updateBankName(_bsb: any) {
    if (_bsb.length == 7) {
      this.accountBSB = _bsb
      const res = await appUtils.searchInArray(this.BSB, 'bsb', _bsb)
      // console.log(res)
      if (res) {
        this.bankName = await res.branchName
      }
    }

    this.censoredAccountNumber = appUtils.censorWord(this.accountNumber, 2)
  }



  async placeOrder() {
    this.appService.appSpinner(true)

    let order: ICustomerOrder = {
      customerId: this._customerUid,
      selectedDate: this.selectedDate,
      accountName: this.accountName,
      accountNumber: this.accountNumber,
      bsb: this.accountBSB,
      bins_to_drop_off: 1
    } as ICustomerOrder;

    let missingFields = ''

    if (order.selectedDate == '') { missingFields = missingFields + " Drop off date," }

    if (order.accountName == '') { missingFields = missingFields + " Bank account name," }
    if (order.accountNumber == '') { missingFields = missingFields + " Bank account number," }
    if (order.bsb == '') { missingFields = missingFields + " Bank account BSB," }

    if (missingFields.length > 0) {
      this.appService.appSpinner(false)
      this.appService.sendNotification({
        type: alertAttributes.types.info,
        message: 'Please ensure the following fields are filled -' + missingFields,
        body: '',
        displayNotification: true,
      })
      return
    }

    // Stripe payment
    if (this.selectedPaymentType == 'FULL') {

      // send a request to the BYC backend which sends a request to the Stripe api to
      // create a payment intent
      // const paymentIntent = await this.apiService.createPaymentIntent(this._customerUid, this.paymentAmount)
      // if (paymentIntent['client_secret'] == undefined) {
      //   this.appService.appSpinner(false)
      //   return
      // }

      // now send the payment confirmation request to the Stripe api along with the
      // payment intent secret received from the above step
      // const payment = await this.stripe.confirmCardPayment(paymentIntent.client_secret, {
      //   payment_method: {
      //     card: this.cardNumberElement
      //   }
      // })
      // if (payment.hasOwnProperty('paymentIntent')) {
      //
      // } else {
      //   this.appService.sendNotification({
      //     type: alertAttributes.types.error,
      //     message: payment.error.message,
      //     displayNotification: true,
      //     body: {}
      //   });
      //
      //   this.appService.appSpinner(false)
      //   return
      // }
    }


    const res = await this.apiService.createCustomerOrder(this._customerUid, order)
    if (res['id'] != undefined) {
      this.appService.appSpinner(false)
      this.appService.sendNotification({
        type: alertAttributes.types.info,
        message: 'Order has been placed.',
        body: '',
        displayNotification: true,
        autoCloseAfter: 3
      })

      setTimeout(() => {
        this.dialogRef.close()
      }, 3000);

    } else {
      this.appService.appSpinner(false)
    }

  }

}
