<div class="container-fluid col m-0 p-0">
    <div class="row">
        <div class="col m-0 p-0">

            <mat-selection-list class="py-2" (selectionChange)="itemSelected($event)">
                <mat-list-option class="byc-check-button-small py-2 h-100"
                    *ngFor="let item of this._items; let i = index" [value]="''+i"
                    [ngClass]="{'byc-check-button-hidden':this._readonly==true}">
                    {{ item.description }}

                    <div
                        [ngClass]="{'byc-hidden': item.body === undefined, 'byc-radio-button-small-body pt-2': item.body != ''}">
                        {{item.body}}
                    </div>

                    <div
                        [ngClass]="{'byc-hidden': item.binCode === '', 'byc-radio-button-small-body pt-2': item.binCode != ''}">
                        Bin #<b>{{item.binCode}}</b>
                    </div>
                </mat-list-option>

            </mat-selection-list>

        </div>
    </div>
</div>