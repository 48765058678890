import { Component, Inject, OnInit } from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { appParam } from 'src/app/helper/appSettings';
import { ApiService } from 'src/app/services/api.service';
import { AppService } from 'src/app/services/app.service';
import { AuthService } from 'src/app/services/auth.service';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { IAddress, IOptionType, IBSB } from "../../helper/appInterfaces";
import { appUtils } from "../../helper/appUtils";
import { alertAttributes } from "../../helper/appAlert";
import { Uxa9002gComponent } from '../002g/uxa9002g.component';


@Component({
  selector: 'app-uxa9002',
  templateUrl: './uxa9002.component.html',
  styleUrls: ['./uxa9002.component.scss']
})
export class Uxa9002Component implements OnInit {
  _style: string = '';

  _customerId: string = '';
  _customerUid: string = '';
  _customerType: string = '';

  frmGroup: FormGroup;
  frmGroupBackup: FormGroup; // used to track which fields were changed

  customerDetails: any;

  userType: string = '';
  selectedRegion: IOptionType = { key: '', description: '', checked: false };
  BSB: IBSB[] = [];
  bankName: string = '';

  // Location details
  latitude!: number;
  longitude!: number;
  zoom!: number;
  address: string = '';
  postcode!: string;
  suburb!: string;
  state!: string;
  dataObtained: boolean = false;
  oldAddress: string = '';

  addressTypeItems: any[] = [];
  selectedAddressType: IOptionType = { key: '', description: '', checked: false };

  subscriptionTypeItems: any[] = [];
  selectedSubscriptionType: IOptionType = { key: '', description: '', category: '', checked: false };
  isCharity: boolean = false;

  charityItems: any[] = [];
  selectedCharity: IOptionType = { key: '', description: '', checked: false };

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { id: string; uid: string; type: string },
    private dialogRef: MatDialogRef<Uxa9002Component>,
    private fg: FormBuilder,
    private appService: AppService,
    private apiService: ApiService,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    public dialog: MatDialog
  ) {
    this._style = this.authService.getStyle();
    this._customerId = this.data.id || this.route.snapshot.queryParamMap.get('id');
    this._customerType = this.data.type || this.route.snapshot.queryParamMap.get('type');
    this._customerUid = this.data.uid || this.route.snapshot.queryParamMap.get('uid')
  }

  ngOnInit(): void {
    this.frmGroup = this.fg.group({
      id: '',
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.pattern(/^\w+([\.\+-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/)]],
      mobile: ['', [Validators.required, Validators.pattern(/^04\d{8}$|^\+61\d{9}$|^04\d{2}\s\d{3}\s\d{3}$|^\+61\s\d{3}\s\d{3}\s\d{3}/), this.mobileValidator()]],
      address: [''],
      addressType: [''],
      subscriptionType: [''],
      charity: [''],
      taxInvoiceEmail: ['', [Validators.pattern(/^\w+([\.\+-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/)]],
      accountName: [''],
      accountNumber: [''],
      accountBSB: ['', [Validators.pattern(/[0-9]{3}-[0-9]{3}/)]],
      bankName: ['',],
      notes: [''],
      adminNotes: [''],
      driverNotes: ['']
    });

    this.frmGroup.valueChanges.subscribe((value) => {
      this.valueChanged(value);
    });

    this.frmGroup.controls.accountBSB.valueChanges.subscribe((value) => {
      this.updateBankName(value);
    });

    this.getData();
  }

  async getData() {
    this.appService.appSpinner(true);

    // async get all the data
    const result = await Promise.all([
      this.apiService.getCustomer(this._customerId),
      this.apiService.getSubscriptionTypes(''),
      this.apiService.getAddressTypes(),
      this.apiService.getBSB(),
      this.apiService.getCharities('', '')
    ])

    this.appService.appSpinner(false);

    // assign results to expected variables
    this.customerDetails = result[0]
    this.subscriptionTypeItems = result[1]
    this.addressTypeItems = result[2]
    this.BSB = result[3]
    this.charityItems = result[4]

    // patch in the values returned
    this.frmGroup.patchValue({
      firstName: this.customerDetails.first_name,
      lastName: this.customerDetails.last_name,
      mobile: this.customerDetails.mobile,
      email: this.customerDetails.email,
      adminNotes: this.customerDetails.admin_note
    })

    if (this.customerDetails.CustomerAddress !== null) {
      this.frmGroup.patchValue({
        addressType: this.customerDetails.CustomerAddress.refAddressType.id,
        notes: this.customerDetails.CustomerAddress.notes,
        driverNotes: this.customerDetails.CustomerAddress.driver_note
      })
      // set the old address
      this.oldAddress = this.customerDetails.CustomerAddress.street_address
    }

    if (this.customerDetails.refSubscriptionType !== null) {
      this.frmGroup.patchValue({
        subscriptionType: this.customerDetails.refSubscriptionType.id
      })
      this.userType = this.customerDetails.refSubscriptionType.code;

      if (this.customerDetails.refSubscriptionType.category.indexOf('CHARITY') > -1) {
        this.isCharity = true
        if (this.customerDetails.refCharity != null) {
          this.frmGroup.patchValue({
            charity: this.customerDetails.refCharity.id
          })
        }
        this.frmGroup.get('charity').setValidators(Validators.required);
      } else {
        // check the customer has entered their account details and patch in
        if (this.customerDetails.CustomerAccount !== null) {
          this.frmGroup.patchValue({
            accountName: this.customerDetails.CustomerAccount.account_name,
            accountNumber: this.customerDetails.CustomerAccount.account_number,
            accountBSB: this.customerDetails.CustomerAccount.refBSB.code
          })
        }
      }
    }

    // set the backup for comparison
    this.frmGroupBackup = this.frmGroup;
    this.dataObtained = true;
  }

  async valueChanged(frmValues: any) {
    if (this.dataObtained) {
      if (frmValues.subscriptionType != '') {
        const resSubscriptionType = await appUtils.searchInArray(this.subscriptionTypeItems, 'key', frmValues.subscriptionType)
        this.selectedSubscriptionType.key = resSubscriptionType.key;
        this.selectedSubscriptionType.description = resSubscriptionType.description;
        this.selectedSubscriptionType.category = resSubscriptionType.category;

        this.isCharity = this.selectedSubscriptionType.category.indexOf('CHARITY') > -1;

        if (this.isCharity == false) {
          this.frmGroup.get('charity').clearValidators();
          this.selectedCharity.key = '';
          this.selectedCharity.description = '';

          if (this.selectedSubscriptionType.category.indexOf('BASIC') > -1) {
            this.userType = appParam.userType.basic;
          } else if (this.selectedSubscriptionType.category.indexOf('PREMIUM') > -1) {
            this.userType = appParam.userType.premium;
          } else {
            this.userType = appParam.userType.commercial
          }
        } else {
          this.userType = appParam.userType.charity;
        }
      } else if (frmValues.addressType != '') {
        const resAddressType = await appUtils.searchInArray(this.addressTypeItems, 'key', frmValues.addressType)
        this.selectedAddressType.key = resAddressType.key;
        this.selectedAddressType.description = resAddressType.description;
      }
      this.frmGroupBackup = this.frmGroup;
    }
  }

  mobileValidator(): ValidatorFn {
    return (controls: AbstractControl) => {
      if (controls.value) {
        const value = controls.value.replace(/\D/gi, '');
        if (value.length < 9 || value.length > 12) {
          return { format: true };
        }
      }
      return null;
    };
  }

  passwordValidator(): ValidatorFn {
    return (controls: AbstractControl) => {
      if (controls.value && this.frmGroup) {
        if (controls.value !== this.frmGroup.value.password) {
          return { match: true };
        }
      }
      return null;
    };
  }

  async updateBankName(_bsb: any) {
    let regex = /[0-9]{6}$/g
    if (regex.test(_bsb)) {
      this.frmGroup.patchValue({ accountBSB: _bsb.substring(0,3)+'-'+_bsb.substring(3,6)})
    }

    if (_bsb.length == 7) {
      const res = await appUtils.searchInArray(this.BSB, 'bsb', _bsb);
      if (res) {
        this.frmGroup.patchValue({ bankName: res.bankCode + ' - ' + res.state });
      } else this.frmGroup.patchValue({ bankName: null });
    }
  }

  async addressPicked(event: IAddress) {
    this.address = (
      document
        .getElementById('addressPicker')!
        .querySelector('input') as HTMLInputElement
    ).value;
    this.latitude = event.lat;
    this.longitude = event.lng;
    this.postcode = event.postcode;
    this.suburb = event.suburb;
    this.state = event.state;

    const lastComma = this.address.lastIndexOf(',');
    // join the postcode to the emitted address
    this.address = [
      this.address.slice(0, lastComma),
      ' ',
      this.postcode,
      this.address.slice(lastComma)
    ].join('');

    this.frmGroup.patchValue({ address: this.address });
  }

  async save() {
    try {
      this.appService.appSpinner(true);

      let user = {
        userType: this.userType,
        userId: this.frmGroup.value.email.toLowerCase(),
        email: this.frmGroup.value.email.toLowerCase(),
        mobile: this.frmGroup.value.mobile,
        firstName: this.frmGroup.value.firstName,
        lastName: this.frmGroup.value.lastName,
        charityId: this.frmGroup.value.charity,
        taxInvoiceEmail: this.frmGroup.value.taxInvoiceEmail.toLowerCase(),
        address: this.address,
        postcode: this.postcode,
        suburb: this.suburb,
        state: this.state,
        lat: this.latitude,
        lng: this.longitude,
        locationTypeId: this.frmGroup.value.addressType,
        subscriptionTypeId: this.frmGroup.value.subscriptionType,
        accountNumber: this.frmGroup.value.accountNumber,
        accountName: this.frmGroup.value.accountName,
        accountBSB: this.frmGroup.value.accountBSB,
        bankName: this.frmGroup.value.bankName,
        notes: this.frmGroup.value.notes,
        adminNotes: this.frmGroup.value.adminNotes,
        driverNotes: this.frmGroup.value.driverNotes,
        updated: true
      };

      if (user.userType != 'CHARITY') {
        user.taxInvoiceEmail = undefined
        user.charityId = undefined
      }

      if (this.customerDetails.email != user.email) {
        // set userId to old email and then update in lambda
        user.userId = this.customerDetails.email.toLowerCase()
      }

      if (user.address != '' || user.notes != null || user.driverNotes != null) {
        let res = await this.apiService.updateCustomerAddress(user);
        if (res.length == 0) {
          this.appService.appSpinner(true);
          return;
        }
      }

      let res = await this.apiService.updateCustomerDetails(user);
      if (res.length == 0) {
        this.appService.appSpinner(true);
        return;
      }

      this.appService.appSpinner(false);

      this.appService.sendNotification({
        type: alertAttributes.types.info,
        message: 'Customer has been updated!',
        body: '',
        displayNotification: true,
        autoCloseAfter: 2
      });
      setTimeout(() => {
        this.dialogRef.close(true);
      }, 1500);
    } catch (err: any) {
      console.log(err)
      this.appService.appSpinner(false);
    }
  }

  async openChangeCustomerPlanDialog(){
    let oldPlan = await appUtils.searchInArray(this.subscriptionTypeItems, 'key', this.frmGroup.value.subscriptionType)

    if (oldPlan == undefined) {
      oldPlan = {
        "description": "No Plan"
      }
    }

    let dialogRef = this.dialog.open(Uxa9002gComponent,{
      autoFocus: true,
      height: 'auto',
      data: {
        id: this._customerId,
        username: this._customerUid,
        customerName: this.frmGroup.value.firstName + ' ' + this.frmGroup.value.lastName,
        oldPlanDesc: oldPlan.description,
        oldPlan: oldPlan,
        accountNumber: this.frmGroup.value.accountNumber,
        accountName: this.frmGroup.value.accountName,
        accountBSB: this.frmGroup.value.accountBSB,
        bankName: this.frmGroup.value.bankName,
      }
    });

    dialogRef.afterClosed().subscribe(async(result: boolean) => {
      if (result) {
        this.appService.appSpinner(false);
        this.getData();
      }
    })
  };
}
