<div class="container">
  <div class="row">
    <div class="col-2 my-auto {{ this._style }}-text-bold">
      Current Pick up requests
    </div>
    <div class="col-10">
      <mat-form-field>
        <mat-select data-cy="subscription">
          <mat-option
            *ngFor="let req of _pickupRequests"
            [value]="req.id"
            (onSelectionChange)="itemSelected(req)"
            >{{ req.selected_date | date: this._defaultDateFormat }}</mat-option
          >
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <div class="row" *ngIf="this.selectedDate != ''">
    <div class="col">
      <div class="container-fluid h-100 p-2 {{ this._style }}-main-panel">
        <!--  -->
        <div class="row">
          <div class="col-2 my-auto">Request id</div>
          <div class="col d-flex justify-content-start">
            {{ this._selectedPickupRequest?.id }}
          </div>
        </div>
        <!--  -->
        <div class="row">
          <div class="col-2 my-auto">Selected date</div>
          <div class="col d-flex justify-content-start">
            <app-date-picker
              (mDatePicked)="datePicked($event)"
              [_availableDate]="this.availableDates"
              [_selectedDate]="this.selectedDate">
            </app-date-picker>
          </div>
        </div>

        <!--  -->

        <div class="row">
          <div class="col-12">
            <span class="px-1">
              <button
                mat-raised-button
                class="{{ this._style }}-small {{ this._style }}-primary mt-2"
                (click)="updateRequest()">
                Update pickup request
              </button>
            </span>

            <span class="px-1">
              <button
                mat-raised-button
                class="{{ this._style }}-small {{ this._style }}-primary mt-2"
                (click)="cancelRequest()">
                Cancel pickup request
              </button>
            </span>
          </div>

          <!--  -->
        </div>
      </div>
    </div>
  </div>
</div>
