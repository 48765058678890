<div fxLayout="column">
  <div fxLayout="row" fxLayoutGap="10px" class="mx-3 mt-3">
    <div>
      <button mat-raised-button color="primary" (click)="openAddPlanDialog()"><mat-icon>add</mat-icon>Add Plan</button>
    </div>
    <mat-form-field fxFlex="grow">
      <mat-label>Search</mat-label>
      <mat-icon matPrefix>search</mat-icon>
      <input matInput (keyup)="applyFilter($event)" placeholder="E.g. Basic plan"/>
    </mat-form-field>
  </div>
  <div style="max-height: 800px; overflow-y: auto;">
    <table mat-table [dataSource]="dataSource" matSort matSortDirection="desc" matSortActive="id"
           style="max-height: 500px; min-width: 100%;">

      <!-- Plan Name Column -->
      <ng-container matColumnDef="plan_name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header style="padding: 0 0 0 12px !important;">Plan Name</th>
        <td mat-cell *matCellDef="let element" style="padding: 0 0 0 12px !important;">
          {{ element.plan_name}}
        </td>
      </ng-container>

      <!-- Description Column -->
      <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Description</th>
        <td mat-cell *matCellDef="let element">
          {{ element.description }}
        </td>
      </ng-container>

      <!-- Checkbox Column HIDDEN -->
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header [hidden]="true">ID</th>
        <td mat-cell *matCellDef="let element" [hidden]="true">
          {{ element.id }}
        </td>
      </ng-container>

      <!-- Category Column -->
      <ng-container matColumnDef="category">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Category</th>
        <td mat-cell *matCellDef="let element">
          {{ element.category }}
        </td>
      </ng-container>

      <!-- Bin Limit Column -->
      <ng-container matColumnDef="bin_limit">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Bin Limit</th>
        <td mat-cell *matCellDef="let element">
          {{ element.bin_limit }}
        </td>
      </ng-container>

      <!-- Bin Fee Column -->
      <ng-container matColumnDef="bin_fee">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Bin Fee</th>
        <td mat-cell *matCellDef="let element">
          <div *ngIf=" element.bin_fee != null">
            ${{ element.bin_fee }}
          </div>
        </td>
      </ng-container>

      <!-- Membership Fee Column -->
      <ng-container matColumnDef="membership_fee">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Membership Fee</th>
        <td mat-cell *matCellDef="let element">
          <div *ngIf=" element.membership_fee != null">
            ${{ element.membership_fee }}
          </div>
        </td>
      </ng-container>

      <!-- Instalment Amount Column -->
      <ng-container matColumnDef="instalment_amount">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Instalment Amount</th>
        <td mat-cell *matCellDef="let element">
          <div *ngIf=" element.instalment_amount != null">
            ${{ element.instalment_amount }}
          </div>
        </td>
      </ng-container>

      <!-- Direct Collect Commission Column -->
      <ng-container matColumnDef="direct_collect_commission">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Direct Collect Commission</th>
        <td mat-cell *matCellDef="let element">
          ${{ element.direct_collect_commission }}
        </td>
      </ng-container>

      <!-- Handling Fee Column -->
      <ng-container matColumnDef="handling_fee">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Handling Fee</th>
        <td mat-cell *matCellDef="let element">
          ${{ element.handling_fee }}
        </td>
      </ng-container>

      <!-- Per Container Refund Column -->
      <ng-container matColumnDef="per_container_refund">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Per Container Refund</th>
        <td mat-cell *matCellDef="let element">
          ${{ element.per_container_refund }}
        </td>
      </ng-container>

      <!-- Display On UI Column -->
      <ng-container matColumnDef="display_on_ui">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Display?</th>
        <td mat-cell *matCellDef="let element">
          {{ element.display_on_ui }}
        </td>
      </ng-container>

      <ng-container matColumnDef="context_menu">
        <th mat-header-cell *matHeaderCellDef data-cy="edit-plan">Edit Plan</th>
        <td mat-cell *matCellDef="let element" (click)="editRowClick(element); openEditPlanDialog();">
          <button mat-icon-button>
            <mat-icon>edit</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </div>

  <mat-paginator
    [length]="dataSource.data.length"
    [pageSizeOptions]="[20, 50, 100, 500]"
    showFirstLastButtons
  ></mat-paginator>

</div>
