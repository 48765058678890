import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { appParam } from "../../helper/appSettings";
import { AppService } from "../../services/app.service";
import { ApiService } from "../../services/api.service";
import { AuthService } from "../../services/auth.service";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { alertAttributes } from "../../helper/appAlert";
import {FormBuilder, FormGroup, Validators } from '@angular/forms';

//  row structure
export interface TableRow {
  id: string; // used for the checkbox column
  bin_fee: string;
  bin_limit: string;
  category: string;
  description: string;
  direct_collect_commission: string;
  display_on_ui: boolean;
  handling_fee: string;
  instalment_amount: string;
  membership_fee: string;
  per_container_refund: string;
  plan_name: string;
  context_menu: string;
}

let ELEMENT_DATA: TableRow[] = [];

@Component({
  selector: 'app-uxa15001',
  templateUrl: './uxa15001.component.html',
  styleUrls: ['./uxa15001.component.scss']
})
export class Uxa15001Component implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  _style: string = '';
  _defaultDateFormat: string = appParam.defaultDateFormat;

  dataSource = new MatTableDataSource<TableRow>(ELEMENT_DATA);
  _currentContext: TableRow;

  //  columns to be displayed in the table
  displayedColumns: string[] = [
    'id',
    'plan_name',
    'description',
    'category',
    'bin_fee',
    'bin_limit',
    'membership_fee',
    'instalment_amount',
    'direct_collect_commission',
    'handling_fee',
    'per_container_refund',
    'display_on_ui',
    'context_menu'
  ];

  constructor(
    private appService: AppService,
    private apiService: ApiService,
    private authService: AuthService,
    public dialog: MatDialog
  ) {
    this._style = this.authService.getStyle();
  }

  async ngOnInit(): Promise<void> {
    await this.getData();
  }

  async getData() {
    this.appService.appSpinner(true);

    let _data = await this.apiService.getAvailablePlans();

    //  update the array for the table
    this.dataSource.data = [];
    try {
      if (_data.length > 0) {
        for (let _row of _data) {
          this.dataSource.data.push({
            id: _row['plan_id'],
            bin_fee: _row['bin_fee'],
            bin_limit: _row['bin_limit'],
            category: _row['category'],
            description: _row['description'],
            direct_collect_commission: _row['direct_collect_commission'],
            display_on_ui: _row['display_on_ui'],
            handling_fee: _row['handling_fee'],
            instalment_amount: _row['instalment_amount'],
            membership_fee: _row['membership_fee'],
            per_container_refund: _row['per_container_refund'],
            plan_name: _row['plan_name'],
            context_menu: '...'
          });
        }
      }
    } catch (err) {
      console.log('err', err);
    }

    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;

    this.appService.appSpinner(false);
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  async editRowClick(row: any) {
    this._currentContext = row;
  }

  async openAddPlanDialog() {
    let dialogRef = this.dialog.open(AddPlanDialog, {
      autoFocus: true,
      minWidth: '25%',
      height: 'auto'
    });
    dialogRef.afterClosed().subscribe(async (result: boolean) => {
        if (result) await this.getData();
      }
    )
  }

  async openEditPlanDialog() {
    let dialogRef = this.dialog.open(EditPlanDialog, {
      autoFocus: true,
      minWidth: '25%',
      height: 'auto',
      data: {
        planName: this._currentContext.plan_name,
        description: this._currentContext.description,
        category: this._currentContext.category,
        binFee: this._currentContext.bin_fee,
        binLimit: this._currentContext.bin_limit,
        membershipFee: this._currentContext.membership_fee,
        instalmentAmount: this._currentContext.instalment_amount,
        directCollectCommission: this._currentContext.direct_collect_commission,
        handlingFee: this._currentContext.handling_fee,
        refundPerContainer: this._currentContext.per_container_refund,
        displayOnUI: this._currentContext.display_on_ui,
        planId: this._currentContext.id
      }
    });
    dialogRef.afterClosed().subscribe(async (result: boolean) => {
        if (result) await this.getData();
      }
    )
  }
}

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'add-plan-dialog',
  templateUrl: 'add-plan-dialog.html',
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class AddPlanDialog implements OnInit {
  isBtnDisabled: boolean = false;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  displayOnUI: boolean = false;
  disableBinLimit: boolean = false;

  categories: any[] = [
    'BASIC',
    'CHARITY',
    'PREMIUM'
  ];

  constructor(
    public dialogRef: MatDialogRef<AddPlanDialog>,
    private route: ActivatedRoute,
    private appService: AppService,
    private fg: FormBuilder,
    private apiService: ApiService,
  ) { }

  ngOnInit(): void {
    this.firstFormGroup = this.fg.group({
      planName: ['', Validators.required],
      description: ['', Validators.required],
      category: ['', Validators.required],
    })

    this.secondFormGroup = this.fg.group({
      binFee: [null, [Validators.pattern(/^\d*\.?\d+$/)]],
      binLimit: [null, [Validators.pattern(/^[0-9]+$/)]],
      membershipFee: [null, [Validators.pattern(/^\d*\.?\d+$/)]],
      instalmentAmount: [null, [Validators.pattern(/^\d*\.?\d+$/)]],
      directCollectCommission: ['0.005', [Validators.required, Validators.pattern(/^\d*\.?\d+$/)]],
      handlingFee: ['0.025', [Validators.required, Validators.pattern(/^\d*\.?\d+$/)]],
      refundPerContainer: ['0.1', [Validators.required, Validators.pattern(/^\d*\.?\d+$/)]],
      displayOnUI: [this.displayOnUI]
    })
  }

  closeDialogRef() {
    this.dialogRef.close(true)
  }

  changeDisplayOnUI() {
    this.displayOnUI = !this.displayOnUI
  }

  disableBinLimitClick() {
    this.disableBinLimit = !this.disableBinLimit

    if (this.disableBinLimit == true) {
      this.secondFormGroup.patchValue({
        binLimit: null
      })
    }
  }

  async save() {
    this.appService.appSpinner(true);
    this.isBtnDisabled = true;

    // in case admin changes mind on type of plan reset variables
    if (this.firstFormGroup.value.category == 'BASIC') {
      this.secondFormGroup.value.binFee = null
      this.secondFormGroup.value.membershipFee = null
      this.secondFormGroup.value.instalmentAmount = null
    } else if (this.firstFormGroup.value.category == 'PREMIUM') {
      this.secondFormGroup.value.instalmentAmount = null
    }

    try {
      let res = await this.apiService.createPlan(
        this.firstFormGroup.value.planName,
        this.firstFormGroup.value.description,
        this.firstFormGroup.value.category,
        this.secondFormGroup.value.binLimit,
        this.secondFormGroup.value.binFee,
        this.secondFormGroup.value.handlingFee,
        this.secondFormGroup.value.instalmentAmount,
        this.secondFormGroup.value.membershipFee,
        this.secondFormGroup.value.refundPerContainer,
        this.secondFormGroup.value.directCollectCommission,
        this.displayOnUI
      )

      this.appService.appSpinner(false)

      this.appService.sendNotification({
        type: alertAttributes.types.info,
        message: 'Plan has been added',
        body: '',
        displayNotification: true,
        autoCloseAfter: 3
      })

      setTimeout(() => {
        this.closeDialogRef()
      }, 1000);

    } catch (err) {
      this.appService.appSpinner(false);
      this.isBtnDisabled = false;
      console.log(err)
    }
  }
}

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'edit-plan-dialog',
  templateUrl: 'edit-plan-dialog.html',
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class EditPlanDialog implements OnInit {
  isBtnDisabled: boolean = false;
  frmGroup: FormGroup;
  disableBinLimit: boolean = false;
  displayOnUI: boolean;

  categories: any[] = [
    'BASIC',
    'CHARITY',
    'PREMIUM'
  ];

  displayOnUIs: any[] = [
    true,
    false
  ]

  constructor(
    public dialogRef: MatDialogRef<EditPlanDialog>,
    private route: ActivatedRoute,
    private appService: AppService,
    private apiService: ApiService,
    private fg: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: {
      planName: string;
      description: string;
      category: string;
      binFee: string;
      binLimit: string;
      membershipFee: string;
      instalmentAmount: string;
      directCollectCommission: string;
      handlingFee: string;
      refundPerContainer: string;
      displayOnUI: boolean;
      planId: string;
    }
  ) {  }

  ngOnInit(): void {
    this.frmGroup = this.fg.group({
      planName: ['', Validators.required],
      description: ['', Validators.required],
      category: ['', Validators.required],
      binFee: [null, [Validators.pattern(/^\d*\.?\d+$/)]],
      binLimit: [null, [Validators.pattern(/^[0-9]+$/)]],
      membershipFee: [null, [Validators.pattern(/^\d*\.?\d+$/)]],
      instalmentAmount: [null, [Validators.pattern(/^\d*\.?\d+$/)]],
      directCollectCommission: ['', [Validators.required, Validators.pattern(/^\d*\.?\d+$/)]],
      handlingFee: ['', [Validators.required, Validators.pattern(/^\d*\.?\d+$/)]],
      refundPerContainer: ['', [Validators.required, Validators.pattern(/^\d*\.?\d+$/)]],
    })

    this.frmGroup.patchValue({
      planName: this.data.planName,
      description: this.data.description,
      category: this.data.category,
      binFee: this.data.binFee,
      binLimit: this.data.binLimit,
      membershipFee: this.data.membershipFee,
      instalmentAmount: this.data.instalmentAmount,
      directCollectCommission: this.data.directCollectCommission,
      handlingFee: this.data.handlingFee,
      refundPerContainer: this.data.refundPerContainer,
    })

    this.displayOnUI = this.data.displayOnUI
  }

  disableBinLimitClick() {
    this.disableBinLimit = !this.disableBinLimit

    if (this.disableBinLimit == true) {
      this.frmGroup.patchValue({
        binLimit: null
      })
    }
  }

  changeDisplayOnUI() {
    this.displayOnUI = !this.displayOnUI
  }

  closeDialogRef() {
    this.dialogRef.close(true)
  }

  async save() {
    this.appService.appSpinner(true);
    this.isBtnDisabled = true;

    try {
      let res = await this.apiService.editPlan(
        this.frmGroup.value.planName,
        this.frmGroup.value.description,
        this.frmGroup.value.category,
        this.frmGroup.value.binLimit,
        this.frmGroup.value.binFee,
        this.frmGroup.value.handlingFee,
        this.frmGroup.value.instalmentAmount,
        this.frmGroup.value.membershipFee,
        this.frmGroup.value.refundPerContainer,
        this.frmGroup.value.directCollectCommission,
        this.displayOnUI,
        this.data.planId
      )

      this.appService.appSpinner(false)

      if (res[0].statusid == 1) {
        this.appService.sendNotification({
          type: alertAttributes.types.success,
          message: res[0].statusdesc,
          body: '',
          displayNotification: true,
          autoCloseAfter: 3
        })
      } else if (res[0].statusid == -1) {
        this.appService.sendNotification({
          type: alertAttributes.types.error,
          message: res[0].statusdesc,
          body: '',
          displayNotification: true,
          autoCloseAfter: 3
        })

        return;
      } else if (res[0].statusid == -2) {
        this.appService.sendNotification({
          type: alertAttributes.types.warning,
          message: res[0].statusdesc,
          body: '',
          displayNotification: true,
          autoCloseAfter: 3
        })

        return;
      }


      setTimeout(() => {
        this.closeDialogRef()
      }, 1000);

    } catch (err) {
      this.appService.appSpinner(false);
      this.isBtnDisabled = false;
      console.log(err)
    }
  }
}
