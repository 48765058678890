<mat-menu #userMenu="matMenu">
  <div mat-menu-item disabled>
    {{ userObject.firstName.charAt(0).toUpperCase() + userObject.firstName.slice(1) }}
    -
    {{ userObject.email }}
  </div>
  <button mat-menu-item (click)="signOut()"><mat-icon>logout</mat-icon>Log out</button>
</mat-menu>

<mat-toolbar color="primary" fxLayout="row" fxLayoutAlign="space-between center">
  <button mat-icon-button (click)="sidenav.toggle()" data-cy="side-menu">
    <mat-icon>menu</mat-icon>
  </button>
  <img class="img-logo" src="assets/logos/DIRECT-COLLECT-SECONDARY-LOGO-RGB-INVERTED-01.png" alt="logo" />
  <button mat-icon-button [matMenuTriggerFor]="userMenu">
    <mat-icon>account_circle</mat-icon>
  </button>
</mat-toolbar>

<mat-sidenav-container autosize="true">
  <mat-sidenav mode="side" #sidenav position="start" opened>
    <mat-nav-list class="px-0 py-0">
<!--      <a-->
<!--        mat-list-item-->
<!--        [routerLink]="'/' + _route_AdminHome"-->
<!--        (click)="closeSideNav('/' + _route_AdminHome)"-->
<!--        [ngClass]="{ 'my-list-item': this.router.url.endsWith(_route_AdminHome) }"-->
<!--      >-->
<!--        Home-->
<!--      </a>-->
      <a
        mat-list-item
        [routerLink]="'./' + _route_A2001a"
        (click)="closeSideNav('./' + _route_A2001a)"
        [ngClass]="{ 'my-list-item': this.router.url.endsWith(_route_A2001a) }"
      >
        Orders
      </a>
      <a
        mat-list-item
        [routerLink]="'./' + _route_A3001"
        (click)="closeSideNav('./' + _route_A3001)"
        [ngClass]="{ 'my-list-item': this.router.url.endsWith(_route_A3001) }"
      >
        Manifests
      </a>
      <a
        mat-list-item
        [routerLink]="'./' + _route_A9001"
        (click)="closeSideNav('./' + _route_A9001)"
        [ngClass]="{ 'my-list-item': this.router.url.endsWith(_route_A9001) }"
      >
        Customers
      </a>
<!--      <a-->
<!--        mat-list-item-->
<!--        [routerLink]="'./' + _route_A6001"-->
<!--        (click)="closeSideNav('./' + _route_A6001)"-->
<!--        [ngClass]="{ 'my-list-item': this.router.url.endsWith(_route_A6001) }"-->
<!--      >-->
<!--        Bin Management-->
<!--      </a>-->
      <a
        mat-list-item
        [routerLink]="'./' + _route_A7001"
        (click)="closeSideNav('./' + _route_A7001)"
        [ngClass]="{ 'my-list-item': this.router.url.endsWith(_route_A7001) }"
      >
        Driver Management
      </a>
      <a
        mat-list-item
        [routerLink]="'./' + _route_A8001"
        (click)="closeSideNav('./' + _route_A8001)"
        [ngClass]="{ 'my-list-item': this.router.url.endsWith(_route_A8001) }"
      >
        Announcements
      </a>
      <a
        mat-list-item
        [routerLink]="'./' + _route_A10001"
        (click)="closeSideNav('./' + _route_A10001)"
        [ngClass]="{ 'my-list-item': this.router.url.endsWith(_route_A10001) }"
      >
        Payments
      </a>
      <a
        mat-list-item
        [routerLink]="'./' + _route_A12001"
        (click)="closeSideNav('./' + _route_A12001)"
        [ngClass]="{ 'my-list-item': this.router.url.endsWith(_route_A12001) }"
      >
        Public Holidays
      </a>
      <a
        mat-list-item
        [routerLink]="'./' + _route_A13001"
        (click)="closeSideNav('./' + _route_A13001)"
        [ngClass]="{ 'my-list-item': this.router.url.endsWith(_route_A13001) }"
      >
        Depot Day Config
      </a>
      <a
        mat-list-item
        [routerLink]="'./' + _route_A14001"
        (click)="closeSideNav('./' + _route_A14001)"
        [ngClass]="{ 'my-list-item': this.router.url.endsWith(_route_A14001) }"
      >
        Depot Date Exceptions
      </a>
      <a
        mat-list-item
        [routerLink]="'./' + _route_A15001"
        (click)="closeSideNav('./' + _route_A15001)"
        [ngClass]="{ 'my-list-item': this.router.url.endsWith(_route_A15001) }"
      >
        Plans
      </a>
    </mat-nav-list>
  </mat-sidenav>

  <mat-sidenav-content>
    <router-outlet></router-outlet>
    <td mat-footer-cell *matFooterCellDef></td>
    <span class="version-info">Current build: {{ _currentApplicationVersion }} </span>
  </mat-sidenav-content>
</mat-sidenav-container>


