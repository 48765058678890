import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { AppService } from 'src/app/services/app.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-customer-financials',
  templateUrl: './customer-financials.component.html',
  styleUrls: ['./customer-financials.component.scss']
})
export class CustomerFinancialsComponent implements OnInit {
  _style: string = '';

  constructor(private fg: FormBuilder,
    private appService: AppService,
    private apiService: ApiService,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute) {
    this._style = this.authService.getStyle()

  }

  ngOnInit(): void {
  }

}
