<form [formGroup]="formGroup" fxLayout="column" [style.width.px]="400">
  <!-- Title -->
  <div fxFlex class="mat-h4 text-center">Add new bin</div>

  <!--  &lt;!&ndash; States &ndash;&gt;-->
  <!--  <div fxFlex>-->
  <!--    <mat-form-field appearance="outline">-->
  <!--      <mat-label>State</mat-label>-->
  <!--      <input data-cy="desc" formControlName="state" matInput readonly />-->
  <!--      <mat-error>required</mat-error>-->
  <!--    </mat-form-field>-->
  <!--  </div>-->

  <!--  &lt;!&ndash; Regions for selected state &ndash;&gt;-->
  <!--  <div fxFlex>-->
  <!--    <mat-form-field appearance="outline">-->
  <!--      <mat-label>Region</mat-label>-->
  <!--      <input data-cy="desc" formControlName="region" matInput readonly />-->
  <!--      <mat-error>required</mat-error>-->
  <!--    </mat-form-field>-->
  <!--  </div>-->

  <!-- Depots for selected region -->
  <div fxFlex>
    <mat-form-field appearance="outline">
      <mat-label>Depot</mat-label>
      <input data-cy="desc" formControlName="depot" matInput readonly/>
      <!--      <mat-select formControlName="depot">-->
      <!--        <mat-option *ngFor="let depot of depots" [value]="depot.key">{{ depot.desc }}</mat-option>-->
      <!--      </mat-select>-->
      <!--      <mat-error>required</mat-error>-->
    </mat-form-field>
  </div>

  <!-- Container types for selected region -->
  <div fxFlex>
    <mat-form-field appearance="outline">
      <mat-label>Container Type</mat-label>
      <mat-select formControlName="container_type">
        <mat-option *ngFor="let containerType of containerTypes" [value]="containerType.key">
          {{ containerType.desc }}
        </mat-option>
      </mat-select>
      <mat-error>required</mat-error>
    </mat-form-field>
  </div>

  <!-- Purchase date -->
  <div fxFlex>
    <mat-form-field appearance="outline">
      <mat-label>Purchase date</mat-label>
      <input matInput [matDatepicker]="picker" formControlName="purchase_date"/>
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
      <mat-error>required</mat-error>
    </mat-form-field>
  </div>

  <!-- Description -->
  <div fxFlex>
    <mat-form-field appearance="outline">
      <mat-label>Description</mat-label>
      <input matInput formControlName="desc" data-cy="desc"/>
      <mat-error>required</mat-error>
    </mat-form-field>
  </div>

  <!-- Quantity -->
  <div fxFlex>
    <mat-form-field appearance="outline">
      <mat-label>Quantity</mat-label>
      <input matInput data-cy="desc" formControlName="quantity" type="number"/>
      <mat-error>required</mat-error>
    </mat-form-field>
  </div>

  <!-- Save -->
  <div fxFlex>
    <button
      mat-raised-button
      [disabled]="!formGroup.valid"
      (click)="save()"
      data-cy="save"
      color="primary"
    >
      Save
    </button>
  </div>
</form>
