<div *ngIf="abaDownloadFile != ''" class="p-3 text-center">
  <a [href]="abaDownloadFile" [download]="abaFilename"
    >Click here to download ABA file</a
  >
</div>

<div class="form-container">
  <!-- <div class="filterInput">
    <input
      matInput
      placeholder="Search"
      class="typewriter"
      #searchInput
      (keyup)="doFilter($event.target.value)"
    />
  </div> -->

  <table
    mat-table
    [dataSource]="dataSource"
    matSort
    matSortDirection="desc"
    [matSortActive]="this._browseTableSettings.active"
    (matSortChange)="sortEvent($event)"
    class="tableBrowser"
  >
    <!-- Id/Checkbox Column -->
    <ng-container matColumnDef="id">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        class="colCode colHeader"
      >
        Code
      </th>
      <td mat-cell *matCellDef="let element" class="colCodeCell">
        <mat-icon
          class="material-icons-outlined"
          *ngIf="element.aba_batch != null && element.aba_batch != ''"
          matTooltip="ABA file already generated"
        >
          auto_graph
        </mat-icon>

        <mat-checkbox
          *ngIf="
            (element.aba_batch == null || element.aba_batch == '') &&
            element.bin_code != '-'
          "
          (change)="checkboxClick(element.id)"
        ></mat-checkbox>
      </td>
    </ng-container>

    <!-- batch_id:  Column -->
    <ng-container matColumnDef="batch_id">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        class="colCode colHeader"
      >
        Batch Id
      </th>
      <td mat-cell *matCellDef="let element" class="colCodeCell">
        {{ element.batch_id }}
      </td>
    </ng-container>

    <!-- Device Column -->
    <ng-container matColumnDef="device">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        class="colDesc colHeader"
      >
        Device
      </th>
      <td mat-cell *matCellDef="let element" class="colDescCell">
        {{ element.device }}
      </td>
    </ng-container>

    <!-- Method Column -->
    <ng-container matColumnDef="method">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        class="colDesc colHeader"
      >
        Method
      </th>
      <td mat-cell *matCellDef="let element" class="colDescCell">
        {{ element.method }}
      </td>
    </ng-container>

    <!-- Batch refund Column -->
    <ng-container matColumnDef="batch_refund">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        class="colAmount colHeader"
      >
        Batch refund ($)
      </th>
      <td mat-cell *matCellDef="let element" class="colAmountCell">
        {{ element.batch_refund | currency }}
      </td>
    </ng-container>

    <!-- Offboard date Column -->
    <ng-container matColumnDef="batch_status">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        class="colCode colHeader"
      >
        Batch status
      </th>
      <td mat-cell *matCellDef="let element" class="colCodeCell">
        {{ element.batch_status }}
      </td>
    </ng-container>

    <!-- Total qty Column -->
    <ng-container matColumnDef="total_qty">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        class="colAmount colHeader"
      >
        Batch qty
      </th>
      <td mat-cell *matCellDef="let element" class="colAmountCell">
        {{ element.total_qty | number }}
      </td>
    </ng-container>

    <!-- Bin Column -->
    <ng-container matColumnDef="bin_code">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        class="colDesc colHeader"
      >
        Bin
      </th>
      <td mat-cell *matCellDef="let element" class="colDescCell">
        {{ element.bin_code }}
      </td>
    </ng-container>

    <!-- ABA Batch no Column -->
    <ng-container matColumnDef="aba_batch">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        class="colDesc colHeader"
      >
        ABA Batch
      </th>
      <td mat-cell *matCellDef="let element" class="colDescCell">
        <span
          *ngIf="element.aba_batch != undefined && element.aba_batch != ''"
          style="cursor: pointer"
          class="text-decoration-underline"
          (click)="downloadABA(element.aba_batch)"
        >
          {{ element.aba_batch }}
        </span>
      </td>
    </ng-container>

    <!-- Bin code Column -->
    <ng-container matColumnDef="context_menu">
      <th
        mat-header-cell
        *matHeaderCellDef
        class="colIconOnly colHeader"
        [routerLink]="_route_NewDriver"
      ></th>
      <td
        mat-cell
        *matCellDef="let element"
        class="colIconOnlyCell"
        [matMenuTriggerFor]="menu"
        (click)="contextClick(element.id)"
      >
        {{ element.context_menu }}
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>

  <div class="tableFooter">
    <!-- <div class="page">
      Page:
      <mat-select
        (selectionChange)="loadPage($event)"
        [(value)]="this._currentBlock"
      >
        <mat-option
          *ngFor="let dataBlock of this._dataBlocks"
          [value]="dataBlock.label"
          >{{ dataBlock.label }}</mat-option
        ></mat-select
      >
    </div> -->

    <div class="paginator">
      <mat-paginator
        [pageSizeOptions]="[5, 10, 20]"
        showFirstLastButtons
        (page)="paginatorEvent($event)"
      ></mat-paginator>
    </div>
  </div>
</div>

<mat-menu #menu="matMenu">
  <button mat-menu-item (click)="generateABA()">
    <span>Generate ABA</span>
  </button>
</mat-menu>
