<div fxLayout="column" style="padding: 10px 10px 10px 10px">
  <div fxLayout="row" fxLayoutAlign="center center">
    <h2 mat-dialog-title>Add Exception - {{ depot }}</h2>
  </div>

  <div fxLayout="row" fxLayoutAlign="space-between center">
    <mat-form-field fxFlex>
      <input placeholder="Description" matInput [(ngModel)]="description">
    </mat-form-field>
  </div>

  <div class="mb-2" fxLayoutAlign="space-between center">
    <mat-form-field fxFlex>
      <input placeholder="New Dropoff Limit" matInput [(ngModel)]="newDropoffs" required>
    </mat-form-field>

    <mat-form-field fxFlex style="margin-left: 13px">
      <input placeholder="New Changeover Limit" matInput [(ngModel)]="newChangeovers" required>
    </mat-form-field>
  </div>

  <div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="center center">
    <button mat-raised-button color="primary" mat-dialog-close style="width: 25%">Cancel</button>
    <button mat-raised-button color="primary" (click)="save()" style="width: 25%" [disabled]="!newDropoffs || !newChangeovers">Update</button>
  </div>

</div>
