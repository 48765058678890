<div class="container-fluid byc-main-container">
  <!-- Headers -->
  <!-- ------------------------------------------------------- -->
  <div class="row py-2">
    <div class="col-1"></div>
    <!--  -->
    <!-- Location -->
    <div class="col-3 px-3 {{ this._style }}-text-title">Location</div>

    <!--  -->
    <!-- Drop off date, Payment type, Payment details -->
    <div class="col-3 px-3 {{ this._style }}-text-title">Drop off date</div>

    <!--  -->
    <!-- Order details -->
    <div class="col-4 px-3 {{ this._style }}-text-title">Order Details</div>
    <div class="col-1"></div>
  </div>

  <!--  -->
  <div class="row py-2">
    <div class="col-1"></div>

    <!-- Location -->
    <!-- ------------------------------------------------------- -->
    <div class="col-3 px-2">
      <div class="container-fluid {{ this._style }}-main-panel">
        <!--  -->
        <div class="row">
          <div class="col-12 py-xxl-3">
            <app-item-picker
              [_items]="this.addressItems"
              (itemPicked)="addressPicked($event)"
              #addressPicker>
            </app-item-picker>
          </div>
        </div>

        <!--  -->
        <div class="row">
          <div class="col py-2">
            <app-address-picker
              (addressPicked)="addressEntered($event)"></app-address-picker>
          </div>
        </div>
      </div>
    </div>
    <!--  -->

    <!-- Drop-off date, Payment type/details -->
    <!-- ------------------------------------------------------- -->
    <div class="col-3 px-2">
      <div class="container-fluid {{ this._style }}-main-panel py-2">
        <!-- Drop off date -->
        <div class="row">
          <div class="col">
            <app-date-picker
              (mDatePicked)="datePicked($event)"
              [_availableDate]="this.availableDates"
              [_selectedDate]="this.selectedDate">
            </app-date-picker>
          </div>
        </div>
        <!--  -->

        <!-- Payment type -->
        <div class="row">
          <div class="col-2"></div>
          <div class="col">
            <app-item-picker
              [_items]="this.paymentTypeItems"
              (itemPicked)="paymentTypePicked($event)"></app-item-picker>
          </div>
          <div class="col-2"></div>
        </div>
        <!--  -->

        <!-- Stripe payment -->
        <!-- ------------------------------------------- -->
        <div class="row pt-2" *ngIf="this.selectedPaymentType == 'FULL'">
          <div class="col-2"></div>
          <div class="col p-0">
            <div
              class="container-fluid p-2 {{
                this._style
              }}-main-panel byc-02-primary"
              data-cy="stripeCard">
              <div class="row">
                <div class="col py-1">Card Number</div>
              </div>
              <div class="row">
                <div class="col">
                  <div id="card-number"></div>
                </div>
              </div>

              <div class="row">
                <div class="col py-1 pt-4">Expiry</div>
              </div>
              <div class="row">
                <div class="col">
                  <div id="card-expiry"></div>
                </div>
              </div>

              <div class="row">
                <div class="col py-1 pt-4">CVC</div>
              </div>
              <div class="row">
                <div class="col pb-2">
                  <div id="card-cvc"></div>
                </div>
              </div>
            </div>
            <!--  -->
          </div>
          <div class="col-2"></div>
        </div>
        <!--  -->
      </div>
    </div>

    <!--  -->

    <!-- Bank and Order summary -->
    <!-- ------------------------------------------------------- -->
    <div class="col-4 px-3 byc-new-order-panel">
      <div class="container-fluid {{ this._style }}-main-panel">
        <!-- Bank details -->
        <div class="row">
          <div class="col py-1">
            <div class="container-fluid {{ this._style }}-main-panel">
              <div class="row">
                <div class="col py-3">
                  This account will only be used to deposit your payout amounts
                  into
                </div>
              </div>

              <div class="row">
                <div class="col">Account name</div>
              </div>

              <div class="row">
                <div class="col">
                  <mat-form-field
                    class="{{ this._style }}-form-field"
                    appearance="fill">
                    <input
                      matInput
                      value=""
                      id="accountName"
                      [(ngModel)]="this.accountName"
                      placeholder="Names(s) account is held under" />
                  </mat-form-field>
                </div>
              </div>

              <div class="row">
                <div class="col">Account number</div>
              </div>

              <div class="row">
                <div class="col">
                  <mat-form-field
                    class="{{ this._style }}-form-field"
                    appearance="fill">
                    <input
                      matInput
                      value=""
                      id="accountNumber"
                      [(ngModel)]="this.accountNumber"
                      placeholder="00000000" />
                  </mat-form-field>
                </div>
              </div>

              <div class="row">
                <div class="col">BSB</div>
              </div>

              <div class="row">
                <div class="col">
                  <mat-form-field
                    class="{{ this._style }}-form-field"
                    appearance="fill">
                    <input
                      matInput
                      value=""
                      id="accountBSB"
                      [(ngModel)]="this.accountBSB"
                      placeholder="000-000"
                      (ngModelChange)="updateBankName($event)" />
                  </mat-form-field>
                </div>
              </div>

              <div class="row">
                <div class="col">Bank</div>
              </div>

              <div class="row">
                <div class="col">
                  <mat-form-field
                    class="{{ this._style }}-form-field"
                    appearance="fill">
                    <input
                      matInput
                      value=""
                      id="bankName"
                      [(ngModel)]="this.bankName"
                      placeholder="Name of bank"
                      readonly />
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Order Summary -->
        <!-- ------------------------------------------------------- -->
        <div class="row">
          <div class="col py-2">
            <div class="container-fluid {{ this._style }}-main-panel">
              <div class="row pt-2 {{ this._style }}-text-panel-title">
                Subscription
              </div>
              <div class="row py-1 {{ this._style }}-text-panel-subtitle">
                {{ this.subscriptionType.description }}
              </div>
              <div class="row py-1 {{ this._style }}-text-panel-subtitle">
                {{ this.containerType.description }}
              </div>
              <div class="row py-3">
                Payout ($0.07 for each container) deposited into nominated bank
                account
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col py-1">
            <div class="container-fluid {{ this._style }}-main-panel">
              <div class="row">
                <div class="col pt-2 {{ this._style }}-text-panel-title">
                  Deposit payouts to
                </div>
              </div>
              <div class="row pt-2">
                <div class="col {{ this._style }}-text-panel-subtitle">
                  Account number
                </div>
              </div>
              <div class="row py-2">
                <div class="col {{ this._style }}-text-panel-subtitle">
                  {{ this.censoredAccountNumber }}
                </div>
              </div>
              <div class="row py-2">
                <div class="col {{ this._style }}-text-panel-subtitle">
                  {{ this.bankName }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <!--  -->
        <div class="row">
          <div class="col"></div>
          <div class="col">
            <button
              mat-raised-button
              class="d-block {{ this._style }}-next {{
                this._style
              }}-primary mt-2"
              (click)="placeOrder()">
              Place order
            </button>
          </div>
          <div class="col"></div>
        </div>

        <!--  -->
      </div>
    </div>
    <!--  -->
  </div>
</div>
