import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { alertAttributes } from 'src/app/helper/appAlert';
import { appParam } from 'src/app/helper/appSettings';
import { appUtils } from 'src/app/helper/appUtils';
import { ApiService } from 'src/app/services/api.service';
import { AppService } from 'src/app/services/app.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-uxa9002f',
  templateUrl: './uxa9002f.component.html',
  styleUrls: ['./uxa9002f.component.scss']
})
export class Uxa9002fComponent implements OnInit {
  _style: string = '';

  _customerId: string = '';
  _customerUid: string = '';

  _pickupRequests: any[] = [];
  _selectedPickupRequest: any;
  _defaultDateFormat: string = appParam.defaultDateFormat;

  // --------------------------
  // Date picker section
  availableDates: any[] = [];
  selectedDate: string = '';
  selectedDay: string = ''

  constructor(
    private appService: AppService,
    private apiService: ApiService,
    private authService: AuthService,
    private router: Router,
    private dialogRef: MatDialogRef<Uxa9002fComponent>,
    private route: ActivatedRoute) {
    this._style = this.authService.getStyle()

    this._customerId = this.route.snapshot.queryParamMap.get('id')
  }

  ngOnInit(): void {
    this.getCustomerRequests()
  }



  // Date picker section
  // --------------------------------------------------
  async getAvailableDates() {
  }

  async datePicked(event: any) {
    await this.updateSelectedDate(event)
  }


  async updateSelectedDate(_date: any) {
    this.selectedDate = _date
    this.selectedDay = ' ' + appUtils.getDayofWeek(this.selectedDate)
  }


  async getCustomerRequests() {
    this.appService.appSpinner(true)

    const res = await this.apiService.getCustomer(this._customerId)
    const resCustomer = res[0]

    this._customerUid = resCustomer['code']

    if (this._customerId != '') {
      this._pickupRequests = await this.apiService.getCustomerPickupRequests(this._customerUid, true)
      // console.log('_pickupRequests', this._pickupRequests)
    }

    // await this.getAvailableDates()
    this.appService.appSpinner(false)
  }

  async itemSelected(item: any) {
    this.appService.appSpinner(true)

    this._selectedPickupRequest = item
    this.selectedDate = this._selectedPickupRequest['selected_date']

    this.appService.appSpinner(false)
  }


  async updateRequest() {
    this.appService.appSpinner(true)

    const res = await this.apiService.updateOrder(this._customerUid, 'pickup_update', 'selected_date',
      { order_id: this._selectedPickupRequest.id, selected_date: appUtils.getDateAtZeroHrs(this.selectedDate) })
    if (res['id'] != undefined) {
      this.appService.appSpinner(false)

      this._selectedPickupRequest = null
      this.selectedDate = ''
      this.getCustomerRequests();

      this.appService.sendNotification({
        type: alertAttributes.types.info,
        message: 'Pickup request has been updated.',
        body: '',
        displayNotification: true,
        autoCloseAfter: 3
      })

    } else {
      this.appService.appSpinner(false)
    }
    this.dialogRef.close();
  }

  async cancelRequest() {
    this.appService.appSpinner(true)

    const res = await this.apiService.updateOrder(this._customerUid, 'pickup_cancel', '',
      { order_id: this._selectedPickupRequest.id })
    if (res['id'] != undefined) {
      this.appService.appSpinner(false)

      this._selectedPickupRequest = null
      this.selectedDate = ''
      this.getCustomerRequests();

      this.appService.sendNotification({
        type: alertAttributes.types.info,
        message: 'Pickup request has been cancelled!',
        body: '',
        displayNotification: true,
        autoCloseAfter: 3
      })

    } else {
      this.appService.appSpinner(false)
    }
    this.dialogRef.close();
  }

}
