<div fxLayout="column" fxLayoutAlign="center center">
  <div class="container-fluid h-100 p-0 m-0">
    <div fxLayout="row">
      <div fxFlex="10">
        <button
          mat-icon-button
          id="backButton"
          (click)="close()"
          data-cy="backButton">
          <mat-icon>keyboard_backspace</mat-icon>
        </button>
      </div>
      <div fxFlex="90" fxFlexAlign="center">
        <h2 mat-dialog-title>Order Summary</h2>
      </div>
    </div>
  </div>

  <mat-card class="card-secondary">
    <div class="mat-h3">Customer Name</div>
    <div class="mat-h4">{{ data && data['customerName'] }}</div>
    <div class="mat-h3">Address</div>
    <div class="mat-h4">{{ data && data['address'] }}</div>
    <div class="mat-h3">Order Status</div>
    <div class="mat-h4">{{ data && data['orderStatus'] }}</div>
    <div class="mat-h3"*ngIf="data['orderStatus'] != 'PENDING'">Images</div>
    <div class="d-flex flex-wrap" *ngIf="data['orderStatus'] != 'PENDING'">
      <mat-card
        fxLayout="column"
        fxLayoutAlign="start center"
        *ngFor="let item of data['images']; let i = index"
        class="card-secondary m-1 p-0">
        <img [src]="item" style="object-fit: contain; height: 75px" [alt]="'img bin' + i" />
      </mat-card>
    </div>
  </mat-card>
</div>

