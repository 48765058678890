import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { AppService } from 'src/app/services/app.service';
import { AuthService } from 'src/app/services/auth.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { ApiService } from 'src/app/services/api.service';
import { appParam } from 'src/app/helper/appSettings';
import { alertAttributes } from 'src/app/helper/appAlert';
import { Router, ActivatedRoute } from '@angular/router';
import { SelectionModel } from '@angular/cdk/collections';
import { MatDialog } from '@angular/material/dialog';
import { Uxa7001aComponent } from '../001a/uxa7001a.component';
import { Uxa7001cComponent } from '../001c/uxa7001c.component';

//  row structure
export interface TableRow {
  id: string; // used for the checkbox column
  full_name: string;
  depot: string;
  license_number: string;
  onboard_date: string;
  offboard_date: string;
  phone_asset_id: string;
  actions: string;
  selected: boolean;
}

let ELEMENT_DATA: TableRow[] = [];

@Component({
  selector: 'app-uxa7001',
  templateUrl: './uxa7001.component.html',
  styleUrls: ['./uxa7001.component.scss']
})
export class Uxa7001Component implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  dataSource = new MatTableDataSource<TableRow>(ELEMENT_DATA);
  selection = new SelectionModel<TableRow>(true, []);

  _style: string = '';
  _defaultDateFormat: string = appParam.defaultDateFormat;

  // used to store the selected driver
  _currentContext: TableRow;

  //  columns to be displayed in the table
  displayedColumns: string[] = [
    'id',
    'full_name',
    'depot',
    'license_number',
    'onboard_date',
    'offboard_date',
    'phone_asset_id',
    'actions'
  ];

  constructor(
    private appService: AppService,
    private apiService: ApiService,
    private authService: AuthService,
    public dialog: MatDialog
  ) {
    this._style = this.authService.getStyle();
  }

  async ngOnInit(): Promise<void> {
    await this.getData();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  //  get data from API
  async getData() {
    this.appService.appSpinner(true);

    // get all the users depot access
    let depotIds = [];
    let user = await this.apiService.getUsers(this.authService.getCurrentUserId())
    for (let depot of user.UsersDepotAccess) {
      depotIds.push(depot.Depot.id)
    }

    let _data = await this.apiService.getDriversByDepot(depotIds.toString());

    //  update the array for the table
    this.dataSource.data = [];
    try {
      if (_data.length > 0) {
        for (let _row of _data) {
          this.dataSource.data.push({
            id: _row['id'],
            full_name: _row['full_name'],
            depot: _row['depot'],
            license_number: _row['license_number'],
            onboard_date: _row['onboard_date'],
            offboard_date: _row['offboard_date'],
            phone_asset_id: _row['device_number'],
            actions: '...',
            selected: false
          });
        }
      }
    } catch (err) {
      console.log('err', err);
    }
    this.selection.clear();
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.appService.appSpinner(false);
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.filteredData.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.filteredData.forEach((row) => this.selection.select(row));
  }

  toggle(row, event) {
    event ? this.selection.toggle(row) : null;
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: TableRow): string {
    if (!row) return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    else return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id + 1}`;
  }

  contextClick(row: any) {
    this._currentContext = row;
  }

  openDoc(event: any) {
    console.log('openDoc', event);
  }

  async offBoardByDrivers() {
    await this.offBoard(this.selection.selected);
  }

  async offBoardByDriver() {
    await this.offBoard([this._currentContext]);
  }

  async offBoard(drivers) {
    this.appService.appSpinner(true);

    if (drivers.length > 0) {
      const res = await this.apiService.offboardDriver(drivers);

      if (res.length > 0) {
        this.appService.appSpinner(false);

        this.appService.sendNotification({
          type: alertAttributes.types.info,
          message: 'Selected drivers have been offboarded.',
          body: '',
          displayNotification: true
        });

        await this.getData();
      }
    } else {
      this.appService.appSpinner(false);
      this.appService.sendNotification({
        type: alertAttributes.types.warning,
        message: 'Please check at least one driver',
        body: '',
        displayNotification: true
      });
    }

    this.appService.appSpinner(false);
  }

  openCreateDriverDialog() {
    let dialogRef = this.dialog.open(Uxa7001aComponent, { data: {} });
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) await this.getData();
    });
  }

  openEditDriverDialog() {
    let dialogRef = this.dialog.open(Uxa7001aComponent, { data: { id: this._currentContext.id } });
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) await this.getData();
    });
  }

  // openHistoryDialog() {
  //   this.dialog.open(Uxa7001cComponent, { data: { id: this._currentContext.id } });
  // }
}
