<form [formGroup]="frmGroup" fxLayout="column">
  <div fxLayout="row" fxLayoutGap="50px">
    <div fxLayout="column" [style.min-width.px]="300">
      <div class="mat-title">Plan Details</div>
      <mat-form-field appearance="outline">
        <mat-label>Plan Name</mat-label>
        <input
          matInput
          value=""
          data-cy="planName"
          id="planName"
          formControlName="planName" />
        <mat-error>Required</mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Description</mat-label>
        <textarea
          matInput
          rows="6"
          value=""
          data-cy="description"
          id="description"
          formControlName="description"></textarea>
        <mat-error>Required</mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Category</mat-label>
        <mat-select formControlName="category">
          <mat-option
            *ngFor="let category of categories"
            [value]="category">
            {{ category }}
          </mat-option>
        </mat-select>
        <mat-error>Required</mat-error>
      </mat-form-field>
    </div>

    <div fxLayout="column" [style.min-width.px]="250">
      <div class="mat-title">Fees & Limits</div>
      <mat-form-field appearance="outline">
        <mat-label>Bin Limit</mat-label>
        <input
          matInput
          value=""
          data-cy="binLimit"
          id="binLimit"
          formControlName="binLimit" />
        <mat-error *ngIf="this.frmGroup.controls.binLimit.errors?.pattern">Only numbers allowed</mat-error>
      </mat-form-field>

      <mat-checkbox color="primary" (change)="disableBinLimitClick()" style="padding-bottom: 20px;">
        Unlimited number of bins?
      </mat-checkbox>

      <mat-form-field appearance="outline" *ngIf="frmGroup.value.category !== 'BASIC'">
        <mat-label>Additional Bin Fee</mat-label>
        <input
          matInput
          value=""
          data-cy="binFee"
          id="binFee"
          formControlName="binFee" />
        <mat-error *ngIf="this.frmGroup.controls.binFee.errors?.pattern">Only numbers allowed</mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline" *ngIf="frmGroup.value.category !== 'BASIC'">
        <mat-label>Membership Fee</mat-label>
        <input
          matInput
          value=""
          data-cy="membershipFee"
          id="membershipFee"
          formControlName="membershipFee" />
        <mat-error *ngIf="this.frmGroup.controls.membershipFee.errors?.pattern">Only numbers allowed</mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline" *ngIf="frmGroup.value.category == 'CHARITY'">
        <mat-label>Instalment Amount</mat-label>
        <input
          matInput
          value=""
          data-cy="instalmentAmount"
          id="instalmentAmount"
          formControlName="instalmentAmount" />
        <mat-error *ngIf="this.frmGroup.controls.instalmentAmount.errors?.pattern">Only numbers allowed</mat-error>
      </mat-form-field>

    </div>

    <div fxLayout="column" [style.min-width.px]="250">
      <div class="mat-title">Direct Collect Fees</div>
      <mat-form-field appearance="outline">
        <mat-label>Direct Collect Commission</mat-label>
        <input
          matInput
          value=""
          data-cy="directCollectCommission"
          id="directCollectCommission"
          formControlName="directCollectCommission" />
        <mat-error *ngIf="this.frmGroup.controls.directCollectCommission.errors?.required">required</mat-error>
        <mat-error *ngIf="this.frmGroup.controls.directCollectCommission.errors?.pattern">Only numbers allowed</mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Handling Fee</mat-label>
        <input
          matInput
          value=""
          data-cy="handlingFee"
          id="handlingFee"
          formControlName="handlingFee" />
        <mat-error *ngIf="this.frmGroup.controls.handlingFee.errors?.required">required</mat-error>
        <mat-error *ngIf="this.frmGroup.controls.handlingFee.errors?.pattern">Only numbers allowed</mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Refund Per Container</mat-label>
        <input
          matInput
          value=""
          data-cy="refundPerContainer"
          id="refundPerContainer"
          formControlName="refundPerContainer" />
        <mat-error *ngIf="this.frmGroup.controls.refundPerContainer.errors?.required">required</mat-error>
        <mat-error *ngIf="this.frmGroup.controls.refundPerContainer.errors?.pattern">Only numbers allowed</mat-error>
      </mat-form-field>
    </div>

    <div fxLayout="column" [style.min-width.px]="250">
      <div class="mat-title">Display Options</div>
      <mat-label>Display on UI?</mat-label>
      <mat-slide-toggle class="text-invisible" color="accent" [checked]="displayOnUI" (change)="changeDisplayOnUI()"></mat-slide-toggle>
    </div>
  </div>

  <div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="center center">
    <button mat-raised-button color="primary" mat-dialog-close style="width: 20%">Cancel</button>
    <button mat-raised-button color="primary" (click)="save()" [disabled]="!frmGroup.valid" style="width: 20%">Save</button>
  </div>

</form>
