import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { alertAttributes } from 'src/app/helper/appAlert';
import { ApiService } from 'src/app/services/api.service';
import { AppService } from 'src/app/services/app.service';
import { AuthService } from 'src/app/services/auth.service';
import { MatDialogRef } from '@angular/material/dialog';
import {appParam} from "../../helper/appSettings";

@Component({
  selector: 'app-uxa6001a',
  templateUrl: './uxa6001a.component.html',
  styleUrls: ['./uxa6001a.component.scss']
})
export class Uxa6001aComponent implements OnInit {
  style = '';
  formGroup: FormGroup;
  user: any = null;
  containerTypes: any[] = [];
  _defaultDateTimeFormat: string = appParam.defaultDateTimeFormat;

  constructor(
    private dialogRef: MatDialogRef<Uxa6001aComponent>,
    private formBuilder: FormBuilder,
    private appService: AppService,
    private apiService: ApiService,
    private authService: AuthService,
  ) {
    this.style = this.authService.getStyle();
    this.getData();
  }

  ngOnInit(): void {
    this.formGroup = this.formBuilder.group({
      prefix: ['DC', Validators.required],
      depot: ['', Validators.required],
      container_type: ['', Validators.required],
      desc: ['', Validators.required],
      purchase_date: ['', Validators.required],
      quantity: [1, [Validators.required, Validators.min(1)]]
    });
  }

  async getData(): Promise<void> {
    this.appService.appSpinner(true);

    // get user
    this.user = await this.apiService.getUsers(this.appService.UserObject.userId);

    // Set customer state and region
    if (this.user) {
      // this.formGroup.controls.state.setValue(this.customer.refLocationType.refState.desc);
      // this.formGroup.controls.region.setValue(this.customer.refRegion.desc);
      this.formGroup.controls.depot.setValue(this.user.refDepot.desc)
      this.containerTypes = await this.apiService.getAdminContainerTypes(this.user.refDepot.refRegion.id);
    }
    this.appService.appSpinner(false);
  }

  async save(): Promise<void> {
    try {
      this.appService.appSpinner(true);

      // Prepare the Bin object
      const stateId = this.user.refState.id;
      const regionId = this.user.refDepot.refRegion.id;
      const depotId = this.user.refDepot.id;
      const seconds = new Date().getSeconds();
      const bin = {
        state_id: stateId,
        region_id: regionId,
        depot_id: depotId,
        container_type_id: this.formGroup.value.container_type,
        purchase_date: this.formGroup.value.purchase_date,
        desc: this.formGroup.value.desc,
        bin_number: 'DC' + stateId + regionId + seconds
      };

      // Insert bins
      const idList: number[] = [];
      for (let i = 0; i < this.formGroup.value.quantity; i++) {
        const res = await this.apiService.createBin(bin);
        idList.push(res.code);
      }

      // Go back to the bin management menu and raise a notification
      this.appService.sendNotification({
        type: alertAttributes.types.info,
        message: `${idList.length} bin(s) have been onboarded: ${idList.join(', ')}`,
        body: '',
        displayNotification: true
      });
      this.appService.appSpinner(false);
      this.dialogRef.close(true);
    } catch (err) {
      console.error(err);
      this.appService.appSpinner(false);
      this.appService.sendNotification({
        type: alertAttributes.types.error,
        message: 'Error creating bin: ' + err,
        body: '',
        displayNotification: true
      });
    }
  }
}
