// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  appVersion: require('../../package.json').version,
  production: true,
  environment: 'dev',

  apiBase: 'https://depotapi.test.recyclingapp.net',
  apiBaseCustomer: 'https://api.test.recyclingapp.net',
  Cognito: {
    domain: 'direct-collect-test-admin-domain.auth.ap-southeast-2.amazoncognito.com',
    userPoolId: 'ap-southeast-2_NsPFgS2EJ',
    userPoolWebClientId: '2ch7pq51j8j3u62hbn1cipkrpg',
    redirectSignIn: 'https://d26q23fraxr5e7.cloudfront.net:4200/user-home',
    redirectSignOut: 'https://d26q23fraxr5e7.cloudfront.net:4200'
  },
  routeOptimization: {
    baseUrl: 'https://sandbox.flexops-api.adionatech.com/api/v1'
  },
  S3: {
    bucket: 'direct-collect-test-admin-cds-s3',
    identityPoolId: 'ap-southeast-2:bdbe6cea-3132-475c-bddb-85617fe93d26'
  },
  stripe_pk: 'pk_test_51LZ2OoJWH8z1ad0RUSmYUtu6xYhbPhRizIPEj9Q6PbYftO9PPu65Bqxf16e0xK76BOuWMJdimsXvA7HdTo8ZxEJr008NXJ8tdo',
  maps_key: 'AIzaSyCoi9BscJgb32_p3ZoWlW5d1Ll6eaxGq4g'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
