import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { AppService } from 'src/app/services/app.service';
import { AuthService } from 'src/app/services/auth.service';
import { IBrowseTableSettings, IDataBlock } from 'src/app/helper/appInterfaces';

import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';

import { ApiService } from 'src/app/services/api.service';
import { appParam } from 'src/app/helper/appSettings';
import { ActivatedRoute } from '@angular/router';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

//  row structure
export interface TableRow {
  created_at: Date;
  desc: string;
}

let ELEMENT_DATA: TableRow[] = [];

@Component({
  selector: 'app-uxa6001c',
  templateUrl: './uxa6001c.component.html',
  styleUrls: ['./uxa6001c.component.scss']
})
export class Uxa6001cComponent implements OnInit {
  _style: string = '';
  _defaultDateTimeFormat: string = appParam.defaultDateTimeFormat;

  _id: string = '';

  _rowCounts: number = 0;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { id: string },
    private dialogRef: MatDialogRef<Uxa6001cComponent>,
    private appService: AppService,
    private apiService: ApiService,
    private authService: AuthService,
    private route: ActivatedRoute
  ) {
    this._style = this.authService.getStyle();
  }

  ngOnInit(): void {
    this._id = this.data.id || this.route.snapshot.queryParamMap.get('id');
    this.getData(0, 10);
  }

  //  table settings
  public _browseTableSettings: IBrowseTableSettings = {
    active: 'id',
    direction: 'asc',
    pageIndex: 0,
    pageSize: 10
  };

  //  used to control the pagination with the back-end
  _pageSize = 5;
  public _dataBlocks: IDataBlock[] = [
    {
      label: '0-10',
      from: 0,
      size: this._pageSize,
      class: 'btnPaginator'
    }
  ];
  _currentBlock: string = '1-10';

  //  columns to be displayed in the table
  displayedColumns: string[] = ['created_at', 'desc'];

  dataSource = new MatTableDataSource<TableRow>(ELEMENT_DATA);

  tableSortField: string;
  tableSortDirection: string;
  tablePageSize: number = 10;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  // @ViewChild("searchInput") input: ElementRef;

  //  get data from API
  async getData(from: number, size: number) {
    this.appService.appSpinner(true);

    let _data = await this.apiService.getBinHistory(this._id);
    this._rowCounts = _data.length;

    await this.updatePages(_data.length);
    this.dataSource.data = [];
    try {
      for (let _row of _data) {
        this.dataSource.data.push({
          created_at: _row['created_at'],
          desc: _row['log_details_1'] + ',    ' + _row['log_details_2']
        });
      }
    } catch (err) {
      console.log('err', err);
    }

    // console.log("tableData", this.dataSource.data);

    this.paginator.pageSize = this.tablePageSize;
    this.paginator.pageSize = this._browseTableSettings.pageSize;
    this.paginator.pageIndex = this._browseTableSettings.pageIndex;

    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    this.tableSortField = this._browseTableSettings.active;
    this.tableSortDirection = this._browseTableSettings.direction;

    this.appService.appSpinner(false);
  }

  //  when the user clicks on a column to sort
  sortEvent(event: any) {
    this._browseTableSettings = {
      active: event.active,
      direction: event.direction === '' ? 'asc' : 'desc',
      pageIndex: this._browseTableSettings.pageIndex,
      pageSize: this._browseTableSettings.pageSize
    };
  }


  openDoc(event: any) {
    // console.log("openDoc", event);
  }

  //  setup the current displayed page
  updatePages(hits: number) {
    this._dataBlocks = [];

    let isCurrentPage = 'btnPaginatorActive';

    if (hits < this._pageSize) {
      this._dataBlocks.push({
        label: ''.concat('1', ' - ', hits.toString()),
        from: 1,
        size: this._pageSize,
        class: isCurrentPage
      });

      return true;
    } else {
      for (let i = 0; i < hits; i += this._pageSize) {
        this._dataBlocks.push({
          label: ''.concat((i + 1).toString(), '-', (i + this._pageSize).toString()),
          from: i,
          size: this._pageSize,
          class: isCurrentPage
        });
        if (i > hits) {
          return true;
        }

        isCurrentPage = 'btnPaginator';
      }
    }

    return true;
  }

  // controls pagination
  async loadPage(event: any) {
    this._currentBlock = event.value;
    let blockStart = 0;

    await this._dataBlocks.forEach((_block) => {
      if (_block.label == event.value) {
        blockStart = _block.from;
      }
    });

    this.getData(blockStart, this._pageSize);
  }

  paginatorEvent(event: any) {
    this._browseTableSettings = {
      active: this._browseTableSettings.active,
      direction: this._browseTableSettings.direction,
      pageIndex: event.pageIndex,
      pageSize: event.pageSize
    };
  }
}
