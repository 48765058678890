<form [formGroup]="frmGroup" fxLayout="column">
  <div fxLayout="row" fxLayoutGap="50px">
    <div fxLayout="column" [style.min-width.px]="250">
      <div class="mat-title">Driver Details</div>

      <mat-form-field appearance="outline">
        <mat-label>First name</mat-label>
        <input
          matInput
          placeholder="First name"
          formControlName="first_name"
          data-cy="first_name"
        />
        <mat-error>Required</mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Last name</mat-label>
        <input
          matInput
          placeholder="Last name"
          formControlName="last_name"
          data-cy="last_name"
        />
        <mat-error>Required</mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Mobile</mat-label>
        <input
          matInput
          placeholder="Mobile number"
          formControlName="mobile"
          data-cy="mobile"
        />
        <mat-error>Required</mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>License number</mat-label>
        <input
          matInput
          placeholder="License number"
          formControlName="license_number"
          data-cy="license_number"
        />
        <mat-error>Required</mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Email</mat-label>
        <input
          matInput
          placeholder="Email"
          formControlName="email"
          data-cy="email"
        />
        <mat-error>Required</mat-error>
      </mat-form-field>


    </div>

    <div fxLayout="column" [style.min-width.px]="250">
      <div class="mat-title">Location</div>

      <mat-form-field appearance="outline">
        <mat-label>Old address</mat-label>
        <input matInput value="{{this.oldAddress}}" disabled="true"/>
      </mat-form-field>

      <app-address-picker (addressPicked)="addressPicked($event)"></app-address-picker>
    </div>

    <div fxLayout="column" [style.min-width.px]="250">
      <div class="mat-title">Depot</div>
      <mat-form-field appearance="outline">
        <mat-label>Depot</mat-label>
        <mat-select formControlName="depot" data-cy="depot">
          <mat-option
            *ngFor="let depot of depots"
            [value]="depot.key"
          >{{ depot.desc }}</mat-option
          ></mat-select
        >
        <mat-error>Required</mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Phone Asset ID</mat-label>
        <input
          matInput
          placeholder="Phone Asset ID"
          formControlName="device_number"
          data-cy="device_number"
        />
      </mat-form-field>

    </div>
  </div>

  <button
    mat-raised-button
    id="save"
    class="d-block {{ this._style }}-next {{ this._style }}-primary mt-4"
    (click)="save()"
    data-cy="save"
    [disabled]="!frmGroup.valid">
    Save
  </button>
</form>
