<mat-card [style.max-width.px]="750" class="my-2 p-3 mat-elevation-z2">
  <div fxLayout="row">
    <div>
      <div class="row">
        <div class="col-6 {{ this._style }}-announcement-pub">
          {{ _announcement.pub_from | date: this._defaultDateFormat }}
        </div>
        <div class="col-6 {{ this._style }}-announcement-pub">
          {{ _announcement.pub_to | date: this._defaultDateFormat }}
        </div>
      </div>

      <div class="row">
        <div class="col-12 {{ this._style }}-announcement-main-heading">
          {{ _announcement.main_heading }}
        </div>
      </div>

      <div class="row">
        <div class="col-12 {{ this._style }}-announcement-sub-heading">
          {{ _announcement.sub_heading }}
        </div>
      </div>

      <div class="row">
        <div class="col-12 {{ this._style }}-announcement-body-content">
          {{ _announcement.body_content }}
        </div>
      </div>
    </div>
    <div class="p-5">
      <img [src]="_thumbnail" *ngIf="_thumbnail" class="img-announcement" />
    </div>
  </div>
</mat-card>
