import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { alertAttributes } from 'src/app/helper/appAlert';
import { IAddress } from 'src/app/helper/appInterfaces';
import { ApiService } from 'src/app/services/api.service';
import { AppService } from 'src/app/services/app.service';
import { AuthService } from 'src/app/services/auth.service';
import { appUtils } from '../../helper/appUtils'


@Component({
  selector: 'app-customer-details',
  templateUrl: './customer-details.component.html',
  styleUrls: ['./customer-details.component.scss']
})
export class CustomerDetailsComponent implements OnInit {
  _style: string = '';

  @Input() _customerId: any = '';
  _customerUid: string = '';

  _editMode: boolean = false; //  for edit driver purpose

  frmGroup: FormGroup;

  constructor(private fg: FormBuilder,
    private appService: AppService,
    private apiService: ApiService,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute) {
    this._style = this.authService.getStyle()

  }

  ngOnInit(): void {

    this.frmGroup = this.fg.group({
      id: '',
      first_name: ['', Validators.required],
      last_name: ['', Validators.required],
      mobile: ['', Validators.required],
      email: ['', Validators.required],
      street_address: ['', Validators.required],
      suburb: ['', Validators.required],
    })

    this.frmGroup.valueChanges.subscribe((value) => {
    })

    this.getData()
  }


  async getData() {
    this.appService.appSpinner(true)

    //  load data for edit mode
    if (this._customerId != '') {
      let resCustomer = await this.apiService.getCustomer(this._customerId)

      if (resCustomer.length > 0) {
        const editCustomer = resCustomer[0]

        this._customerUid = editCustomer['code']
        this.frmGroup.controls['id'].setValue(editCustomer['id'])
        this.frmGroup.controls['first_name'].setValue(editCustomer['first_name'])
        this.frmGroup.controls['last_name'].setValue(editCustomer['last_name'])
        this.frmGroup.controls['mobile'].setValue(editCustomer['mobile'])
        this.frmGroup.controls['email'].setValue(editCustomer['email'])
        this.frmGroup.controls['street_address'].setValue(editCustomer['CustomerAddress']['street_address'])
        this.frmGroup.controls['suburb'].setValue(appUtils.getSuburb(editCustomer['CustomerAddress']['street_address']))
      }
    }

    this.appService.appSpinner(false)
  }

  async editMode() {
    this._editMode = !this._editMode
  }

  async save() {
    this.appService.appSpinner(true)

    const customer = {
      customer_id: this.frmGroup.value.id,
      userId: this._customerUid,
      depot_id: this.frmGroup.value.depot,
      firstName: this.frmGroup.value.first_name,
      lastName: this.frmGroup.value.last_name,
      mobile: this.frmGroup.value.mobile,
      email: this.frmGroup.value.email,
      street_address: this.frmGroup.value.street_address,
      suburb: this.frmGroup.value.suburb,
    }

    const res = await this.apiService.createCustomer(this._customerUid, customer);
    if (res['id'] != undefined) {
      this.appService.appSpinner(false)

      this.appService.sendNotification({
        type: alertAttributes.types.info,
        message: 'Customer details have been updated.',
        body: '',
        displayNotification: true,
        autoCloseAfter: 2
      })

    } else {
      this.appService.appSpinner(false)
    }

  }
}
