<div class="container-fluid parent-container" id="manifest">
  <div class="row py-2 h-100">
    <!-- Left side stops and stats -->

    <div class="col-4">
      <div class="container-fluid h-100 p-0 m-0">
        <!-- Action buttons -->
          <div fxLayout="row" fxLayoutGap="10px" class="my-3">
<!--            <button-->
<!--              mat-raised-button-->
<!--              id="sendToDriver"-->
<!--              color="accent"-->
<!--              (click)="sendToDriver()"-->
<!--              data-cy="sendToDriver"-->
<!--            >-->
<!--              Send to driver app-->
<!--            </button>-->

<!--            <button-->
<!--              mat-raised-button-->
<!--              id="printManifest"-->
<!--              color="accent"-->
<!--              (click)="printManifest()"-->
<!--              data-cy="printManifest"-->
<!--            >-->
<!--              Print manifest-->
<!--            </button>-->
            <button
              mat-icon-button
              id="backButton"
              (click)="back()"
              data-cy="backButton">
            <mat-icon>keyboard_backspace</mat-icon>
            </button>
          </div>

        <!-- header -->
        <div class="row">
          <div class="col-7 p-2 byc-01-text-bold">
            {{ this.sendToDriver }} Stops, duration ({{ this.totalDuration }})
          </div>
          <div class="col p-2 byc-01-text-bold">
            Driver - {{ this.assignedDriver }}
          </div>
        </div>

        <div class="row py-2 h-100">
          <!-- Route list -->
          <div class="col-12 p-0">
            <div class="container-fluid w-100 p-1" style="height: calc(100vh - 326px); overflow-y: scroll; overflow-x: hidden;">
              <div class="row">
                <div class="" *ngFor="let stop of stops; let lst = last; let fst = first">
                  <app-stop
                    *ngIf="!fst && !lst"
                    [_stop]="stop"
                    [_clickable]="true"
                    (click)="orderSummaryDialog(stop)"
                  ></app-stop>
                  <app-stop
                    [_clickable]="false"
                    *ngIf="fst || lst"
                    [_stop]="stop"
                  ></app-stop>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Map -->
    <div class="col-8">
      <div class="container-fluid h-100 p-0 m-0">
        <div class="row py-2 h-100">
          <div class="col-12">
            <agm-map
              [latitude]="lat"
              [longitude]="lng"
              [zoom]="zoom"
              style="height: 90%; width: 100%"
            >
              <!-- Marker -->
              <agm-marker
                *ngFor="let marker of markers"
                [latitude]="marker.lat"
                [longitude]="marker.lng"
                (markerClick)="selectMarker($event)"
                [label]="{
                  fontSize: '13 px',
                  fontWeight: 'bold',
                  text: marker.label,
                  color: 'white'
                }"
              >
              </agm-marker>

              <!-- directions -->
              <agm-direction
                [origin]="origin"
                [destination]="destination"
                [waypoints]="waypoints"
                [renderOptions]="renderOptions"
              ></agm-direction>
            </agm-map>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- header -->
</div>
