import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { AppService } from 'src/app/services/app.service';
import { AuthService } from 'src/app/services/auth.service';

import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { alertAttributes } from 'src/app/helper/appAlert';
import { appUtils } from 'src/app/helper/appUtils';
import { appParam } from 'src/app/helper/appSettings';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-uxa8001a',
  templateUrl: './uxa8001a.component.html',
  styleUrls: ['./uxa8001a.component.scss']
})
export class Uxa8001aComponent implements OnInit {
  _style: string = '';

  frmGroup: FormGroup;
  frmGroupBackup: FormGroup; // used to track which fields were changed

  regions: any[] = []

  availableDates: any[] = [];

  _id: string = ''; //  for edit driver purpose
  _editMode: boolean = false; //  for edit driver purpose

  constructor(private fg: FormBuilder,
    private appService: AppService,
    private apiService: ApiService,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer
  ) {
    this._style = this.authService.getStyle()
  }

  ngOnInit(): void {
    this.frmGroup = this.fg.group({
      id: '',
      anc_type: ['CUS', Validators.required],
      region: ['', Validators.required],
      main_heading: ['', Validators.required],
      sub_heading: ['', Validators.required],
      body_content: ['', Validators.required],
      pub_from: ['', Validators.required],
      pub_to: ['', Validators.required],
    })

    this.frmGroupBackup = this.frmGroup.value

    this.frmGroup.valueChanges.subscribe((value) => {
      this.valueChanged(value)
    })

    this._id = this.route.snapshot.queryParamMap.get('id')
    this.getData()
  }



  async getData() {
    this.appService.appSpinner(true)

    // get all the users depot access
    let regionIds = [];
    let user = await this.apiService.getUsers(this.authService.getCurrentUserId())
    for (let depot of user.UsersDepotAccess) {
      regionIds.push(depot.Depot.refSuburb.refRegion.id);
    }

    await this.getRegions(regionIds)

    this.availableDates = appUtils.getDates(new Date(), new Date(appParam.maxDate));

    //  load data for edit mode
    if (this._id != '') {
      let resAnnouncement = await this.apiService.getAnnouncements(this._id)

      if (resAnnouncement.length > 0) {
        const editAnnouncement = resAnnouncement[0]

        this.frmGroup.controls['id'].setValue(editAnnouncement['id'])
        this.frmGroup.controls['anc_type'].setValue(editAnnouncement['announcement_type'])

        this.frmGroup.controls['region'].setValue(editAnnouncement['refRegion']['id'])

        this.frmGroup.controls['main_heading'].setValue(editAnnouncement['main_heading'])
        this.frmGroup.controls['sub_heading'].setValue(editAnnouncement['sub_heading'])
        this.frmGroup.controls['body_content'].setValue(editAnnouncement['body_content'])
        this.frmGroup.controls['pub_from'].setValue(editAnnouncement['pub_from'])
        this.frmGroup.controls['pub_to'].setValue(editAnnouncement['pub_to'])

        this._editMode = true;
      }
    }


    this.appService.appSpinner(false)
  }


  async valueChanged(frmValues: any) {
    this.frmGroupBackup = frmValues;
  }

  async getRegions(regionIds: any) {
    let resRegions = await this.apiService.getRegions('', regionIds)
    this.regions = resRegions
  }

  async pubFrom(value: any) {
    this.frmGroup.patchValue({ pub_from: value })
  }

  async pubTo(value: any) {
    this.frmGroup.patchValue({ pub_to: value })
  }


  get f() {
    return this.frmGroup.controls;
  }


  async save() {
    this.appService.appSpinner(true)

    const announcement = {
      id: this.frmGroup.value.id,
      announcement_type: this.frmGroup.value.anc_type,
      region_id: this.frmGroup.value.region,
      code: this.frmGroup.value.region + '-' + this.frmGroup.value.main_heading,
      desc: this.frmGroup.value.main_heading,
      main_heading: this.frmGroup.value.main_heading,
      sub_heading: this.frmGroup.value.sub_heading,
      body_content: this.frmGroup.value.body_content,
      pub_from: this.frmGroup.value.pub_from,
      pub_to: this.frmGroup.value.pub_to,
    }

    const res = await this.apiService.createAnnouncement(announcement);
    if (res['id'] != undefined) {
      this.appService.appSpinner(false)

      this.appService.sendNotification({
        type: alertAttributes.types.info,
        message: 'The announcement published',
        body: '',
        displayNotification: true,
        autoCloseAfter: 3
      })

      setTimeout(() => {
        this.router.navigate(['../' + appParam.routing.A8_announcementManagement.A8001], { relativeTo: this.route, });
      }, 3000);

    }

    this.appService.appSpinner(false)
  }




}
