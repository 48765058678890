import { Component, OnInit, ViewChild } from '@angular/core';

import { NgxCsvParser } from 'ngx-csv-parser';
import { NgxCSVParserError } from 'ngx-csv-parser';
import { alertAttributes } from 'src/app/helper/appAlert';
import { appUtils } from 'src/app/helper/appUtils';
import { ApiService } from 'src/app/services/api.service';
import { AppService } from 'src/app/services/app.service';
import { AuthService } from 'src/app/services/auth.service';
import { Storage } from 'aws-amplify';

@Component({
  selector: 'app-uxa10001',
  templateUrl: './uxa10001.component.html',
  styleUrls: ['./uxa10001.component.scss']
})
export class Uxa10001Component implements OnInit {
  _style: string = '';
  _filename: string = '';
  depotIds: any[] = []
  blob: Blob;

  csvRecordsTemp: any[] = [];
  csvRecords: any[] = [];
  csvHeaders: any[] = [];

  abaDownloadFile: any = '';
  abaFilename: any = '';

  header: boolean = false;
  displayLoading: boolean = false;
  csvImport: boolean = false;

  rowCount: number = 0;

  @ViewChild('fileImportInput', { static: false }) fileImportInput: any;

  constructor(
    private ngxCsvParser: NgxCsvParser,
    private appService: AppService,
    private apiService: ApiService,
    private authService: AuthService,
  ) {
    this._style = this.authService.getStyle()
  }

  ngOnInit(): void {
    this.getData()
  }

  async getData() {
    // get all the users depot access
    let user = await this.apiService.getUsers(this.authService.getCurrentUserId())
    for (let depot of user.UsersDepotAccess) {
      this.depotIds.push(depot.Depot.id)
    }
  }

  // Your applications input change listener for the CSV File
  async fileChangeListener($event: any): Promise<void> {
    this.appService.appSpinner(true)

    this.csvImport = false;

    // Select the files from the event
    const files = $event.srcElement.files;

    if (files.length == 0) {
      this.clear();
      this.appService.appSpinner(false)
      return;
    }

    this._filename = files[0].name

    // Parse the file you want to select for the operation along with the configuration
    this.ngxCsvParser.parse(files[0], { header: this.header, delimiter: ',' })
      .pipe().subscribe((result: Array<any>) => {
        this.csvRecordsTemp = result;
        const keys = result.shift()
        this.csvRecordsTemp = result.reduce((agg, arr) => {
          agg.push(arr.reduce((obj, item, index) => {
            obj[keys[index]] = item;
            return obj;
          }, {}));
          return agg;
        }, [])
        this.done()
      }, (error: NgxCSVParserError) => {
        console.log('Error', error);
      });

    this.appService.appSpinner(false)
  }


  async done() {
    let data = []
    this.csvRecords = await appUtils.sortArrayOfObjects(this.csvRecordsTemp, 'batch_id')

    data.push('batch_id,processed_time,unassigned_time,batch_type,crp_site_id,bin_code,total_qty,total_refund,batch_status\n')

    // set the bin code to be the bagdrop site from CDS
    this.csvRecords.forEach((item) => {
      // parse the obj and push to an array to convert to a blob
      item = Object.keys(item).map(key => item[key])
      item = item.join(",");
      let obj = JSON.stringify(item)
      obj = obj.replace(/[{}"]/g, '')
      obj = obj + '\n'
      data.push(obj)
    })

    if (this.csvRecords.length > 0) {
      this.csvHeaders = ['batch_id', 'crp_site_id', 'bin_code', 'total_qty', 'total_refund', 'batch_status']
    }

    this.blob = new Blob(data, { type: 'text/csv'})

    this.rowCount = this.csvRecords.length
    this.csvImport = true;
  }

  async upload() {
    this.appService.appSpinner(true)

    const res = await Storage.put(this._filename, this.blob, {
      level: 'private',
      contentType: 'text/csv',
      resumable: true,
      completeCallback: (event) => {
        this.appService.sendNotification({
          type: alertAttributes.types.info,
          message: 'Successfully uploaded: ' + this._filename + '!',
          body: '',
          displayNotification: true,
          autoCloseAfter: 5
        })
        this.displayLoading = true;
        setTimeout(async () => {
          // call the generate ABA file lambda
          for (let i of this.depotIds) {
            let res = await this.apiService.generateABAFile(i)
            if (res != null) {
              // use the returned array and generate the file
              let data = res['data'].join('\r\n')
              const blob = new Blob([data], { type: 'text/csv' });
              const url = window.URL.createObjectURL(blob);
              const anchor = document.createElement("a");
              // download the aba file
              anchor.download = res['batchId'] + '.ABA';
              anchor.href = url;
              anchor.click();
            }
          }

          // reset the data after
          this.displayLoading = false;
          this.clear();

          this.appService.sendNotification({
            type: alertAttributes.types.info,
            message: 'ABA files downloaded!',
            body: '',
            displayNotification: true,
            autoCloseAfter: 3
          })
        }, 10000)
      },
      progressCallback: (progress) => {
        console.log(`uploaded: ${progress.loaded}B/${progress.total}B`)
      },
      errorCallback: (err) => {
        this.appService.sendNotification({
          type: alertAttributes.types.error,
          message: err,
          body: '',
          displayNotification: true,
          autoCloseAfter: 5
        })
      }
    })

    this.appService.appSpinner(false)
  }

  clear() {
    this.csvImport = false
    this.displayLoading = false
    this.csvRecords = []
    this.csvHeaders = []
    this.rowCount = 0
    this.fileImportInput.nativeElement.value = '';
  }
}
