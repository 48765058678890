import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { alertAttributes } from 'src/app/helper/appAlert';
import { ICustomerOrder, IOptionType, IOptionWithNumberType } from 'src/app/helper/appInterfaces';
import { appParam } from 'src/app/helper/appSettings';
import { appUtils } from 'src/app/helper/appUtils';
import { ApiService } from 'src/app/services/api.service';
import { AppService } from 'src/app/services/app.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-uxa9002e',
  templateUrl: './uxa9002e.component.html',
  styleUrls: ['./uxa9002e.component.scss']
})
export class Uxa9002eComponent implements OnInit {
  _style: string = '';

  _customerId: string = '';
  _customerUid: string = '';

  // --------------------------
  // Defaults
  containerType: IOptionType = { key: '', description: '' };
  subscriptionType: IOptionType = { key: '', description: '' };
  orderType: IOptionType = { key: '', description: '' };

  // --------------------------
  // Bin locations
  binLocations: IOptionWithNumberType[];
  selectedBinLocations: any[] = [];
  selectedItem: IOptionType = { key: '', description: '', checked: false };

  // --------------------------
  // Date picker section
  availableDates: any[] = [];
  selectedDate: string = '';
  selectedDay: string = ''

  // --------------------------
  // Final pickup
  finalPickup: IOptionWithNumberType[];
  isFinalPickup: boolean = false;


  constructor(private fg: FormBuilder,
    private appService: AppService,
    private apiService: ApiService,
    private authService: AuthService,
    private router: Router,
    private dialogRef: MatDialogRef<Uxa9002eComponent>,
    private route: ActivatedRoute,
  ) {
    this._style = this.authService.getStyle()
    this._customerId = this.route.snapshot.queryParamMap.get('id')

    this.finalPickup = []
    this.finalPickup.push({
      key: '0',
      description: 'This is a final pick up request',
      value: 0,
      binCode: '',
      checked: false,
      body: 'Selecting this means that you no longer require a bin and wish to end your current subscription',
      icon: ''
    })
  }

  ngOnInit(): void {
    this.getData()
  }


  async getData() {
    this.appService.appSpinner(true)

    await this.getDefaults()
    await this.getDataStep1()
    await this.getDataStep2()

    this.appService.appSpinner(false)
  }


  // Get defaults for Pickup request
  // --------------------------------------------------
  async getDefaults() {
    const res = await this.apiService.getCustomer(this._customerId)
    const resCustomer = res[0]

    this._customerUid = resCustomer['code']

    // get default container details and store in workflow object
    let resContainerType = await this.apiService.getContainerTypes()
    if (resContainerType.length > 0) {
      this.containerType.key = resContainerType[0].key
      this.containerType.description = resContainerType[0].description
    }

    // get subscription type details and store in workflow object
    let resSubscriptionType = await this.apiService.getSubscriptionTypes(appParam.subscriptionCategory.charity)
    if (resSubscriptionType.length > 0) {
      this.subscriptionType.key = resSubscriptionType[0].key
      this.subscriptionType.description = resSubscriptionType[0].description
    }

    // get order type details and store in workflow object
    let resOrderType = await this.apiService.getOrderTypes(this._customerUid, appParam.orderType.pickup)
    if (resOrderType.length > 0) {
      this.orderType.key = resOrderType[0].key
      this.orderType.description = resOrderType[0].description
    }
  }


  // Address picker section
  // --------------------------------------------------
  async getDataStep1() {
    //  get all the bin orders
    let res = await this.apiService.getCustomerOrdersForPickupRequest(this._customerUid);

    //  save the orders into the variable for usage later
    // this.orders = res

    // map the incoming data into Key/Description
    this.binLocations = res.map((item: any) => {
      return {
        key: item.id,
        description: item['address'],
        postcode: item['post_code'],
        state: item.refCustomer.refRegion.refState.code,
        value: item['qty'],
        checked: false,
        binCode: item['refBin'] != null ? item['refBin']['code'] : ""
      }
    });
  }

  async binLocationPicked(event: IOptionType) {
    let item = await appUtils.searchInArray(this.binLocations, 'key', event.key)
    this.selectedBinLocations.push({ order_id: item['key'], binCode: item['binCode'], description: item['description'], key: item['key'] })
    this.selectedItem = item
    // console.log('requestPicked', event, this.selectedItem)
  }


  // Date picker section
  // --------------------------------------------------
  async getDataStep2() {
  }

  async datePicked(event: any) {
    await this.updateSelectedDate(event)
  }


  async updateSelectedDate(_date: any) {
    this.selectedDate = _date
    this.selectedDay = ' ' + appUtils.getDayofWeek(this.selectedDate)
  }


  // Pinal pickup section
  // --------------------------------------------------
  async finalPickupSelected(event: IOptionType) {
    this.isFinalPickup = event.checked as boolean
  }





  async placeOrder() {

    this.appService.appSpinner(true)

    let order: ICustomerOrder = {
      customerId: this._customerUid,
      selectedDate: this.selectedDate,
      accountName: '',
      accountNumber: '',
      bsb: '',
      bins_to_drop_off: 0
    } as ICustomerOrder;

    const res = await this.apiService.createCustomerOrder(this._customerUid, order)

    if (res['id'] != undefined) {
      this.appService.appSpinner(false)

      this.appService.sendNotification({
        type: alertAttributes.types.info,
        message: 'Pickup request has been placed.',
        body: '',
        displayNotification: true,
        autoCloseAfter: 3
      })

      setTimeout(() => {
        this.dialogRef.close()
      }, 3000);

    } else {
      this.appService.appSpinner(false)
    }
  }

}
