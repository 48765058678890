import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { AppService } from 'src/app/services/app.service';
import { AuthService } from 'src/app/services/auth.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { ApiService } from 'src/app/services/api.service';
import { appParam } from 'src/app/helper/appSettings';
import { alertAttributes } from 'src/app/helper/appAlert';
import { Router, ActivatedRoute } from '@angular/router';
import { SelectionModel } from '@angular/cdk/collections';
import { AngularCsv } from 'angular-csv-ext/dist/Angular-csv';
import * as moment from 'moment';
import { MatDialog } from '@angular/material/dialog';
import { Uxa6001aComponent } from '../001a/uxa6001a.component';
import { Uxa6001cComponent } from '../001c/uxa6001c.component';

//  row structure
export interface TableRow {
  id: string; // used for the checkbox column
  code: string;
  desc: string;
  type: string;
  depot: string;
  purchase_date: string;
  decommission_date: string;
  updated_at: string;
}

let ELEMENT_DATA: TableRow[] = [];

@Component({
  selector: 'app-uxa6001',
  templateUrl: './uxa6001.component.html',
  styleUrls: ['./uxa6001.component.scss']
})
export class Uxa6001Component implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  _style: string = '';
  _defaultDateFormat: string = appParam.defaultDateFormat;
  _defaultDateTimeFormat: string = appParam.defaultDateTimeFormat;

  dataSource = new MatTableDataSource<TableRow>(ELEMENT_DATA);
  selection = new SelectionModel<TableRow>(true, []);

  // used to store the selected bin
  _currentContext: TableRow;

  //  columns to be displayed in the table
  displayedColumns: string[] = [
    'id',
    'code',
    'desc',
    'type',
    'depot',
    'purchase_date',
    'decommission_date',
    'actions',
    'updated_at'
  ];

  constructor(
    private appService: AppService,
    private apiService: ApiService,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    public dialog: MatDialog
  ) {
    this._style = this.authService.getStyle();
  }

  async ngOnInit(): Promise<void> {
    await this.getData();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  //  get data from API
  async getData() {
    this.appService.appSpinner(true);

    //  get the bins from the db
    let _data = await this.apiService.getBins();

    //  update the array for the table
    this.dataSource.data = [];
    try {
      for (let _row of _data) {
        this.dataSource.data.push({
          id: _row['id'],
          code: _row['code'],
          desc: _row['desc'],
          type: _row['refContainerType']['desc'],
          depot: _row['refDepot']['desc'],
          purchase_date: _row['purchase_date'],
          decommission_date: _row['decommission_date'],
          updated_at: _row['updated_at']
        });
      }
    } catch (err) {
      console.log('err', err);
    }
    this.selection.clear();
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.appService.appSpinner(false);
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.filteredData.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.filteredData.forEach((row) => this.selection.select(row));
  }

  toggle(row, event) {
    event ? this.selection.toggle(row) : null;
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: TableRow): string {
    if (!row) return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    else return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id + 1}`;
  }

  contextClick(row: any) {
    this._currentContext = row;
  }

  async decommissionAll() {
    const binsToDecommission = this.selection.selected.filter((b) => !b.decommission_date);
    await this.decommission(binsToDecommission);
  }

  async decommissionByBin() {
    await this.decommission([this._currentContext]);
  }

  async decommission(binsToDecommission: TableRow[]) {
    if (binsToDecommission.length) {
      let binsText = binsToDecommission.map((b) => b.code).join(', ');
      this.appService.appSpinner(true);
      await this.getData();
      this.appService.sendNotification({
        type: alertAttributes.types.info,
        message: 'Selected bins have been decommissioned: ' + binsText,
        body: '',
        displayNotification: true
      });
    } else {
      let binsText = this.selection.selected.map((b) => b.code).join(', ');
      this.appService.sendNotification({
        type: alertAttributes.types.info,
        message: 'Selected bins already decommissioned: ' + binsText,
        body: '',
        displayNotification: true
      });
    }
  }

  async viewHistory() {
    await this.router.navigate(['../' + appParam.routing.A6_binManagement.A6001c], {
      relativeTo: this.route,
      queryParams: { id: this._currentContext.id }
    });
  }

  transferBin() {
    console.log('transferBin');
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  openHistoryDialog() {
    this.dialog.open(Uxa6001cComponent, { data: { id: this._currentContext.id } });
  }

  openAddBinDialog() {
    let dialogRef = this.dialog.open(Uxa6001aComponent);
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) await this.getData();
    });
  }

  exportCSV() {
    let date = moment().format('YYYY/MM/DD_HH-MM');
    new AngularCsv(this.selection.selected, 'BINS_' + date, {
      showLabels: true,
      headers: ['id', 'code', 'desc', 'type', 'depot', 'purchase_date', 'decommission_date', 'updated_at'],
      useHeader: true,
      nullToEmptyString: true
    });
  }
}
