import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IOptionType } from 'src/app/helper/appInterfaces';
import { AppService } from 'src/app/services/app.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-charity-panel',
  templateUrl: './charity-panel.component.html',
  styleUrls: ['./charity-panel.component.scss']
})
export class CharityPanelComponent implements OnInit {
  _style: string = '';

  @Input() _Charity: IOptionType = { key: '', description: '', checked: false };
  @Input() _Icon: string = '';
  @Input() _Selected: String = '';

  @Output() charityPicked = new EventEmitter<IOptionType>();

  constructor(public appService: AppService,
    private authService: AuthService) {
    this._style = this.authService.getStyle()
  }

  ngOnInit(): void {
  }

  itemSelected(event: IOptionType) {
    console.log('itemSelected', event)
    this.charityPicked.emit(event);
  }

}
