<form [formGroup]="frmGroup" fxLayout="column">
  <div fxLayout="row" fxLayoutGap="50px">
    <div fxLayout="column" [style.min-width.px]="250">
      <div class="mat-title">Customer details</div>

      <!-- Customer details -->
      <mat-form-field appearance="outline">
        <mat-label>First name</mat-label>
        <input
          matInput
          value=""
          data-cy="firstName"
          id="firstName"
          formControlName="firstName" />
        <mat-error>Required</mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Last name</mat-label>
        <input
          matInput
          value=""
          data-cy="lastName"
          id="lastName"
          formControlName="lastName" />
        <mat-error>Required</mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Mobile</mat-label>
        <input
          placeholder="+61"
          matInput
          value=""
          data-cy="mobile"
          id="mobile"
          formControlName="mobile" />
        <mat-error *ngIf="this.frmGroup.controls.mobile.errors?.required"
          >required</mat-error
        >
        <mat-error
          *ngIf="
            this.frmGroup.controls.mobile.errors?.pattern ||
            this.frmGroup.controls.mobile.errors?.format
          ">
          invalid format
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Email</mat-label>
        <input
          matInput
          value=""
          data-cy="email"
          id="email"
          formControlName="email" />
        <mat-error *ngIf="this.frmGroup.controls.email.errors?.required"
          >required</mat-error
        >
        <mat-error *ngIf="this.frmGroup.controls.email.errors?.email"
          >invalid format</mat-error
        >
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Password</mat-label>
        <input
          type="password"
          matInput
          value=""
          data-cy="password"
          id="password"
          formControlName="password" />
        <mat-error *ngIf="this.frmGroup.controls.password.errors?.required"
          >required</mat-error
        >
        <mat-error *ngIf="this.frmGroup.controls.password.errors?.pattern">
          minimum 8 characters with at least 1 number, 1 special char, 1 upper
          case
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Confirm Password</mat-label>
        <input
          type="password"
          matInput
          value=""
          data-cy="confirmPassword"
          id="confirmPassword"
          formControlName="confirmPassword" />
        <mat-error
          *ngIf="this.frmGroup.controls.confirmPassword.errors?.required"
          >required</mat-error
        >
        <mat-error *ngIf="this.frmGroup.controls.confirmPassword.errors?.match"
          >doesn't match</mat-error
        >
      </mat-form-field>
    </div>

    <!-- Location sub type -->
    <div fxLayout="column" [style.min-width.px]="250">
      <div class="mat-title">Location and Subscription</div>

      <mat-form-field appearance="outline">
        <mat-label>Region</mat-label>
        <mat-select formControlName="region">
          <mat-option *ngFor="let region of regions" [value]="region.key">
            {{ region['code'] + ' - ' + region['desc'] }}
          </mat-option>
        </mat-select>
        <mat-error>Required</mat-error>
      </mat-form-field>

      <app-address-picker
        (addressPicked)="addressPicked($event)"></app-address-picker>

      <div *ngIf="this.address != ''">
        <mat-form-field appearance="outline">
          <mat-label>Address Type</mat-label>
          <mat-select formControlName="locationType">
            <mat-option
              *ngFor="let addresstype of this.locationTypeItems"
              [value]="addresstype.key">
              {{ addresstype['description'] }}
            </mat-option>
          </mat-select>
          <mat-error>Required</mat-error>
        </mat-form-field>
      </div>

      <!--      TODO: continue with updating to mat-select types-->
      <div *ngIf="this.selectedLocationType.key != ''">
        <!--        <div>Subscription type?</div>-->
        <!--        <div>-->
        <!--          <app-item-picker-->
        <!--            [_items]="this.subscriptionTypeItems"-->
        <!--            (itemPicked)="subscriptionTypePicked($event)"-->
        <!--          ></app-item-picker>-->
        <!--        </div>-->
        <mat-form-field appearance="outline">
          <mat-label>Subscription Type</mat-label>
          <mat-select formControlName="subscriptionType">
            <mat-option
              *ngFor="let subscriptionType of this.subscriptionTypeItems"
              [value]="subscriptionType.key">
              {{ subscriptionType['description'] }}
            </mat-option>
          </mat-select>
          <mat-error>Required</mat-error>
        </mat-form-field>
      </div>

      <div *ngIf="this.isCharity">
        <mat-form-field appearance="outline">
          <mat-label>Charity</mat-label>
          <mat-select formControlName="charity">
            <mat-option
              *ngFor="let item of this.charityItems"
              [value]="item.key">
              {{ item.description }}
            </mat-option>
          </mat-select>
          <mat-error>Required</mat-error>
        </mat-form-field>
      </div>

      <div *ngIf="this.isCharity">
        <mat-form-field appearance="outline">
          <mat-label>Tax invoice email</mat-label>
          <input
            matInput
            [(ngModel)]="this.frmGroup.value.email"
            value=""
            data-cy="taxInvoiceEmail"
            id="taxInvoiceEmail"
            formControlName="taxInvoiceEmail" />
        </mat-form-field>
      </div>
    </div>
  </div>

  <button
    mat-raised-button
    id="save"
    (click)="save()"
    data-cy="save"
    [disabled]="!frmGroup.valid"
    color="primary">
    Save
  </button>
</form>
