<div fxLayout="column">
  <div fxLayout="row" fxLayoutGap="10px" class="mx-3 mt-3">
    <div>
      <button mat-raised-button color="primary" (click)="openCreateDriverDialog()"><mat-icon>add</mat-icon>Create</button>
    </div>
    <div>
      <button mat-raised-button color="primary" (click)="offBoardByDrivers()" [disabled]="!selection.selected.length">
        <mat-icon>person_off</mat-icon>Off-Board Drivers
      </button>
    </div>
    <mat-form-field fxFlex="grow">
      <mat-label>Search</mat-label>
      <mat-icon matPrefix>search</mat-icon>
      <input matInput (keyup)="applyFilter($event)" placeholder="Ex. Chris" />
    </mat-form-field>
  </div>

  <table mat-table [dataSource]="dataSource" matSort matSortDirection="desc" matSortActive="id">
    <!-- Checkbox Column -->
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox
          (change)="$event ? masterToggle() : null"
          [checked]="selection.hasValue() && isAllSelected()"
          [indeterminate]="selection.hasValue() && !isAllSelected()"
          [aria-label]="checkboxLabel()"
        >
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row">
        <mat-checkbox
          (click)="$event.stopPropagation()"
          (change)="this.toggle(row, $event)"
          [checked]="selection.isSelected(row)"
          [aria-label]="checkboxLabel(row)"
        >
        </mat-checkbox>
      </td>
    </ng-container>

    <!-- full_name:  Column -->
    <ng-container matColumnDef="full_name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
      <td mat-cell *matCellDef="let element">
        {{ element.full_name }}
      </td>
    </ng-container>

    <!-- Depot Column -->
    <ng-container matColumnDef="depot">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Depot</th>
      <td mat-cell *matCellDef="let element">
        {{ element.depot }}
      </td>
    </ng-container>

    <!-- License number Column -->
    <ng-container matColumnDef="license_number">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>License</th>
      <td mat-cell *matCellDef="let element">
        {{ element.license_number }}
      </td>
    </ng-container>

    <!-- Onboard date Column -->
    <ng-container matColumnDef="onboard_date">
      <th mat-header-cell *matHeaderCellDef mat-sort-header >Onboard Date</th>
      <td mat-cell *matCellDef="let element">
        {{ element.onboard_date | date: this._defaultDateFormat }}
      </td>
    </ng-container>

    <!-- Offboard date Column -->
    <ng-container matColumnDef="offboard_date">
      <th mat-header-cell *matHeaderCellDef mat-sort-header >Offboard Date</th>
      <td mat-cell *matCellDef="let element">
        {{ element.offboard_date | date: this._defaultDateFormat }}
      </td>
    </ng-container>

    <!-- Device number Column -->
    <ng-container matColumnDef="phone_asset_id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Phone Asset ID</th>
      <td mat-cell *matCellDef="let element">
        {{ element.phone_asset_id }}
      </td>
    </ng-container>

    <!-- Actions Column -->
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef data-cy="actions">Actions</th>
      <td mat-cell *matCellDef="let row" [matMenuTriggerFor]="menu" (click)="contextClick(row)">
        <button mat-icon-button>
          <mat-icon>more_vert</mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>

  <mat-paginator pageSize="10" [pageSizeOptions]="[10, 20, 50, 100]" showFirstLastButtons></mat-paginator>
</div>

<mat-menu #menu="matMenu">
  <button mat-menu-item (click)="openEditDriverDialog()">
    <span>Edit Driver</span>
  </button>
  <button mat-menu-item (click)="offBoardByDriver()">
    <span>Offboard Driver</span>
  </button>
<!--  <button mat-menu-item (click)="openHistoryDialog()">-->
<!--    <span>View Bin History</span>-->
<!--  </button>-->
</mat-menu>
