<div class="container-fluid">
  <div class="row py-2">
    <div class="col-1"></div>
    <div class="col d-flex justify-content-center">
      <mat-icon class="m-1 byc-icon-x2">account_circle</mat-icon>
    </div>
    <div class="col-1"></div>
  </div>

  <div class="row">
    <div class="col-4"></div>
    <div class="col">Email address</div>
    <div class="col-4"></div>
  </div>

  <div class="row">
    <div class="col-4"></div>
    <div class="col">
      <mat-form-field class="{{ this._style }}-form-field" appearance="fill">
        <input matInput value="" id="userId" [(ngModel)]="this.userId" />
      </mat-form-field>
    </div>
    <div class="col-4"></div>
  </div>

  <div class="row">
    <div class="col-4"></div>
    <div class="col">Password</div>
    <div class="col-4"></div>
  </div>
  <div class="row">
    <div class="col-4"></div>
    <div class="col">
      <mat-form-field class="{{ this._style }}-form-field" appearance="fill">
        <input
          matInput
          value=""
          id="pwd"
          type="password"
          [(ngModel)]="this.pwd"
        />
      </mat-form-field>
    </div>
    <div class="col-4"></div>
  </div>

  <div class="row">
    <div class="col-4"></div>
    <div class="col {{ this._style }}-text-tiny">
      v{{ _currentApplicationVersion }}
    </div>
  </div>

  <div class="row">
    <div class="col-4"></div>
    <div class="col">
      <button
        mat-raised-button
        id="signIn"
        class="d-block {{ this._style }}-next {{ this._style }}-primary mt-4"
        (click)="resetPassword()"
      >
        Change password
      </button>
    </div>
    <div class="col-4"></div>
  </div>
</div>
