import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { AppService } from 'src/app/services/app.service';
import { AuthService } from 'src/app/services/auth.service';

import { appErrorHandler } from 'src/app/helper/appErrorHandler';
import { appUtils } from 'src/app/helper/appUtils';
import * as moment from 'moment';

@Component({
  selector: 'app-uxa3001',
  templateUrl: './uxa3001.component.html',
  styleUrls: ['./uxa3001.component.scss']
})
export class Uxa3001Component implements OnInit {
  errHandler: appErrorHandler;

  _style: string = '';

  drivers: any[] = [];

  _newManifests: any[] = [];
  _activeManifests: any[] = [];
  _prevManifests: any[] = [];

  constructor(
    private appService: AppService,
    private apiService: ApiService,
    private authService: AuthService,
  ) {
    this._style = this.authService.getStyle()
    this.errHandler = new appErrorHandler(this.appService)
  }

  ngOnInit(): void {
    this.getData()
  }


  // get all manifest details and drivers for the shared components
  async getData() {
    // get all the users depot access
    let depotIds = [];
    let user = await this.apiService.getUsers(this.authService.getCurrentUserId())
    for (let depot of user.UsersDepotAccess) {
      depotIds.push(depot.Depot.id)
    }

    let res = await this.apiService.getManifestsByDepotId(depotIds.toString())

    for (var idx in res) {
      const manifest = res[idx]

      let dt1 = moment(manifest['run_date'])
      let dt2 = moment.utc(moment(new Date())).format()

      const manifestAge = await appUtils.getDaysBetween(appUtils.getNowInUtc(), manifest['run_date'])

      if (manifestAge > 0) {
        this._newManifests.push(manifest)
        appUtils.sortArrayOfObjectsDesc(this._newManifests, 'manifest_header_id')
      } else if (manifestAge == 0) {
        this._activeManifests.push(manifest)
        appUtils.sortArrayOfObjectsDesc(this._activeManifests, 'manifest_header_id')
      } else {
        this._prevManifests.push(manifest)
        appUtils.sortArrayOfObjectsDesc(this._prevManifests, 'manifest_header_id')
      }
    }

    this.drivers = await this.apiService.getDriversByDepot(depotIds.toString())
  }

}

