<div fxLayout="column">
  <div fxLayout="row" fxLayoutGap="10px" class="mx-3 mt-1">
    <div class="mt-3">
      <button mat-raised-button color="primary" (click)="createManifestByOrders()" [disabled]="!selection.selected.length">
        <mat-icon>auto_graph</mat-icon>Create Manifest
      </button>
    </div>
    <div fxFlex fxLayoutGap="10px" fxLayoutAlign="none center">
      <mat-form-field fxFlex="grow" class="mt-2">
        <mat-label>Search</mat-label>
        <mat-icon matPrefix>search</mat-icon>
        <input matInput (keyup)="applyFilter($event)" placeholder="E.g. Joe Citizen" />
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Filter Date</mat-label>
        <input matInput
          #dateInput
          [matDatepicker]="picker"
          (dateChange)="applyDateFilter($event)"
          disabled/>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker-toggle matSuffix (click)="resetDateFilter()">
          <mat-icon matDatepickerToggleIcon>clear</mat-icon>
        </mat-datepicker-toggle>
        <mat-datepicker #picker disabled="false"></mat-datepicker>
      </mat-form-field>
    </div>
  </div>

  <table mat-table [dataSource]="dataSource" matSort matSortDirection="desc" matSortActive="id">
    <!-- Checkbox Column -->
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox
          (change)="$event ? masterToggle() : null"
          [checked]="selection.hasValue() && isAllSelected()"
          [indeterminate]="selection.hasValue() && !isAllSelected()"
          [aria-label]="checkboxLabel()"
        >
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let element">
        <mat-icon class="material-icons-outlined" *ngIf="element.assigned" matTooltip="Already in manifest">
          auto_graph
        </mat-icon>
        <mat-checkbox
          *ngIf="!element.assigned"
          (click)="$event.stopPropagation()"
          (change)="this.toggle(element, $event)"
          [checked]="selection.isSelected(element)"
          [aria-label]="checkboxLabel(element)"
        >
        </mat-checkbox>
      </td>
    </ng-container>

    <!-- order type:  Column -->
    <ng-container matColumnDef="order_type">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Order Type</th>
      <td mat-cell *matCellDef="let element">
        {{ element.order_type }}
      </td>
    </ng-container>

    <!-- customer:  Column -->
    <ng-container matColumnDef="customer">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Customer</th>
      <td mat-cell *matCellDef="let element">
        {{ element.customer }}
      </td>
    </ng-container>

    <!-- customer type:  Column -->
    <ng-container matColumnDef="customer_type">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Customer Type</th>
      <td mat-cell *matCellDef="let element">
        {{ element.customer_type }}
      </td>
    </ng-container>

    <!-- street_address:  Column -->
    <ng-container matColumnDef="street_address">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Street Address</th>
      <td mat-cell *matCellDef="let element">
        {{ element.street_address }}
      </td>
    </ng-container>

    <!-- suburb Column -->
    <ng-container matColumnDef="suburb">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Suburb</th>
      <td mat-cell *matCellDef="let element">
        {{ element.suburb }}
      </td>
    </ng-container>

    <!-- region Column -->
    <ng-container matColumnDef="region">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Region</th>
      <td mat-cell *matCellDef="let element">
        {{ element.region }}
      </td>
    </ng-container>

    <!-- post code Column -->
<!--    <ng-container matColumnDef="post_code">-->
<!--      <th mat-header-cell *matHeaderCellDef mat-sort-header>Post code</th>-->
<!--      <td mat-cell *matCellDef="let element">-->
<!--        {{ element.post_code }}-->
<!--      </td>-->
<!--    </ng-container>-->

    <!-- Order date Column -->
    <ng-container matColumnDef="order_date">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Delivery Date</th>
      <td mat-cell *matCellDef="let element">
        {{ element.order_date | date: this._defaultDateFormat }}
      </td>
    </ng-container>

    <!-- Bins to drop off Column -->
    <ng-container matColumnDef="bins_to_drop_off">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Bins to Drop off</th>
      <td mat-cell *matCellDef="let element">
        {{ element.bins_to_drop_off }}
      </td>
    </ng-container>

    <!-- Bins to pick up Column -->
    <ng-container matColumnDef="bins_to_pick_up">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Bins to Pickup</th>
      <td mat-cell *matCellDef="let element">
        {{ element.bins_to_pick_up }}
      </td>
    </ng-container>

    <!-- Context menu Column -->
    <ng-container matColumnDef="context_menu">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element" [matMenuTriggerFor]="menu" (click)="contextClick(element)">
        <button mat-icon-button>
          <mat-icon>more_vert</mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>

  <mat-paginator pageSize="10" [pageSizeOptions]="[10, 20, 50, 100]" showFirstLastButtons></mat-paginator>
</div>

<mat-menu #menu="matMenu">
  <button mat-menu-item (click)="cancelOrder()">
    <span>Cancel Order</span>
  </button>
  <button mat-menu-item (click)="removeFromManifest()">
    <span>Remove From Manifest</span>
  </button>
</mat-menu>
