import { IOptionType } from "./appInterfaces";
import * as moment from 'moment';

export const appUtils = {
  //  search and return a specific json object
  objectArrayHas(_array: any[], _searchKey: string, _searchValue: any) {
    for (var index = 0; index < _array.length; ++index) {
      var _object = _array[index];
      if (_object[_searchKey] == _searchValue) {
        return _object;
      }
    }

    return false;
  },

  censorWord(_value: string, _displayChars: number = 2) {
    if (_value.length < 3) return _value
    return _value[0] + "*".repeat(_value.length - 2) + _value.slice(- _displayChars);
  },

  censorEmail(_email: string) {
    if (_email == undefined || _email == "") return "";

    var arr = _email.split("@");
    return this.censorWord(arr[0]) + "@" + this.censorWord(arr[1]);
  },

  async removeItemFromArray(_array: any[], _keyField: string, _keyValue: string) {
    await _array.forEach((item, index) => {
      if (item[_keyField] == _keyValue) {
        _array.splice(index, 1)
      }
    })
    return _array
  },

  isInDateArray(_array: any[], value: any) {
    if (value == undefined) { return false }
    return !!_array.find(item => { return item.getTime() == value.getTime() });
  },


  // getFromArray(_array: any[], value: any) {
  //   if (value == undefined) { return false }
  //   return _array.find(item => { return item });
  // },

  getDayofWeek(_date: any) {
    const date = new Date(_date)
    var weekday = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    return weekday[date.getDay()];
  },

  async addToOptionsIfNew(_currentArray: IOptionType[], _newKey: string, _newValue: string) {
    let isNew = true

    await _currentArray.forEach((item) => {
      if (item.key == _newKey) {
        isNew = false;
        return;
      }
    })

    if (isNew && _newKey != '' && _newValue != '') {
      _currentArray.push({ key: _newKey, description: _newValue, checked: false })
    }

    return _currentArray
  },


  async setOptionsDefault(_currentArray: IOptionType[], _defaultKey: string) {

    await _currentArray.forEach((item) => {
      if (item.key == _defaultKey) {
        item.checked = true
      } else {
        item.checked = false
      }
    })

    return _currentArray
  },


  async searchInArray(_currentArray: any[], _searchKey: string, _searchValue: string) {
    let itemFound: any = undefined

    await _currentArray.forEach((item) => {
      if (item[_searchKey] === _searchValue) {
        itemFound = item
      }
    })

    return itemFound
  },

  async updateSelectedItem(_currentArray: IOptionType[], _key: string) {
    let itemFound: any = undefined

    await _currentArray.forEach((item) => {
      item.checked = false

      if (item.key == _key) {
        item.checked = true
      }

    })

    return _currentArray
  },

  async getObjectByKey(_object: any, _keyName: string) {
    let key

    for (key in _object) {
      // console.log('key', key)
      if (key == _keyName) {
        // console.log('getObjectByKey', _object[key])

        return _object[key]
      }
    }
  },

  async filleCharityArray(_currentArray: any[]) {
    // console.log('start', _currentArray)
    // following lines are only for testing
    // _currentArray.push({ key: 100, checked: false, description: '101', body: 'Test entry 1' })
    // _currentArray.push({ key: 101, checked: false, description: '102', body: 'Test entry 2' })
    // _currentArray.push({ key: 102, checked: false, description: '103', body: 'Test entry 3' })
    // _currentArray.push({ key: 103, checked: false, description: '104', body: 'Test entry 4' })


    let charityCount = _currentArray.length
    let fillCount = 0

    if (charityCount < 4) {
      fillCount = 4 - charityCount
    } else {
      fillCount = charityCount % 4
      fillCount = 4 - fillCount
    }

    for (let n = 0; n < fillCount; n += 1) {

      _currentArray.push({
        key: 1000 + n,
        checked: false,
        description: '',
        body: ''
      })
    }

    // console.log('end', charityCount, fillCount, _currentArray)
    return _currentArray
  },


  formatCurrency(_value: any): any {
    return parseFloat(_value).toFixed(2)
  },



  getDates(start, end) {
    var
      arr = new Array(),
      dt = new Date(start);

    while (dt <= end) {
      let dtNew = new Date(new Date(dt).setHours(0, 0, 0, 0));
      arr.push(dtNew);
      dt.setDate(dt.getDate() + 1);
    }

    return arr;
  },


  getNowInUtc() {
    return moment.utc(moment(new Date())).format()
  },

  getDateAtZeroHrs(_date: any) {
    return moment(new Date(_date)).format("ddd MMM D YYYY 00:00:00");
  },

  getDateInUTC(_date: any) {
    return moment(new Date(_date)).utc();
  },


  getLongDate(_date: any) {
    return moment(new Date(_date)).format("dddd, DD MMMM");
  },

  formatHumanDate(_date: any) {
    return moment(_date).format('dddd D MMMM');
  },

  async getDaysBetween(_date1: any, _date2: any) {
    var a = moment(_date1).startOf('day');
    var b = moment(_date2).startOf('day');
    return b.diff(a, 'days')
  },

  getSuburb(_address: string) {
    if (_address == undefined || _address == '') return ''

    let add = _address.split(',')
    let subAdd = add[1].split(' ')
    subAdd = subAdd.slice(1,subAdd.length)
    let removeLast = subAdd.splice(subAdd.length-2, 2)
    let suburb = subAdd.join(' ')

    return suburb
  },

  getNowAsCode() {
    return moment(new Date()).format("YYYYMMDD-HHmmss");
  },


  sortArrayOfObjects(arr, key) {
    return arr.sort((a, b) => {
      return a[key] - b[key];
    });
  },

  sortArrayOfObjectsDesc(arr, key) {
    return arr.sort((a, b) => {
      return b[key] - a[key];
    });
  },


};
