<div class="container-fluid h-100 dc-primary-bg">
  <div fxLayout="column" fxLayoutAlign="center center">
    <div fxFlex class="mt-5">
      <img class="img-logo" src="assets/logos/DIRECT-COLLECT-SECONDARY-LOGO-RGB-INVERTED-01.png" alt="logo" />
    </div>

    <div fxFlex class="mt-5">
      <mat-form-field appearance="outline" color="accent">
        <mat-label>Email address</mat-label>
        <input matInput [(ngModel)]="this.userId" />
      </mat-form-field>
    </div>

    <div fxFlex>
      <mat-form-field appearance="outline" color="accent">
        <mat-label>Password</mat-label>
        <input matInput type="password" [(ngModel)]="this.pwd" />
      </mat-form-field>
    </div>

    <div fxFlex>
      <button mat-raised-button (click)="signIn()" color="accent">Sign in</button>
    </div>
  </div>

  <div class="dc-primary-variant mat-h6 bottom-version">v{{ _currentApplicationVersion }}</div>
</div>
