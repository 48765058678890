import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { alertAttributes } from 'src/app/helper/appAlert';
import { ICustomerOrder } from 'src/app/helper/appInterfaces';
import { appParam } from 'src/app/helper/appSettings';
import { appUtils } from 'src/app/helper/appUtils';
import { ApiService } from 'src/app/services/api.service';
import { AppService } from 'src/app/services/app.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-uxa9002c',
  templateUrl: './uxa9002c.component.html',
  styleUrls: ['./uxa9002c.component.scss']
})
export class Uxa9002cComponent implements OnInit {
  _style: string = '';

  _customerId: string = '';
  _customerUid: string = '';

  _defaultDateFormat: string = appParam.defaultDateFormat;

  dateInputControl = new FormControl('', [Validators.required]);
  orderTypeControl = new FormControl('', [Validators.required])

  getDateErrorMessage() {
    return this.dateInputControl.hasError('required') ? 'You must select a date!' : '';
  };

  getOrderTypeErrorMessage() {
    return this.orderTypeControl.hasError('required') ? 'You must select an order type!' : '';
  }

  customerDetails: any;
  userDetails: any;
  userType: string = '';
  userPlan: string = '';
  items: any[];
  customerOrderAvail: any;
  orderTypes: any[];
  dates: any[] = [];
  errorMsg: string = '';
  notFirstOrder: boolean = false;

  qty: number = 1;
  currentBins: number = 0;

  additionalFee: number = 0;
  successfulPayment: boolean = false;
  displayStripePayment: boolean = false;

  selectedDay: string = '';
  selectedDate: string = '';
  selectedOrderType: string = '';

  isCharity: boolean = false;
  isBtnDisabled: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { id: string; uid: string; },
    private fg: FormBuilder,
    private appService: AppService,
    private apiService: ApiService,
    private authService: AuthService,
    private dialogRef: MatDialogRef<Uxa9002cComponent>,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    this._style = this.authService.getStyle()
    this._customerId = this.data.id
    this._customerUid = this.data.uid
  }

  ngOnInit(): void {
    this.getData()
    }

  async getData() {
    this.appService.appSpinner(true);

    const result = await Promise.all([
      this.apiService.getCustomer(this._customerId),
      this.apiService.getContainerTypes(),
      this.apiService.getOrderTypes(this._customerId),
      this.apiService.getCustomerOrderCreationAvail(this._customerId),
      this.apiService.getCustomerOrders(this._customerUid)
    ])

    // assign results to expected variables
    this.customerDetails = result[0]
    this.items = result[1]
    this.orderTypes = result[2]
    this.customerOrderAvail = result[3]

    for (let order of result[4]) {
      if (order.refOrderStatus.code == 'COMPLETED') this.notFirstOrder = true;
    }

    this.customerOrderAvail = JSON.parse(this.customerOrderAvail[0].fn_get_order_creation_options_by_customer);

    this.currentBins = this.customerOrderAvail.current_bin_count;

    // check customer user type first
    if (this.customerDetails.refSubscriptionType == null) {
      this.userType = 'NONE'
      this.userPlan = 'NONE'
      this.errorMsg = 'Error: customer has no plan, please contact support to assign them a plan!'
      this.orderTypes.forEach((type) => {
        type.disabled = true
      });
      this.appService.appSpinner(false);
      // return if an open order to avoid getting dates
      return;
    }

    this.userPlan = this.customerDetails.refSubscriptionType.desc

    if (this.customerDetails.refSubscriptionType.category.indexOf('CHARITY') > -1) {
      this.isCharity = true;
      this.userType = appParam.userType.charity;
    } else {
      if (this.customerDetails.refSubscriptionType.category.indexOf('BASIC') > -1) {
        this.userType = appParam.userType.basic;
      } else {
        this.userType = appParam.userType.premium;
      }
      // check the customer has bank details
      if (!this.customerDetails.CustomerAccount) {
        this.errorMsg = 'Error: customer has no bank details, please add them then create the order!'
      }
    }

    // check the customer has no open order
    if (!this.customerOrderAvail.no_existing_open_order) {
      this.errorMsg = 'Error: customer has an open order!'

      this.orderTypes.forEach((type) => {
        type.disabled = true
      });
      this.appService.appSpinner(false);
      // return if an open order to avoid getting dates
      return;
    }

    // if not enabled disable in the orderType array
    if (!this.customerOrderAvail.dropoff_enabled) this.orderTypes.find(o => o.code == 'DROPOFF').disabled = true;
    if (!this.customerOrderAvail.changeover_enabled) this.orderTypes.find(o => o.code == 'CHANGEOVER').disabled = true;
    if (!this.customerOrderAvail.pickup_enabled) this.orderTypes.find(o => o.code == 'PICKUP').disabled = true;

    this.dates =  await this.apiService.getAvailableDates(
      this.customerDetails.CustomerAddress.refSuburb.id,
      true
    );

    for (let i = 0; i < this.items.length; i++) {
      if (this.items[i].description != '240L Bin') {
        this.items.splice(i, 1);
      }
    }

    this.appService.appSpinner(false);
  }

  dateClass = (d: Date) => {
    const isValidDate = appUtils.isInDateArray(this.dates, d);
    return isValidDate ? 'byc-datepicker-available-dates' : '';
  };

  async datePicked(event: any) {
    await this.updateSelectedDate(event.value);
  }

  async updateSelectedDate(_date: any) {
    let date = new Date(_date)
    this.selectedDate = date.toLocaleDateString('en-CA')
    this.selectedDay = appUtils.formatHumanDate(this.selectedDate)
  }

  async updateSelectedOrderType(orderType: string) {
    this.selectedOrderType = this.orderTypes.find(o => o.key === orderType).code
  }

  async incrementValue() {
    if (this.selectedOrderType == 'DROPOFF') {
      if (this.qty < (this.customerDetails.refSubscriptionType.bin_limit - this.currentBins) || this.customerDetails.refSubscriptionType.bin_limit == null) {
        this.qty += 1;
      }
    } else {
      if (this.qty < this.currentBins) {
        this.qty += 1;
      }
    }
  }

  async decrementValue() {
    if (this.qty > 1) {
      this.qty -= 1;
    }
  }

  closeDialogRef() {
    this.dialogRef.close()
  }

  paymentAttempted(event) {
    if (event == true) {
      this.successfulPayment = true;
    };
  }

  renderCardPayment() {
    this.displayStripePayment = true;
  }

  async placeOrder() {
    this.appService.appSpinner(true);
    this.isBtnDisabled = true;

    let order: ICustomerOrder = {
      customerId: this._customerUid,
      selectedDate: this.selectedDate
    }

    if (this.userType != 'CHARITY') {
      order.accountName = this.customerDetails.CustomerAccount.account_name
      order.accountNumber = this.customerDetails.CustomerAccount.account_number
      order.bsb = this.customerDetails.CustomerAccount.refBSB.code
    }

    if (this.selectedOrderType == 'DROPOFF') {
      order.bins_to_drop_off = this.qty;
      order.orderType = this.selectedOrderType;
      order.notFirstOrder = this.notFirstOrder;
    } else if (this.selectedOrderType == 'CHANGEOVER') {
      order.bins_to_drop_off = this.customerOrderAvail.current_bin_count;
      order.bins_to_pick_up = this.customerOrderAvail.current_bin_count;
      order.orderType = this.selectedOrderType;
    } else {
      order.bins_to_pick_up = this.qty;
      order.orderType = this.selectedOrderType;
    }

    try {
      const res = await this.apiService.createCustomerOrder(this._customerUid, order)

      if (res != null) {
        this.appService.appSpinner(true);
        return;
      }

      this.appService.appSpinner(false)
      this.appService.sendNotification({
        type: alertAttributes.types.info,
        message: 'Order has been placed.',
        body: '',
        displayNotification: true,
        autoCloseAfter: 3
      })

      setTimeout(() => {
        this.closeDialogRef()
      }, 3000);
    } catch (err) {
      this.appService.appSpinner(false);
      this.isBtnDisabled = false;
      console.log(err)
    }

  }

}
