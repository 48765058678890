import { Component, Inject, Input, OnInit, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { alertAttributes } from 'src/app/helper/appAlert';
import { appErrorHandler } from 'src/app/helper/appErrorHandler';
import { appRouteOptimization } from 'src/app/helper/appRouteOptimization';
import { appParam } from 'src/app/helper/appSettings';
import { appUtils } from 'src/app/helper/appUtils';
import { ApiService } from 'src/app/services/api.service';
import { AppService } from 'src/app/services/app.service';
import { AuthService } from 'src/app/services/auth.service';
import { formatDate } from '@angular/common';

import JsonFind from "json-find";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';
import { IAddress } from "../../helper/appInterfaces";

@Component({
  selector: 'app-manifest',
  templateUrl: './manifest.component.html',
  styleUrls: ['./manifest.component.scss']
})
export class ManifestComponent implements OnInit {
  errHandler: appErrorHandler;
  _dateFormat: string = appParam.defaultDateFormat;

  _style: string = '';

  @Input() _manifest: any;
  @Input() _drivers: any[] = [];
  // @Output() onItemPicked = new EventEmitter<IOptionType>();

  _readOnly: boolean = false;
  _runDate: string = '';
  _selectedDriver: string = '';
  _selectedDriverName: string = '';

  _canOptimize: boolean = false;
  _canChangeRunDate: boolean = false;
  _canAssignDriver: boolean = false;
  _canDisplayStats: boolean = true;
  _canUpdateNotes: boolean = true;
  _canDelete: boolean = false;
  _canReopen: boolean = false;

  _startAddressEntered: boolean = false;
  _endAddressEntered: boolean = false;
  _startDepot: any = null;
  _endDepot: any = null;
  _editStart: boolean = false;
  _editFinish: boolean = false;

  availableDates: any[] = [];

  _depotNotes: string = '';

  constructor(
    public appService: AppService,
    private authService: AuthService,
    private apiService: ApiService,
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog
  ) {
    this.errHandler = new appErrorHandler(this.appService)
    this._style = this.authService.getStyle()
  }

  async ngOnInit() {
    // get the manifest by the manifest_id
    let manifest = await this.apiService.getManifests(this._manifest['manifest_header_id'])
    this._manifest = manifest[0]

    if (this._manifest['manifest_status'] == appParam.manifestStatus.draft
      || this._manifest['manifest_status'] == appParam.manifestStatus.optimizeInprogress
      || this._manifest['manifest_status'] == appParam.manifestStatus.optimized
      || this._manifest['manifest_status'] == appParam.manifestStatus.driverAssigned) {
      this._canDelete = true;
    }

    this._runDate = this._manifest['run_date']
    this.availableDates = appUtils.getDates(new Date(), new Date(appParam.maxDate));
    this._depotNotes = this._manifest['depot_notes']
    await this.updateLockStatus()
  }

  async updateLockStatus() {

    // lock if manifest is old
    const manifestAge = await appUtils.getDaysBetween(appUtils.getNowInUtc(), this._manifest['run_date'])
    if (manifestAge < 0) {
      this._canOptimize = false;
      this._canAssignDriver = false
      this._canDisplayStats = true;

    } else if (this._manifest['manifest_status'] == appParam.manifestStatus.draft) {
      this._canOptimize = true;
      this._canAssignDriver = false
      this._canDisplayStats = false;
      this._canChangeRunDate = true;

    } else if (this._manifest['manifest_status'] == appParam.manifestStatus.optimizeInprogress) {
      this._canOptimize = false;
      this._canAssignDriver = false
      this._canDisplayStats = false;
      this._canChangeRunDate = false;

      this.getOptimizationStatus();

    } else if (this._manifest['manifest_status'] == appParam.manifestStatus.optimized) {
      this._canOptimize = false;
      this._canAssignDriver = true
      this._canDisplayStats = true;
      this._canChangeRunDate = true;

    } else if (this._manifest['manifest_status'] == appParam.manifestStatus.startRoute) {
      this._canReopen = false;
      this._canUpdateNotes = true;

    } else if (this._manifest['manifest_status'] == appParam.manifestStatus.complete) {
      this._canReopen = true;

    }

    if (this._manifest['manifest_status'] == appParam.manifestStatus.complete) this._canUpdateNotes = false;

    if (this._manifest['refDriver'] != null) this._selectedDriver = this._manifest['refDriver']['id']
  }


  driverSelected(event: any) {
    this._selectedDriver = event.value
  }

  async optimize() {
    this.appService.appSpinner(true)
    const resAdmin = await this.apiService.getUsers(this.authService.getCurrentUserId())

    // get all orders in the manifest - Sort by id to ensure the Start/Finish is in the correct place in the array
    // const resOrders = await this.apiService.getOrdersByManifest(this._manifest['id'])
    const resOrders = this._manifest['ManifestDetails']

    let jobDes
    jobDes = await appRouteOptimization.prepareTspJob(
      resOrders,
      this._manifest['id'],
      resAdmin.UsersDepotAccess[0].Depot,
      this._startDepot,
      this._endDepot
    )

    const resJob = await this.apiService.startRouteOptimization(jobDes)

    if (resJob != 0) {
      await this.apiService.updateManifest('optimization_start',
        {
          manifest_id: this._manifest['id'],
          job_id: await resJob['jobId'],
          startDepot: this._startDepot,
          endDepot: this._endDepot
        })


      this._canOptimize = false;

      this._manifest['manifest_status'] = appParam.manifestStatus.optimizeInprogress
      this.appService.appSpinner(false)

      this.errHandler.handleErrors({
        type: alertAttributes.types.info,
        displayMessage: alertAttributes.alerts.A047,
        displayNotification: true, errorObject: {}
      })
    } else {
      this.appService.appSpinner(false)
      this.errHandler.handleErrors({
        type: alertAttributes.types.error,
        displayMessage: alertAttributes.alerts.A048,
        displayNotification: true, errorObject: {}
      })
    }
  }

  async addressPicked(event: IAddress, start: boolean, end: boolean) {
    if (start) {
      this._startAddressEntered = true;
      let address = (
        document
          .getElementById('startAddressPicker')!
          .querySelector('input') as HTMLInputElement
      ).value;
      let postcode = event.postcode;
      let lat = event.lat;
      let lng = event.lng;

      const lastComma = address.lastIndexOf(',');
      // join the postcode to the emitted address
      address = [
        address.slice(0, lastComma),
        ' ',
        postcode,
        address.slice(lastComma)
      ].join('');

      // mimic depot for optimisation
      this._startDepot = {
        id: 'User defined start',
        location: {
          address: address,
          lat: lat,
          long: lng
        }
      }
    }

    if (end) {
      let address = (
        document
          .getElementById('finishAddressPicker')!
          .querySelector('input') as HTMLInputElement
      ).value;
      let postcode = event.postcode;
      let lat = event.lat;
      let lng = event.lng;

      const lastComma = address.lastIndexOf(',');
      // join the postcode to the emitted address
      address = [
        address.slice(0, lastComma),
        ' ',
        postcode,
        address.slice(lastComma)
      ].join('');

      // mimic depot for optimisation
      this._endDepot = {
        id: 'User defined end',
        location: {
          address: address,
          lat: lat,
          long: lng
        }
      }
    }
  }

  async skipOptimization() {
    await this.apiService.updateManifest('skip_optimization',
      {
        manifest_id: this._manifest['id']
      })

    this._manifest['manifest_status'] = appParam.manifestStatus.optimized
    await this.updateLockStatus()
  }

  async reopen() {
    await this.apiService.updateManifest('start_route', {
      manifest_id: this._manifest['id']
    })

    this._manifest['manifest_status'] = appParam.manifestStatus.startRoute
    await this.updateLockStatus()
  }

  async getOptimizationStatus() {
    const resJobStatus = await this.apiService.getRouteOptimizationJObStatus(this._manifest['route_optimize_jobid'])
    // console.log('getOptimizationStatus', resJobStatus)

    let _orders: any[] = []
    if (resJobStatus['output']['status'] == "Finished") {
      const jobResult = JsonFind(resJobStatus);
      const routes = jobResult.findValues("sequence")


      // needed to use the routes sequence that is returned from the optimisation
      // IF don't use the routes sequence then it's out of order
      // then if it's start or end don't bother do anything
      // then get the id of the customer from the id of the route and add the stop
      for (var idx in routes['sequence']) {
        if (routes['sequence'][idx]['id'] !== 'start' && routes['sequence'][idx]['id'] !== 'end') {
          const _stop = routes['sequence'][idx]
          const _route = await appUtils.searchInArray(routes['sequence'], 'id', _stop['id'])

          // convert epoch time to seconds since 12AM
          _route['arrivalTime'] = this.secondsSinceMidnight(_route['arrivalTime'])
          _route['departureTime'] = this.secondsSinceMidnight(_route['departureTime'])

          _orders.push({ stop: _stop, route: _route })
        }
      }

      const _depotStart = await appUtils.searchInArray(routes['sequence'], 'id', 'start')
      const _depotFinish = await appUtils.searchInArray(routes['sequence'], 'id', 'end')

      _depotStart['arrivalTime'] = this.secondsSinceMidnight(_depotStart['departureTime'])
      _depotStart['departureTime'] = this.secondsSinceMidnight(_depotStart['departureTime'])

      // !!! careful here need to set the departure time first otherwise the reference to dep time will cause a double up
      _depotFinish['departureTime'] = this.secondsSinceMidnight(_depotFinish['arrivalTime'])
      _depotFinish['arrivalTime'] = this.secondsSinceMidnight(_depotFinish['arrivalTime'])

      _orders.push({ depotStart: _depotStart, depotFinish: _depotFinish })

      await this.apiService.updateManifest('optimization_complete',
        {
          manifest_id: this._manifest['id'],
          orders: _orders,
        })

      // set status and update locks
      this._manifest['manifest_status'] = appParam.manifestStatus.optimized
      this.updateLockStatus()
    }

  }

  secondsSinceMidnight(epoch: number): number {
    const date = new Date(epoch * 1000);

    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();

    return (hours * 3600) + (minutes * 60) + seconds;
  }


  async assignDriver() {
    this.appService.appSpinner(true)

    await this.apiService.updateManifest('assign_driver',
      {
        manifest_id: this._manifest['id'],
        driver_id: this._selectedDriver
      })

    this.appService.sendNotification({
      type: alertAttributes.types.info,
      message: alertAttributes.alerts.A053,
      body: '',
      displayNotification: true
    })

    setTimeout(() => {
      this.router.navigate(["../" + appParam.routing.A3_manifestManagement.A3001], {
        relativeTo: this.route
      }).then(() => {
        window.location.reload()
      })
    }, 1500)
    this.appService.appSpinner(false)
  }

  async sendMessage() {
    const dialogRef = this.dialog.open(ConfirmSMSDialog, {
      autoFocus: true,
      minWidth: '25%',
      height: 'auto',
      data: {
        manifestDate: moment(this._manifest.run_date).format('DD/MM/YYYY')
      }
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      // only if they click yes do something
      if (result) {
        for (const item of this._manifest.ManifestDetails) {
          if (!item.code.includes("START") && !item.code.includes("FINISH")) {
            let message = ''

            // get the phone number of each order
            let phoneNumber = item.refCustomerOrder.refCustomerAddress.refCustomer.mobile

            // format date
            let dt = new Date(this._manifest.run_date)
            let date = appUtils.getDayofWeek(dt)
            date = date + " " + formatDate(dt, 'dd MMM', 'en-US')

            if (item.refCustomerOrder.code.includes("DROPOFF")) {
              message = 'Direct Collect REMINDER: Hi ' + item.refCustomerOrder.refCustomerAddress.refCustomer.first_name +
                '. Your bin is due for drop off on ' + date + '. Please make sure your premises is accessible.'
            } else if (item.refCustomerOrder.code.includes("CHANGEOVER")) {
              message = 'Direct Collect REMINDER: Hi ' + item.refCustomerOrder.refCustomerAddress.refCustomer.first_name +
                '. Your bin is due for change over on ' + date + '. Please make sure your premises is accessible.'
            } else {
              message = 'Direct Collect REMINDER: Hi ' + item.refCustomerOrder.refCustomerAddress.refCustomer.first_name +
                '. Your bin is due for collection on ' + date + '. Please make sure your premises is accessible.'
            }

            // send SMS
            try {
              await this.apiService.sendSMS(message, phoneNumber)
              this.appService.sendNotification({
                type: alertAttributes.types.info,
                message: 'Sent reminder SMS to customers!',
                body: '',
                displayNotification: true,
                autoCloseAfter: 3
              })
            } catch (err) {
              this.appService.sendNotification({
                type: alertAttributes.types.error,
                message: 'CANNOT send reminder SMS to customers!',
                body: '',
                displayNotification: true,
                autoCloseAfter: 3
              })
              console.log(err)
            }
          }
        }
      }
    })
  }

  async updateRunDate(event: any) {
    this.appService.appSpinner(true)
    await this.apiService.updateManifest('update_run_date',
      {
        manifest_id: this._manifest['id'],
        run_date: event
      })

    this.appService.sendNotification({
      type: alertAttributes.types.info,
      message: alertAttributes.alerts.A052,
      body: '',
      displayNotification: true
    })

    setTimeout(() => {
      this.router.navigate(["../" + appParam.routing.A3_manifestManagement.A3001], {
        relativeTo: this.route
      }).then(() => {
        window.location.reload()
      })
    }, 1500)

    this.appService.appSpinner(false)
  }


  async updateNotes() {

    this.appService.appSpinner(true)
    await this.apiService.updateManifest('update_depot_notes',
      {
        manifest_id: this._manifest['id'],
        notes: this._depotNotes
      })

    this.appService.sendNotification({
      type: alertAttributes.types.info,
      message: alertAttributes.alerts.A051,
      body: '',
      displayNotification: true
    })

    this.appService.appSpinner(false)
  }

  async deleteManifest() {
    let res = await this.apiService.deleteManifest(this._manifest['id'])
    if (res.length == 0) {
      this.appService.sendNotification({
        type: alertAttributes.types.info,
        message: 'Manifest has been deleted!',
        body: '',
        displayNotification: true,
        autoCloseAfter: 3
      })
      setTimeout(() => {
        this.router.navigate(["../" + appParam.routing.A3_manifestManagement.A3001], {
          relativeTo: this.route
        }).then(() => {
          window.location.reload()
        })
      }, 3000)
    } else {
      this.appService.sendNotification({
        type: alertAttributes.types.error,
        message: 'Manifest could NOT be deleted!',
        body: '',
        displayNotification: true,
        autoCloseAfter: 3
      });
    }
  }

  async openManifest() {
    //console.log('openManifest')
    this.router.navigate(["../" + appParam.routing.A3_manifestManagement.A3003], {
      relativeTo: this.route,
      queryParams: { id: this._manifest['id'] }
    });
  }
}

export interface DialogData {
  manifestDate: string
}

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'confirm-send-sms',
  templateUrl: 'confirm-send-sms.html'
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class ConfirmSMSDialog {
  constructor(
    public dialogRef: MatDialogRef<ConfirmSMSDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}
}
