
import { AppService } from "../services/app.service";
import { IError } from "./appInterfaces";



export class appErrorHandler {

  constructor(private appService: AppService) {

  }

  handleErrors(_ierror: IError) {
    this.appService.sendNotification({
      type: _ierror.type,
      message: _ierror.displayMessage,
      displayNotification: _ierror.displayNotification,
      body: _ierror.errorObject
    })
  }

}
