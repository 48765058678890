<mat-tab-group #tabGroup mat-align-tabls="center" (selectedTabChange)="updateTableData($event)">
  <ng-container *ngFor="let tab of tabList | keyvalue">
    <mat-tab [label]="tab.key">
      <ng-template matTabContent>
        <div fxLayout="column">

          <div fxLayout="row" fxLayoutGap="10px" class="mx-3 mt-3">
            <div>
              <button mat-raised-button color="primary" (click)="regenerateAvailableDays()"><mat-icon>autorenew</mat-icon>Regenerate Available Days</button>
            </div>
            <mat-form-field fxFlex="grow">
              <mat-label>Search</mat-label>
              <mat-icon matPrefix>search</mat-icon>
              <input matInput (keyup)="applyFilter($event)" placeholder="E.g Christmas" />
            </mat-form-field>
          </div>

          <table mat-table [dataSource]="dataSource" matSort matSortDirection="asc" matSortActive="date">
            <!-- Date Column -->
            <ng-container matColumnDef="date">
              <th mat-header-cell *matHeaderCellDef mat-sort-header style="padding: 0 0 0 12px !important;">Date</th>
              <td mat-cell *matCellDef="let element" style="padding: 0 0 0 12px !important;">
                {{ element.date | date: this._defaultDateFormat}}
              </td>
            </ng-container>

            <!-- Holiday name Column -->
            <ng-container matColumnDef="holiday">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Holiday</th>
              <td mat-cell *matCellDef="let element">
                {{ element.holiday }}
              </td>
            </ng-container>

            <!-- Checkbox Column HIDDEN -->
            <ng-container matColumnDef="id">
              <th mat-header-cell *matHeaderCellDef mat-sort-header [hidden]="true">ID</th>
              <td mat-cell *matCellDef="let element" [hidden]="true">
                {{ element.id }}
              </td>
            </ng-container>

            <!-- Depot Column HIDDEN -->
            <ng-container matColumnDef="depot">
              <th mat-header-cell *matHeaderCellDef mat-sort-header [hidden]="true">Depot</th>
              <td mat-cell *matCellDef="let element" [hidden]="true">
                {{ element.depot }}
              </td>
            </ng-container>

            <!-- Dropoff Column -->
            <ng-container matColumnDef="dropoff">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Dropoff Limit</th>
              <td mat-cell *matCellDef="let element">
                {{ element.dropoff }}
              </td>
            </ng-container>

            <!-- Changeover Column -->
            <ng-container matColumnDef="changeover">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Changeover Limit</th>
              <td mat-cell *matCellDef="let element">
                {{ element.changeover }}
              </td>
            </ng-container>

            <!-- Context menu Column -->
            <ng-container matColumnDef="context_menu">
              <th mat-header-cell *matHeaderCellDef data-cy="edit-data">Actions</th>
              <td mat-cell *matCellDef="let element" (click)="contextClick(element); openEditDaysDialog()">
                <button
                  mat-icon-button
                >
                  <mat-icon>edit</mat-icon>
                </button>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          </table>

          <mat-paginator
            [length]="dataSource.data.length"
            [pageSizeOptions]="[10, 20, 50, 100]"
            showFirstLastButtons
          ></mat-paginator>

        </div>
      </ng-template>
    </mat-tab>
  </ng-container>
</mat-tab-group>
