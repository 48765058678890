<div class="container h-100 w-100 {{this._style}}-charity-panel p-1">

    <div class="row h-100" *ngIf="this._Charity.description!=''">
        <div class='h-20 d-inline-block d-flex justify-content-center text-center py-3'>
            {{_Charity.description}}
        </div>
        <div class='h-20 d-inline-block d-flex justify-content-center'>
            <img src="./assets/logos/charity/{{_Charity.icon}}"
                class="d-block bc_halign_center {{this._style}}-image-charity-logo" alt="..." />
        </div>
        <div class='h-60 d-inline-block d-flex justify-content-center text-center py-3'>
            {{_Charity.body}}
        </div>
    </div>
</div>