import { Injectable } from '@angular/core';
import Amplify, { Auth, Storage } from 'aws-amplify';
import { alertAttributes } from '../helper/appAlert';
import { appErrorHandler } from '../helper/appErrorHandler';
import { IUserAttr } from '../helper/appInterfaces';
import { appParam } from '../helper/appSettings';
import { AppService } from './app.service';
import { environment } from '../../environments/environment';

Amplify.configure({
  Auth: {
    // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
    identityPoolId: environment.S3.identityPoolId,

    // REQUIRED - Amazon Cognito Region
    region: appParam.auth.region,

    // OPTIONAL - Amazon Cognito Federated Identity Pool Region
    // Required only if it's different from Amazon Cognito Region
    // identityPoolRegion: 'XX-XXXX-X',

    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: appParam.auth.userPoolId,

    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: appParam.auth.userPoolWebClientId,

    // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
    mandatorySignIn: false,

    // OPTIONAL - Configuration for cookie storage
    // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
    // cookieStorage: {
    //   // REQUIRED - Cookie domain (only required if cookieStorage is provided)
    //   domain: "backyard-cash-dev.auth.ap-southeast-2.amazoncognito.com",
    //   // OPTIONAL - Cookie path
    //   // path: '/',
    //   // OPTIONAL - Cookie expiration in days
    //   // expires: 365,
    //   // OPTIONAL - See: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie/SameSite
    //   // sameSite: "strict" | "lax",
    //   // OPTIONAL - Cookie secure flag
    //   // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
    //   secure: true,
    // },

    // OPTIONAL - customized storage object
    // storage: MyStorage,

    // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
    authenticationFlowType: 'USER_PASSWORD_AUTH',

    // OPTIONAL - Manually set key value pairs that can be passed to Cognito Lambda Triggers
    // clientMetadata: { myCustomKey: 'myCustomValue' },

    // OPTIONAL - Hosted UI configuration
    oauth: {
      domain: appParam.auth.domain,
      scope: [
        'phone',
        'email',
        'profile',
        'openid',
        'aws.cognito.signin.user.admin'
      ],
      redirectSignIn: appParam.auth.redirectSignIn,
      redirectSignOut: appParam.auth.redirectSignOut,
      responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
    }
  },
  Storage: {
    AWSS3: {
      bucket: environment.S3.bucket,
      identityPoolId: environment.S3.identityPoolId,
      region: appParam.auth.region
    }
  }
});

// You can get the current config object
const currentConfig = Auth.configure();

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  errHandler: appErrorHandler;

  private userId: string = '';
  private userObject: any;

  constructor(private appService: AppService) {}

  // reset the user's password if the entered verification code is correct
  async changePassword(
    _userId: string,
    _oldPassword: string,
    _newPassword: string
  ): Promise<any> {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        return Auth.changePassword(user, _oldPassword, _newPassword);
      })
      .then((data) => console.log(data))
      .catch((err) => console.log(err));
  }

  async signIn(_userId: string, _pwd: string): Promise<boolean> {
    this.userId = _userId;

    return Auth.signIn(_userId, _pwd)
      .then((user) => {
        this.userObject = user['signInUserSession'];

        // 2021-10-25 CJ: API + Cognito auth integration
        this.appService.updateCache(
          appParam.cacheKeys.authToken,
          user.signInUserSession.idToken.jwtToken
        );

        // console.log(this.userObject, user)

        this.appService.sendNotification({
          type: alertAttributes.types.workflowSteps.A1_signIn.A1001,
          message: '',
          body: this.userObject['idToken']['payload'],
          displayNotification: false
        });

        return true;
      })
      .catch((err) => {
        console.log(err);
        // this.errHandler.handleAuthErrors({
        //   type: alertAttributes.types.error, displayMessage: alertAttributes.alerts.C2_signIn.C2001_01 + err.message, displayNotification: true, errorObject: {}
        // })
        return false;
      });
  }

  getStyle() {
    return 'byc-01';
  }

  public async isLoggedIn() {
    try {
      this.userObject = await Auth.currentSession();

      if (!environment.production) {
        console.log('userObject', this.userObject);
        console.log(
          'token.exp  ',
          this.parseJwt(this.userObject.accessToken.jwtToken).exp
        );
        console.log('currentTime', Math.floor(Date.now() / 1000));
      }

      /**
       * Get the authentication token, and then update the cache to ensure
       * it is always up to date.
       */
      const token = this.userObject.idToken.jwtToken;
      this.appService.updateCache(appParam.cacheKeys.authToken, token);

      this.appService.sendNotification({
        type: alertAttributes.types.workflowSteps.A1_signIn.A1001,
        message: '',
        body: this.userObject['idToken']['payload'],
        displayNotification: false
      });

      this.appService.UserObject.userId =
        this.userObject['idToken']['payload']['cognito:username'];

      // console.log('isLoggedIn ========= ', this.appService.UserObject)

      return true;
    } catch (err: any) {
      console.error('isLoggedIn', err);
      return false;
    }
  }

  async signOut() {
    try {
      await Auth.signOut();

      this.appService.UserObject.userId = '';
      this.appService.UserObject.mobile = '';
    } catch (error) {
      console.log('error signing out: ', error);
    }
  }

  getIdToken() {
    return this.userObject['idToken']['payload'];
  }

  getCurrentUserId() {
    const res = this.getIdToken();
    return res['cognito:username'];
  }

  /**
   * Handle decoding the contents ofthe authentication token to obtain
   * access to the details.
   *
   * @param token The authentication token to decode.
   *
   * @returns The payload of the authentication or undefined.
   */
  private parseJwt(token: string) {
    try {
      return JSON.parse(atob(token.split('.')[1]));
    } catch {
      return undefined;
    }
  }
}
