import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { appParam } from "../../helper/appSettings";
import { AppService } from "../../services/app.service";
import { ApiService } from "../../services/api.service";
import { AuthService } from "../../services/auth.service";
import { AfterViewInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { alertAttributes } from "../../helper/appAlert";

//  row structure
export interface TableRow {
  id: string; // used for the checkbox column
  date: string;
  holiday: string;
  depotId: number;
  depot: string;
  dropoff: string;
  changeover: string;
  context_menu: string;
}

let ELEMENT_DATA: TableRow[] = [];

@Component({
  selector: 'app-uxa12001',
  templateUrl: './uxa12001.component.html',
  styleUrls: ['./uxa12001.component.scss']
})
export class Uxa12001Component implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('tabGroup') tabGroup;

  dataSource = new MatTableDataSource<TableRow>(ELEMENT_DATA);

  _style: string = '';
  _defaultDateFormat: string = appParam.defaultDateFormat;
  tabList: {[key: string]: number} = {};

  _currentDepot: string;
  _currentContext: TableRow;

  //  columns to be displayed in the table
  displayedColumns: string[] = [
    'id',
    'date',
    'holiday',
    'dropoff',
    'changeover',
    'context_menu',
  ];

  constructor(
    private appService: AppService,
    private apiService: ApiService,
    private authService: AuthService,
    public dialog: MatDialog
  ) {
    this._style = this.authService.getStyle();
  }

  async ngOnInit(): Promise<void> {
    await this.getData()
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  contextClick(row: any) {
    this._currentContext = row;
  }

  async updateTableData($event?) {
    if ($event === undefined) {
      this._currentDepot = this.tabGroup._allTabs.first.textLabel
    } else if ($event.tab !== undefined) {
      this._currentDepot = $event.tab.textLabel
    } else {
      this._currentDepot = $event
    }

    let depot = this.tabList[this._currentDepot].toString()

    this.appService.appSpinner(true);

    let _data = await this.apiService.getPublicHolidaysByDepot(depot);
    let tempData = []

    for (let data of _data) {
      let depotId = this.tabList[data['depot_name']]
      tempData.push({
        id: data['holiday_id'],
        date: data['holiday_date'],
        holiday: data['holiday_name'],
        depot: data['depot_name'],
        depotId: depotId,
        dropoff: data['dropoff_limit'],
        changeover: data['changeover_limit'],
        context_menu: '...'
      })
    }

    setTimeout(() => {
      this.dataSource = new MatTableDataSource(tempData);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.appService.appSpinner(false);
    }, 500);
  }

  // get data from API
  async getData() {
    this.appService.appSpinner(true);

    // get all the users depot access
    let depotIds = [];
    let user = await this.apiService.getUsers(this.authService.getCurrentUserId())
    for (let depot of user.UsersDepotAccess) {
      this.tabList[depot.Depot.desc] = depot.Depot.id
      depotIds.push(depot.Depot.id)
    }

    this.appService.appSpinner(false);
  }

  async regenerateAvailableDays() {
    this.appService.appSpinner(true);
    try {
      let res = await this.apiService.updateAvailableDates();
    } catch (err) {
      this.appService.sendNotification({
        type: alertAttributes.types.error,
        message: 'Could not generate available dates!',
        body: '',
        displayNotification: true
      })
      this.appService.appSpinner(false);
      return;
    }
    this.appService.sendNotification({
      type: alertAttributes.types.success,
      message: 'Regenerated available dates!',
      body: '',
      displayNotification: true
    })
    this.appService.appSpinner(false);
  }

  async openEditDaysDialog() {
    let dialogRef = this.dialog.open(EditDaysDialog, {
      autoFocus: true,
      minWidth: '25%',
      height: 'auto',
      data: {
        date: this._currentContext.date,
        holiday: this._currentContext.holiday,
        holidayId: this._currentContext.id,
        depot: this._currentContext.depot,
        depotId: this._currentContext.depotId,
        dropoff: this._currentContext.dropoff,
        changeover: this._currentContext.changeover }
    });

    dialogRef.afterClosed().subscribe(async (result: boolean) => {
        if (result) await this.updateTableData(this._currentDepot);
      }
    )
  }
}

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'edit-days-dialog',
  templateUrl: 'edit-days-dialog.html',
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class EditDaysDialog {
  holidayId: number;
  depotId: number;
  dropoff: number;
  depot: string;
  changeover: number;
  date: Date;
  dateString: string;
  holiday: string;
  isBtnDisabled: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<EditDaysDialog>,
    private route: ActivatedRoute,
    private appService: AppService,
    private apiService: ApiService,
    @Inject(MAT_DIALOG_DATA) public data: {
      holidayId: string, depotId: number, dropoff: string, changeover: string, date: string, holiday: string, depot: string
    },
  ) {
    this.date = new Date(this.data.date)
    this.depot = this.data.depot
    this.holidayId = Number(this.data.holidayId)
    this.depotId = this.data.depotId
    this.dateString = this.date.toLocaleDateString('en-GB')
    this.holiday = this.data.holiday
    this.dropoff = Number(this.data.dropoff)
    this.changeover = Number(this.data.changeover)
  }

  closeDialogRef() {
    this.dialogRef.close(true)
  }

  async save() {
    this.appService.appSpinner(true);
    this.isBtnDisabled = true;

    try {
      const res = await this.apiService.updatePublicHolidaysLimits(this.holidayId, this.depotId, this.dropoff, this.changeover)

      this.appService.appSpinner(false)
      this.appService.sendNotification({
        type: alertAttributes.types.info,
        message: 'Date limits have been updated',
        body: '',
        displayNotification: true,
        autoCloseAfter: 3
      })

      setTimeout(() => {
        this.closeDialogRef()
      }, 500);

    } catch (err) {
      this.appService.appSpinner(false);
      this.isBtnDisabled = false;
      console.log(err)
    }
  }
}
