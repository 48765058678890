import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-uxa000',
  templateUrl: './uxa000.component.html',
  styleUrls: ['./uxa000.component.scss']
})
export class Uxa000Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
