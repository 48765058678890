<div class="container-fluid col m-0 p-0">
  <div class="row">
    <div class="col m-0 p-0">
      <mat-radio-group
        aria-labelledby="example-radio-group-label"
        class="byc-radio-group"
        labelPosition="before"
      >
        <mat-radio-button
          class="byc-radio-button-small d-block"
          *ngFor="let item of this._items; let i = index"
          [value]="item.key"
          [checked]="item.checked"
          data-cy="itemPicker"
          [disabled]="_readOnly"
          (change)="itemSelected($event)"
        >
          {{ item.description }}

          <div
            [ngClass]="{
              'byc-hidden': item.body === undefined,
              'byc-radio-button-small-body pt-2': item.body != ''
            }"
          >
            {{ item.body }}
          </div>
        </mat-radio-button>
      </mat-radio-group>
    </div>
  </div>
</div>
