import { Component, Input, OnInit } from '@angular/core';
import { IOptionType } from 'src/app/helper/appInterfaces';
import { AppService } from 'src/app/services/app.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-display-charity',
  templateUrl: './display-charity.component.html',
  styleUrls: ['./display-charity.component.scss']
})
export class DisplayCharityComponent implements OnInit {
  _style: string = '';
  @Input() _Charity: IOptionType = { key: '', description: '', checked: false, body: '', icon: '' };

  constructor(public appService: AppService,
    private authService: AuthService) {
    this._style = this.authService.getStyle()
  }

  ngOnInit(): void {
  }

}
