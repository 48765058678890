<mat-tab-group #tabGroup mat-align-tabls="center" (selectedTabChange)="updateTableData($event)">
  <ng-container *ngFor="let tab of tabList | keyvalue">
    <mat-tab [label]="tab.key">
      <ng-template matTabContent>
        <div fxLayout="column">

          <div fxLayout="row" fxLayoutGap="10px" class="mx-3 mt-3">
            <div>
              <button mat-raised-button color="primary" (click)="regenerateAvailableDays()"><mat-icon>autorenew</mat-icon>Regenerate Available Days</button>
            </div>
            <mat-form-field fxFlex="grow">
              <mat-label>Search</mat-label>
              <mat-icon matPrefix>search</mat-icon>
              <input matInput (keyup)="applyFilter($event)" placeholder="E.g Monday" />
            </mat-form-field>
          </div>

          <div style="max-height: 600px; overflow-y: auto;">
            <table mat-table [dataSource]="dataSource" matSort matSortDirection="asc" matSortActive="date"
                   style="max-height: 500px;">

              <!-- Date Column -->
              <ng-container matColumnDef="date">
                <th mat-header-cell *matHeaderCellDef mat-sort-header style="padding: 0 0 0 12px !important;">Date</th>
                <td mat-cell *matCellDef="let element" style="padding: 0 0 0 12px !important;">
                  {{ element.date | date: this._defaultDateFormat}}
                </td>
              </ng-container>

              <!-- Weekday Column -->
              <ng-container matColumnDef="weekday">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Weekday</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.weekday }}
                </td>
              </ng-container>

              <!-- Description Column -->
              <ng-container matColumnDef="description">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Description</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.description }}
                </td>
              </ng-container>

              <!-- Checkbox Column HIDDEN -->
              <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef mat-sort-header [hidden]="true">ID</th>
                <td mat-cell *matCellDef="let element" [hidden]="true">
                  {{ element.id }}
                </td>
              </ng-container>

              <!-- Dropoff Actual Column -->
              <ng-container matColumnDef="dropoff_actual">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Dropoff Orders Actual</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.dropoff_actual }}
                </td>
              </ng-container>

              <!-- Dropoff Limit Column -->
              <ng-container matColumnDef="dropoff_limit">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Dropoff Limit</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.dropoff_limit }}
                </td>
              </ng-container>

              <!-- Changeover Actual Column -->
              <ng-container matColumnDef="changeover_actual">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Changeover Orders Actual</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.changeover_actual }}
                </td>
              </ng-container>

              <!-- Changeover Limit Column -->
              <ng-container matColumnDef="changeover_limit">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Changeover Limit</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.changeover_limit }}
                </td>
              </ng-container>

              <!-- Context menu Column -->
              <ng-container matColumnDef="block_order">
                <th mat-header-cell *matHeaderCellDef data-cy="edit-data">Block Orders</th>
                <td mat-cell *matCellDef="let element" (click)="blockOrderClick(element);">
                  <mat-slide-toggle color="accent" [checked]="element.block_order"></mat-slide-toggle>
                </td>
              </ng-container>

              <ng-container matColumnDef="context_menu">
                <th mat-header-cell *matHeaderCellDef data-cy="edit-data">Edit Exception</th>
                <td mat-cell *matCellDef="let element" (click)="editRowClick(element); openAddExceptionDialog();">
                  <button mat-icon-button>
                    <mat-icon>edit</mat-icon>
                  </button>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            </table>
          </div>

          <mat-paginator
            [length]="dataSource.data.length"
            [pageSizeOptions]="[20, 50, 100, 500]"
            showFirstLastButtons
          ></mat-paginator>
        </div>
      </ng-template>
    </mat-tab>
  </ng-container>
</mat-tab-group>
