<div fxLayout="column" fxLayoutAlign="center center">
  <h2 mat-dialog-title>Are you sure?</h2>
  <div fxFlex class="mb-2">
    <h3 mat-dialog-content>
      Delete customer <b>{{ data.userId }}</b>?
    </h3>
  </div>
  <div fxLayout="row" fxLayoutGap="10px">
    <div fxFlex>
      <button mat-raised-button color="primary" mat-dialog-close>Cancel</button>
    </div>
    <div fxFlex>
      <button mat-raised-button color="primary" [mat-dialog-close]="true">
        Confirm
      </button>
    </div>
  </div>
</div>
