import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { IAnnouncement } from 'src/app/helper/appInterfaces';
import { appParam } from 'src/app/helper/appSettings';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-announcement-tile',
  templateUrl: './announcement-tile.component.html',
  styleUrls: ['./announcement-tile.component.scss']
})

export class AnnouncementTileComponent implements OnInit {
  _style: string = '';
  _defaultDateFormat: string = appParam.defaultDateFormat;

  _thumbnail: any;

  @Input() _announcement: IAnnouncement;

  constructor(private sanitizer: DomSanitizer, private authService: AuthService) {
    this._style = this.authService.getStyle()

  }

  ngOnInit(): void {
    if (this._announcement.image != '') this._thumbnail = this.sanitizer.bypassSecurityTrustUrl(this._announcement.image)
  }

}
