<mat-horizontal-stepper linear="true" #stepper>
  <mat-step [stepControl]="firstFormGroup">
    <ng-template matStepLabel>Plan Details</ng-template>
    <form [formGroup]="firstFormGroup" fxLayout="column">
      <div fxLayout="row" fxLayoutGap="50px">
        <div fxLayout="column" [style.min-width.px]="300">
          <div class="mat-title">Plan Details</div>
          <mat-form-field appearance="outline">
            <mat-label>Plan Name</mat-label>
            <input
              matInput
              value=""
              data-cy="planName"
              id="planName"
              formControlName="planName" />
            <mat-error>Required</mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Description</mat-label>
            <textarea
              matInput
              rows="6"
              value=""
              data-cy="description"
              id="description"
              formControlName="description"></textarea>
            <mat-error>Required</mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Category</mat-label>
            <mat-select formControlName="category">
              <mat-option
                *ngFor="let category of categories"
                [value]="category">
                {{ category }}
              </mat-option>
            </mat-select>
            <mat-error>Required</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="center center">
        <button mat-raised-button mat-dialog-close color="primary" style="width: 20%">Cancel</button>
        <button mat-raised-button matStepperNext color="primary" style="width: 20%" [disabled]="!firstFormGroup.valid">Next</button>
      </div>
    </form>
  </mat-step>
  <mat-step>
    <ng-template matStepLabel>Fees & Limits</ng-template>
    <form [formGroup]="secondFormGroup" fxLayout="column">
      <div fxLayout="row" fxLayoutGap="50px">
        <div fxLayout="column" [style.min-width.px]="250">
          <div class="mat-title">Fees & Limits</div>
          <mat-form-field appearance="outline">
            <mat-label>Bin Limit</mat-label>
            <input
              [readonly]="disableBinLimit"
              matInput
              value=""
              data-cy="binLimit"
              id="binLimit"
              formControlName="binLimit" />
            <mat-error *ngIf="this.secondFormGroup.controls.binLimit.errors?.pattern">Only numbers allowed</mat-error>
          </mat-form-field>
          <mat-checkbox color="primary" (change)="disableBinLimitClick()" style="padding-bottom: 20px;">
            Unlimited number of bins?
          </mat-checkbox>

          <mat-form-field appearance="outline" *ngIf="firstFormGroup.value.category !== 'BASIC'">
            <mat-label>Additional Bin Fee ($)</mat-label>
            <input
              matInput
              value=""
              data-cy="binFee"
              id="binFee"
              formControlName="binFee" />
            <mat-error *ngIf="this.secondFormGroup.controls.binFee.errors?.pattern">Only numbers allowed</mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline" *ngIf="firstFormGroup.value.category !== 'BASIC'">
            <mat-label>Membership Fee ($)</mat-label>
            <input
              matInput
              value=""
              data-cy="membershipFee"
              id="membershipFee"
              formControlName="membershipFee" />
            <mat-error *ngIf="this.secondFormGroup.controls.membershipFee.errors?.pattern">Only numbers allowed</mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline" *ngIf="firstFormGroup.value.category == 'CHARITY'">
            <mat-label>Instalment Amount ($)</mat-label>
            <input
              matInput
              value=""
              data-cy="instalmentAmount"
              id="instalmentAmount"
              formControlName="instalmentAmount" />
            <mat-error *ngIf="this.secondFormGroup.controls.instalmentAmount.errors?.pattern">Only numbers allowed</mat-error>
          </mat-form-field>

        </div>

        <div fxLayout="column" [style.min-width.px]="250">
          <div class="mat-title">Direct Collect Fees</div>
          <mat-form-field appearance="outline">
            <mat-label>Direct Collect Commission ($)</mat-label>
            <input
              matInput
              value=""
              data-cy="directCollectCommission"
              id="directCollectCommission"
              formControlName="directCollectCommission" />
            <mat-error *ngIf="this.secondFormGroup.controls.directCollectCommission.errors?.required">required</mat-error>
            <mat-error *ngIf="this.secondFormGroup.controls.directCollectCommission.errors?.pattern">Only numbers allowed</mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Handling Fee ($)</mat-label>
            <input
              matInput
              value=""
              data-cy="handlingFee"
              id="handlingFee"
              formControlName="handlingFee" />
            <mat-error *ngIf="this.secondFormGroup.controls.handlingFee.errors?.required">required</mat-error>
            <mat-error *ngIf="this.secondFormGroup.controls.handlingFee.errors?.pattern">Only numbers allowed</mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Refund Per Container ($)</mat-label>
            <input
              matInput
              value=""
              data-cy="refundPerContainer"
              id="refundPerContainer"
              formControlName="refundPerContainer" />
            <mat-error *ngIf="this.secondFormGroup.controls.refundPerContainer.errors?.required">required</mat-error>
            <mat-error *ngIf="this.secondFormGroup.controls.refundPerContainer.errors?.pattern">Only numbers allowed</mat-error>
          </mat-form-field>
        </div>

        <div fxLayout="column" [style.min-width.px]="250">
          <div class="mat-title">Display Options</div>
          <mat-label>Display on UI?</mat-label>
          <mat-slide-toggle class="text-invisible" color="accent" (change)="changeDisplayOnUI()"></mat-slide-toggle>
        </div>
      </div>

      <div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="center center" style="padding-top: 10px;">
        <button mat-raised-button matStepperPrevious color="primary" style="width: 20%">Back</button>
        <button mat-raised-button matStepperNext color="primary" style="width: 20%" [disabled]="!secondFormGroup.valid">Next</button>
      </div>
    </form>
  </mat-step>

  <mat-step>
    <ng-template matStepLabel>Summary</ng-template>
    <div class="mat-title">Summary</div>

    <div fxLayout="row">
      <div fxLayout="column" style="width: 300px;">
        <span fxFlex class="dc-primary-variant mat-h3 mb-0">Plan Name</span>
        <span fxFlex class="dc-primary mat-h4">{{ firstFormGroup.value.planName }}</span>
        <span fxFlex class="dc-primary-variant mat-h3 mb-0">Description</span>
        <span fxFlex class="dc-primary mat-h4">{{ firstFormGroup.value.description }}</span>
        <span fxFlex class="dc-primary-variant mat-h3 mb-0">Category</span>
        <span fxFlex class="dc-primary mat-h4">{{ firstFormGroup.value.category }}</span>
      </div>

      <div fxLayout="column" style="padding-left: 15px; width: 300px;">
        <span class="dc-primary-variant mat-h3 mb-0">Bin Limit</span>
        <span class="dc-primary mat-h4" *ngIf="secondFormGroup.value.binLimit != null">{{ secondFormGroup.value.binLimit }}</span>
        <span class="dc-primary mat-h4" *ngIf="secondFormGroup.value.binLimit == null">Unlimited</span>
        <span *ngIf="firstFormGroup.value.category !== 'BASIC'" class="dc-primary-variant mat-h3 mb-0">Additional Bin Fee</span>
        <span *ngIf="firstFormGroup.value.category !== 'BASIC'" class="dc-primary mat-h4">${{ secondFormGroup.value.binFee }}</span>
        <span *ngIf="firstFormGroup.value.category !== 'BASIC'" class="dc-primary-variant mat-h3 mb-0">Membership Fee</span>
        <span *ngIf="firstFormGroup.value.category !== 'BASIC'" class="dc-primary mat-h4">${{ secondFormGroup.value.membershipFee }}</span>
        <span *ngIf="firstFormGroup.value.category == 'CHARITY'" class="dc-primary-variant mat-h3 mb-0">Charity Instalment Amount</span>
        <span *ngIf="firstFormGroup.value.category == 'CHARITY'" class="dc-primary mat-h4">${{ secondFormGroup.value.instalmentAmount }}</span>
      </div>

      <div fxLayout="column" style="width: 300px;">
        <span fxFlex class="dc-primary-variant mat-h3 mb-0">Direct Collect Commission</span>
        <span fxFlex class="dc-primary mat-h4">${{ secondFormGroup.value.directCollectCommission }}</span>
        <span fxFlex class="dc-primary-variant mat-h3 mb-0">Handling Fee</span>
        <span fxFlex class="dc-primary mat-h4">${{ secondFormGroup.value.handlingFee }}</span>
        <span fxFlex class="dc-primary-variant mat-h3 mb-0">Refund Per Container</span>
        <span fxFlex class="dc-primary mat-h4">${{ secondFormGroup.value.refundPerContainer }}</span>
        <span fxFlex class="dc-primary-variant mat-h3 mb-0">Display on UI?</span>
        <span fxFlex class="dc-primary mat-h4">{{ displayOnUI }}</span>
      </div>
    </div>

    <div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="center center" style="padding-top: 10px;">
      <button mat-raised-button matStepperPrevious color="primary" style="width: 20%">Back</button>
      <button mat-raised-button matStepperNext color="primary"
              (click)="save()" [disabled]="!secondFormGroup.valid && !firstFormGroup.valid" style="width: 20%">
        Confirm
      </button>
    </div>
  </mat-step>
</mat-horizontal-stepper>


