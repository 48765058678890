import { Component, Inject, OnInit } from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { alertAttributes } from 'src/app/helper/appAlert';
import { appParam } from 'src/app/helper/appSettings';
import { ApiService } from 'src/app/services/api.service';
import { AppService } from 'src/app/services/app.service';
import { AuthService } from 'src/app/services/auth.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {IAddress} from "../../helper/appInterfaces";
import {appUtils} from "../../helper/appUtils";

@Component({
  selector: 'app-uxa7001a',
  templateUrl: './uxa7001a.component.html',
  styleUrls: ['./uxa7001a.component.scss']
})
export class Uxa7001aComponent implements OnInit {
  _style: string = '';

  frmGroup: FormGroup;
  frmGroupBackup: FormGroup; // used to track which fields were changed

  latitude!: number;
  longitude!: number;
  zoom!: number;
  address: string = '';
  postcode!: string;
  state!: string;
  oldAddress: string = '';

  states: any[] = [];
  regions: any[] = [];
  depots: any[] = [];

  _id: string = ''; //  for edit driver purpose
  _editMode: boolean = false; //  for edit driver purpose

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { id: string },
    private dialogRef: MatDialogRef<Uxa7001aComponent>,
    private fg: FormBuilder,
    private appService: AppService,
    private apiService: ApiService,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this._style = this.authService.getStyle();
    this._id = this.data.id || this.route.snapshot.queryParamMap.get('id');
  }

  ngOnInit(): void {
    this.frmGroup = this.fg.group({
      id: '',
      depot: ['', Validators.required],
      first_name: ['', Validators.required],
      last_name: ['', Validators.required],
      mobile: ['', [Validators.required, Validators.pattern(/^04\d{8}$|^\+61\d{9}$|^04\d{2}\s\d{3}\s\d{3}$|^\+61\s\d{3}\s\d{3}\s\d{3}/)]],
      license_number: ['', Validators.required],
      email: ['', [Validators.required, Validators.pattern(/^\w+([\.\+-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)]],
      device_number: [''],
      street_address: ['', Validators.required],
    });

    this.frmGroup.valueChanges.subscribe((value) => {
      this.valueChanged(value);
    });

    this.getData();
  }

  async getData() {
    this.appService.appSpinner(true);
    await this.getDepots()

    //  load data for edit mode
    if (this._id != '' && this._id != undefined) {
      let resDriver = await this.apiService.getDrivers(this._id);

      if (resDriver.length > 0) {
        const editDriver = resDriver[0];

        this.frmGroup.patchValue({
          depot: editDriver['refDepot']['id'],
          id: editDriver['id'],
          first_name: editDriver['first_name'],
          last_name: editDriver['last_name'],
          mobile: editDriver['mobile'],
          license_number: editDriver['license_number'],
          email: editDriver['email'],
          device_number: editDriver['device_number']
        })
        // set the old address
        this.oldAddress = editDriver.street_address
        this._editMode = true;
      }
    }

    this.frmGroupBackup = this.frmGroup;

    this.appService.appSpinner(false);
  }

  async valueChanged(frmValues: any) {
    this.frmGroupBackup = this.frmGroup;
  }

  async getDepots() {
    // get the signed in user's depot access
    let depots = await this.apiService.getUsers(this.authService.getCurrentUserId())

    // for each depot, push to depot array to be displayed
    for (let depot of depots.UsersDepotAccess) {
      this.depots.push({
        key: depot.Depot.id,
        code: depot.Depot.code,
        desc: depot.Depot.desc
      })
    }
  }

  async addressPicked(event: IAddress) {
    this.address = event.address;
    this.latitude = event.lat;
    this.longitude = event.lng;
    this.postcode = event.postcode;
    this.state = event.state;

    this.frmGroup.patchValue({ street_address: this.address });
  }

  mobileValidator(): ValidatorFn {
    return (controls: AbstractControl) => {
      if (controls.value) {
        const value = controls.value.replace(/\D/gi, '');
        if (value.length < 9 || value.length > 12) {
          return { format: true };
        }
      }
      return null;
    };
  }

  async save(): Promise<void> {
    try {
      this.appService.appSpinner(true);

      const driver = {
        driver_id: this.frmGroup.value.id,
        depot_id: this.frmGroup.value.depot,
        code: this.frmGroup.value.depot + this.frmGroup.value.first_name + this.frmGroup.value.last_name,
        full_name: this.frmGroup.value.first_name + ' ' + this.frmGroup.value.last_name,
        first_name: this.frmGroup.value.first_name,
        last_name: this.frmGroup.value.last_name,
        mobile: this.frmGroup.value.mobile,
        license_number: this.frmGroup.value.license_number,
        email: this.frmGroup.value.email,
        street_address: this.frmGroup.value.street_address,
        suburb: appUtils.getSuburb(this.frmGroup.value.street_address),
        device_number: this.frmGroup.value.device_number
      };

      const res = await this.apiService.createDriver(driver);

      if (res['id'] != undefined) {
        if (!this._editMode) {
          const resCognito = await this.apiService.createDriverInCognito(res);
        }

        this.appService.appSpinner(false);

        let _msg = 'The driver has been onboraded.';
        if (this._editMode) {
          _msg = 'The driver has been updated.';
        }
        this.appService.sendNotification({
          type: alertAttributes.types.info,
          message: _msg,
          body: '',
          displayNotification: true
        });

        setTimeout(() => {
          //this.router.navigate(['../' + appParam.routing.A7_driverManagement.A7001], { relativeTo: this.route });
        }, 3000);
      }
      this.appService.appSpinner(false);
      this.dialogRef.close(true);
    } catch (err) {
      console.error(err);
      this.appService.appSpinner(false);
      this.appService.sendNotification({
        type: alertAttributes.types.error,
        message: 'Error creating driver: ' + err,
        body: '',
        displayNotification: true
      });
    }
  }
}
