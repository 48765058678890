import { Component, AfterViewInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs/internal/Subscription';
import { AppService } from './services/app.service';
import { AuthService } from './services/auth.service';
import { AlertComponent } from './shared/alert/alert.component';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit, OnDestroy {
  _style: string = '';

  alertSub: Subscription | undefined;
  spinnerSub: Subscription | undefined;

  title = 'Backyard Cash - Depot experience';

  // variable that controls the spinner globally
  _displaySpinner: boolean = true;

  constructor(private appService: AppService, private authService: AuthService, private snackBar: MatSnackBar) {
    this._style = this.authService.getStyle();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.alertSub = this.appService.currentAppNotification.subscribe((notification) => {
        if (notification && notification.displayNotification)
          this.snackBar.openFromComponent(AlertComponent, {
            data: notification,
            horizontalPosition: 'center',
            verticalPosition: 'top',
            duration: notification.autoCloseAfter ? notification.autoCloseAfter * 1000 : null
          });
      });

      this.spinnerSub = this.appService.currentAppSpinner.subscribe((status) => {
        if (status) this.snackBar.dismiss();
        this._displaySpinner = status;
      });
    });
  }

  public ngOnDestroy() {
    if (this.alertSub) {
      this.alertSub.unsubscribe();
    }
  }
}
