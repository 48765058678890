import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { alertAttributes } from 'src/app/helper/appAlert';
import { appParam } from 'src/app/helper/appSettings';
import { appUtils } from 'src/app/helper/appUtils';
import { ApiService } from 'src/app/services/api.service';
import { AppService } from 'src/app/services/app.service';
import { AuthService } from 'src/app/services/auth.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-uxa1001',
  templateUrl: './uxa1001.component.html',
  styleUrls: ['./uxa1001.component.scss']
})
export class Uxa1001Component implements OnInit {
  _currentApplicationVersion = environment.appVersion;
  _style: string = '';

  public userId: string = '';
  public pwd: string = ''

  constructor(
    public appService: AppService,
    public authService: AuthService,
    private apiService: ApiService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this._style = this.authService.getStyle()

  }

  ngOnInit(): void {
  }


  async signIn() {
    this.appService.appSpinner(true)

    // clear the local cache
    await this.appService.clearCache();

    const signIn = await this.authService.signIn(this.userId, this.pwd)

    if (signIn == true) {

      const userDetails = await this.apiService.getUsers(this.userId)
      // console.log('user details', userDetails)

      if (userDetails != undefined) {
        await this.appService.updateCache(appParam.cacheKeys.userDetails, userDetails)
      }


      this.router.navigate(["/admin-home/" + appParam.routing.A2_orderManagement.A2001a], {
        relativeTo: this.route,
      });

    } else {
      this.appService.sendNotification({
        type: alertAttributes.types.error,
        message: 'Invalid user id or password!',
        body: '',
        displayNotification: true
      })
    }

    this.appService.appSpinner(false)
  }


}
