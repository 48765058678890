import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { appParam } from 'src/app/helper/appSettings';
import { ApiService } from 'src/app/services/api.service';
import { AppService } from 'src/app/services/app.service';
import { AuthService } from 'src/app/services/auth.service';
import { IAnnouncement } from 'src/app/helper/appInterfaces';

@Component({
  selector: 'app-uxa8001',
  templateUrl: './uxa8001.component.html',
  styleUrls: ['./uxa8001.component.scss']
})
export class Uxa8001Component implements OnInit {
  _style: string = '';

  _route_NewAnnouncement: string = '../' + appParam.routing.A8_announcementManagement.A8001a;
  _route_ViewAllAnnouncement: string = '../' + appParam.routing.A8_announcementManagement.A8001b;

  _announcements: IAnnouncement[]

  constructor(private appService: AppService,
    private apiService: ApiService,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this._style = this.authService.getStyle()

  }

  ngOnInit(): void {

    this.getData()
  }


  async getData() {
    this.appService.appSpinner(true)

    const res = await this.apiService.getAnnouncements();
    console.log('_announcements', this._announcements)

    this._announcements = res.map((item: any) => {
      return {
        main_heading: item['main_heading'],
        sub_heading: item['sub_heading'],
        body_content: item['body_content'],
        image: item['picture'],
        pub_from: item['pub_from'],
        pub_to: item['pub_to'],
      }
    });

    this.appService.appSpinner(false)
  }

  viewAnnouncements(event: any) {
    this.router.navigate([this._route_ViewAllAnnouncement], { relativeTo: this.route, queryParams: { type: event } });
  }
}
