<!--  -->
<!-- Form -->
<div class="container-fluid h-90 p-0">
  <!--  -->
  <form [formGroup]="frmGroup" class="{{ this._style }}-form">
    <div class="container-fluid p-2">
      <!-- Title row -->
      <div class="row p-0">
        <div class="col-8 my-auto {{ this._style }}-text-title pb-3">
          Create new announcement
        </div>
      </div>

      <!--  -->
      <!-- Announcement details and Image picker row -->
      <div class="row">
        <!-- Announcement details -->
        <div class="col-8">
          <div class="container-fluid {{ this._style }}-main-panel">
            <!-- Regions for selected state -->
            <div class="row">
              <div class="col-2 my-auto">Region</div>
              <div class="col">
                <mat-form-field>
                  <mat-select formControlName="region" data-cy="region">
                    <mat-option
                      *ngFor="let region of regions"
                      [value]="region.key"
                      >{{ region.desc }}</mat-option
                    ></mat-select
                  >
                </mat-form-field>
              </div>
            </div>

            <!--  -->
            <!-- Main heading -->
            <div class="row">
              <div class="col-2 my-auto">Main heading</div>
              <div class="col-10">
                <mat-form-field class="{{ this._style }}-form-field w-100">
                  <input
                    matInput
                    placeholder="main heading"
                    formControlName="main_heading"
                    data-cy="main_heading"
                  />
                </mat-form-field>
              </div>
            </div>

            <!--  -->
            <!-- Sub heading -->
            <div class="row">
              <div class="col-2 my-auto">Sub heading</div>
              <div class="col-10">
                <mat-form-field class="{{ this._style }}-form-field w-100">
                  <input
                    matInput
                    placeholder="sub heading"
                    formControlName="sub_heading"
                    data-cy="sub_heading"
                  />
                </mat-form-field>
              </div>
            </div>

            <!--  -->
            <!-- Body content -->
            <div class="row">
              <div class="col-2 mb-auto">Body content</div>
              <div class="col-10">
                <mat-form-field class="{{ this._style }}-form-field w-100">
                  <textarea
                    matInput
                    placeholder="content"
                    formControlName="body_content"
                    data-cy="body_content"
                  ></textarea>
                </mat-form-field>
              </div>
            </div>

            <!--  -->
            <!-- Date range and App selection-->
            <div class="row">
              <!-- Date range -->
              <div class="col-2 my-auto">Publish from</div>
              <!--  -->
              <!-- from/to -->
              <div class="col-4 d-block my-auto">
                <app-date-picker
                  [_availableDate]="this.availableDates"
                  [_selectedDate]="this.frmGroup.value.pub_from"
                  (mDatePicked)="pubFrom($event)"
                  data-cy="pub_from"
                >
                </app-date-picker>
              </div>
              <div class="col-4 d-block my-auto">
                <app-date-picker
                  [_availableDate]="this.availableDates"
                  [_selectedDate]="this.frmGroup.value.pub_to"
                  (mDatePicked)="pubTo($event)"
                  data-cy="pub_to"
                >
                </app-date-picker>
              </div>
            </div>
          </div>
        </div>

        <!-- Image picker and preview -->
        <div class="col-4">
          <div class="container-fluid {{ this._style }}-main-panel">
            <!-- Preview section -->
            <div class="row">
              <div class="col my-auto {{ this._style }}-text-title py-3">
                Preview
              </div>
            </div>
            <!--  -->
            <div class="row">
              <div class="col">
                <div class="container-fluid {{ this._style }}-main-panel p-2">
                  <div class="row">
                    <div
                      class="col my-auto {{
                        this._style
                      }}-text-subtitle byc-text-bold py-2"
                    >
                      {{ this.frmGroup.value.main_heading }}
                    </div>
                  </div>
                  <div class="row">
                    <div
                      class="col my-auto {{ this._style }}-text-subtitle py-1"
                    >
                      {{ this.frmGroup.value.sub_heading }}
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-8 preview-body-content p-3">
                      {{ this.frmGroup.value.body_content }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--  -->
          </div>
        </div>
      </div>
      <!--  -->
      <!-- Save -->
      <div class="row">
        <div class="col-12">
          <button
            mat-raised-button
            id="signIn"
            class="d-block {{ this._style }}-next {{
              this._style
            }}-primary mt-4"
            (click)="save()"
            [disabled]="frmGroup.status != 'VALID'"
            data-cy="save"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  </form>
  <!--  -->
</div>
