<div fxLayout="column" fxLayoutAlign="space-between center" style="padding: 10px 10px 10px 10px">
  <h1>Change Customer Plan</h1>

  <div fxLayout="row" fxLayoutGap="30px">


    <div fxLayout="column" [style.min-width.px]="300">
      <mat-form-field appearance="outline">
        <mat-label>Old Subscription Type</mat-label>
        <input matInput value="{{ data.oldPlanDesc }}" [disabled]="true"/>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Subscription Type</mat-label>
        <mat-select (selectionChange)="onChange($event)" [(value)]="selectedSubscription">
          <mat-option
            *ngFor="let subscriptionType of this.subscriptionTypeItems"
            [value]="subscriptionType.key">
            {{ subscriptionType['description'] }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <ng-container *ngIf="selectedSubscription != ''">
        <h2>Plan Details</h2>
        <div fxLayout="column">
          <span fxFlex class="dc-primary mat-h3" *ngIf="this.selectedSubObj.membership_fee != null"
                style="display: inline-block;"><b>Membership fee:</b> ${{this.selectedSubObj.membership_fee}}</span>
          <span fxFlex class="dc-primary mat-h3" *ngIf="this.selectedSubObj.bin_fee != null"
                style="display: inline-block;"><b>Bin fee:</b> ${{this.selectedSubObj.bin_fee}}</span>
          <span fxFlex class="dc-primary mat-h3" *ngIf="this.selectedSubObj.bin_limit != null"
                style="display: inline-block;"><b>Bin limit:</b> {{this.selectedSubObj.bin_limit}}</span>
          <span fxFlex class="dc-primary mat-h3" *ngIf="this.selectedSubObj.instalment_amount != null"
                style="display: inline-block;"><b>Instalment amount:</b> ${{this.selectedSubObj.instalment_amount}}</span>
          <span fxFlex class="dc-primary mat-h4" *ngIf="this.selectedSubObj.body != null"
                style="display: inline-block; width: 300px;">
          <b>Description:</b> {{this.selectedSubObj.body}}
        </span>
        </div>
      </ng-container>
    </div>

    <ng-container *ngIf="selectedSubscription != ''">
      <div fxLayout="column" [style.min-width.px]="300">
        <h2>{{data.customerName}} - Payment History</h2>
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z1 mb-4">
          <ng-container matColumnDef="desc">
            <th mat-header-cell *matHeaderCellDef>Desc</th>
            <td mat-cell *matCellDef="let history"> {{history.desc}}</td>
          </ng-container>

          <ng-container matColumnDef="date">
            <th mat-header-cell *matHeaderCellDef>Date</th>
            <td mat-cell *matCellDef="let history"> {{history.date}}</td>
          </ng-container>

          <ng-container matColumnDef="value">
            <th mat-header-cell *matHeaderCellDef>Value</th>
            <td mat-cell *matCellDef="let history"> {{history.value}}</td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumn"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumn;"></tr>
        </table>

        <h2>Reimbursement</h2>
        <mat-checkbox class="mb-2" [(ngModel)]="reimburseCustomer">Reimburse customer?</mat-checkbox>
        <ng-container *ngIf="reimburseCustomer">
          <mat-form-field appearance="outline">
            <mat-label>Reimbursement Amount</mat-label>
            <input
              matInput
              value=""
              id="reimbursementAmount"
              formControlName="reimbursementAmount"/>
          </mat-form-field>
        </ng-container>

        <h2 class="mt-2">Terms and Conditions</h2>
        <mat-checkbox [(ngModel)]="tcAccepted">Accept terms and conditions?</mat-checkbox>
      </div>

      <ng-container *ngIf="selectedSubObj.category != 'CHARITY'">
        <ng-container *ngIf="selectedSubObj.category == 'PREMIUM'">
          <div fxLayout="column" [style.min-width.px]="350" fxLayoutGap="15px">
            <app-credit-card-payment
              [username]='data.username'
              [membershipFee]="selectedSubObj.membership_fee"
              [additionalFee]="selectedSubObj.bin_fee"
              (successfulResponse)="paymentAttempted($event)"
              style="width: 100%"
            ></app-credit-card-payment>
            <ng-container *ngIf="successfulPayment">
              <mat-card [style.padding-bottom]="0" class="success-card">
                <mat-card-content class="pb-3">
                  <span
                    class="mat-h3"
                    style="filter: brightness(70%)">
                    Payment was successful!
                  </span>
                </mat-card-content>
              </mat-card>
            </ng-container>
          </div>
        </ng-container>
        <div fxLayout="column" [style.max-width.px]="300">
          <h2>Bank Details</h2>
          <form [formGroup]="frmGroup">
            <div fxLayout="column" [style.min-width.px]="300">
              <mat-form-field appearance="outline">
                <mat-label>Account name</mat-label>
                <input
                  matInput
                  value=""
                  data-cy="accountName"
                  id="accountName"
                  formControlName="accountName"/>
                <mat-error>Required</mat-error>
              </mat-form-field>
              <mat-form-field appearance="outline">
                <mat-label>Account number</mat-label>
                <input
                  matInput
                  value=""
                  data-cy="accountNumber"
                  id="accountNumber"
                  formControlName="accountNumber"/>
                <mat-error>Required</mat-error>
              </mat-form-field>
              <mat-form-field appearance="outline">
                <mat-label>BSB</mat-label>
                <input
                  matInput
                  value=""
                  data-cy="accountBSB"
                  id="accountBSB"
                  formControlName="accountBSB"/>
                <mat-error *ngIf="this.frmGroup.controls.accountBSB.errors?.pattern"
                >Invalid format
                </mat-error
                >
                <mat-error>Required</mat-error>
              </mat-form-field>
              <mat-form-field appearance="outline">
                <mat-label>Bank</mat-label>
                <input
                  matInput
                  value=""
                  data-cy="bankName"
                  id="bankName"
                  formControlName="bankName"/>
                <mat-error>Required</mat-error>
              </mat-form-field>
            </div>
          </form>
        </div>
      </ng-container>

      <ng-container *ngIf="selectedSubObj.category == 'CHARITY'">
        <div fxLayout="column" [style.min-width.px]="300">
          <h2>Charity Details</h2>
          <mat-form-field appearance="outline">
            <mat-label>Charity</mat-label>
            <mat-select [formControl]="charityControl" (selectionChange)="onCharityChange($event)" [(value)]="selectedCharity">
              <mat-option
                *ngFor="let charityType of this.charityItems"
                [value]="charityType.key">
                {{ charityType['description'] }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="charityControl.hasError('required')">Please choose a charity</mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Tax invoice email</mat-label>
            <input
              [(ngModel)]="data.username"
              matInput
              value=""
              id="taxInvoiceEmail"/>
          </mat-form-field>
        </div>
      </ng-container>
    </ng-container>
  </div>

  <div class="pt-3" fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="center center">
    <button mat-raised-button color="primary" mat-dialog-close style="width: 150px;" [disabled]="disableCancel">Cancel</button>
    <button mat-raised-button color="primary" (click)="save()" [disabled]="frmGroup.invalid || !tcAccepted" style="width: 150px;">Save
    </button>
  </div>

</div>
