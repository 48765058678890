<mat-tab-group #tabGroup (selectedTabChange)="updateTableData($event)">
  <ng-container *ngFor="let tab of tabList | keyvalue">
    <mat-tab [label]="tab.key">
      <ng-template matTabContent>
        <mat-tab-group #nestedTabGroup [(selectedIndex)]="tabIndex" (click)="getWeekdayConfig($event)">
          <mat-tab label="Weekday Config">
            <ng-template matTabContent>
              <div class="my-3 mx-3">
                <button mat-raised-button color="primary" (click)="regenerateAvailableDays()"><mat-icon>autorenew</mat-icon>Regenerate Available Days</button>
              </div>

              <div fxLayout="column">
                <table mat-table [dataSource]="dataSource" matSort matSortDirection="asc" matSortActive="date">
                  <!-- Checkbox Column -->
                  <ng-container matColumnDef="id">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>ID</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.id }}
                    </td>
                  </ng-container>

                  <!-- Weekday Column -->
                  <ng-container matColumnDef="weekday">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Weekday</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.weekday }}
                    </td>
                  </ng-container>

                  <!-- Description Column -->
                  <ng-container matColumnDef="description">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Description</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.description }}
                    </td>
                  </ng-container>

                  <!-- Dropoff Column -->
                  <ng-container matColumnDef="dropoff">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Current Dropoff Limit</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.dropoff }}
                    </td>
                  </ng-container>

                  <!-- Dropoff Column -->
                  <ng-container matColumnDef="new_dropoff">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>New Dropoff Limit</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.new_dropoff }}
                    </td>
                  </ng-container>

                  <!-- Changeover Column -->
                  <ng-container matColumnDef="changeover">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Current Changeover Limit</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.changeover }}
                    </td>
                  </ng-container>

                  <!-- Changeover Column -->
                  <ng-container matColumnDef="new_changeover">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>New Changeover Limit</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.new_changeover }}
                    </td>
                  </ng-container>

                  <!-- Date Column -->
                  <ng-container matColumnDef="effective">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Effective</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.effective | date: this._defaultDateFormat}}
                    </td>
                  </ng-container>

                  <!-- Context menu Column -->
                  <ng-container matColumnDef="context_menu">
                    <th mat-header-cell *matHeaderCellDef data-cy="edit-data">Actions</th>
                    <td mat-cell *matCellDef="let element" (click)="contextClick(element); openEditConfigDialog()">
                      <button
                        mat-icon-button
                      >
                        <mat-icon>edit</mat-icon>
                      </button>
                    </td>
                  </ng-container>

                  <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                </table>
              </div>
            </ng-template>
          </mat-tab>
          <mat-tab label="Suburb Config">
            <ng-template matTabContent>
              <ng-container *ngIf="suburbConfigTabList.length == 0">
                <div fxFlex="row" fxLayoutAlign="center center">
                  <h2 style="padding-top: 10px">Please configure a weekday before assigning suburbs!</h2>
                </div>
              </ng-container>
              <mat-tab-group #suburbWeekdayGroup (selectedTabChange)="updateSuburbData()">
                <ng-container *ngFor="let tab of suburbConfigTabList">
                  <mat-tab [label]="tab">

                    <div class="suburb-outside">
                      <div class="suburb-container" fxLayout="column" fxLayoutAlign="center center">
                        <div fxLayout="row">
                          <h2> Suburb Config - {{ _currentDepot }} - {{ tab }}</h2>
                        </div>

                        <div fxLayout="row" fxLayoutGap="20px">
                          <mat-card>
                            <mat-card-content>
                              <div fxLayout="row" fxLayoutAlign="center center">
                                <h3>Unassigned</h3>
                              </div>
                              <ng-container *ngIf="unassignedSuburbs.length > 0">
                                <div fxLayout="row">
                                  <div fxLayout="column" fxLayoutAlign="start center">
                                    <p>Postcode</p>
                                    <mat-list>
                                      <mat-list-item *ngFor="let postcode of unassignedSuburbs"> {{ postcode['postcode'] }} </mat-list-item>
                                    </mat-list>
                                  </div>
                                  <div fxLayout="column" fxLayoutAlign="start center">
                                    <p>Suburb</p>
                                    <mat-selection-list [(ngModel)]="selectedUnassignedSuburbs">
                                      <mat-list-option *ngFor="let suburb of unassignedSuburbs" [value]="suburb"> {{ suburb['suburb_name'] }} </mat-list-option>
                                    </mat-selection-list>
                                  </div>
                                </div>
                              </ng-container>
                              <ng-container *ngIf="unassignedSuburbs.length == 0">
                                <div fxLayout="row" fxLayoutAlign="center center">
                                  <h3>No suburbs unassigned</h3>
                                </div>
                              </ng-container>
                            </mat-card-content>
                          </mat-card>

                          <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="30px">
                            <button mat-raised-button color="primary" (click)="addSuburbs()">Add >></button>
                            <button mat-raised-button color="primary" (click)="removeSuburbs()"><< Remove</button>
                          </div>

                          <mat-card>
                            <mat-card-content>
                              <div fxLayout="row" fxLayoutAlign="center center">
                                <h3>Assigned</h3>
                              </div>
                              <ng-container *ngIf="assignedSuburbs.length > 0">
                                <div fxLayout="row">
                                  <div fxLayout="column" fxLayoutAlign="start center">
                                    <p>Postcode</p>
                                    <mat-list>
                                      <mat-list-item *ngFor="let postcode of assignedSuburbs"> {{ postcode['postcode'] }} </mat-list-item>
                                    </mat-list>
                                  </div>
                                  <div fxLayout="column" fxLayoutAlign="start center">
                                    <p>Suburb</p>
                                    <mat-selection-list [(ngModel)]="selectedAssignedSuburbs">
                                      <mat-list-option *ngFor="let suburb of assignedSuburbs" [value]="suburb"> {{ suburb['suburb_name'] }} </mat-list-option>
                                    </mat-selection-list>
                                  </div>
                                </div>
                              </ng-container>
                              <ng-container *ngIf="assignedSuburbs.length == 0">
                                <div fxLayout="row" fxLayoutAlign="center center">
                                  <h3>No suburbs assigned</h3>
                                </div>
                              </ng-container>
                            </mat-card-content>
                          </mat-card>

                        </div>
                        <div class="mt-3" fxLayout="row" fxLayoutAlign="center center">
                          <button mat-raised-button color="primary" (click)="addSuburb()">Add a new suburb</button>
                        </div>
                      </div>

                    </div>

                  </mat-tab>
                </ng-container>
              </mat-tab-group>
            </ng-template>
          </mat-tab>
        </mat-tab-group>
      </ng-template>
    </mat-tab>
  </ng-container>
</mat-tab-group>
