import { AfterViewInit, Component, OnInit, ViewChild, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { IMarker } from 'src/app/helper/appInterfaces';
import { appParam } from 'src/app/helper/appSettings';
import { appUtils } from 'src/app/helper/appUtils';
import { ApiService } from 'src/app/services/api.service';
import { AppService } from 'src/app/services/app.service';
import { AuthService } from 'src/app/services/auth.service';
import { environment } from 'src/environments/environment';
import { Location } from "@angular/common";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

// import { Loader } from '@googlemaps/js-api-loader';

@Component({
  selector: 'app-uxa3003',
  templateUrl: './uxa3003.component.html',
  styleUrls: ['./uxa3003.component.scss']
})
export class Uxa3003Component implements OnInit, AfterViewInit {
  _style: string = '';
  _id: string = '';

  @ViewChild('gmap') gmapElement: any;
  map: google.maps.Map;

  lat = appParam.mapLocation.default.lat;
  lng = appParam.mapLocation.default.long;
  zoom = appParam.mapLocation.default.zoom;

  origin: any
  destination: any;
  waypoints: any[] = [];

  manifest: any;
  markers: IMarker[] = [];
  stops: any[] = [];

  stopCount: number = 0;
  assignedDriver: string = '';

  startTime: number = 0;
  endTime: number = 0;
  totalDuration: string = '';
  renderOptions: any = {
    suppressMarkers: true
  }

  constructor(
    private appService: AppService,
    private apiService: ApiService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
  private location: Location
  ) {
    this._style = this.authService.getStyle()
    this._id = this.route.snapshot.queryParamMap.get('id')
  }


  ngOnInit(): void {

    // const loader = new Loader({
    //   apiKey: environment.maps_key,
    //   version: "weekly",
    // });

    this.getData()
  }


  ngAfterViewInit(): void {

  }

  back(): void {
    this.location.back()
  }

  async getData() {
    this.appService.appSpinner(true)

    const res = await this.apiService.getManifests(this._id)
    const resAdmin = await this.apiService.getUsers(this.authService.getCurrentUserId())

    if (res.length > 0) {
      this.manifest = res[0]

      this.sendToDriver = this.manifest['stats_01']
      if (this.manifest['refDriver'] != undefined) this.assignedDriver = this.manifest['refDriver']['desc']

      await this.manifest['ManifestDetails'].forEach((item) => {
        // only time this will be null is for the start and end orders
        if (item['refCustomerOrder']['refCustomerAddress'] == null) {
          if (this.manifest['start_location'] != null && item['draft_order'] == 0) {
            this.stops.push({
              address: this.manifest['start_location']['location']['address'],
              name: 'Depot',
              manifestDetailId: item['id'],
              arrivalTime: item['arrivalTime'],
              departureTime: item['departureTime'],
              sortOrder: item['optimized_order'] != null ? item['optimized_order'] : item['draft_order']
            })
            this.markers.push({
              sortId: item['optimized_order'] != null ? item['optimized_order'] : item['draft_order'],
              lat: this.manifest['start_location']['location']['lat'],
              lng: this.manifest['start_location']['location']['long'],
              isOpen: false,
              info: this.manifest['start_location']['location']['address'],
              label: ' '
            })
          } else if (this.manifest['finish_location'] != null && item['draft_order'] == 999) {
            this.stops.push({
              address: this.manifest['finish_location']['location']['address'],
              name: 'Depot',
              manifestDetailId: item['id'],
              arrivalTime: item['arrivalTime'],
              departureTime: item['departureTime'],
              sortOrder: item['optimized_order'] != null ? item['optimized_order'] : item['draft_order']
            })
            this.markers.push({
              sortId: item['optimized_order'] != null ? item['optimized_order'] : item['draft_order'],
              lat: this.manifest['finish_location']['location']['lat'],
              lng: this.manifest['finish_location']['location']['long'],
              isOpen: false,
              info: this.manifest['finish_location']['location']['address'],
              label: ' '
            })
        } else {
            this.stops.push({
              address: resAdmin.UsersDepotAccess[0].Depot.street_address,
              name: 'Depot',
              manifestDetailId: item['id'],
              arrivalTime: item['arrivalTime'],
              departureTime: item['departureTime'],
              sortOrder: item['optimized_order'] != null ? item['optimized_order'] : item['draft_order']
            })
            this.markers.push({
              sortId: item['optimized_order'] != null ? item['optimized_order'] : item['draft_order'],
              lat: resAdmin.UsersDepotAccess[0].Depot.lat,
              lng: resAdmin.UsersDepotAccess[0].Depot.lng,
              isOpen: false,
              info: resAdmin.UsersDepotAccess[0].Depot.street_address,
              label: ' '
            })
          }
        } else {
          this.stops.push({
            address: item['refCustomerOrder']['refCustomerAddress']['street_address'],
            name: item['refCustomerOrder']['refCustomerAddress']['refCustomer']['first_name'] + ' ' +  item['refCustomerOrder']['refCustomerAddress']['refCustomer']['last_name'],
            manifestDetailId: item['id'],
            arrivalTime: item['arrivalTime'],
            departureTime: item['departureTime'],
            sortOrder: item['optimized_order'] != null ? item['optimized_order'] : item['draft_order']
          })
          this.markers.push({
            // sortId: item['arrivalTime'],
            sortId: item['optimized_order'] != null ? item['optimized_order'] : item['draft_order'],
            lat: item['refCustomerOrder']['refCustomerAddress']['lat'],
            lng: item['refCustomerOrder']['refCustomerAddress']['lng'],
            isOpen: false,
            info: item['refCustomerOrder']['refCustomerAddress']['street_address'],
            label: ' '
          })
        }
        // save arrival and departure for total time duration calculation
        if (item['arrivalTime'] < this.startTime || this.startTime == 0) this.startTime = item['arrivalTime']
        if (item['departureTime'] > this.endTime) this.endTime = item['departureTime']
      })

      // sort the stops based on arrival time
      // console.log('before', this.stops)
      // console.log('before', this.markers)

      appUtils.sortArrayOfObjects(this.stops, 'sortOrder')
      // !! this does nothing !!
      appUtils.sortArrayOfObjects(this.markers, 'sortId')

      // Updates for route
      this.stopCount = this.markers.length

      const userDetails = await this.appService.getCache(appParam.cacheKeys.userDetails)

      if (this.manifest['start_location'] != null || this.manifest['finish_location'] != null) {
        this.origin = {lat: this.markers[0].lat, lng: this.markers[0].lng}
        this.destination = {lat: this.markers[this.markers.length -1].lat, lng: this.markers[this.markers.length - 1].lng}
      } else {
        this.origin = {lat: this.markers[0].lat, lng: this.markers[0].lng}
        this.destination = this.origin
      }

      this.stopCount = this.markers.length

      let idx = 0;
      this.markers.forEach(item => {
        if (this.manifest['start_location'] != null || this.manifest['finish_location'] != null) {
          item.label = String(0)
        }
        if (idx > 0) {
          item.label = String(idx)
        }
        // remove the destination now that the ordering is correct
        if (idx > 0 && idx < (this.stopCount - 1)) {
          this.waypoints.push({location: {lat: item.lat, lng: item.lng}, stopover: false})
        }
        idx++
      })

      // calculate total duration
      this.totalDuration = moment("00:00:00", 'HH:mm:ss').add(this.endTime - this.startTime, 'seconds').format('HH:mm');

    }

    this.appService.appSpinner(false)
  }


  selectMarker(event) {
    // console.log(event)
  }


  async sendToDriver() {

  }

  async printManifest() {
    let printContents = document.getElementById('manifest').innerHTML;
    let originalContents = document.body.innerHTML;

    // document.body.innerHTML = printContents;
    window.print();
    // document.body.innerHTML = originalContents;
  }

  async orderSummaryDialog(stop) {
    let _images = []

    if (stop['orderStatus'] == null) {
      stop['orderStatus'] = 'PENDING'
    } else {
      // TODO: get the manifest media here
    }

    const dialogRef = this.dialog.open(OrderSummaryDialog, {
      autoFocus: true,
      minWidth: '30%',
      height: 'auto',
      data: { customerName: stop['name'], address: stop['address'], orderStatus: stop['orderStatus'], images: _images }
    });
  }
}

export interface DialogData {
  customerName: string,
  address: string,
  orderStatus: string,
  images: string[]
}

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'order-summary-dialog',
  templateUrl: 'uxa3003-order-summary.html'
})

// eslint-disable-next-line @angular-eslint/component-class-suffix
export class OrderSummaryDialog {
  constructor(
    public dialogRef: MatDialogRef<OrderSummaryDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  close() {
    this.dialogRef.close()
  }
}
