import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { AppService } from 'src/app/services/app.service';
import { AuthService } from 'src/app/services/auth.service';

import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { alertAttributes } from 'src/app/helper/appAlert';
import { appUtils } from 'src/app/helper/appUtils';
import { appParam } from 'src/app/helper/appSettings';
import { DomSanitizer } from '@angular/platform-browser';
import { IAddress, IOptionType } from 'src/app/helper/appInterfaces';
import { appErrorHandler } from 'src/app/helper/appErrorHandler';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-uxa9003',
  templateUrl: './uxa9003.component.html',
  styleUrls: ['./uxa9003.component.scss']
})
export class Uxa9003Component implements OnInit {
  errHandler: appErrorHandler;

  _style: string = '';

  frmGroup: FormGroup;
  frmGroupBackup: FormGroup; // used to track which fields were changed

  userType: string = '';
  regions: any[] = [];
  selectedRegion: IOptionType = { key: '', description: '', checked: false };

  // Location details
  latitude!: number;
  longitude!: number;
  zoom!: number;
  address: string = '';
  postcode!: string;
  state!: string;

  locationTypeItems: any[] = [];
  selectedLocationType: IOptionType = { key: '', description: '', checked: false };

  subscriptionTypeItems: any[] = [];
  selectedSubscriptionType: IOptionType = { key: '', description: '', checked: false };
  isCharity: boolean = false;

  charityItems: any[] = [];
  selectedCharity: IOptionType = { key: '', description: '', checked: false };

  constructor(
    private dialogRef: MatDialogRef<Uxa9003Component>,
    private fg: FormBuilder,
    private appService: AppService,
    private apiService: ApiService,
    private authService: AuthService
  ) {
    this._style = this.authService.getStyle();
  }

  ngOnInit(): void {
    this.frmGroup = this.fg.group({
      id: '',
      region: ['', Validators.required],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.pattern(/^\w+([\.\+-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)]],
      mobile: ['', [Validators.required, Validators.pattern(/^04\d{8}$|^\+61\d{9}$|^04\d{2}\s\d{3}\s\d{3}$|^\+61\s\d{3}\s\d{3}\s\d{3}/), this.mobileValidator()]],
      password: [
        '',
        [
          Validators.required,
          Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[=+\-^$*.\[\]{}()?"!@#%&/\\,><':;|_~`])\S{8,99}$/)
        ]
      ],
      confirmPassword: ['', [Validators.required, this.passwordValidator()]],
      address: ['', Validators.required],
      locationType: ['', Validators.required],
      subscriptionType: ['', Validators.required],
      charity: [''],
      taxInvoiceEmail: ['', [Validators.required, Validators.pattern(/^\w+([\.\+-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)]]
    });

    this.frmGroup.valueChanges.subscribe((value) => {
      this.valueChanged(value);
    });

    this.getData();
  }

  async valueChanged(frmValues: any) {
    if (frmValues.charity != '') {
      const resCharity = await appUtils.searchInArray(this.charityItems, 'key', frmValues.charity)
      this.selectedCharity.key = resCharity.key;
      this.selectedCharity.description = resCharity.description;
    } else if (frmValues.subscriptionType != '') {
      const resSubscriptionType = await appUtils.searchInArray(this.subscriptionTypeItems, 'key', frmValues.subscriptionType)
      this.selectedSubscriptionType.key = resSubscriptionType.key;
      this.selectedSubscriptionType.description = resSubscriptionType.description;

      this.isCharity = this.selectedSubscriptionType.description.indexOf('Donation Plan') > -1;

      if (this.isCharity == false) {
        this.selectedCharity.key = '';
        this.selectedCharity.description = '';

        if (this.selectedSubscriptionType.description.indexOf('Basic Plan') > -1) {
          this.userType = appParam.userType.basic;
        } else if (this.selectedSubscriptionType.description.indexOf('Premium Plan') > -1) {
          this.userType = appParam.userType.premium;
        } else {
          this.userType = appParam.userType.commercial
        }
      } else {
        this.userType = appParam.userType.charity;
      }
    } else if (frmValues.locationType != '') {
      const resAddressType = await appUtils.searchInArray(this.locationTypeItems, 'key', frmValues.locationType)
      this.selectedLocationType.key = resAddressType.key;
      this.selectedLocationType.description = resAddressType.description;
    // if (frmValues.region != '' && (this.frmGroupBackup == undefined || this.frmGroupBackup['region'] != frmValues.region)) {
    } else if (frmValues.region != '') {
      this.appService.appSpinner(true);

      const resRegion = await appUtils.searchInArray(this.regions, 'key', frmValues.region);
      this.selectedRegion.key = resRegion.key;
      this.selectedRegion.description = resRegion.desc;

      // TODO: turn this into an async promise all
      this.locationTypeItems = await this.apiService.getAddressTypes();
      this.subscriptionTypeItems = await this.apiService.getSubscriptionTypes('');
      this.charityItems = await this.apiService.getCharities('', '');

      this.appService.appSpinner(false);
    }

    this.frmGroupBackup = frmValues;
  }

  async getData() {
    this.regions = await this.apiService.getRegions();
  }

  mobileValidator(): ValidatorFn {
    return (controls: AbstractControl) => {
      if (controls.value) {
        const value = controls.value.replace(/\D/gi, '');
        if (value.length < 9 || value.length > 12) {
          return { format: true };
        }
      }
      return null;
    };
  }

  passwordValidator(): ValidatorFn {
    return (controls: AbstractControl) => {
      if (controls.value && this.frmGroup) {
        if (controls.value !== this.frmGroup.value.password) {
          return { match: true };
        }
      }
      return null;
    };
  }

  async addressPicked(event: IAddress) {
    this.address = event.address;
    this.latitude = event.lat;
    this.longitude = event.lng;
    this.postcode = event.postcode;
    this.state = event.state;

    this.frmGroup.patchValue({ address: this.address });
  }

  async save() {
    try {
      this.appService.appSpinner(true);

      let user = {
        userType: this.userType,
        charityCode: '',
        userId: this.frmGroup.value.email,
        password: this.frmGroup.value.password,
        email: this.frmGroup.value.email,
        mobile: this.frmGroup.value.mobile,
        firstName: this.frmGroup.value.firstName,
        lastName: this.frmGroup.value.lastName,
        regionId: this.frmGroup.value.region,
        charityId: this.frmGroup.value.charity,
        taxInvoiceEmail: this.frmGroup.value.taxInvoiceEmail,
        address: this.address,
        postcode: this.postcode,
        state: this.state,
        lat: this.latitude,
        lng: this.longitude,
        locationTypeId: this.frmGroup.value.locationType,
        subscriptionTypeId: this.frmGroup.value.subscriptionType,
        userConfirmed: false
      };

      if (this.selectedCharity != undefined) {
        user.charityCode = this.selectedCharity['key'];
      }

      let res;

      //  create user in cognito
      res = await this.apiService.createCustomerInCognito(user);
      // console.log('createCustomerInCognito', res)
      if (res['User'] == undefined) {
        this.appService.appSpinner(false);

        this.appService.sendNotification({
          type: alertAttributes.types.info,
          message: 'Could not create the customer',
          body: '',
          displayNotification: true
        });
        return;
      }

      // create user in backend database
      user.password = ''; // password is not stored anywhere except in Cognito
      res = await this.apiService.createCustomer(user.userId, user);
      // console.log('createCustomer', res)
      if (res.length == 0) {
        this.appService.appSpinner(true);
        return;
      }

      res = await this.apiService.updateCustomerAddress(user);
      // console.log('updateCustomerAddress', res)
      if (res.length == 0) {
        this.appService.appSpinner(true);
        return;
      }

      res = await this.apiService.updateCustomerDetails(user);
      // console.log('updateCustomerDetails', res)
      if (res.length == 0) {
        this.appService.appSpinner(true);
        return;
      }

      this.appService.appSpinner(false);

      this.appService.sendNotification({
        type: alertAttributes.types.info,
        message: 'Customer has been created',
        body: '',
        displayNotification: true,
        autoCloseAfter: 3
      });
      this.dialogRef.close(true);
    } catch (err: any) {
      this.appService.appSpinner(false);
    }
  }
}
