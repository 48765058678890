import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment } from "../../../environments/environment";
import { ApiService } from "../../services/api.service";
import { AppService } from "../../services/app.service";
import {alertAttributes} from "../../helper/appAlert";

@Component({
  selector: 'app-credit-card-payment',
  templateUrl: './credit-card-payment.component.html',
  styleUrls: ['./credit-card-payment.component.scss']
})
export class CreditCardPaymentComponent implements OnInit {
  cardFormGroup: FormGroup;
  stripeElements: any;
  stripe: any;
  @Input() username: string = '';
  @Input() membershipFee: number;
  @Input() additionalFee: number;
  @Output() successfulResponse = new EventEmitter<boolean>();

  cardNumberElement: any;
  cardExpiryElement: any;
  cardCVCElement: any;

  btnDisabled: boolean = false;

  constructor(
    private fg: FormBuilder,
    private appApi: ApiService,
    private appService: AppService
  ) {
    this.stripe = (<any>window).Stripe(environment.stripe_pk);
  }

  ngOnInit(): void {
    this.cardFormGroup = this.fg.group({
      amount: ['', [Validators.required, Validators.pattern(/^[0-9]+$/)]],
      username: [this.username, [Validators.required]]
    })

    this.stripeElements = this.stripe.elements();

    var style = {
      base: {
        color: '#6C757D',
        fontFamily: 'Arial, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '14px',
        padding: '4px',
        '::placeholder': {
          color: '#6C757D'
        }
      },
      invalid: {
        fontFamily: 'Arial, sans-serif',
        color: '#fa755a',
        iconColor: '#fa755a'
      }
    };

    this.cardNumberElement = this.stripeElements.create('cardNumber', {
      style: style
    });
    this.cardNumberElement.mount('#card-number');

    this.cardExpiryElement = this.stripeElements.create('cardExpiry', {
      style: style
    });
    this.cardExpiryElement.mount('#card-expiry');

    this.cardCVCElement = this.stripeElements.create('cardCvc', {
      style: style
    });
    this.cardCVCElement.mount('#card-cvc');

    this.cardNumberElement.on('change', function (event: any) {});
  }

  async submitPayment(): Promise<void> {
    this.btnDisabled = true;
    await this.makePayment().then((payment) => {
      if (payment.hasOwnProperty('paymentIntent')) {
        this.successfulResponse.emit(true)
      } else {
        this.successfulResponse.emit(false)
        this.appService.sendNotification({
          type: alertAttributes.types.error,
          message: payment.error.message,
          body: '',
          displayNotification: true,
          autoCloseAfter: 5
        });
      }
    });
    this.appService.appSpinner(false);
    this.btnDisabled = false;
  }

  async makePayment() {
    this.appService.appSpinner(true);

    // send a request to the BYC backend which sends a request to the Stripe API to create a payment intent
    const paymentIntent = await this.appApi.createPaymentIntent(
      this.username,
      this.cardFormGroup.value.amount,
      this.membershipFee,
      this.additionalFee
    );

    if (paymentIntent['client_secret'] == undefined) {
      return undefined;
    }

    // now send the payment confirmation request to the Stripe api along with the
    // payment intent secret received from the above step
    return this.stripe.confirmCardPayment(paymentIntent.client_secret, {
      payment_method: {
        card: this.cardNumberElement
      }
    });
  }
}
