<div fxLayout="column" fxLayoutAlign="center center">
  <h2 mat-dialog-title>Edit Limits - {{ depot }}</h2>
  <div  fxLayout="row" fxLayoutGap="25px">
    <h3> {{ holiday }} </h3>
    <h3> {{ dateString }} </h3>
  </div>

  <div fxFlex class="mb-2">
    <mat-form-field>
      <input placeholder="Edit Dropoff Limit" matInput [(ngModel)]="dropoff">
    </mat-form-field>

    <mat-form-field style="margin-left: 14px">
      <input placeholder="Edit Changeover Limit" matInput [(ngModel)]="changeover">
    </mat-form-field>
  </div>

  <div fxLayout="row" fxLayoutGap="10px">
    <div fxFlex>
      <button mat-raised-button color="primary" mat-dialog-close>Cancel</button>
    </div>
    <div fxFlex>
      <button mat-raised-button color="primary" (click)="save()">
        Update
      </button>
    </div>
  </div>
</div>
