<div class="form-container">
  <table
    mat-table
    [dataSource]="dataSource"
    matSort
    [matSortActive]="_browseTableSettings.active"
    (matSortChange)="sortEvent($event)"
    class="tableBrowser"
  >
    <!-- created_at Column -->
    <ng-container matColumnDef="created_at">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        class="colCode colHeader"
      >
        Date/Time
      </th>
      <td mat-cell *matCellDef="let element" class="colCodeCell">
        {{ element.created_at | date: this._defaultDateTimeFormat }}
      </td>
    </ng-container>

    <!-- Desc Column -->
    <ng-container matColumnDef="desc">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        class="colDesc colHeader"
      >
        Desc
      </th>
      <td mat-cell *matCellDef="let element" class="colDescCell">
        {{ element.desc }}
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>

  <div class="p-1">Record count {{ this._rowCounts }}</div>

  <div class="tableFooter">
    <!-- <div class="page">
      Page:
      <mat-select
        (selectionChange)="loadPage($event)"
        [(value)]="this._currentBlock"
      >
        <mat-option
          *ngFor="let dataBlock of this._dataBlocks"
          [value]="dataBlock.label"
          >{{ dataBlock.label }}</mat-option
        ></mat-select
      >
    </div> -->

    <div class="paginator">
      <mat-paginator
        [pageSizeOptions]="[5, 10, 20]"
        showFirstLastButtons
        (page)="paginatorEvent($event)"
      ></mat-paginator>
    </div>
  </div>
</div>
