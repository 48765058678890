<div fxLayout="column">
  <div fxLayout="row" fxLayoutGap="10px" class="mx-3 mt-3">
    <div>
      <button mat-raised-button color="accent" (click)="openAddBinDialog()"><mat-icon>add</mat-icon>Create</button>
    </div>
    <div>
      <button mat-raised-button color="primary" (click)="decommissionAll()" [disabled]="!selection.selected.length">
        <mat-icon>delete_forever</mat-icon>Decommission
      </button>
    </div>
    <div>
      <button
        mat-raised-button
        color="primary"
        (click)="exportCSV()"
        [disabled]="!selection.selected.length"
        [style.margin-right.px]="10"
      >
        <mat-icon>file_upload</mat-icon>Export
      </button>
    </div>
    <mat-form-field fxFlex="grow">
      <mat-label>Search</mat-label>
      <mat-icon matPrefix>search</mat-icon>
      <input matInput (keyup)="applyFilter($event)" placeholder="Ex. DC101000145" />
    </mat-form-field>
  </div>

  <table mat-table [dataSource]="dataSource" matSort matSortDirection="desc" matSortActive="id">
    <!-- Checkbox Column -->
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox
          (change)="$event ? masterToggle() : null"
          [checked]="selection.hasValue() && isAllSelected()"
          [indeterminate]="selection.hasValue() && !isAllSelected()"
          [aria-label]="checkboxLabel()"
        >
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row">
        <mat-checkbox
          (click)="$event.stopPropagation()"
          (change)="this.toggle(row, $event)"
          [checked]="selection.isSelected(row)"
          [aria-label]="checkboxLabel(row)"
        >
        </mat-checkbox>
      </td>
    </ng-container>

    <!-- Code Column -->
    <ng-container matColumnDef="code">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Code</th>
      <td mat-cell *matCellDef="let row">
        {{ row.code }}
      </td>
    </ng-container>

    <!-- Desc Column -->
    <ng-container matColumnDef="desc">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Description</th>
      <td mat-cell *matCellDef="let row">{{ row.desc }}</td>
    </ng-container>

    <!-- Type Column-->
    <ng-container matColumnDef="type">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Type</th>
      <td mat-cell *matCellDef="let row">{{ row.type }}</td>
    </ng-container>

    <!-- Depot Column -->
        <ng-container matColumnDef="depot">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Depot</th>
          <td mat-cell *matCellDef="let row">{{ row.depot }}</td>
        </ng-container>

    <!-- Purchase date Column -->
    <ng-container matColumnDef="purchase_date">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Purchase date</th>
      <td mat-cell *matCellDef="let row">{{ row.purchase_date | date: this._defaultDateFormat }}</td>
    </ng-container>

    <!-- Decommission date Column -->
    <ng-container matColumnDef="decommission_date">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Decommission date</th>
      <td mat-cell *matCellDef="let row">{{ row.decommission_date | date: this._defaultDateFormat }}</td>
    </ng-container>

    <!-- Actions Column -->
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef data-cy="actions">Actions</th>
      <td mat-cell *matCellDef="let row" [matMenuTriggerFor]="menu" (click)="contextClick(row)">
        <button mat-icon-button>
          <mat-icon>more_vert</mat-icon>
        </button>
      </td>
    </ng-container>

    <!-- Updated at -->
    <ng-container matColumnDef="updated_at">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Updated at</th>
      <td mat-cell *matCellDef="let row" class="fst-italic text-secondary">
        {{ row.updated_at | date: this._defaultDateTimeFormat }}
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

    <!-- Row shown when there is no data. -->
    <tr *matNoDataRow>
      <td colspan="7" class="mat-h2 text-center">No bin found</td>
    </tr>
  </table>

  <mat-paginator pageSize="50" [pageSizeOptions]="[20, 50, 100, 500]" showFirstLastButtons></mat-paginator>
</div>

<mat-menu #menu="matMenu">
  <button mat-menu-item (click)="decommissionByBin()">
    <span>Decommission bin</span>
  </button>
  <button mat-menu-item (click)="openHistoryDialog()" data-cy="view-history">
    <span>View bin history</span>
  </button>
  <button mat-menu-item (click)="transferBin()">
    <span>Assign region/depot</span>
  </button>
</mat-menu>
