import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';

import { AuthGuard } from './services/auth-guard.service';

import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';

import { MatInputModule } from '@angular/material/input';
import { MatSliderModule } from '@angular/material/slider';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatListModule } from '@angular/material/list';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatRadioModule } from '@angular/material/radio';
import { MatMenuModule } from '@angular/material/menu';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatCardModule } from '@angular/material/card';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatProgressBarModule } from '@angular/material/progress-bar'
import { MatSlideToggleModule } from '@angular/material/slide-toggle'

import { Uxa1001Component } from './UX-A1/001/uxa1001.component';
import { Uxa1002Component } from './UX-A1/002/uxa1002.component';

import { Uxa2001Component } from './UX-A2/001/uxa2001.component';
import { Uxa2001aComponent } from './UX-A2/001a/uxa2001a.component';

import { Uxa3001Component } from './UX-A3/001/uxa3001.component';
import { Uxa3002Component } from './UX-A3/002/uxa3002.component';
import { OrderSummaryDialog, Uxa3003Component } from './UX-A3/003/uxa3003.component';

import { Uxa6001Component } from './UX-A6/001/uxa6001.component';
import { Uxa6001aComponent } from './UX-A6/001a/uxa6001a.component';
import { Uxa6001cComponent } from './UX-A6/001c/uxa6001c.component';

import { Uxa7001Component } from './UX-A7/001/uxa7001.component';
import { Uxa7001aComponent } from './UX-A7/001a/uxa7001a.component';
import { Uxa7001cComponent } from './UX-A7/001c/uxa7001c.component';

import { Uxa8001Component } from './UX-A8/001/uxa8001.component';
import { Uxa8001aComponent } from './UX-A8/001a/uxa8001a.component';
import { Uxa8001bComponent } from './UX-A8/001b/uxa8001b.component';
import { Uxa8001cComponent } from './UX-A8/001c/uxa8001c.component';
import { Uxa8001dComponent } from './UX-A8/001d/uxa8001d.component';

import { DeleteConfirmDialog, Uxa9001Component } from './UX-A9/001/uxa9001.component';
import { Uxa9002Component } from './UX-A9/002/uxa9002.component';
import { Uxa9002cComponent } from './UX-A9/002c/uxa9002c.component';
import { Uxa9002dComponent } from './UX-A9/002d/uxa9002d.component';
import { Uxa9002eComponent } from './UX-A9/002e/uxa9002e.component';
import { Uxa9002fComponent } from './UX-A9/002f/uxa9002f.component';
import { Uxa9002gComponent } from './UX-A9/002g/uxa9002g.component';
import { Uxa9003Component } from './UX-A9/003/uxa9003.component';


import { Uxa10001Component } from './UX-A10/001/uxa10001.component';

import { Uxa11001Component } from './UX-A11/001/uxa11001.component';

import { EditDaysDialog, Uxa12001Component } from './UX-A12/001/uxa12001.component';

import { AddSuburbDialog, EditConfigDialog, Uxa13001Component } from './UX-A13/001/uxa13001.component';

import { AddExceptionDialog, Uxa14001Component } from './UX-A14/001/uxa14001.component';

import { AddPlanDialog, EditPlanDialog, Uxa15001Component } from './UX-A15/001/uxa15001.component';

import { Uxa000Component } from './000/uxa000.component';

import { DatePickerComponent } from './shared/date-picker/date-picker.component';
import { AddressPickerComponent } from './shared/address-picker/address-picker.component';
import { NewAddressPickerComponent } from "./shared/new-address-picker/new-address-picker.component";
import { ItemPickerComponent } from './shared/item-picker/item-picker.component';
import { ItemAndNumberPickerComponent } from './shared/item-and-number-picker/item-and-number-picker.component';
import { CharityPanelComponent } from './shared/charity-panel/charity-panel.component';
import { AnnouncementTileComponent } from './shared/announcement-tile/announcement-tile.component';
import { CustomerDetailsComponent } from './shared/customer-details/customer-details.component';
import { CustomerSubscriptionComponent } from './shared/customer-subscription/customer-subscription.component';
import { CustomerFinancialsComponent } from './shared/customer-financials/customer-financials.component';
import { DisplayCharityComponent } from './shared/display-charity/display-charity.component';
import { ConfirmSMSDialog, ManifestComponent } from './shared/manifest/manifest.component';
import { StopComponent } from './shared/stop/stop.component';
import { AlertComponent } from './shared/alert/alert.component';
import { SuburbPickerComponent } from './shared/suburb-picker/suburb-picker.component'

import { AgmCoreModule } from '@agm/core';
import { AgmDirectionModule } from 'agm-direction';

import { NgxCSVtoJSONModule } from 'ngx-csvto-json';
import { NgxCsvParserModule } from 'ngx-csv-parser';

import { FlexLayoutModule, FlexModule } from '@angular/flex-layout';
import { MatStepperModule } from "@angular/material/stepper";
import { MatDialogModule } from '@angular/material/dialog';
import { MatTabsModule } from '@angular/material/tabs';
import { CreditCardPaymentComponent } from './shared/credit-card-payment/credit-card-payment.component';

@NgModule({
  declarations: [
    AppComponent,
    Uxa1001Component,
    Uxa2001Component,
    Uxa1002Component,
    Uxa6001Component,
    Uxa6001aComponent,
    Uxa000Component,
    Uxa6001cComponent,
    Uxa7001Component,
    Uxa7001aComponent,
    Uxa7001cComponent,
    Uxa8001Component,
    Uxa8001aComponent,
    Uxa8001bComponent,
    Uxa8001cComponent,
    Uxa8001dComponent,
    DatePickerComponent,
    AddressPickerComponent,
    NewAddressPickerComponent,
    ItemPickerComponent,
    ItemAndNumberPickerComponent,
    AnnouncementTileComponent,
    Uxa9001Component,
    Uxa9002Component,
    Uxa9003Component,
    CustomerDetailsComponent,
    CustomerSubscriptionComponent,
    CustomerFinancialsComponent,
    CharityPanelComponent,
    DisplayCharityComponent,
    Uxa9002cComponent,
    Uxa9002dComponent,
    Uxa9002eComponent,
    Uxa9002fComponent,
    Uxa2001aComponent,
    Uxa3001Component,
    Uxa3002Component,
    ManifestComponent,
    Uxa3003Component,
    StopComponent,
    Uxa10001Component,
    Uxa11001Component,
    AlertComponent,
    OrderSummaryDialog,
    ConfirmSMSDialog,
    DeleteConfirmDialog,
    Uxa12001Component,
    EditDaysDialog,
    Uxa13001Component,
    EditConfigDialog,
    AddSuburbDialog,
    SuburbPickerComponent,
    Uxa14001Component,
    AddExceptionDialog,
    Uxa15001Component,
    AddPlanDialog,
    EditPlanDialog,
    CreditCardPaymentComponent,
    Uxa9002gComponent,
  ],
  imports: [
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyAGJ_TGzKtLrN9ceEI0YoF01wxtFmip9EI',
      libraries: ['places']
    }),
    AgmDirectionModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    MatInputModule,
    MatIconModule,
    MatSliderModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    MatSidenavModule,
    MatButtonModule,
    MatDividerModule,
    MatListModule,
    MatSelectModule,
    MatPaginatorModule,
    MatTableModule,
    MatSortModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatMenuModule,
    MatCheckboxModule,
    MatRadioModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatCardModule,
    MatSnackBarModule,
    NgxCSVtoJSONModule,
    NgxCsvParserModule,
    FlexLayoutModule,
    MatStepperModule,
    MatDividerModule,
    MatDialogModule,
    MatListModule,
    FlexModule,
    MatTabsModule,
    MatSlideToggleModule
  ],
  providers: [AuthGuard, { provide: MAT_DATE_LOCALE, useValue: 'en-gb' }],
  bootstrap: [AppComponent]
})
export class AppModule {}
