import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { AppService } from 'src/app/services/app.service';
import { AuthService } from 'src/app/services/auth.service';

import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';

import { ApiService } from 'src/app/services/api.service';
import { appParam } from 'src/app/helper/appSettings';
import { appUtils } from 'src/app/helper/appUtils';
import { alertAttributes } from 'src/app/helper/appAlert';
import { Router, ActivatedRoute } from '@angular/router';
import { appErrorHandler } from 'src/app/helper/appErrorHandler';
import { SelectionModel } from '@angular/cdk/collections';
import { MatInput } from '@angular/material/input';

//  row structure
export interface TableRow {
  id: string; // used for the checkbox column
  order_type: string;
  customer: string;
  customer_type: string;
  street_address: string;
  suburb: string;
  region: string;
  order_date: string;
  bins_to_drop_off: number;
  bins_to_pick_up: number;
  context_menu: string;
  selected: boolean;
  order: any;
  assigned: boolean;
}

let ELEMENT_DATA: TableRow[] = [];

@Component({
  selector: 'app-uxa2001a',
  templateUrl: './uxa2001a.component.html',
  styleUrls: ['./uxa2001a.component.scss']
})
export class Uxa2001aComponent implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('dateInput', { read: MatInput }) dateInput: MatInput;

  errHandler: appErrorHandler;
  _style: string = '';
  _defaultDateFormat: string = appParam.defaultDateFormat;
  availableDates: any[] = [];

  dataSource = new MatTableDataSource<TableRow>(ELEMENT_DATA);
  selection = new SelectionModel<TableRow>(true, []);

  // used to store the selected bin
  _currentContext: TableRow;

  displayedColumns: string[] = [
    'id',
    'order_type',
    'customer',
    'customer_type',
    'street_address',
    'suburb',
    'region',
    'order_date',
    'bins_to_drop_off',
    'bins_to_pick_up',
    'context_menu'
  ];

  constructor(
    private appService: AppService,
    private apiService: ApiService,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.errHandler = new appErrorHandler(this.appService);
    this._style = this.authService.getStyle();
  }

  async ngOnInit(): Promise<void> {
    await this.getData();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  //  get data from API
  async getData() {
    this.appService.appSpinner(true);

    //  update the array for the table
    this.dataSource.data = [];

    // get all the users depot access
    let depotIds = [];
    let user = await this.apiService.getUsers(this.authService.getCurrentUserId())
    for (let depot of user.UsersDepotAccess) {
      depotIds.push(depot.Depot.id)
    }
    let _data = await this.apiService.getInProgressOrdersByDepot(depotIds.toString())

    try {
      if (_data.length > 0) {
        for (let _row of _data) {
          // ignore dummy orders
          if (_row['customer_address'] != '') {
            let _assigned = false;

            this.dataSource.data.push({
              id: _row['customer_order_id'],
              order_type: _row['customer_order_type'],
              customer: _row['customer_full_name'],
              customer_type: _row['customer_plan_type'],
              street_address: _row['customer_address'],
              suburb: _row['suburb'],
              region: _row['region_name'],
              order_date: _row['order_date'],
              bins_to_drop_off: _row['bins_to_drop_off'],
              bins_to_pick_up: _row['bins_to_pick_up'],
              context_menu: '...',
              selected: false,
              order: _row,
              assigned: _row['in_progress']
            });
          }
        }
      }
    } catch (err) {
      console.log('err', err);
    }
    this.selection.clear();
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.appService.appSpinner(false);
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  applyDateFilter(event: any) {
    if (event == '') {
      this.dataSource.filter = event.trim().toLowerCase();
    } else {
      let filterValue = event.value;
      // remove the timezone offset so it's UTC
      let date = new Date(filterValue + filterValue.getTimezoneOffset()).toISOString().slice(0,10)
      this.dataSource.filter = date.trim().toLowerCase();
    }
  }

  resetDateFilter() {
    this.dateInput.value = '';
    this.applyDateFilter('')
  }

    /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    // const numRows = this.dataSource.filteredData.filter((row) => !row.manifest_id).length;
    const numRows = this.dataSource.filteredData.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.filteredData.forEach((row) => {
          this.selection.select(row);
        });
  }

  toggle(row, event) {
    event ? this.selection.toggle(row) : null;
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: TableRow): string {
    if (!row) return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    else return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id + 1}`;
  }

  contextClick(row: any) {
    this._currentContext = row;
  }

  async createManifestByOrders() {
    await this.createManifest(this.selection.selected);
  }

  async cancelOrder() {
    const res = await this.apiService.updateOrder('', 'order_cancel', '', this._currentContext)
    if (res.length == 0) {
      this.appService.sendNotification({
        type: alertAttributes.types.info,
        message: 'Order has been cancelled!',
        body: '',
        displayNotification: true,
        autoCloseAfter: 3
      });
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } else {
      this.appService.sendNotification({
        type: alertAttributes.types.error,
        message: 'Order was NOT cancelled!',
        body: '',
        displayNotification: true,
        autoCloseAfter: 3
      })
    }
  }

  async removeFromManifest() {
    // set remove manifest condition
    let canRemove = true;
    let manifestID = 0;

    // get the depot IDs
    let depotIds = [];
    let user = await this.apiService.getUsers(this.authService.getCurrentUserId())
    for (let depot of user.UsersDepotAccess) {
      depotIds.push(depot.Depot.id)
    };

    // get the manifests
    let manifests = await this.apiService.getManifestsByDepotId(depotIds.toString())

    // get the manifest detail items
    let manifestIds = manifests.map(a => a.manifest_header_id)
    for (let manifestId of manifestIds) {
      let orderFound = false;
      const manifest = await this.apiService.getManifests(manifestId)
      const manifestDetails = manifest[0].ManifestDetails

      for (let manifestDetail of manifestDetails) {
        if (manifestDetail.refCustomerOrder.id == this._currentContext.id) {
          orderFound = true;
        }
      }

      if (orderFound && manifest[0].manifest_status != 'DRAFT') {
        canRemove = false;
      }
    }

    if (canRemove) {
      const res = await this.apiService.removeOrderFromManifest(Number(this._currentContext.id));
      if (res.length == 0) {
        this.appService.sendNotification({
          type: alertAttributes.types.info,
          message: 'Order has been removed from the manifest!',
          body: '',
          displayNotification: true,
          autoCloseAfter: 3
        });
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      } else {
        this.appService.sendNotification({
          type: alertAttributes.types.error,
          message: 'Order could NOT been removed from the manifest!',
          body: '',
          displayNotification: true,
          autoCloseAfter: 3
        })
      }
    } else {
      this.appService.sendNotification({
        type: alertAttributes.types.error,
        message: 'Order can not be removed because manifest is not in DRAFT state!',
        body: '',
        displayNotification: true,
        autoCloseAfter: 3
      })
    }
  }

  async createManifest(orders: TableRow[]) {
    this.appService.appSpinner(true);
    const manifest = {
      code: appUtils.getNowAsCode(),
      rundate: new Date()
    };

    // create draft manifest
    const res = await this.apiService.createManifest(manifest, orders);
    this.appService.appSpinner(false);
    if (res) {
      // pass the selected orders to the next screen to eliminate the need to reload the order information
      // from the backend again
      this.appService.manifest = res;
      this.appService.manifestOrders = orders;
      await this.router.navigate(['../' + appParam.routing.A3_manifestManagement.A3001], { relativeTo: this.route });
    } else {
      this.errHandler.handleErrors({
        type: alertAttributes.types.error,
        displayMessage: alertAttributes.alerts.A050,
        displayNotification: true,
        errorObject: {}
      });
    }
  }

  async contextSelected(event: any) {
    if (event == 'create-manifest') {
      let selectedOrders = await this.dataSource.data.filter((i) => i.selected === true);
      // console.log('manifest items', selectedOrders)

      if (selectedOrders.length == 0) {
        this.errHandler.handleErrors({
          type: alertAttributes.types.error,
          displayMessage: alertAttributes.alerts.A049,
          displayNotification: true,
          errorObject: {}
        });
        return;
      }

      this.appService.appSpinner(true);

      const manifest = {
        code: appUtils.getNowAsCode(),
        rundate: new Date()
      };

      // create draft manifest
      const res = await this.apiService.createManifest(manifest, selectedOrders);
      // console.log(res)

      if (res.length == 0) {
        this.appService.appSpinner(false);

        this.errHandler.handleErrors({
          type: alertAttributes.types.error,
          displayMessage: alertAttributes.alerts.A050,
          displayNotification: true,
          errorObject: {}
        });
        return;
      }

      // pass the selected orders to the next screen to eliminate the need to reload the order information
      // from the backend again
      this.appService.manifest = res;
      this.appService.manifestOrders = selectedOrders;

      this.router.navigate(['../' + appParam.routing.A3_manifestManagement.A3001], { relativeTo: this.route });

      this.appService.appSpinner(false);
    }
  }
}
