import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { alertAttributes } from '../helper/appAlert';
import { appErrorHandler } from '../helper/appErrorHandler';
import { appParam } from '../helper/appSettings';
import { AppService } from './app.service';
import { ICustomerOrder, IOptionType } from '../helper/appInterfaces';
import { environment as env } from 'src/environments/environment';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  errHandler: appErrorHandler;
  apiUrl: string;

  constructor(public httpClient: HttpClient, private appService: AppService) {
    this.errHandler = new appErrorHandler(this.appService);
    this.apiUrl = appParam.apiBase;
  }


  // 2021-10-25 CJ: API + Cognito auth integration
  async getAuthDocs(_method: any, _query: any, _apiBase: string = ''): Promise<any[]> {
    // switch between Admin and Customer API when required.
    if (_apiBase == '') {
      _apiBase = env.apiBaseCustomer;
    }
    return await this.httpClient
      .post<any>(_apiBase + '/' + _method, _query, {
        headers: new HttpHeaders({
          Authorization: await this.appService.getCache(appParam.cacheKeys.authToken)
        })
      })
      .toPromise();
  }

  async getDocs(_method: any, _query: any, _apiBase: string = ''): Promise<any> {
    try {
      // switch between Admin and Customer API when required.
      if (_apiBase == '') {
        _apiBase = this.apiUrl;
      }
      return await this.httpClient
        .post<any>(_apiBase + '/' + _method, _query, {
          headers: new HttpHeaders({
            Authorization: await this.appService.getCache(appParam.cacheKeys.authToken)
          })
        })
        .toPromise();
    } catch (err) {
      console.error(err);
      return [0];
    }
  }

  async sendDocs(_method: any, _query: any, _apiBase: string = '', _isSignup: boolean = false): Promise<any[]> {
    // 2021-11-19 CJ: bug fix for api calls when user is signing up
    let res;

    if (_apiBase == '') {
      _apiBase = this.apiUrl;
    }

    if (_isSignup == false) {
      res = await this.httpClient
        .post<any>(_apiBase + '/' + _method, _query, {
          headers: new HttpHeaders({
            Authorization: await this.appService.getCache(appParam.cacheKeys.authToken)
          })
        })
        .toPromise();
    } else {
      res = await this.httpClient.post<any>(this.apiUrl + '/' + _method, _query).toPromise();
    }
    return res;
  }

  //  get bins
  async getBins(): Promise<any[]> {
    return this.getDocs('getBins', { body: { userId: this.appService.UserObject.userId } }).then(data => {
      return data[0];

    }).catch(err => {
      this.errHandler.handleErrors({
        type: alertAttributes.types.error,
        displayMessage: alertAttributes.alerts.A001,
        displayNotification: true,
        errorObject: {}
      });
      return [];
    });
  }

  //  update delivery dates
  async updateAvailableDates(): Promise<any> {
    return this.getDocs('updateAvailableDates', {}).then(data => {
      return data[0];

    }).catch(err => {
      this.errHandler.handleErrors({
        type: alertAttributes.types.error,
        displayMessage: alertAttributes.alerts.A058,
        displayNotification: true,
        errorObject: {}
      });
      return [];
    });
  }

  //  get customer(s)
  async getCustomer(_customer_id: string = ''): Promise<any[]> {
    return this.getDocs('getCustomer', {
      body: {
        userId: this.appService.UserObject.userId,
        customer_id: _customer_id
      }
    }).then(data => {
      return data[0];

    }).catch(err => {
      this.errHandler.handleErrors({
        type: alertAttributes.types.error,
        displayMessage: alertAttributes.alerts.A002,
        displayNotification: true,
        errorObject: {}
      });
      return [];
    });
  }

  async getCustomerTransactionLogByIdAndType(_customerOrderId: number, _customerOrderStatusId: number): Promise<any> {
    return this.getDocs('getCustomerTransactionLog', {
      body: {
        orderId: _customerOrderId,
        orderStatusId: _customerOrderStatusId
      }
    })
      .then((data) => data[0])
      .catch((err) => {
        this.errHandler.handleErrors({
          type: alertAttributes.types.error,
          displayMessage: 'Cannot get customer transaction log!',
          displayNotification: true,
          errorObject: {}
        });
        return [];
      });
  }

  async getCustomerOrders(_customerId: string): Promise<any> {
    return this.getDocs('getCustomerOrders', { body: { userId: _customerId } })
      .then((data) => data[0])
      .catch((err) => {
        this.errHandler.handleErrors({
          type: alertAttributes.types.error,
          displayMessage: 'Cannot get customer orders!',
          displayNotification: true,
          errorObject: {}
        });
      });
  }

  async getInProgressOrdersByDepot(_depotId: string): Promise<any> {
    return this.getDocs('getInProgressOrdersByDepot', { body: { depotId: _depotId } })
      .then((data) => data[0])
      .catch((err) => {
        this.errHandler.handleErrors({
          type: alertAttributes.types.error,
          displayMessage: 'Cannot get the in progress orders by depot ID!',
          displayNotification: true,
          errorObject: {}
        });
        return [];
      });
  }

  async createSuburbInRegion(_suburbName: string, _postcode: string, _depotId: string, _stateAbbr: string): Promise<any> {
    return this.getDocs('createSuburbInRegion', {
      body: {
        suburbName: _suburbName, postcode: _postcode, depotId: _depotId, stateAbbr: _stateAbbr
      }
    })
      .then((data) => data[0])
      .catch((err) => {
        this.errHandler.handleErrors({
          type: alertAttributes.types.error,
          displayMessage: 'Cannot create suburb!',
          displayNotification: true,
          errorObject: {}
        });
        return [];
      });
  }

  async getCustomersByDepot(depotIds: string,
                            page: number = 1,
                            pageSize: number = 10,
                            sortColumn: string = 'id',
                            sortDirection: string = 'desc',
                            searchTerm: string = null): Promise<any> {
    const defaultResponse: any = { data: [], total: 0 };
    return this.getDocs('getCustomersByDepot', {
      body: {
        depotId: depotIds,
        page: page,
        size: pageSize,
        sortColumn: sortColumn,
        sortDirection: sortDirection,
        searchTerm: searchTerm
      }
    })
      .then((response) => response)
      .catch((err) => {
        this.errHandler.handleErrors({
          type: alertAttributes.types.error,
          displayMessage: 'Cannot get customers by depot ID!',
          displayNotification: true,
          errorObject: err
        });
        return defaultResponse;
      });
  }

  async getSuburbsByDepot(_unassigned: boolean, _depotId: string, _weekdayId: string): Promise<any> {
    return this.getDocs('getSuburbsByDepotDay', {
      body: {
        unassigned: _unassigned, depotId: _depotId, weekdayId: _weekdayId
      }
    })
      .then((data) => data[0])
      .catch((err) => {
        this.errHandler.handleErrors({
          type: alertAttributes.types.error,
          displayMessage: 'Cannot get suburbs by depot weekday!',
          displayNotification: true,
          errorObject: {}
        });
        return [];
      });
  }

  async updateDepotDaySuburbs(_depotDayId: string, _suburbIds: string): Promise<any> {
    return this.getDocs('updateDepotDaySuburbs', {
      body: {
        depotDayId: _depotDayId, suburbIds: _suburbIds
      }
    })
      .then((data) => data[0])
      .catch((err) => {
        this.errHandler.handleErrors({
          type: alertAttributes.types.error,
          displayMessage: 'Cannot update suburb config!',
          displayNotification: true,
          errorObject: {}
        });
        return [];
      });
  }

  async getPublicHolidaysByDepot(_depotId: string): Promise<any> {
    return this.getDocs('getPublicHolidaysByDepot', { body: { depotId: _depotId } })
      .then((data) => data[0])
      .catch((err) => {
        this.errHandler.handleErrors({
          type: alertAttributes.types.error,
          displayMessage: 'Cannot get public holidays by depot ID!',
          displayNotification: true,
          errorObject: {}
        });
        return [];
      });
  }

  async getDateInstancesWithDetail(_depotId: string): Promise<any> {
    return this.getDocs('getDateInstancesWithDetail', { body: { depotId: _depotId } })
      .then((data) => data[0])
      .catch((err) => {
        this.errHandler.handleErrors({
          type: alertAttributes.types.error,
          displayMessage: 'Cannot get date instances!',
          displayNotification: true,
          errorObject: {}
        });
        return [];
      });
  }

  async toggleAvailableDateBlockFlag(_depotId: string, _availableDate: string): Promise<any> {
    return this.getDocs('toggleAvailableDateBlockFlag', { body: { depotId: _depotId, availableDate: _availableDate } })
      .then((data) => data[0])
      .catch((err) => {
        this.errHandler.handleErrors({
          type: alertAttributes.types.error,
          displayMessage: 'Cannot toggle block order!',
          displayNotification: true,
          errorObject: {}
        });
        return [];
      });
  }

  async createDepotDayException(
    _depotDayId: string, _date: string, _description: string, _dropoffs: string, _changeovers: string): Promise<any> {
    return this.getDocs('createDepotDayException', {
      body: {
        depotDayId: _depotDayId, date: _date, description: _description, dropoffs: _dropoffs, changeovers: _changeovers
      }
    })
      .then((data) => data[0])
      .catch((err) => {
        this.errHandler.handleErrors({
          type: alertAttributes.types.error,
          displayMessage: 'Cannot create depot date exception!',
          displayNotification: true,
          errorObject: {}
        });
        return [];
      });
  }

  async getServiceDayConfig(_depotId: string): Promise<any> {
    return this.getDocs('getServiceDayConfig', { body: { depotId: _depotId } })
      .then((data) => data[0])
      .catch((err) => {
        this.errHandler.handleErrors({
          type: alertAttributes.types.error,
          displayMessage: 'Cannot get service day config by depot ID!',
          displayNotification: true,
          errorObject: {}
        });
        return [];
      });
  }

  async updateServiceDayConfig(_depotDayId: number, _newDropoffs: number, _newChangeovers: number, _effectiveDate: string): Promise<any> {
    return this.getDocs('updateServiceDayConfig', {
      body: {
        depotDayId: _depotDayId,
        newDropoffs: _newDropoffs,
        newChangeovers: _newChangeovers,
        effectiveDate: _effectiveDate
      }
    })
      .then((data) => data[0])
      .catch((err) => {
        this.errHandler.handleErrors({
          type: alertAttributes.types.error,
          displayMessage: 'Cannot update service day config!',
          displayNotification: true,
          errorObject: {}
        });
        return [];
      });
  }

  async updatePublicHolidaysLimits(_holidayId: number, _depotId: number, _dropoffLimit: number, _changeoverLimit: number): Promise<any> {
    return this.getDocs('updatePublicHolidaysOrderLimits', {
      body: {
        holidayId: _holidayId, depotId: _depotId, dropoffLimit: _dropoffLimit, changeoverLimit: _changeoverLimit
      }
    })
      .then((data) => data[0])
      .catch((err) => {
        this.errHandler.handleErrors({
          type: alertAttributes.types.error,
          displayMessage: 'Cannot update public holiday order limits!',
          displayNotification: true,
          errorObject: {}
        });
        return [];
      });
  }

  async getDriversByDepot(_depotId: string): Promise<any> {
    return this.getDocs('getDriversByDepot', { body: { depotId: _depotId } })
      .then((data) => data[0])
      .catch((err) => {
        this.errHandler.handleErrors({
          type: alertAttributes.types.error,
          displayMessage: 'Cannot get drivers by depot ID!',
          displayNotification: true,
          errorObject: {}
        });
        return [];
      });
  }

  async deleteManifest(_manifestId: number): Promise<any> {
    return this.getDocs('deleteManifest', { body: { manifestId: _manifestId } })
      .then((data) => data[0])
      .catch((err) => {
        this.errHandler.handleErrors({
          type: alertAttributes.types.error,
          displayMessage: 'Cannot delete manifest!',
          displayNotification: true,
          errorObject: {}
        });
        return err;
      });
  }

  async deleteCustomer(_userId: string, _email: string): Promise<any> {
    return this.getDocs('deleteCustomer', { body: { userId: _userId, email: _email } })
      .then((data) => data[0])
      .catch((err) => {
        this.errHandler.handleErrors({
          type: alertAttributes.types.error,
          displayMessage: 'Cannot delete customer!',
          displayNotification: true,
          errorObject: {}
        });
        return err;
      });
  }

  //  get customer by email
  async getCustomerByEmail(): Promise<any> {
    const query = { body: { email: this.appService.UserObject.email } };
    return this.getDocs('getCustomer', query).then(data => {
      return data[0].length ? data[0][0] : null;
    }).catch(err => {
      this.errHandler.handleErrors({
        type: alertAttributes.types.error,
        displayMessage: alertAttributes.alerts.A002,
        displayNotification: true,
        errorObject: { error: err }
      });
      return null;
    });
  }

  async getCustomerOrderCreationAvail(_customerId: string): Promise<any> {
    return this.getDocs('getCusOrderAvail', {
      body: {
        userId: this.appService.UserObject.userId,
        customerId: _customerId
      }
    }).then(data => {
      return data[0];

    }).catch(err => {
      this.errHandler.handleErrors({
        type: alertAttributes.types.error,
        displayMessage: 'Cannot get Customer Order Availability!',
        displayNotification: true,
        errorObject: {}
      });
      return [];
    });
  }

  async sendSMS(_message: string, _number: string): Promise<any> {
    return this.sendDocs('sendSMS', { body: { message: _message, phoneNumber: _number } })
      .then((data) => {
        return data[0];
      })
      .catch((err) => {
        this.errHandler.handleErrors({
          type: alertAttributes.types.error,
          displayMessage: 'Cannot send SMS',
          displayNotification: true,
          errorObject: {}
        });
        return [];
      });
  }

  //  get all subscription (order)
  async getAllCustomerSubs(): Promise<any[]> {
    return this.getDocs('getOrders', { body: { userId: this.appService.UserObject.userId } }).then(data => {
      return data[0];

    }).catch(err => {
      this.errHandler.handleErrors({
        type: alertAttributes.types.error,
        displayMessage: alertAttributes.alerts.A003,
        displayNotification: true,
        errorObject: {}
      });
      return [];
    });
  }


  //  get customer subscription (order)
  async getCustomerSubs(_customer_id: string = ''): Promise<any[]> {
    return this.getDocs('getOrders', {
      body: {
        userId: this.appService.UserObject.userId,
        filter_by: 'customer',
        customer_id: _customer_id
      }
    }).then(data => {
      return data[0];

    }).catch(err => {
      this.errHandler.handleErrors({
        type: alertAttributes.types.error,
        displayMessage: alertAttributes.alerts.A004,
        displayNotification: true,
        errorObject: {}
      });
      return [];
    });
  }


  //  get customer subscription (order)
  async getCustomerSub(_id: string = ''): Promise<any[]> {
    return this.getDocs('getOrders', {
      body: {
        userId: this.appService.UserObject.userId,
        order_id: _id
      }
    }).then(data => {
      return data[0];

    }).catch(err => {
      this.errHandler.handleErrors({
        type: alertAttributes.types.error,
        displayMessage: alertAttributes.alerts.A005,
        displayNotification: true,
        errorObject: {}
      });
      return [];
    });
  }


  //  get announcements
  async getAnnouncements(_announcement_id: string = '', _region_id: string = '', _announcement_type: string = '', _filter_by: string = ''): Promise<any[]> {
    return this.getDocs('getAnnouncements', {
      body: {
        userId: this.appService.UserObject.userId,
        filter_by: _filter_by,
        region_id: _region_id,
        announcement_type: _announcement_type,
        announcement_id: _announcement_id
      }
    }).then(data => {

      return data[0];

    }).catch(err => {
      this.errHandler.handleErrors({
        type: alertAttributes.types.error,
        displayMessage: alertAttributes.alerts.A006,
        displayNotification: true,
        errorObject: {}
      });
      return [];
    });
  }

  async getManifestMedia(_orderId: number): Promise<any[]> {
    return this.getDocs('getManifestMedia', {
      body: { order_id: _orderId }
    })
      .then((data) => {
        return data[0];
      })
      .catch((err) => {
        this.errHandler.handleErrors({
          type: alertAttributes.types.error,
          displayMessage: alertAttributes.alerts.A057,
          displayNotification: true,
          errorObject: {}
        });
        return [];
      });
  }

  //  get drivers
  async getDrivers(_driver_id: string = ''): Promise<any[]> {
    return this.getDocs('getDrivers', {
      body: {
        userId: this.appService.UserObject.userId,
        driver_id: _driver_id
      }
    }).then(data => {

      return data[0];

    }).catch(err => {
      this.errHandler.handleErrors({
        type: alertAttributes.types.error,
        displayMessage: alertAttributes.alerts.A007,
        displayNotification: true,
        errorObject: {}
      });
      return [];
    });
  }


  //  get regions
  async getRegions(_state_id: string = '', _region_id: string = ''): Promise<any[]> {
    return this.getDocs('getRegions', {
      body: {
        userId: this.appService.UserObject.userId,
        state_id: _state_id,
        region_id: _region_id
      }
    }).then(data => {

      return data[0].map((item: any) => {
        return {
          key: item.id,
          code: item.code,
          desc: item.desc
        };
      });

    }).catch(err => {
      this.errHandler.handleErrors({
        type: alertAttributes.types.error,
        displayMessage: alertAttributes.alerts.A009,
        displayNotification: true,
        errorObject: {}
      });
      return [];
    });
  }


  //  get depots
  async getDepots(_region_id: string = ''): Promise<any[]> {
    return this.getDocs('getDepots', {
      body: {
        userId: this.appService.UserObject.userId,
        region_id: _region_id
      }
    }).then(data => {

      return data[0].map((item: any) => {
        return {
          key: item.id,
          code: item.code,
          desc: item.desc
        };
      });

    }).catch(err => {
      this.errHandler.handleErrors({
        type: alertAttributes.types.error,
        displayMessage: alertAttributes.alerts.A010,
        displayNotification: true,
        errorObject: {}
      });
      return [];
    });
  }


  //  get container types
  async getAdminContainerTypes(_region_id: string = ''): Promise<any[]> {
    return this.getDocs('getAdminContainerTypes', {
      body: {
        userId: this.appService.UserObject.userId,
        region_id: _region_id
      }
    }).then(data => {

      return data[0].map((item: any) => {
        return {
          key: item.id,
          code: item.code,
          desc: item.desc
        };
      });

    }).catch(err => {
      this.errHandler.handleErrors({
        type: alertAttributes.types.error,
        displayMessage: alertAttributes.alerts.A011,
        displayNotification: true,
        errorObject: {}
      });
      return [];
    });
  }


  //  create bin
  async createBin(_bin: any): Promise<any> {
    return this.getDocs('createBin', { body: { userId: this.appService.UserObject.userId, bin: _bin } }).then(data => {
      return data[0];

    }).catch(err => {
      this.errHandler.handleErrors({
        type: alertAttributes.types.error,
        displayMessage: alertAttributes.alerts.A012,
        displayNotification: true,
        errorObject: {}
      });
      return [];
    });
  }

  //  remove order from manifest
  async removeOrderFromManifest(_orderId: number): Promise<any> {
    return this.getDocs('removeOrderFromManifest', {
      body: {
        userId: this.appService.UserObject.userId,
        orderId: _orderId
      }
    }).then(data => {
      return data[0];

    }).catch(err => {
      this.errHandler.handleErrors({
        type: alertAttributes.types.error,
        displayMessage: 'Could not remove order from manifest!',
        displayNotification: true,
        errorObject: {}
      });
      return err;
    });
  }


  //  create driver
  async createDriver(_driver: any): Promise<any[]> {
    return this.getDocs('createDriver', {
      body: {
        userId: this.appService.UserObject.userId,
        driver: _driver
      }
    }).then(data => {
      return data[0];

    }).catch(err => {
      this.errHandler.handleErrors({
        type: alertAttributes.types.error,
        displayMessage: alertAttributes.alerts.A013,
        displayNotification: true,
        errorObject: {}
      });
      return [];
    });
  }


  //  create customer
  async createCustomer(_user_id: string, _customer: any): Promise<any[]> {
    return this.getDocs('createCustomer', { body: _customer }).then(data => {
      return data[0];

    }).catch(err => {
      this.errHandler.handleErrors({
        type: alertAttributes.types.error,
        displayMessage: alertAttributes.alerts.A014,
        displayNotification: true,
        errorObject: {}
      });
      return [];
    });
  }


  //  create driver in cognito
  async createDriverInCognito(_driver: any): Promise<any[]> {
    return this.getDocs('createDriverInCognito', {
      body: {
        userId: this.appService.UserObject.userId,
        driver: _driver
      }
    }).then(data => {
      return data[0];

    }).catch(err => {
      this.errHandler.handleErrors({
        type: alertAttributes.types.error,
        displayMessage: alertAttributes.alerts.A015,
        displayNotification: true,
        errorObject: {}
      });
      return [];
    });
  }


  //  create customer in cognito
  async createCustomerInCognito(_customer: any): Promise<any[]> {
    return this.getDocs('createCustomerInCognito', {
      body: {
        userId: this.appService.UserObject.userId,
        customer: _customer
      }
    }).then(data => {
      return data[0];

    }).catch(err => {
      this.errHandler.handleErrors({
        type: alertAttributes.types.error,
        displayMessage: alertAttributes.alerts.A016,
        displayNotification: true,
        errorObject: {}
      });
      return [];
    });
  }


  //  end announcement(s)
  async endAnnouncement(_announcements: any[]): Promise<any[]> {
    return this.getDocs('endAnnouncement', {
      body: {
        userId: this.appService.UserObject.userId,
        announcements: _announcements
      }
    }).then(data => {
      return data[0];

    }).catch(err => {
      this.errHandler.handleErrors({
        type: alertAttributes.types.error,
        displayMessage: alertAttributes.alerts.A018,
        displayNotification: true,
        errorObject: {}
      });
      return [];
    });
  }

  //  offboard driver(s)
  async offboardDriver(_drivers: any[]): Promise<any[]> {
    return this.getDocs('offboardDriver', {
      body: {
        userId: this.appService.UserObject.userId,
        drivers: _drivers
      }
    }).then(data => {
      return data[0];

    }).catch(err => {
      this.errHandler.handleErrors({
        type: alertAttributes.types.error,
        displayMessage: alertAttributes.alerts.A019,
        displayNotification: true,
        errorObject: {}
      });
      return [];
    });
  }


  //  get bin history
  async getBinHistory(_id: string): Promise<any[]> {
    return this.getDocs('getHistory', {
      body: {
        userId: this.appService.UserObject.userId,
        type: 'bin',
        id: _id
      }
    }).then(data => {

      return data[0].map((item: any) => {
        return {
          key: item.id,
          created_at: item.created_at,
          log_details_1: item.log_details_1,
          log_details_2: item.log_details_2
        };
      });

    }).catch(err => {
      this.errHandler.handleErrors({
        type: alertAttributes.types.error,
        displayMessage: alertAttributes.alerts.A020,
        displayNotification: true,
        errorObject: {}
      });
      return [];
    });
  }


  //  get driver history
  async getDriverHistory(_id: string): Promise<any[]> {
    return this.getDocs('getHistory', {
      body: {
        userId: this.appService.UserObject.userId,
        type: 'driver',
        id: _id
      }
    }).then(data => {

      return data[0].map((item: any) => {
        return {
          key: item.id,
          created_at: item.created_at,
          log_details_1: item.log_details_1,
          log_details_2: item.log_details_2
        };
      });

    }).catch(err => {
      this.errHandler.handleErrors({
        type: alertAttributes.types.error,
        displayMessage: alertAttributes.alerts.A021,
        displayNotification: true,
        errorObject: {}
      });
      return [];
    });
  }


  //  create/update announcement
  async createAnnouncement(_announcement: any): Promise<any[]> {
    return this.getDocs('createAnnouncement', {
      body: {
        userId: this.appService.UserObject.userId,
        announcement: _announcement
      }
    }).then(data => {
      return data[0];

    }).catch(err => {
      this.errHandler.handleErrors({
        type: alertAttributes.types.error,
        displayMessage: alertAttributes.alerts.A022,
        displayNotification: true,
        errorObject: {}
      });
      return [];
    });
  }


  //  get all addressed for this customer
  async getCustomerAddresses(_user_id: string): Promise<IOptionType[]> {
    return this.getDocs('getCustomerAddresses', { body: { userId: _user_id } }).then(data => {

      // map the incoming data into Key/Description
      return data[0].map((item: any) => {
        return {
          key: item.id,
          description: item.desc,
          postcode: item.post_code,
          state: item.state,
          lat: item.lat,
          lng: item.lng,
          checked: false
        };
      });


    }).catch(err => {
      this.errHandler.handleErrors({
        type: alertAttributes.types.error,
        displayMessage: alertAttributes.alerts.A023,
        displayNotification: true,
        errorObject: {}
      });
      return [];
    });
  }


  async updateCustomerAddress(_data: any): Promise<any> {
    return this.getDocs('updateCustomerAddress', { body: _data }).then(data => {
      return data[0];

    }).catch(err => {
      this.errHandler.handleErrors({
        type: alertAttributes.types.error,
        displayMessage: alertAttributes.alerts.A024,
        displayNotification: true,
        errorObject: {}
      });
      return [];
    });
  }


  async getAvailableDates(
    _refSuburbId: number,
    _firstDrop: boolean
  ): Promise<any[]> {
    return this.getDocs('getAvailableDates', {
      body: {
        userId: this.appService.UserObject.userId,
        refSuburbId: _refSuburbId,
        firstDrop: _firstDrop
      }
    })
      .then((data) => {
        // map the incoming data into Key/Description
        return data[0].map((item: any) =>
          moment(item.available_dates, 'YYYY-MMM-DD').toDate()
        );
      })
      .catch((err) => {
        this.errHandler.handleErrors({
          type: alertAttributes.types.error,
          displayMessage: alertAttributes.alerts.A025,
          displayNotification: true,
          errorObject: {}
        });
        return [];
      });
  }

  async getAvailablePlans(): Promise<any[]> {
    return this.getDocs('getAvailablePlans', {
      body: { userId: this.appService.UserObject.userId }
    })
      .then((data) => {
        return data[0];
      })
      .catch((err) => {
        this.errHandler.handleErrors({
          type: alertAttributes.types.error,
          displayMessage: alertAttributes.alerts.A059,
          displayNotification: true,
          errorObject: {}
        });
        return [];
      });
  }

  async createPlan(_planName: string, _description: string, _category: string, _binLimit: string, _binFee: string,
                   _handlingFee: string, _instalmentAmount: string, _membershipFee: string, _perContainerRefund: string,
                   _directCollectCommission: string, _displayOnUI: boolean): Promise<any> {
    return this.getDocs('createPlan', {
      body: {
        plan_name: _planName,
        description: _description,
        category: _category,
        bin_limit: _binLimit,
        bin_fee: _binFee,
        handling_fee: _handlingFee,
        instalment_amount: _instalmentAmount,
        membership_fee: _membershipFee,
        per_container_refund: _perContainerRefund,
        direct_collect_commission: _directCollectCommission,
        display_on_ui: _displayOnUI
      }
    })
      .then((data) => {
        return data[0];
      })
      .catch((err) => {
        console.error(err);
        this.errHandler.handleErrors({
          type: alertAttributes.types.error,
          displayMessage: 'Could not create plan!',
          displayNotification: true,
          errorObject: {}
        });
        return [];
      });
  }

  async editPlan(_planName: string, _description: string, _category: string, _binLimit: string, _binFee: string,
                 _handlingFee: string, _instalmentAmount: string, _membershipFee: string, _perContainerRefund: string,
                 _directCollectCommission: string, _displayOnUI: boolean, _planId): Promise<any> {
    return this.getDocs('updatePlan', {
      body: {
        plan_name: _planName, description: _description, category: _category, bin_limit: _binLimit, bin_fee: _binFee,
        handling_fee: _handlingFee, instalment_amount: _instalmentAmount, membership_fee: _membershipFee,
        per_container_refund: _perContainerRefund, direct_collect_commission: _directCollectCommission,
        display_on_ui: _displayOnUI, plan_id: _planId
      }
    })
      .then((data) => {
        return data[0];
      })
      .catch((err) => {
        console.error(err);
        this.errHandler.handleErrors({
          type: alertAttributes.types.error,
          displayMessage: 'Could not edit plan!',
          displayNotification: true,
          errorObject: {}
        });
        return [];
      });
  }


  //  container types (BIns/Drums)
  async getContainerTypes(_code: string = ''): Promise<IOptionType[]> {
    return this.getDocs('getContainerTypes', {
      body: { userId: this.appService.UserObject.userId, code: _code }
    })
      .then((data) => {
        return data[0].map((item: any) => {
          return {
            key: item.id,
            description: item.desc
          };
        });
      })
      .catch((err) => {
        console.error(err);
        this.errHandler.handleErrors({
          type: alertAttributes.types.error,
          displayMessage: 'Could not get container types',
          displayNotification: true,
          errorObject: {}
        });
        return [];
      });
  }


  async getSubscriptionTypes(_category: string = ''): Promise<IOptionType[]> {
    return this.getDocs(
      'getSubscriptionTypes',
      { body: { userId: this.appService.UserObject.userId, category: _category } }
    )
      .then((data) => {
        // map the incoming data into Key/Description
        return data[0].map((item: any) => {
          return {
            key: item.id,
            description: item.desc,
            body: item.bodyText,
            code: item.code,
            bin_limit: item.bin_limit,
            bin_fee: item.bin_fee,
            membership_fee: item.membership_fee,
            instalment_amount: item.instalment_amount,
            category: item.category,
            checked: false
          };
        });
      })
      .catch((err) => {
        this.errHandler.handleErrors({
          type: alertAttributes.types.error,
          displayMessage: alertAttributes.alerts.A027,
          displayNotification: true,
          errorObject: {}
        });
        return [];
      });
  }

  //  get customer subscription types
  async getPlanResetDetails(_customerId: string): Promise<any> {
    return this.getDocs('getPlanResetDetails', { body: { customerId: _customerId } }).then(data => {
      return data[0];
    }).catch(err => {
      this.errHandler.handleErrors({
        type: alertAttributes.types.error,
        displayMessage: alertAttributes.alerts.A028,
        displayNotification: true,
        errorObject: {}
      });
      return [];
    });
  }

  async updateCustomerToNewPlan(_customerId: string, _planId: string, _refundAmount: number, _tcAccepted: boolean, _attributes: object): Promise<any> {
    return this.getDocs('updateCustomerToNewPlan', {
      body: {
        customerId: _customerId,
        planId: _planId,
        refundAmount: _refundAmount,
        tcAccepted: _tcAccepted,
        attributes: _attributes
      }
    }).then(data => {
      return data[0];
    }).catch(err => {
      this.errHandler.handleErrors({
        type: alertAttributes.types.error,
        displayMessage: alertAttributes.alerts.A060,
        displayNotification: true,
        errorObject: {}
      });
      return [];
    });
  }

  //  get customer subscription types
  async getOrderTypes(_user_id: string, _category: string = ''): Promise<IOptionType[]> {
    return this.getDocs('getOrderTypes', { body: { userId: _user_id, category: _category } }).then(data => {

      // map the incoming data into Key/Description
      return data[0].map((item: any) => {
        return {
          key: item.id,
          code: item.code
        };
      });


    }).catch(err => {
      this.errHandler.handleErrors({
        type: alertAttributes.types.error,
        displayMessage: alertAttributes.alerts.A028,
        displayNotification: true,
        errorObject: {}
      });
      return [];
    });
  }

  //  get BSB listing
  async getBSB(): Promise<any[]> {
    return this.getDocs('getBSB', { body: { userId: this.appService.UserObject.userId } })
      .then((data) => {
        // map the incoming data into Key/Description
        return data[0].map((item: any) => {
          return {
            id: item.id,
            bsb: item.code,
            branchName: item.desc,
            bankCode: item.bank_code,
            state: item.state
          };
        });
      }).catch(err => {
        this.errHandler.handleErrors({
          type: alertAttributes.types.error,
          displayMessage: alertAttributes.alerts.A030,
          displayNotification: true,
          errorObject: {}
        });
        return [];
      });
  }


  //  get all charities or individual charity by passing the charity CODE NOT ID
  async getCharities(_user_id: string, _charityId: string = '', _state_id: string = ''): Promise<IOptionType[]> {
    return this.getDocs('getCharities', {
      body: {
        userId: _user_id,
        charityId: _charityId,
        state_id: _state_id
      }
    }).then(data => {

      // map the incoming data into Key/Description
      if (data[0].length > 1) {
        //  multiple charities
        return data[0].map((item: any) => {
          return {
            key: item.id,
            description: item.desc,
            body: item.bodyText,
            icon: item.logo,
            checked: false
          };
        });
      } else {
        //  single charity
        return data.map((item: any) => {
          return {
            key: item.id,
            description: item.desc,
            body: item.bodyText,
            icon: item.logo,
            checked: false
          };
        });
      }

    }).catch(err => {
      console.error(err);
      this.errHandler.handleErrors({
        type: alertAttributes.types.error,
        displayMessage: alertAttributes.alerts.A031,
        displayNotification: true,
        errorObject: {}
      });
      return [];
    });
  }


  async createCustomerOrder(_user_id: string, _order: ICustomerOrder): Promise<any> {
    const payload = { body: { userId: _user_id, order: _order } };
    return this.getDocs('createCustomerOrder', payload).then(data => {
      return data[0];

    }).catch(err => {
      this.errHandler.handleErrors({
        type: alertAttributes.types.error,
        displayMessage: alertAttributes.alerts.A032,
        displayNotification: true,
        errorObject: {}
      });
      return [];
    });
  }


  //  get customer orders for pickup request
  async getCustomerOrdersForPickupRequest(_user_id: string): Promise<any[]> {
    return this.getDocs('getCustomerOrdersForPickups', { body: { userId: _user_id } }).then(data => {
      return data[0];

    }).catch(err => {
      this.errHandler.handleErrors({
        type: alertAttributes.types.error,
        displayMessage: alertAttributes.alerts.A033,
        displayNotification: true,
        errorObject: {}
      });
      return [];
    });
  }


  //  get customer pickup request
  async getCustomerPickupRequests(_user_id: string, _pending: boolean): Promise<any[]> {
    return this.getDocs('getCustomerPickupRequests', { body: { userId: _user_id, pending: _pending } }).then(data => {
      return data[0];

    }).catch(err => {
      this.errHandler.handleErrors({
        type: alertAttributes.types.error,
        displayMessage: alertAttributes.alerts.A034,
        displayNotification: true,
        errorObject: {}
      });
      return [];
    });
  }


  //  update order (edit, cancel...etc)
  async updateOrder(_user_id: string, _update_type: string, _update_field: string, _update_values: any): Promise<any[]> {
    return this.getDocs('updateOrder', {
      body: {
        userId: _user_id,
        type: _update_type,
        update: _update_field,
        order: _update_values
      }
    }).then(data => {
      return data[0];

    }).catch(err => {
      this.errHandler.handleErrors({
        type: alertAttributes.types.error,
        displayMessage: alertAttributes.alerts.A035,
        displayNotification: true,
        errorObject: {}
      });
      return [];
    });
  }


  async createPaymentIntent(
    _username: string,
    _amount: number,
    _membershipFee: number,
    _additionalFee: number
  ): Promise<any> {
    return this.getDocs('createPaymentIntent', {
      body: {
        userId: this.appService.UserObject.userId,
        username: _username,
        amount: _amount,
        membershipFee: _membershipFee,
        additionalFee: _additionalFee
      }
    })
      .then((data) => {
        return data[0];
      })
      .catch((err) => {
        this.errHandler.handleErrors({
          type: alertAttributes.types.error,
          displayMessage: alertAttributes.alerts.A036,
          displayNotification: true,
          errorObject: {}
        });
        return [];
      });
  }

  async getAddressTypes(_isSignup: boolean = false): Promise<IOptionType[]> {
    //  user id is empty since this function is called during sign-up where the user/customer does not
    //  have a region/state assgined yet
    return this.getDocs('getAddressTypes', { body: { userId: '' } })
      .then((data) => {
        // map the incoming data into Key/Description
        return data[0].map((item: any) => {
          return {
            key: item.id,
            description: item.desc,
            checked: false
          };
        });
      })
      .catch((err) => {
        this.errHandler.handleErrors({
          type: alertAttributes.types.error,
          displayMessage: alertAttributes.alerts.A037,
          displayNotification: true,
          errorObject: {}
        });
        return [];
      });
  }


  async updateCustomerDetails(_data: any): Promise<any> {
    return this.getDocs('updateCustomerDetails', { body: _data }).then(data => {
      return data[0];
    }).catch(err => {
      console.error(err);
      this.errHandler.handleErrors({
        type: alertAttributes.types.error,
        displayMessage: alertAttributes.alerts.A038,
        displayNotification: true,
        errorObject: {}
      });
      return [];
    });
  }


  async createManifest(_manifest: any, _orders: any): Promise<any> {
    return this.getDocs('createManifest', {
      body: {
        userId: this.appService.UserObject.userId,
        manifest: _manifest,
        orders: _orders
      }
    }).then(data => {
      return data[0];

    }).catch(err => {
      this.errHandler.handleErrors({
        type: alertAttributes.types.error,
        displayMessage: alertAttributes.alerts.A040,
        displayNotification: true,
        errorObject: {}
      });
      return [];
    });
  }


  async updateManifest(_update_type: any, _update_details: any): Promise<any> {
    return this.getDocs('updateManifest', { body: { update: _update_type, manifest: _update_details } }).then(data => {
      return data[0];

    }).catch(err => {
      this.errHandler.handleErrors({
        type: alertAttributes.types.error,
        displayMessage: alertAttributes.alerts.A041,
        displayNotification: true,
        errorObject: {}
      });
      return [];
    });
  }


  async getManifests(_manifest_id: any): Promise<any> {
    return this.getDocs('getManifests', { body: { manifest_id: _manifest_id } }).then(data => {
      return data[0];

    }).catch(err => {
      this.errHandler.handleErrors({
        type: alertAttributes.types.error,
        displayMessage: alertAttributes.alerts.A042,
        displayNotification: true,
        errorObject: {}
      });
      return [];
    });
  }

  async getManifestsByDepotId(_depotIds: any): Promise<any> {
    return this.getDocs('getManifests', { body: { manifest_id: '', depotId: _depotIds } }).then(data => {
      return data[0];
    }).catch(err => {
      this.errHandler.handleErrors({
        type: alertAttributes.types.error,
        displayMessage: 'Cannot get manifest by depot ID',
        displayNotification: true,
        errorObject: {}
      });
      return [];
    });
  }


  async getOrdersByManifest(_manifest_id: any): Promise<any> {
    return this.getDocs('getOrders', { body: { manifest_id: _manifest_id } }).then(data => {
      return data[0];

    }).catch(err => {
      this.errHandler.handleErrors({
        type: alertAttributes.types.error,
        displayMessage: alertAttributes.alerts.A043,
        displayNotification: true,
        errorObject: {}
      });
      return [];
    });
  }


  async getUsers(_user_id: any): Promise<any> {
    return this.getDocs('getUsers', { body: { user_id: _user_id } }).then(data => {
      return data[0];

    }).catch(err => {
      this.errHandler.handleErrors({
        type: alertAttributes.types.error,
        displayMessage: alertAttributes.alerts.A044,
        displayNotification: true,
        errorObject: {}
      });
      return [];
    });
  }


  // async uploadCDSData(_data: any): Promise<any> {
  //   return this.getDocs('uploadCDSData', { body: { rows: _data }, }).then(data => {
  //     return data[0];
  //
  //   }).catch(err => {
  //     this.errHandler.handleErrors({
  //       type: alertAttributes.types.error, displayMessage: alertAttributes.alerts.A044, displayNotification: true, errorObject: {}
  //     });
  //     return [];
  //   });
  // }


  async startRouteOptimization(_jobDef: any): Promise<any> {
    return this.getDocs('startRouteOptimization', { body: { job: _jobDef } }).then(data => {
      return data[0];

    }).catch(err => {
      this.errHandler.handleErrors({
        type: alertAttributes.types.error,
        displayMessage: alertAttributes.alerts.A045,
        displayNotification: true,
        errorObject: {}
      });
      return [];
    });
  }

  async getRouteOptimizationJObStatus(_job_id: any): Promise<any> {
    return this.getDocs('checkRouteOptimization', { body: { job_id: _job_id } }).then(data => {
      return data[0];

    }).catch(err => {
      this.errHandler.handleErrors({
        type: alertAttributes.types.error,
        displayMessage: alertAttributes.alerts.A046,
        displayNotification: true,
        errorObject: {}
      });
      return [];
    });
  }


  //  get records for ABA
  async getRecordsForABA(): Promise<any[]> {
    return this.getDocs('getRecordsForABA', { body: { userId: this.appService.UserObject.userId } }).then(data => {

      return data[0];

    }).catch(err => {
      this.errHandler.handleErrors({
        type: alertAttributes.types.error,
        displayMessage: alertAttributes.alerts.A007,
        displayNotification: true,
        errorObject: {}
      });
      return [];
    });
  }


  //  generate aba file
  async generateABAFile(_depotId: number): Promise<any[]> {
    return this.getDocs('generateABA', {
      body: {
        userId: this.appService.UserObject.userId,
        depotId: _depotId
      }
    }).then(data => {
      return data[0];

    }).catch(err => {
      this.errHandler.handleErrors({
        type: alertAttributes.types.error,
        displayMessage: alertAttributes.alerts.A019,
        displayNotification: true,
        errorObject: {}
      });
      return [];
    });
  }


  //  get aba file
  async getABAFile(_batch_id: any[]): Promise<any[]> {
    return this.getDocs('getABAFile', {
      body: {
        userId: this.appService.UserObject.userId,
        batch_id: _batch_id
      }
    }).then(data => {
      return data[0];

    }).catch(err => {
      this.errHandler.handleErrors({
        type: alertAttributes.types.error,
        displayMessage: alertAttributes.alerts.A019,
        displayNotification: true,
        errorObject: {}
      });
      return [];
    });
  }


}
