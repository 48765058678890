<div class="container-fluid byc-main-container">
  <!-- Header -->
  <!-- --------------------------------------------- -->
  <div class="row py-2 {{ this._style }}-text-title">
    <div class="col-4">New</div>
    <div class="col-4">Today - Active</div>
    <div class="col-4">Previous 5 Business Days</div>
  </div>

  <!-- Content -->
  <!-- --------------------------------------------- -->
  <div class="row py-2">
    <!-- New -->
    <!-- --------------------------------------------- -->
    <div class="col-4">
      <app-manifest
        *ngFor="let manifest of _newManifests"
        [_manifest]="manifest"
        [_drivers]="this.drivers"
      ></app-manifest>
    </div>

    <!-- Active -->
    <!-- --------------------------------------------- -->
    <div class="col-4">
      <app-manifest
        *ngFor="let manifest of _activeManifests"
        [_manifest]="manifest"
        [_drivers]="this.drivers"
      ></app-manifest>
    </div>

    <!-- Prev -->
    <!-- --------------------------------------------- -->
    <div class="col-4">
      <app-manifest
        *ngFor="let manifest of _prevManifests"
        [_manifest]="manifest"
        [_drivers]="this.drivers"
      ></app-manifest>
    </div>

    <!--  -->
  </div>
</div>
