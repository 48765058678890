<form [formGroup]="frmGroup" fxLayout="column">
  <div fxLayout="row" fxLayoutGap="50px">
    <div fxLayout="column" [style.min-width.px]="250">
      <div class="mat-title">Customer Details</div>

      <!-- Customer details -->
      <mat-form-field appearance="outline">
        <mat-label>First name</mat-label>
        <input
          matInput
          value=""
          data-cy="firstName"
          id="firstName"
          formControlName="firstName" />
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Last name</mat-label>
        <input
          matInput
          value=""
          data-cy="lastName"
          id="lastName"
          formControlName="lastName" />
        <mat-error>Required</mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Mobile</mat-label>
        <input
          placeholder="+61"
          matInput
          value=""
          data-cy="mobile"
          id="mobile"
          formControlName="mobile" />
        <mat-error *ngIf="this.frmGroup.controls.mobile.errors?.required"
          >required</mat-error
        >
        <mat-error
          *ngIf="
            this.frmGroup.controls.mobile.errors?.pattern ||
            this.frmGroup.controls.mobile.errors?.format
          ">
          invalid format
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Email</mat-label>
        <input
          matInput
          value=""
          data-cy="email"
          id="email"
          formControlName="email"/>
        <mat-error *ngIf="this.frmGroup.controls.email.errors?.required"
          >required</mat-error
        >
        <mat-error *ngIf="this.frmGroup.controls.email.errors?.email"
          >invalid format</mat-error
        >
      </mat-form-field>
    </div>

    <!-- Location sub type -->
    <div fxLayout="column" [style.min-width.px]="250">
      <div class="mat-title">Location and Subscription</div>

      <mat-form-field appearance="outline">
        <mat-label>Old address</mat-label>
        <input matInput value="{{ this.oldAddress }}" [disabled]="true" />
      </mat-form-field>

      <app-new-address-picker
        id="addressPicker"
        (addressPicked)="addressPicked($event)"></app-new-address-picker>

      <mat-form-field appearance="outline">
        <mat-label>Address Type</mat-label>
        <mat-select formControlName="addressType">
          <mat-option
            *ngFor="let addresstype of this.addressTypeItems"
            [value]="addresstype.key">
            {{ addresstype['description'] }}
          </mat-option>
        </mat-select>
        <mat-error>Required</mat-error>
      </mat-form-field>

      <!--        <div>Subscription type?</div>-->
      <!--        <div>-->
      <!--          <app-item-picker-->
      <!--            [_items]="this.subscriptionTypeItems"-->
      <!--            (itemPicked)="subscriptionTypePicked($event)"-->
      <!--          ></app-item-picker>-->
      <!--        </div>-->
      <mat-form-field appearance="outline">
        <mat-label>Subscription Type</mat-label>
        <mat-select formControlName="subscriptionType" [disabled]="true">
          <mat-option
            *ngFor="let subscriptionType of this.subscriptionTypeItems"
            [value]="subscriptionType.key">
            {{ subscriptionType['description'] }}
          </mat-option>
        </mat-select>
        <mat-error>Required</mat-error>
      </mat-form-field>

      <button mat-raised-button color="primary" (click)="openChangeCustomerPlanDialog()">Change Plan</button>
    </div>

    <div fxLayout="column" [style.min-width.px]="250" *ngIf="isCharity">
      <div class="mat-title">Charity Details</div>
      <mat-form-field appearance="outline" *ngIf="this.isCharity">
        <mat-label>Charity</mat-label>
        <mat-select formControlName="charity" [disabled]="true">
          <mat-option *ngFor="let item of this.charityItems" [value]="item.key">
            {{ item.description }}
          </mat-option>
        </mat-select>
        <mat-error>Required</mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline" *ngIf="this.isCharity">
        <mat-label>Tax invoice email</mat-label>
        <input
          matInput
          [(ngModel)]="this.frmGroup.value.email"
          value=""
          data-cy="taxInvoiceEmail"
          id="taxInvoiceEmail"
          formControlName="taxInvoiceEmail" />
      </mat-form-field>
    </div>

    <!-- Bank account information -->
    <div fxLayout="column" [style.min-width.px]="250" *ngIf="!isCharity">
      <div class="mat-title">Bank Details</div>
      <mat-form-field appearance="outline">
        <mat-label>Account name</mat-label>
        <input
          matInput
          value=""
          data-cy="accountName"
          id="accountName"
          formControlName="accountName" />
        <mat-error>Required</mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Account number</mat-label>
        <input
          matInput
          value=""
          data-cy="accountNumber"
          id="accountNumber"
          formControlName="accountNumber" />
        <mat-error>Required</mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>BSB</mat-label>
        <input
          matInput
          value=""
          data-cy="accountBSB"
          id="accountBSB"
          formControlName="accountBSB" />
        <mat-error *ngIf="this.frmGroup.controls.accountBSB.errors?.pattern"
          >Invalid format</mat-error
        >
        <mat-error>Required</mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Bank</mat-label>
        <input
          matInput
          value=""
          data-cy="bankName"
          id="bankName"
          [(ngModel)]="this.bankName"
          formControlName="bankName" />
        <mat-error>Required</mat-error>
      </mat-form-field>
    </div>
  </div>
  <div fxLayout="row" fxLayoutGap="50px">
    <div fxLayout="column" [style.min-width.px]="250">
      <div class="mat-title">Notes</div>
      <mat-form-field appearance="outline">
        <mat-label>Customer notes</mat-label>
        <textarea
          matInput
          [matTextareaAutosize]="true"
          formControlName="notes"></textarea>
      </mat-form-field>
    </div>
    <div fxLayout="column" [style.min-width.px]="250">
      <mat-form-field class="mt-5" appearance="outline">
        <mat-label>Admin notes</mat-label>
        <textarea
          matInput
          [matTextareaAutosize]="true"
          formControlName="adminNotes"></textarea>
      </mat-form-field>
    </div>
    <div fxLayout="column" [style.min-width.px]="250">
      <mat-form-field class="mt-5" appearance="outline">
        <mat-label>Driver notes</mat-label>
        <textarea
          matInput
          [matTextareaAutosize]="true"
          formControlName="driverNotes"></textarea>
      </mat-form-field>
    </div>
  </div>

  <div fxLayoutAlign="center center" fxLayoutGap="20px">
    <button mat-raised-button color="primary" mat-dialog-close style="width: 150px;">Cancel</button>
    <button mat-raised-button id="save" (click)="save()" data-cy="save" [disabled]="!frmGroup.valid" color="primary"
      style="width: 150px;"
    >Save</button>
  </div>
</form>
