<!--<div class="container-fluid m-0 my-1 p-1 px-2 w-100">-->
<!--  <div class="row py-2">-->
<!--    <div class="col-7">{{ _stop["address"] }}</div>-->
<!--    <div class="col-3">{{ _arrivalTime }} - {{ _departureTime }}</div>-->
<!--    <div class="col-1">-->
<!--      <img-->
<!--        *ngIf="_stop['orderStatus'] == 'COMPLETE'"-->
<!--        class="img-24"-->
<!--        src="../../assets/images/DIRECT-COLLECT-ICONS-ALERT-SUCCESS.png"-->
<!--        alt="img bin"-->
<!--      />-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->

<mat-selection-list [multiple]="false" [disabled]="!_clickable">
  <mat-list-option>
    <mat-list-item>
      <div class="col-8">{{ _stop["address"] }}</div>
      <div class="col-4">{{ _arrivalTime }} - {{ _departureTime }}</div>
      <div class="col-1">
        <img
          *ngIf="_stop['orderStatus'] == 'COMPLETE'"
          class="img-24"
          src="../../assets/images/DIRECT-COLLECT-ICONS-ALERT-SUCCESS.png"
          alt="img bin"
        />
      </div>
    </mat-list-item>
  </mat-list-option>
</mat-selection-list>
