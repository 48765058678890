import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { IAddress } from 'src/app/helper/appInterfaces';
import { ApiService } from 'src/app/services/api.service';
import { AppService } from 'src/app/services/app.service';
import { AuthService } from 'src/app/services/auth.service';
import { appUtils } from '../../helper/appUtils'

@Component({
  selector: 'app-customer-subscription',
  templateUrl: './customer-subscription.component.html',
  styleUrls: ['./customer-subscription.component.scss']
})
export class CustomerSubscriptionComponent implements OnInit {
  _style: string = '';

  @Input() _customerId: any = '';
  _editMode: boolean = false; //  for edit driver purpose

  frmGroup: FormGroup;
  _prevId: string = '';

  _subscriptions: any[] = [];

  constructor(private fg: FormBuilder,
    private appService: AppService,
    private apiService: ApiService,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute) {
    this._style = this.authService.getStyle()
  }

  async ngOnInit() {
    this.frmGroup = this.fg.group({
      id: ['', Validators.required],
      subscription_type: ['', Validators.required],
      bin_number: ['', Validators.required],
      qr_Code: ['', Validators.required],
      bin_type: ['', Validators.required],
      payment: ['', Validators.required],
      status: ['', Validators.required],
      charity: ''
    })

    this.frmGroup.valueChanges.subscribe((value) => {
      this.valueChanged(value)
    })

    // this._customerId = this.route.snapshot.queryParamMap.get('id')
    await this.getCustomerSubs()
  }

  async getCustomerSubs() {
    this.appService.appSpinner(true)

    if (this._customerId != '') {
      let resCustomerSubs = await this.apiService.getCustomerSubs(this._customerId)
      console.log(resCustomerSubs)
      this._subscriptions = resCustomerSubs
      // console.log('resCustomerSubs', resCustomerSubs)
    }
    this.appService.appSpinner(false)
  }

  async getData(_id: any) {
    this.appService.appSpinner(true)

    // TODO: get the customer subscription types

    if (_id != '') {
      let resCustomerSub = await this.apiService.getCustomerSub(_id)

      if (resCustomerSub != undefined) {
        const editCustomerSub = resCustomerSub[0]
        // console.log('editCustomerSub', editCustomerSub)

        this.frmGroup.controls['subscription_type'].setValue(editCustomerSub['refSubscriptionType']['desc'])

        if (editCustomerSub['refBin'] != null) {
          this.frmGroup.controls['bin_number'].setValue(editCustomerSub['refBin']['code'])
          this.frmGroup.controls['qr_Code'].setValue(editCustomerSub['refBin']['qr_code'])
        }

        if (editCustomerSub['refContainerType'] != null) {
          this.frmGroup.controls['bin_type'].setValue(editCustomerSub['refContainerType']['desc'])
        }

        if (editCustomerSub['payment'] != null) {
          this.frmGroup.controls['payment'].setValue(editCustomerSub['refPaymentType']['desc'])
        }

        if (editCustomerSub['refCharity'] != null) {
          this.frmGroup.controls['charity'].setValue(editCustomerSub['refCharity']['desc'])
        }

        this.frmGroup.controls['status'].setValue(editCustomerSub['order_status'])

        this._editMode = true;
      }
    }

    this.appService.appSpinner(false)
  }


  async valueChanged(frmValues: any) {

    // get regions
    if (frmValues.id != '' && this._prevId != frmValues.id) {
      this.appService.appSpinner(true)
      this._prevId = frmValues.id // prevent a recursive loop
      this.getData(frmValues.id)
      this.appService.appSpinner(false)
    }

  }

}
