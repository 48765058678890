// 2021-08-12 CJ: This file contains all the attributes used for alerts (info, warning and errors)

import { appParam } from './appSettings';

export const alertAttributes = {
  types: {
    error: -1,
    info: 0,
    warning: 1,
    success: 2,
    workflowSteps: appParam.routing
  },
  alerts: {
    A001: 'A001 - Cannot get list of bins!',
    A002: 'A002 - Cannot get list of customers!',
    A003: 'A003 - Cannot get list of orders!',
    A004: 'A004 - Cannot get list of customer orders!',
    A005: 'A005 - Cannot get order details!',
    A006: 'A006 - Cannot get list of announcements!',
    A007: 'A007 - Cannot get list of drivers!',
    A008: 'A008 - Cannot get list of states!',
    A009: 'A009 - Cannot get list of regions!',
    A010: 'A010 - Cannot get list of depots!',
    A011: 'A011 - Cannot get list of container types!',
    A012: 'A012 - Cannot create bin!',
    A013: 'A013 - Cannot create driver!',
    A014: 'A014 - Cannot create customer!',
    A015: 'A015 - Cannot create driver login!',
    A016: 'A016 - Cannot create customer login!',
    A017: 'A017 - Cannot decommission this bin!',
    A018: 'A018 - Cannot end this announcement!',
    A019: 'A019 - Cannot offboard this driver!',
    A020: 'A020 - Cannot get bin history!',
    A021: 'A021 - Cannot get driver history!',
    A022: 'A022 - Cannot create announcement!',
    A023: 'A023 - Cannot get customer address(s)!',
    A024: 'A024 - Cannot update customer address!',
    A025: 'A025 - Cannot get list of available dates for pickup/drop off!',
    A026: 'A026 - Cannot get list container types!',
    A027: 'A027 - Cannot get list subscription types!',
    A028: 'A028 - Cannot get list order types!',
    A029: 'A029 - Cannot get list payment types!',
    A030: 'A030 - Cannot get list of BSB!',
    A031: 'A031 - Cannot get list of charities!',
    A032: 'A032 - Cannot create the customer order!',
    A033: 'A033 - Cannot get list of customer order(s) for pickup!',
    A034: 'A034 - Cannot get list of customer pickup request(s)!',
    A035: 'A035 - Cannot update order!',
    A036: 'A036 - Cannot payment intent!',
    A037: 'A037 - Cannot list of location types!',
    A038: 'A038 - Cannot update customer details!',
    A039: 'A039 - Cannot update customer location type!',
    A040: 'A040 - Cannot create manifest!',
    A041: 'A041 - Cannot update manifest!',
    A042: 'A042 - Cannot list of manifests!',
    A043: 'A043 - Cannot list of orders!',
    A044: 'A044 - Cannot list of users!',
    A045: 'A045 - Cannot start manifest route optimization!',
    A046: 'A046 - Cannot check manifest route optimization status!',

    A047: 'Route has been submitted for optimization...',
    A048: 'Could not start Route optimization...',

    A049: 'Please select orders first',
    A050: 'Could not create the manifest at this time!',

    A051: 'Manifest depot notes has been updated',
    A052: 'Manifest run date has been updated',
    A053: 'Manifest driver has been updated',

    A054: 'CDS file has been uploaded',
    A055: 'Could not upload the CDS file!',
    A056: 'ABA file has been generated for the selected records.',

    A057:  'C031 - Cannot get manifest media',

    A058:  'A058 - Number of weeks to generate data not provided',

    A059: 'Cannot get plans from database!',
    A060: 'Cannot update customer plan!'
  }
};
