<div class="container-fluid byc-main-container">
  <!--  -->
  <div class="row py-2">
    <div class="col-1"></div>
    <!--  -->
    <!-- Orders -->
    <div class="col-3 px-3 {{ this._style }}-text-title">Bin locations</div>

    <!--  -->
    <!-- Pickup date -->
    <div class="col-3 px-3 {{ this._style }}-text-title">Pick up date</div>

    <!--  -->
    <!-- Order details -->
    <div class="col-4 px-3 {{ this._style }}-text-title">Order Details</div>
  </div>

  <!--  -->
  <div class="row py-2">
    <div class="col-1"></div>

    <!-- Location -->
    <!-- ------------------------------------------------------- -->
    <div class="col-3 px-2 byc-new-order-panel">
      <div class="container-fluid {{ this._style }}-main-panel">
        <!--  -->
        <div class="row">
          <div class="col">
            <app-item-and-number-picker
              [_items]="this.binLocations"
              (itemPicked)="binLocationPicked($event)"
              #addressPicker>
            </app-item-and-number-picker>
          </div>
        </div>
      </div>
    </div>

    <!-- ------------------------------------------------------- -->
    <!-- Pick up date -->
    <div class="col-3 px-3">
      <div class="container-fluid {{ this._style }}-main-panel">
        <!--  -->
        <div class="row">
          <div class="col py-2">
            <app-date-picker
              (mDatePicked)="datePicked($event)"
              [_availableDate]="this.availableDates"
              [_selectedDate]="this.selectedDate">
            </app-date-picker>
          </div>
        </div>
      </div>
    </div>

    <!-- ------------------------------------------------------- -->
    <!-- Pick up order summary -->
    <div class="col-4 px-3">
      <div class="container-fluid {{ this._style }}-main-panel">
        <div class="row mt-2 my-1">
          <div class="col py-1">
            <div
              class="container-fluid h-100 {{ this._style }}-main-panel {{
                this._style
              }}-panel">
              <div class="row py-2 {{ this._style }}-text-panel-subtitle">
                <span class="{{ this._style }}-text-heavy px-0"
                  >Pick up date</span
                >
              </div>
              <div class="row py-2 {{ this._style }}-text-panel-subtitle">
                {{ this.selectedDate }}
              </div>
            </div>
          </div>
        </div>

        <div class="row my-1">
          <div class="col py-1">
            <div
              class="container-fluid h-100 {{ this._style }}-main-panel {{
                this._style
              }}-panel">
              <div class="row py-2 {{ this._style }}-text-panel-subtitle">
                <span class="{{ this._style }}-text-heavy px-0"
                  >Pick up locations</span
                >
              </div>
              <div class="row py-2 {{ this._style }}-text-panel-subtitle">
                <app-item-and-number-picker
                  [_items]="this.selectedBinLocations"
                  [_readonly]="true">
                </app-item-and-number-picker>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-1"></div>
          <div class="col py-2">
            <app-item-and-number-picker
              [_items]="this.finalPickup"
              (itemPicked)="finalPickupSelected($event)">
            </app-item-and-number-picker>
          </div>
          <div class="col-1"></div>
        </div>

        <!--  -->
        <div class="row">
          <div class="col">
            <button
              mat-raised-button
              class="d-block {{ this._style }}-next {{
                this._style
              }}-primary mt-2"
              (click)="placeOrder()">
              Place order
            </button>
          </div>

          <!--  -->
        </div>

        <!--  -->
      </div>
    </div>
  </div>
</div>
