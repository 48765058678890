import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { appParam } from 'src/app/helper/appSettings';
import { AppService } from 'src/app/services/app.service';
import { AuthService } from 'src/app/services/auth.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-uxa1002',
  templateUrl: './uxa1002.component.html',
  styleUrls: ['./uxa1002.component.scss']
})
export class Uxa1002Component implements OnInit {
  _currentApplicationVersion = environment.appVersion;
  _style: string = '';

  public email: string = '';

  public userId: string = '';
  public pwd: string = ''

  constructor(
    public appService: AppService,
    public authService: AuthService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this._style = this.authService.getStyle()

  }

  ngOnInit(): void {
  }


  async resetPassword() {
    this.appService.appSpinner(true)
    // const res = await this.authService.changePassword(this.email, this.pwd)
    this.appService.appSpinner(false)

    // if (res == false) { return }
    // this.router.navigate(["/" + appParam.routing.A1_signIn.A1001], {
    //   relativeTo: this.route,
    // });

  }


}
