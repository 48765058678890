<div fxLayout="column">
  <div fxLayout="row" fxLayoutGap="10px" class="mx-3 mt-3">
    <div>
      <button mat-raised-button color="primary" [disabled]="true" (click)="openAddCustomerDialog()">
        <mat-icon>add</mat-icon>
        Create Commercial Customer
      </button>
    </div>
    <mat-form-field fxFlex="grow">
      <mat-label>Search</mat-label>
      <mat-icon matPrefix>search</mat-icon>
      <input matInput (keyup)="applyFilter($event)" placeholder="E.g. Scott" />
    </mat-form-field>
  </div>

  <table mat-table [dataSource]="dataSource"
         matSort
         [matSortDirection]="currentSortDirection"
         [matSortActive]="currentSortColumn"
         (matSortChange)="sortData($event)">
  >
    <!-- Checkbox Column -->
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>ID</th>
      <td mat-cell *matCellDef="let element">
        {{ element.id }}
      </td>
    </ng-container>

    <!-- Customer customer_type Column -->
    <ng-container matColumnDef="customer_type">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Type</th>
      <td mat-cell *matCellDef="let element">
        {{ element.customer_type }}
      </td>
    </ng-container>

    <!-- First Name Column -->
    <ng-container matColumnDef="first_name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>First name</th>
      <td mat-cell *matCellDef="let element">
        {{ element.first_name }}
      </td>
    </ng-container>

    <!-- Last name Column -->
    <ng-container matColumnDef="last_name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Last name</th>
      <td mat-cell *matCellDef="let element">
        {{ element.last_name }}
      </td>
    </ng-container>

    <!-- User id Column -->
    <ng-container matColumnDef="customer_id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>User id</th>
      <td mat-cell *matCellDef="let element">
        {{ element.customer_id }}
      </td>
    </ng-container>

    <!-- Phone number column -->
    <ng-container matColumnDef="mobile">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Phone Number</th>
      <td mat-cell *matCellDef="let element">
        {{ element.mobile }}
      </td>
    </ng-container>

    <!-- Street address Column -->
    <ng-container matColumnDef="customer_address">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Street address</th>
      <td mat-cell *matCellDef="let element">
        {{ element.customer_address }}
      </td>
    </ng-container>

    <!-- Onboard date Column -->
    <ng-container matColumnDef="onboard_date">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Onboard date</th>
      <td mat-cell *matCellDef="let element">
        {{ element.onboard_date | date: this._defaultDateFormat }}
      </td>
    </ng-container>

    <!-- Onboard date Column -->
    <ng-container matColumnDef="verified">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Verified</th>
      <td mat-cell *matCellDef="let element">
        {{ element.verified.toString().toUpperCase() }}
      </td>
    </ng-container>

    <!-- Context menu Column -->
    <ng-container matColumnDef="context_menu">
      <th mat-header-cell *matHeaderCellDef data-cy="new-bin">Actions</th>
      <td mat-cell *matCellDef="let element" [matMenuTriggerFor]="menu" (click)="contextClick(element)">
        <button mat-icon-button>
          <mat-icon>more_vert</mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>

  <mat-paginator [length]="totalCustomers"
                 [pageSize]="10"
                 [pageSizeOptions]="[10, 20, 50, 100]"
                 showFirstLastButtons>
  </mat-paginator>
</div>

<mat-menu #menu="matMenu">
  <button mat-menu-item (click)="openCustomerCardDialog()">
    <span>Edit Customer</span>
  </button>
  <!--  <button mat-menu-item (click)="disableCustomer()">-->
  <!--    Disable Customer-->
  <!--  </button>-->
  <button mat-menu-item [disabled]="false" (click)="openCreateOrderDialog()">
    Create Order
  </button>
  <button mat-menu-item [disabled]="true" (click)="openChangeOrderDateDialog()">
    Change Order Date
  </button>
  <button mat-menu-item [disabled]="false" (click)="openDeleteCustomerDialog()">
    Delete Customer
  </button>
</mat-menu>
